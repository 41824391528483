import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { faker } from "@faker-js/faker";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";

import ProfileHeader from "../components/profileComponents/ProfileHeader";
import ProfileHeaderSkeleton from "../components/profileComponents/ProfileHeaderSkeleton";
import Feed from "../components/feedComponents/Feed";
import Bio from "../components/profileComponents/Bio";
import PhotosGridCard from "../components/profileComponents/PhotosGridCard";
import Connections from "../components/profileComponents/Connections";
import Records from "../components/profileComponents/recordComponents/Records";
import CreateNewPostButton from "../components/CreateNewPostButton";

import Agenda from "../components/Events/CalendarAndAgenda";
import { GetRecordFolders, GetUserInfo } from "../helper/APIHelper";
import ChooseSharedRecord from "../components/FormComponents/ChooseSharedRecords";

import baseTheme from "../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import CreateFeedbackButton from "../components/CreateFeedbackButton";
import MenuFolderSelector from "../components/profileComponents/encounterComponents/MenuFolderSelector";
import { useAuth } from "../components/AuthProvider";
import { useLocation } from "react-router-dom";
import { GetFilterContext } from "../components/feedComponents/FilterProvider";
import SettingsPage from "./SettingsPage";

function Profile() {
  // Get userID from URL
  //const { userId: memberID } = useParams();

  // Get memberID from location state
  const location = useLocation();
  //const { from } = location !== null ? location.state : "empty_memberID";
  const userID = location.state !== null ? location.state.userID : null;

  // Get requestorID from local storage
  const requestorData = JSON.parse(localStorage.getItem("userInfo"));
  const requestorID = requestorData && requestorData.memberID;

  // Fix this so that it take in from props
  //const memberID = requestorData.memberID;
  const memberID = userID !== null ? userID : requestorData.memberID;

  const [activeTab, setActiveTab] = useState(0); // Default Tab: 0
  const [selectedTable, setSelectedTable] = useState(""); // Table Name for Record Viewer
  const [selectedRecordsCount, setSelectedRecordsCount] = useState(""); // Selected records count for folders
  const [selectedRecords, setSelectedRecords] = useState([]); // Selected records for Sharing Records in record viewer
  const [openPopup, setOpenPopup] = useState(false); // Flag for popup form
  const [userFolders, setUserFolders] = useState(); // Selected folders for Sharing Records

  const [selectedForm, setSelectedForm] = useState(null); // for popup form selection
  const [documentForm, setDocumentForm] = useState(false); // for popup form selection document name

  const [selectedFolders, setSelectedFolders] = useState([]); // for share records popup form
  const [selectedFoldersID, setSelectedFoldersID] = useState([]); // for share records popup form

  const [userData, setUserData] = useState(null); // state to store the fetched user data
  const [loading, setLoading] = useState(true); // state to store whether or not the page is loading

  useEffect(() => {
    // New effect to fetch user data using GetUserInfo API
    const fetchUserData = async () => {
      setLoading(true); //Page is loading
      setActiveTab(0); //Return to first tab
      try {
        const userInfo = await GetUserInfo(memberID);
        setUserData(userInfo);
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Display an error message when the user doesn't exist
        alert("User doesn't exist");
      }
      setLoading(false); //Page is done loading
    };

    fetchUserData();
  }, [memberID]);

  // let memberID = "3-403";
  // let requestorID = "3-403";
  // let username = "w.wolf";
  // let folderName = "Lab Results";

  useEffect(() => {
    recordFolders(memberID, requestorID);
  }, [memberID, requestorID]);

  const recordFolders = async (memberID, requestorID) => {
    let response = await GetRecordFolders(memberID, requestorID);
    // console.log("response ", response );
    setUserFolders(response);
  };

  function handleTabChange(newTab) {
    setActiveTab(newTab);
  }

  function handleOnSelectFolder(folderName) {
    // let selectedFolder = backEndRecords.filter(
    //   (folder) => folder.folderName === folderName
    // );
    // setSelectedRecords(selectedFolder[0].records);
    // console.log(folderName);
  }

  function handleRecordSelection(index, tableName) {
    setSelectedRecordsCount(index);
    setSelectedTable(tableName);
  }

  function handleOnSelectSharedFolders(folders, foldersID) {
    setSelectedFolders(folders);
    setSelectedFoldersID(foldersID);
  }

  function handleShareSelectedRecords(records) {
    setSelectedRecords(records);
  }
  function handleOnAddRecord(folderName) {
    //switch folder name to lowercase
    let switchFolderName = folderName.toLowerCase();

    switch (switchFolderName) {
      case "medications":
        setSelectedForm("medication");
        break;
      case "allergies":
        setSelectedForm("allergy");
        break;
      case "family history":
        setSelectedForm("history");
        break;
      case "sharerecords":
        setSelectedForm("shareRecords");
        break;
      case "shareindividualrecords":
        setSelectedForm("shareIndividualRecords");
        break;
      default:
        setSelectedForm("document");
        setDocumentForm(folderName);
        break;
    }
    setOpenPopup(true);
  }

  function handleClosePopup() {
    setOpenPopup(false);
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // function to console log all state variables
  function logState() {
    console.log("activeTab: ", activeTab);
    console.log("selectedTable: ", selectedTable);
    console.log("selectedRecords: ", selectedRecords);
    console.log("openPopup: ", openPopup);
    console.log("userFolders: ", userFolders);
    console.log("selectedForm: ", selectedForm);
    console.log("documentForm: ", documentForm);
    console.log("selectedFolders: ", selectedFolders);
    console.log("userData: ", userData);
    console.log("loading: ", loading);
  }

  // Modify the user data assignment to use userData from API instead of dummy data
  const user = userData
    ? {
        name: `${userData.firstName} ${userData.lastName}`,
        address: userData.address,
        email: userData.eMail,
        bio: userData.bio,
        coverPhotoURL: faker.image.imageUrl(), // Keep the cover photo from the faker library as it's not available from the API
        profilePictureURL: userData.profilePicture,
        conDetStatus: userData.conDetStatus,
        conDetID: userData.conDetID,
      }
    : null;

  if (loading) {
    return (
      <Box sx={{ flexGrow: 1, padding: 10, paddingTop: 0 }}>
        <ProfileHeaderSkeleton />
        {/* <Typography variant="h6" component="div" marginTop={2}>
          Fetching user data...
        </Typography> */}
      </Box>
    );
  }

  return (
    <ThemeProvider theme={baseTheme}>
      <Box sx={{ flexGrow: 1, padding: 10, paddingTop: 0 }}>
        {/* {logState()} */}
        <ProfileHeader
          onTabChange={handleTabChange}
          toggle={true}
          memberID={memberID}
          requestorID={requestorID}
          name={user.name}
          address={user.address}
          email={user.email}
          coverPhotoURL={user.coverPhotoURL}
          profilePictureURL={user.profilePictureURL}
          conDetStatus={user.conDetStatus}
          conDetID={user.conDetID}
        />
        {activeTab === 0 && (
          <div>
            <Grid container spacing={3}>
              {!isMobile && (
                <Grid item md={5}>
                  <Box
                    sx={{
                      position: "sticky",
                      top: "86px",
                      marginTop: "30px",
                    }}
                  >
                    <Bio bio={user.bio} />
                    <PhotosGridCard
                      imageURLs={[
                        "https://picsum.photos/300/300?random=1",
                        "https://picsum.photos/400/400?random=1",
                        "https://picsum.photos/500/500?random=1",
                        "https://picsum.photos/400/500?random=1",
                        "https://picsum.photos/200/200?random=1",
                        "https://picsum.photos/100/200?random=1",
                        "https://picsum.photos/100/100?random=1",
                        "https://picsum.photos/600/400?random=1",
                        "https://picsum.photos/400/600?random=1",
                        "https://picsum.photos/600/600?random=1",
                        "https://picsum.photos/600/700?random=1",
                        "https://picsum.photos/700/700?random=1",
                      ]}
                      // imageURLs={[
                      //   "https://source.unsplash.com/random/300x300",
                      //   "https://source.unsplash.com/random/400x400",
                      //   "https://source.unsplash.com/random/500x400",
                      //   "https://source.unsplash.com/random/400x500",
                      //   "https://source.unsplash.com/random/500x500",
                      //   "https://source.unsplash.com/random/600x500",
                      //   "https://source.unsplash.com/random/500x600",
                      //   "https://source.unsplash.com/random/600x600",
                      //   "https://source.unsplash.com/random/600x700",
                      //   "https://source.unsplash.com/random/700x600",
                      //   "https://source.unsplash.com/random/700x700",
                      //   "https://source.unsplash.com/random/700x800",
                      // ]}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={7}>
                <Feed onProfile={true} memberID={memberID} />
              </Grid>
            </Grid>
          </div>
        )}

        {activeTab === 1 && <SettingsPage />}
        {/* {activeTab === 1 && <div>Tab 1</div>} */}
        {activeTab === 2 && (
          <Grid style={{ marginTop: "32px" }}>
            <Connections memberID={memberID} />
          </Grid>
        )}
        {activeTab === 3 && <MenuFolderSelector />}
        {activeTab === 4 && (
          <Records
            username={userData.username}
            memberID={memberID}
            requestorID={requestorID}
            openPopup={openPopup}
            handleClosePopup={handleClosePopup}
            handleOnAddRecord={handleOnAddRecord}
            handleOnSelectSharedFolders={handleOnSelectSharedFolders}
            handleShareSelectedRecords={handleShareSelectedRecords}
            selectedForm={selectedForm}
            documentForm={documentForm}
            userFolders={userFolders}
            selectedTable={selectedTable}
            setSelectedTable={setSelectedTable}
            selectedFolders={selectedFolders}
            setSelectedFolders={setSelectedFolders}
            selectedFoldersID={selectedFoldersID}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
          />
        )}
        {activeTab === 5 && <Agenda />}
      </Box>
      {/* <CreateNewPostButton /> */}
      <CreateFeedbackButton />
    </ThemeProvider>
  );
}

export default Profile;
