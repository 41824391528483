import React, { useState } from "react";
import {
    ArrowBack,
    ArrowForward,
} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from "@mui/material";
import './Index.css';

const CustomToolbar = props => {
    const { onNavigate, onView } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handlePrev = () => {
        onNavigate("PREV");
    };

    const handleNext = () => {
        onNavigate("NEXT");
    };
    const handleToday = () => {
        onNavigate("TODAY");
        toggleMenu();
    };
    const handleChangeView = (view) => {
        onView(view);
        toggleMenu();
    };
    const toggleMenu = () => {
        console.log("IS OPEN")
        setIsMenuOpen(!isMenuOpen);
    };


    return (
        <div className="rbc-toolbar">
            <Button className="hamburger-menu" onClick={toggleMenu}>
                <MenuIcon />
            </Button>
            <ul className={`toolbar-list ${isMenuOpen ? "open" : ""}`}>
                <li onClick={handleToday}>
                    Today
                </li>
                <li onClick={() => handleChangeView("month")}>Month</li>
                <li onClick={() => handleChangeView("week")}>Week</li>
                <li onClick={() => handleChangeView("day")}>Day</li>
                <li onClick={() => handleChangeView("agenda")}>Agenda</li>
            </ul>
            <div style={{position: "absolute",top: 0,right: 0,}}>
                <Button onClick={handlePrev}>
                    <ArrowBack />
                </Button>
                <Button onClick={handleNext}>
                    <ArrowForward />
                </Button>
                </div>
            <span className="rbc-toolbar-label">{props.label}</span>
        </div>
    );
};

export default CustomToolbar;