export function getDataVisibilityString(value) {
  switch (value) {
    case 0:
      return "Hidden";
    case 1:
      return "Username";
    case 2:
      return "Profile";
    case 10:
      return "Aggregated";
    case 20:
      return "Redacted1";
    case 30:
      return "Redacted2";
    case 50:
      return "Selective1";
    case 51:
      return "Selective2";
    case 9999:
      return "Full";
    default:
      return "";
  }
}

export function getVisibilityValueFromString(visibility) {
  switch (visibility) {
    case "Hidden":
      return 0;
    case "Username":
      return 1;
    case "Profile":
      return 2;
    case "Aggregated":
      return 10;
    case "Redacted1":
      return 20;
    case "Redacted2":
      return 30;
    case "Selective1":
      return 50;
    case "Selective2":
      return 51;
    case "Full":
      return 9999;
    default:
      return -1; // Return a default value to indicate an unknown visibility string
  }
}