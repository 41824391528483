import { React, useEffect, useState } from "react";
import { Chip, Box, Typography, Divider } from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";
import { ThemeProvider } from "@mui/material";
import theme from "../Theme";
import { GetFilters } from "../helper/APIHelper";
import { GetFilterContext } from "./feedComponents/FilterProvider";

function MultipleChipForLeftNavBar() {
  let filterContext = GetFilterContext();

  useEffect(() => {
    const response = GetFeedFilters();
  }, []);

  async function GetFeedFilters() {
    let temp = await GetFilters(false, 8);
    setTagsData(temp.Tags);
  }

  const [tagsData, setTagsData] = useState([]);

  function handleTagClicked(tag) {
    //filterContext.filter.tag = tag;
    filterContext.setTag(tag);
    console.log("Tag Clicked ", tag);
  }

  //style
  return (
    <ThemeProvider theme={theme}>
      <Divider sx={{ marginBottom: "20px", width: "80%", mx: "auto" }} />
      <Typography
        sx={{
          marginLeft: "15px",
          fontWeight: "bold",
          fontSize: "18px",
          marginBottom: "10px",
        }}
      >
        Trending
      </Typography>
      <Box
        sx={{
          justifyContent: "start",
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          margin: "20px",
          marginTop: "5px",
        }}
      >
        {tagsData.map((tag) => (
          <Chip
            key={tag.settingID}
            label={tag.resourceName}
            variant="outlined"
            icon={<TagIcon color="black" fontSize="small" />}
            onClick={() => handleTagClicked(tag.resourceName)}
            sx={{
              height: "28px",
              backgroundColor: "white",
              color: "#7a7a7a",
              border: "1px color gray",
              marginBottom: "15px",
              textAlign: "left",
            }}
          />
        ))}
      </Box>

      {/* <Box
                sx={{
                    justifyContent: 'center',
                    display: "flex",
                    alignItems: 'center',
                    flexDirection: "column",
                    margin:'20px',
                }}>

                {tagsData.map(tag => (
                        <Chip key={tag.settingID} label={tag.resourceName} 
                        variant="outlined" icon={<TagIcon color="primary" />} 
                        sx={{
                            width: 200,
                            height: '27px',
                            fontWeight: 'bold',
                            backgroundColor: 'white',
                            color: '#0e6d73',
                            border: '1px solid black',
                            marginBottom: '20px',
                        }}/>
                    ))}
            </Box> */}

      <Divider sx={{ marginBottom: "20px", width: "80%", mx: "auto" }} />
    </ThemeProvider>
  );
}
export default MultipleChipForLeftNavBar;
