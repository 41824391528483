import { Autocomplete, ThemeProvider } from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../../../Theme";
import { TextField, Button, CardActions, MenuItem, Card } from "@mui/material";
import { GetSettingsByKey, GetCodes } from "../../../helper/APIHelper";

const ICD10ResourcesKey = ["ICD10Codes"];
function EncounterEdit({
  encDate,
  provider,
  encDetType,
  description,
  fromDate,
  serviceProvider,
  toDate,
  value,
  description1,
  explanation,
  range,
  codeID,
  providerID,
  serviceProviderID,
}) {
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState({
    encDate: "",
    provider: "",
    encDetType: "",
    description: "",
    fromDate: "",
    serviceProvider: "",
    toDate: "",
    value: "",
    description1: "",
    explanation: "",
    range: "",
    codeID: "",
    providerID: "",
    serviceProviderID: "",
  });
  const [state, setState] = useState({
    encDate: encDate !== undefined ? encDate.split("T").shift() : "",
    provider: provider !== undefined ? provider : "",
    encDetType: encDetType !== undefined ? encDetType : "",
    description: description !== undefined ? description : "",
    fromDate: fromDate !== undefined ? fromDate.split("T").shift() : "",
    serviceProvider: serviceProvider !== undefined ? serviceProvider : "",
    toDate: toDate !== undefined ? toDate.split("T").shift() : "",
    value: value !== undefined ? value : "",
    description1: description1 !== undefined ? description1 : "",
    explanation: explanation !== undefined ? explanation : "",
    range: range !== undefined ? range : "",
    codeID: codeID !== undefined ? codeID : "",
    providerID: providerID !== undefined ? providerID : "",
    serviceProviderID: serviceProviderID !== undefined ? serviceProviderID : "",
  });

  function handleCancel() {
    setState({
      encDate: encDate !== undefined ? encDate.split("T").shift() : "",
      provider: provider !== undefined ? provider : "",
      encDetType: encDetType !== undefined ? encDetType : "",
      description: description !== undefined ? description : "",
      fromDate: fromDate !== undefined ? fromDate.split("T").shift() : "",
      serviceProvider: serviceProvider !== undefined ? serviceProvider : "",
      toDate: toDate !== undefined ? toDate.split("T").shift() : "",
      value: value !== undefined ? value : "",
      description1: description1 !== undefined ? description1 : "",
      explanation: explanation !== undefined ? explanation : "",
      range: range !== undefined ? range : "",
      codeID: codeID !== undefined ? codeID : "",
      providerID: providerID !== undefined ? providerID : "",
      serviceProviderID:
        serviceProviderID !== undefined ? serviceProviderID : "",
    });
    setSubmit(false);
  }

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }

  const [searchResults, setSearchResults] = useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      let temp = await GetCodes(state.value);
      setSearchResults(temp);
      console.log(temp);
    };
    fetchData();
  }, [state.value]);
  React.useEffect(() => {
    const fetchData = async () => {
      let temp = await GetCodes(state.description1);
      setSearchResults(temp);
      console.log(temp);
    };
    fetchData();
  }, [state.description1]);
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(ICD10ResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [valueSelected, setValueSelected] = useState(true);
  const [descriptionSelected, setDescriptionSelected] = useState(true);
  const handleCodeChange = (event) => {
    console.log("Medication Name Change " + event.target.value);
    // setMedicationName(event.target.value);\
    setState({
      ...state,
      value: event.target.value,
    });
    setValueSelected(false);
  };
  const handleDescriptionChange = (event) => {
    console.log("Medication Name Change " + event.target.value);
    // setMedicationName(event.target.value);\
    setState({
      ...state,
      description1: event.target.value,
    });
    setDescriptionSelected(false);
  };
  const handleSelectCode = (param, desc) => {
    console.log("param " + param);
    console.log("desc " + desc);
    // setMedicationName(param);
    setState({
      ...state,
      value: param,
      description1: desc,
    });
    setValueSelected(true);
    setDescriptionSelected(true);
  };

  async function handleSubmit() {
    console.log(state);
    setSubmit(true);
    let encounterRecord = "";
    if (
      state.encDate !== "" &&
      state.provider !== "" &&
      state.encDetType !== "" &&
      state.description !== "" &&
      state.fromDate !== "" &&
      state.serviceProvider !== "" &&
      state.toDate !== "" &&
      state.value !== "" &&
      state.description1 !== "" &&
      state.explanation !== "" &&
      state.range !== "" &&
      state.codeID !== "" &&
      state.providerID !== "" &&
      state.serviceProviderID !== ""
    ) {
      encounterRecord = {
        encDate: state.encDate,
        provider: state.provider,
        encDetType: state.encDetType,
        description: state.description,
        fromDate: state.fromDate,
        serviceProvider: state.serviceProvider,
        toDate: state.toDate,
        value: state.value,
        description1: state.description1,
        explanation: state.explanation,
        range: state.range,
        codeID: state.codeID,
        providerID: state.providerID,
        serviceProviderID: state.serviceProviderID,
      };
      //   const response = await PostAllergyRecord(allergyRecord);
      //   console.log("response ",response);
      //   if(response.ok === true){
      //     setError("");
      //     handleEditContactInfoClick();
      //   }
      //   else{
      //     if(response.statusText === ""){
      //       setError("Your Request Was Not Fulfilled")
      //     }
      //     else{
      //       setError(response.statusText);
      //     }
      //     handleEditContactInfoClick();
      //   }
    }
  }
  // Update the state when props change
  useEffect(() => {
    setState({
      encDate: encDate !== undefined ? encDate.split("T").shift() : "",
      provider: provider !== undefined ? provider : "",
      encDetType: encDetType !== undefined ? encDetType : "",
      description: description !== undefined ? description : "",
      fromDate: fromDate !== undefined ? fromDate.split("T").shift() : "",
      serviceProvider: serviceProvider !== undefined ? serviceProvider : "",
      toDate: toDate !== undefined ? toDate.split("T").shift() : "",
      value: value !== undefined ? value : "",
      description1: description1 !== undefined ? description1 : "",
      explanation: explanation !== undefined ? explanation : "",
      range: range !== undefined ? range : "",
      codeID: codeID !== undefined ? codeID : "",
      providerID: providerID !== undefined ? providerID : "",
      serviceProviderID:
        serviceProviderID !== undefined ? serviceProviderID : "",
    });
  }, [
    encDate,
    provider,
    encDetType,
    description,
    fromDate,
    serviceProvider,
    toDate,
    value,
    description1,
    explanation,
    range,
    codeID,
    providerID,
    serviceProviderID,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <TextField
        required
        label="ICD10 Code Value"
        variant="outlined"
        size="small"
        value={state.value}
        onChange={handleCodeChange}
        style={{ marginTop: "10px", width: "85%" }}
        // error={submit && !medicationName}
        // disabled={!enableEditing}
        // sx={{
        //   width: "100%",
        //   "& .MuiInputBase-input.Mui-disabled": {
        //     WebkitTextFillColor: "#7A7A7A",
        //   },
        // }}
      />
      {!valueSelected && (
        <Card sx={{ width: "85%" }}>
          <ul>
            {searchResults.map((option) => (
              <MenuItem
                key={option.resourceID}
                onClick={() =>
                  handleSelectCode(option.resourceName, option.textValue)
                }
              >
                {option.resourceName + " " + option.textValue}
              </MenuItem>
            ))}
          </ul>
        </Card>
      )}
      <TextField
        required
        label="ICD10 Code Description"
        variant="outlined"
        size="small"
        value={state.description1}
        onChange={handleDescriptionChange}
        style={{ marginTop: "10px", width: "85%" }}
        // error={submit && !medicationName}
        // disabled={!enableEditing}
        // sx={{
        //   width: "100%",
        //   "& .MuiInputBase-input.Mui-disabled": {
        //     WebkitTextFillColor: "#7A7A7A",
        //   },
        // }}
      />
      {!descriptionSelected && (
        <Card sx={{ width: "85%" }}>
          <ul>
            {searchResults.map((option) => (
              <MenuItem
                key={option.resourceID}
                onClick={() =>
                  handleSelectCode(option.resourceName, option.textValue)
                }
              >
                {option.resourceName + " " + option.textValue}
              </MenuItem>
            ))}
          </ul>
        </Card>
      )}
      <TextField
        label="Explanation"
        size="small"
        variant="outlined"
        name="explanation"
        value={state.explanation}
        onChange={handleChange}
        multiline
        rows={5}
        // error={(submit && !state.username) || (submit && error.usernameExists)}
        // helperText={error.usernameExists}
        style={{ marginTop: "10px", width: "85%" }}
      />
      <div style={{ marginTop: "10px", width: "85%" }}>
        <Button sx={{ fontSize: "1rem", width: "50%" }} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          sx={{ fontSize: "1rem", width: "50%" }}
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </ThemeProvider>
  );
}
export default EncounterEdit;
