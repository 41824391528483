import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreateNewPostButton from "../components/CreateNewPostButton";
import { Typography, TextField ,Button ,Box ,Card ,CardContent ,CardMedia} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../Theme";
import CustomCard from "../components/CustomCard";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";
import { CloudUpload } from '@material-ui/icons';
import ClearIcon from '@mui/icons-material/Clear';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';


const useStyles = makeStyles((theme) => ({
  quillContainer: {
    "& .ql-container.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      padding: "8px",
    },
    "& .ql-container.ql-snow:focus-within": {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.primary.main, 0.1)}`,
    },
    "& .ql-toolbar.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      padding: "4px",
    },
    "& .ql-container.ql-snow .ql-editor": {
      padding: "0",
    },
    // "& .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar button:focus":
    // {
    //   color: theme.palette.primary.main,
    // },

    // "& .ql-toolbar .ql-stroke": {
    //   fill: 'none',
    //   stroke: '#0e6d73',
    // },

    // "& .ql-toolbar .ql-fill": {
    //   fill: '#0e6d73',
    //   stroke: 'none',
    // },
    // "& .ql-toolbar .ql-stroke:hover" :{
    //   fill: '#0e6d73',
    //   stroke: '#0e6d73',
    // },
    // "& .ql-toolbar .ql-fill:hover" :{
    //   fill: '#0e6d73',
    //   stroke: '#0e6d73',
    // },
    // "& .ql-toolbar .ql-stroke:focus" :{
    //   fill: '#0e6d73',
    //   stroke: '#0e6d73',
    // },
    // ".ql-toolbar .ql-fill:focus" :{
    //   fill: '#0e6d73',
    //   stroke: '#0e6d73',
    // },
    // "& .ql-toolbar .ql-stroke:active" :{
    //   fill: '#0e6d73',
    //   stroke: '#0e6d73',
    // },
    // ".ql-toolbar .ql-fill:active" :{
    //   fill: '#0e6d73',
    //   stroke: '#0e6d73',
    // },
    // "& .ql-editor ":{

    // },
  },
}));

function ArticleEditor() {

  const classes = useStyles(theme);

  const [state, setState] = useState({
    title: "",
  })
  const [richTextBox, setRichTextBox] = useState("")
  const [error, setError] = useState("");

  function handleChange(event) {
    console.log("event.target.name ", event.target.name);
    console.log("event.target.event ", event.target.event);
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value
    });
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  const excludedFileNames = ['mp4', 'mp3', 'lock', 'CON', 'PRN', 'AUX', 'NUL'];
  const excludedPrefixes = ['COM', 'LPT', '_vti_', '~$'];
  const [uploadedFiles, setUploadedFiles] = useState([]);

  function handleFileUpload(files) {
      setError("");
      const existingFileNames = uploadedFiles.map((file) => file.name.toLowerCase());
      const validFiles = Array.from(files).filter((file) => {
          const fileName = file.name.toLowerCase();
          const fileExtension = fileName.split('.').pop();
          if (excludedFileNames.includes(fileExtension) ||
              excludedPrefixes.some((prefix) => fileName.startsWith(prefix))) {
              setError(`The File "${file.name}" Type Is Not Valid`)
          }
          else if (file.size > state.maxSize) {
              setError(`The File "${file.name}" Size Is Too Large`);
          }
          else if (file.size > state.maxSize) {
              setError(`The File "${file.name}" Size Is Too Large`);
          }
          else if (existingFileNames.includes(fileName)) {
              setError(`The File "${file.name}" Has Already Been Uploaded`);
          }
          else {
              return (
                  !excludedFileNames.includes(fileExtension) &&
                  !excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
              );
          }
      });
      if (validFiles.length + uploadedFiles.length > state.maxNumber) {
          setError(`You can only upload up to "${state.maxNumber}" files`);
      } else {
          setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
      }
  }

  function byteToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }

  const handleDeleteFile = (fileToDelete) => {
      setUploadedFiles((prevFiles) => {
          return prevFiles.filter((file) => file !== fileToDelete);
      });
  };

  function handleDrop(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      handleFileUpload(files);
  }

  function handleFileInputChange(e) {
    const files = e.target.files;
    handleFileUpload(files);
}

  return (
    <ThemeProvider theme={theme}>
      <CustomCard title="Create New Article">
        <TextField required label="Enter Title" size="small" variant="outlined"
          name="title"
          value={state.title}
          onChange={handleChange}
          // error={( submit && !state.username )}
          style={{ marginTop: "10px", marginBottom: "10px", width: '85%' }}
        />
        <ReactQuill
          className={classes.quillContainer}
          style={{
            width: '85%',
            height: "150px",
            marginBottom: "32px",
            fontFamily: 'inherit',
            fontSize: 'inherit',
            backgroundColor: 'inherit',
            borderRadius: '10px',
            margin: 'auto'
          }}
          theme="snow"
          value={richTextBox}
          onChange={setRichTextBox}
          modules={modules}
          formats={formats}
        />
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
          multiple
        />
        <div
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={handleDrop}
          style={{
            border: `2px dashed ${error ? 'red' : 'gray'}`,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
            width: "85%",
            margin:'auto',
            marginTop:'20px',
            marginBottom:'20px',
            backgroundColor: '#f1f1f1',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        >
          <p style={{ margin: '0', fontSize: '18px' }}>Drag and drop article header photo</p>
          <p style={{ margin: '0', fontSize: '14px' }}>OR</p>

          <Button
            variant="contained"
            color={uploadedFiles.length === state.maxNumber ? "error" : "primary"}
            component="label"
            htmlFor="fileInput"
            size="small"
            startIcon={<CloudUpload />}
            style={{ width: '200px', height: '40px', marginTop: '10px' }}
          >
            Browse
          </Button>
          <Box>
            {
              error && (
                <p style={{ marginLeft: "10px", marginTop: "10px", color: 'red' }}>{error}</p>
              )
            }
            {uploadedFiles.map((file) => (
              <Card key={file.name} style={{ height: 'auto', width: 'auto', margin: '5px', display: "inline-block" }}>
                {file.type.startsWith('image/') ? (
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <button onClick={() => handleDeleteFile(file)} style={{ position: 'absolute', top: 0, right: 0, border: 'none', backgroundColor: 'transparent' }}>
                      <ClearIcon />
                    </button>
                    <CardMedia component="img" image={URL.createObjectURL(file)} alt={file.name} style={{ padding: 0, margin: 0 }} />
                  </div>
                ) : (
                  <CardContent>
                    <p><InsertDriveFileIcon />{file.name}
                      <button onClick={() => handleDeleteFile(file)} style={{ marginLeft: 'auto', border: 'none', backgroundColor: 'transparent' }}>
                        <ClearIcon />
                      </button>
                    </p>
                    <Typography color="textSecondary" style={{ marginLeft: '25px' }}>
                      {byteToSize(file.size)}
                    </Typography>
                  </CardContent>
                )}
              </Card>
            ))}

          </Box>
        </div>
        <CreateNewPostButton />
      </CustomCard>
    </ThemeProvider>
  );
}
export default ArticleEditor;