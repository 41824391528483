import React from 'react';
import { Card, CardContent, CardActions, Button, CardHeader, Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import theme from "../Theme.js";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function CustomCard(props) {
  const { title, children , maxWidth} = props;

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ margin: 'auto', maxWidth: maxWidth, boxShadow: '0 8px 16px rgba(0,0,0,0.3)' }}>
        <CardHeader title={
          <Typography variant="h5" style={{ color: theme.palette.primary.main, fontWeight: 'bold' }} className="text-center">{title}</Typography>
        }
        sx={{padding:0, paddingTop:'20px'}}
        >
        </CardHeader>
        <CardContent sx={{ textAlign: "center" ,padding:0, paddingBottom:'10px'}}>
          {children}
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}

export default CustomCard;
