import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ShareIcon from "@mui/icons-material/Share";
import SendIcon from "@mui/icons-material/Send";
import theme from "../../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import "../../../assets/Spinner.css";
import Tooltip from "@mui/material/Tooltip";

import CustomAlert from "../../CustomAlert";
import ChooseSharedRecord from "../../FormComponents/ChooseSharedRecords.js";
// import folderData from '../../data/ProfileFolderExample.json';

function MenuFolderSelector({
  folderData,
  onSelect,
  onSelectFolder,
  onAddRecord,
  onShareRecords,
  memberID,
  requestorID,
}) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [records, setRecords] = useState([]);
  const [shareMode, setShareMode] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedFoldersID, setSelectedFoldersID] = useState([]);
  const [loading, setLoading] = useState(true);
  const [folderName, setFolderName] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component

  useEffect(() => {
    if (folderData) {
      setRecords(folderData);
      setLoading(false);
    }
  }, [folderData]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onSelect(index, records[index].encFolder);
  };

  const handlePlusClick = (folderName) => {
    onAddRecord(folderName);
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const handlePlusClickForChooseSharedRecords = () => {
    setOpenPopup(true);
  };

  const handleShareClick = () => {
    setShareMode(!shareMode);
    setSelectedFolders([]);
    setSelectedFoldersID([]);
  };

  const handleSendClick = () => {
    console.log("selected folder ", selectedFolders);
    if (selectedFolders.length > 0) {
      onShareRecords(selectedFolders, selectedFoldersID);
      onAddRecord("shareRecords");
    } else {
      handleSendAlertClick(); //error message
    }
    setShareMode(false);
  };

  const handleFolderSelect = (folderName, folderID) => {
    if (shareMode) {
      if (selectedFolders.includes(folderName)) {
        setSelectedFolders((prevSelectedFolders) =>
          prevSelectedFolders.filter((name) => name !== folderName)
        );
        setSelectedFoldersID((prevSelectedFoldersID) =>
          prevSelectedFoldersID.filter((id) => id !== folderID)
        );
      } else {
        setSelectedFolders((prevSelectedFolders) => [
          ...prevSelectedFolders,
          folderName,
        ]);
        setSelectedFoldersID((prevSelectedFoldersID) => [
          ...prevSelectedFoldersID,
          folderID,
        ]);
      }
    } else {
      onSelectFolder(folderName);
    }
  };

  const handleSendAlertClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title="Selection Error"
        type="error"
        content="Please Select <strong> at least one </strong> folder to share."
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      {loading ? (
        <div className="spinner m-auto"></div>
      ) : (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Card>
            <CardContent>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FolderIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  {requestorID !== memberID ? "Shared Records" : "My Records"}
                </Box>
                <Box
                  sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
                >
                  {requestorID === memberID && (
                    <>
                      <Tooltip title="Select Folders to Share">
                        <IconButton
                          edge="end"
                          onClick={handleShareClick}
                          sx={{
                            backgroundColor: shareMode
                              ? "primary.main"
                              : "rgba(0, 0, 0, 0.2)",
                            mr: 1,
                            "&:hover": {
                              backgroundColor: shareMode
                                ? "primary.dark"
                                : "rgba(0, 0, 0, 0.3)",
                            },
                          }}
                        >
                          <ShareIcon sx={{ color: "common.white" }} />
                        </IconButton>
                      </Tooltip>
                      {shareMode && (
                        <Tooltip title="Share Record">
                          <IconButton
                            variant="contained"
                            onClick={handleSendClick}
                            sx={{
                              backgroundColor: "primary.main",
                              "&:hover": {
                                backgroundColor: "primary.dark",
                              },
                              mr: 1,
                            }}
                          >
                            <SendIcon sx={{ color: "common.white" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Add a Record">
                        <IconButton
                          edge="end"
                          onClick={handlePlusClickForChooseSharedRecords}
                          sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                            mr: 1,
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.3)",
                            },
                          }}
                        >
                          <AddIcon sx={{ color: "common.white" }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {requestorID !== memberID && requestorID.at(0) === "2" && (
                    <>
                      <Tooltip title="Add a Record">
                        <IconButton
                          edge="end"
                          onClick={handlePlusClickForChooseSharedRecords}
                          sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                            mr: 1,
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.3)",
                            },
                          }}
                        >
                          <AddIcon sx={{ color: "common.white" }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Typography>
              {openPopup && (
                <ChooseSharedRecord
                  open={openPopup}
                  onClose={handleClosePopup}
                  onAddRecord={onAddRecord}
                />
              )}
              <List component="nav">
                {records.map((record, index) => (
                  <div key={index}>
                    <ListItem
                      button
                      onClick={(event) => handleListItemClick(event, index)}
                      sx={{
                        backgroundColor:
                          selectedIndex === index ? "gray.main" : "inherit",
                        color:
                          selectedIndex === index ? "common.white" : "inherit",
                        "&:hover": {
                          backgroundColor:
                            selectedIndex === index
                              ? "gray.main"
                              : "lightGray.main",
                          color:
                            selectedIndex === index
                              ? "common.white"
                              : "inherit",
                        },
                      }}
                    >
                      {shareMode && (
                        <Checkbox
                          checked={selectedFolders.includes(record.encFolder)}
                          onChange={() =>
                            handleFolderSelect(
                              record.encFolder,
                              record.encFolderID
                            )
                          }
                          color={selectedIndex === index ? "white" : "primary"}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              fontSize="small"
                              htmlColor={
                                selectedIndex === index
                                  ? "white"
                                  : "primary.main"
                              }
                            />
                          }
                          sx={{ mr: 1 }}
                        />
                      )}
                      <ListItemText
                        primary={
                          <Box display="flex" alignItems="center">
                            {record.encFolder}
                            <Badge
                              badgeContent={record.count}
                              color="secondary"
                              sx={{ ml: 2 }}
                            >
                              <Box />
                            </Badge>
                          </Box>
                        }
                      />
                      <IconButton
                        onClick={() => handlePlusClick(record.encFolder)}
                        edge="end"
                        color="inherit"
                        sx={{ display: shareMode ? "none" : "" }}
                      >
                        <AddIcon />
                      </IconButton>
                    </ListItem>
                    {index < records.length - 1 && <Divider />}
                  </div>
                ))}
              </List>
              {/* {shareMode && (
                          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                              variant="contained"
                              onClick={handleSendClick}
                              sx={{
                                backgroundColor: 'primary',
                                '&:hover': {
                                  backgroundColor: 'primary.dark',
                                },
                              }}
                              startIcon={<SendIcon sx={{ color: 'common.white' }} />}
                            >
                              <Typography sx={{ color: 'common.white' }}>Share</Typography>
                            </Button>
                          </Box>
                        )} */}
            </CardContent>
          </Card>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default MenuFolderSelector;
