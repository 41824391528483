import React, { useState } from "react";
import { Box, Pagination } from "@mui/material";
import RenderContentComponent from "./RenderContentComponent";
import TextField from "@mui/material/TextField";
import { RenderingCardDiv, MergeCellsCardDiv } from "./StyleComponent";
import PaginationComponent from "./PaginationComponent";

const RenderCard = ({
  contents,
  captions,
  setSortBy,
  showPagination,
  currentPage,
  itemsPerPage,
  totalPages,
  handlePageChange,
  paginationColor,
  hoverColor,
  bootstrapZones,
  mergeFields,
  mergeCounts,
  hiddenFields,
  cardViewType,
  autoDetectType,
  types,
  showSearchBar,
  IconColor,
  viewOrEdit,
  editableFields,
  requiredFields,
  sortedContents,
  setSortedContents,
  fieldErrors,
  setFieldErrors,
  separateDirtyFlag,
  setSeparateDirtyFlag,
}) => {
  //search vars
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const term = event.target.value.trim();
    setSearchTerm(term);
  };

  // Function to check if a key should be hidden
  // const isHiddenField = (key) => hiddenFields.includes(key);
  const isHiddenField = (key) => {
    if (hiddenFields && hiddenFields.includes) {
      return hiddenFields.includes(key);
    }
    return false;
  };

  const shouldDisplayAsterisk = (key) =>
    requiredFields?.includes(key) && !isHiddenField(key);

  const renderCardContent = (item, index) => {
    return (
      <RenderingCardDiv
        key={index}
        bootstrapZones={bootstrapZones ? bootstrapZones : [12]}
        showPagination={showPagination}
        index={index}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        totalPages={totalPages}
        contents={contents}
      >
        {Object.entries(item).map(
          ([key, value], i) =>
            !isHiddenField(key) &&
            key !== "externalIdForSorting" && (
              <MergeCellsCardDiv
                key={key}
                keyy={key}
                i={i}
                bootstrapZones={bootstrapZones ? bootstrapZones : [12]}
                mergeFields={mergeFields ? mergeFields : []}
                mergeCounts={mergeCounts ? mergeCounts : []}
              >
                <strong>
                  {captions && captions[key] ? captions[key] : key}
                  {shouldDisplayAsterisk(key) && <span>*</span>}:{" "}
                </strong>
                {viewOrEdit === 0 && cardViewType === 1 ? (
                  <div>
                    {" "}
                    <RenderContentComponent
                      key={key}
                      keyy={key}
                      value={value}
                      types={types !== undefined ? types : []}
                      autoDetectType={autoDetectType}
                      searchTerm={searchTerm}
                      viewOrEdit={viewOrEdit}
                      row={item}
                      setSortBy={setSortBy}
                      sortedContents={sortedContents}
                      setSortedContents={setSortedContents}
                      isCard={true}
                      fieldErrors={fieldErrors}
                      setFieldErrors={setFieldErrors}
                      separateDirtyFlag={separateDirtyFlag}
                      setSeparateDirtyFlag={setSeparateDirtyFlag}
                    />
                  </div>
                ) : (viewOrEdit === 1 &&
                    (editableFields ? editableFields.includes(key) : 1)) ||
                  (viewOrEdit === 0 && cardViewType === 0) ? (
                  <RenderContentComponent
                    key={key}
                    keyy={key}
                    value={value}
                    types={types !== undefined ? types : []}
                    autoDetectType={autoDetectType}
                    searchTerm={searchTerm}
                    viewOrEdit={viewOrEdit}
                    row={item}
                    setSortBy={setSortBy}
                    sortedContents={sortedContents}
                    setSortedContents={setSortedContents}
                    isCard={true}
                    fieldErrors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                    separateDirtyFlag={separateDirtyFlag}
                    setSeparateDirtyFlag={setSeparateDirtyFlag}
                    requiredFields={requiredFields}
                  />
                ) : cardViewType === 1 ? (
                  <div>{value}</div>
                ) : (
                  value
                )}
              </MergeCellsCardDiv>
            )
        )}
      </RenderingCardDiv>
    );
  };

  return (
    <div>
      {showSearchBar && (
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          sx={{ marginBottom: "0.5rem" }}
        />
      )}
      {showPagination
        ? sortedContents
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((item, index) => renderCardContent(item, index))
        : sortedContents.map((item, index) => renderCardContent(item, index))}
      {showPagination && (
        <Box mt={2} display="flex" justifyContent="center">
          <PaginationComponent
            onPageChange={handlePageChange}
            totalCount={contents.length}
            currentPage={currentPage}
            pageSize={itemsPerPage}
            selectedColor={paginationColor}
            hoverColor={hoverColor}
            IconColor={IconColor}
          />
        </Box>
      )}
    </div>
  );
};
export default RenderCard;
