import { Typography } from "@mui/material";

function IsConnected(connectionStatus) {
  connectionStatus = connectionStatus.toLowerCase().trim();
  if (connectionStatus === "connected") {
    return true;
  }
  return false;
}

// function IsOwnProfile() {
//   const userJson = localStorage.getItem("userInfo");
//   let userInfo;
//   if (userJson !== null && userJson.length > 0) {
//     userInfo = JSON.parse(userJson);
//   }
//   if (userInfo !== null) {
//     return true;
//   }
//   return false;
// }

const IsOwnProfile = (memberID) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo.memberID === memberID) {
    return true;
  }
  return false;
};

const RenderStatusMessage = (requestStatus) => {
  if (requestStatus === "accepted") {
    return (
      <Typography sx={{ color: "success.main", paddingLeft: "10px" }}>
        Request has been accepted
      </Typography>
    );
  }
  if (requestStatus === "denied") {
    return (
      <Typography sx={{ color: "error.main", paddingLeft: "10px" }}>
        Request has been denied
      </Typography>
    );
  }
  if (requestStatus === "unsent") {
    return (
      <Typography sx={{ color: "error.main", paddingLeft: "10px" }}>
        Request has been unsent
      </Typography>
    );
  }
  if (requestStatus === "sent") {
    return (
      <Typography sx={{ color: "success.main", paddingLeft: "10px" }}>
        Request has been sent
      </Typography>
    );
  }
  if (requestStatus === "disconnect") {
    return (
      <Typography sx={{ color: "error.main", paddingLeft: "10px" }}>
        Disconnected
      </Typography>
    );
  }
  return null;
};

export { IsConnected, IsOwnProfile, RenderStatusMessage };
