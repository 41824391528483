import React, { useState } from 'react';
import { Card, CardHeader, CardMedia, CardActions, Button, Avatar, useTheme, useMediaQuery, Box, Typography, IconButton } from '@mui/material';
import { PersonAdd, MoreVert } from '@mui/icons-material';

import SettingsDropdown from './SettingsDropdown.js';

import WebSiteTheme from '../../Theme.js';
import { ThemeProvider } from "@mui/material/styles";

function ProfileHeaderSkeleton() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const styles = {
    card: {
      maxWidth: '100%',
      margin: '0 auto',
    },
    coverPhoto: {
      height: isMobile ? 250 : 340,
    },
    profilePicture: {
      width: isMobile ? 100 : 150,
      height: isMobile ? 100 : 150,
      border: '5px solid white',
      position: 'absolute',
      top: isMobile ? 281 : 343,
      left: isMobile ? '50%' : '',
      marginLeft: isMobile ? '-115px' : '5px',
    },
    profileInfoBox: {
      height: isMobile ? 100 : 150,
      position: 'absolute',
      top: isMobile ? 334 : 432,
      left: isMobile ? '50%' : '',
      marginLeft: isMobile ? '-12px' : '169px',
    },
    buttonMenu: {
      justifyContent: 'left',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    rightButton: {
      marginRight: 'auto',
    },
  };

  return (
    <ThemeProvider theme={WebSiteTheme}>
      <Card sx={styles.card}>
        <Box 
          style={{backgroundColor: 'lightGray'}}
          sx={{
            height: styles.coverPhoto.height,
            background: "",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            alt: 'cover',
          }}
        />
        <Avatar
          src=""
          alt="profile"
          sx={styles.profilePicture}
          style={{backgroundColor: 'lightGray'}}
        />
        <Box sx={{ textAlign: 'right', paddingRight: 2, paddingTop: 2 }}>
          <Button
            variant='contained'
            color={theme.palette.lightGray}
            disabled={true}
            sx={{minWidth:"120px", minHeight:"40px"}}
          >
          </Button>
        </Box>
        <Box sx={styles.profileInfoBox}>
          <Button
              variant='contained'
              color={theme.palette.lightGray}
              disabled={true}
              sx={{paddingTop:"12px", minWidth:"120px", minHeight:"70px"}}
          >
          </Button>
        </Box>
        <CardHeader />
      </Card>
    </ThemeProvider>
  );
};

export default ProfileHeaderSkeleton;
