import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Theme";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    DialogActions,
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CategoryIcon from '@mui/icons-material/Category';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MasksIcon from '@mui/icons-material/Masks';
import MedicationIcon from '@mui/icons-material/Medication';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

function ChooseSharedRecord({ open, onClose , onAddRecord}) {
    function handleAllergy() {
        onAddRecord('allergies');
    }
    function handleMedication() {
        onAddRecord('medications');
    }
    function handleHistory() {
        onAddRecord('family history');
    }
    function handleDocument() {
        onAddRecord('document');
    }
    return (
        <ThemeProvider theme={theme}>
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                width="70%"
                open={open}
                onClose={onClose}
                className=""
            >
                <DialogTitle>
                    <Typography
                        variant="h5"
                        style={{ color: theme.palette.primary.main, fontWeight: 'bold' , paddingTop: '20px'}}
                        className="text-center"
                    >
                        What Would You Like To Upload
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div >
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <ListItem button onClick={handleAllergy}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <MasksIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Allergies"/>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem button  onClick={handleHistory}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FamilyRestroomIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Family History" />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem button  onClick={handleMedication}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <MedicationIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Medication" />
                            </ListItem >
                            <Divider variant="inset" component="li" />
                            <ListItem button onClick={handleDocument}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <NoteAddIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Document" />
                            </ListItem>
                        </List>
                    </div>
                    <IconButton sx={{ position: 'absolute', top: 5  , right: 5 }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>

                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );

}

export default ChooseSharedRecord;