import React, { useState } from "react";
import {
  isSpecialType,
  maskPhoneNumber,
  formatDate,
  stripCurrencyMask,
} from "./TypeDetection";
import { IconButton } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { StyledInput } from "./StyleComponent";

function RenderContentComponent({
  value,
  keyy,
  types,
  autoDetectType,
  searchTerm,
  viewOrEdit,
  row,
  isCard,
  fieldErrors,
  setFieldErrors,
  //editGrid
  setSortBy,
  sortedContents,
  setSortedContents,
  separateDirtyFlag,
  setSeparateDirtyFlag,
  requiredFields,
}) {
  const linkStyle = {
    color: "#007bff", // Blue color for the link text
    textDecoration: "underline", // Underline the link
    cursor: "pointer", // Show pointer cursor on hover
  };

  // Function to apply highlighting to the text (new function)
  function getHighlightedText(text, highlight) {
    const escapedHighlight = highlight.replace(
      /[-\/\\^$*+?.()|[\]{}]/g,
      "\\$&"
    );
    // Split on highlight term and include term into parts, ignore case
    const parts = text
      .toString()
      .split(new RegExp(`(${escapedHighlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: "yellow" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  }

  const renderView = () => {
    return types[keyy] === "checkbox" ? (
      <input type="checkbox" checked={value === "true"} readOnly />
    ) : types[keyy] === "color" ? (
      <input type="color" value={value} readOnly />
    ) : types[keyy] === "password" ? (
      <p>{value.replace(/./g, "•")}</p>
    ) : autoDetectType && isSpecialType(value) === "link" ? (
      <a
        href={value}
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
      >
        {getHighlightedText(value, searchTerm)}
      </a>
    ) : autoDetectType && isSpecialType(value) === "email" ? (
      <a style={linkStyle} href={`mailto:${value}`}>
        {getHighlightedText(value, searchTerm)}
      </a>
    ) : autoDetectType && isSpecialType(value) === "phone" ? (
      <a href={`tel:${value}`}>
        {getHighlightedText(maskPhoneNumber(value), searchTerm)}
      </a>
    ) : autoDetectType && isSpecialType(value) === "date" ? (
      getHighlightedText(formatDate(value), searchTerm)
    ) : autoDetectType && isSpecialType(value) === "time" ? (
      getHighlightedText(value, searchTerm)
    ) : autoDetectType && isSpecialType(value) === "dateTime" ? (
      getHighlightedText(formatDate(value) + " " + value.slice(11), searchTerm)
    ) : autoDetectType && isSpecialType(value) === "currency" ? (
      getHighlightedText(stripCurrencyMask(value), searchTerm)
    ) : (
      getHighlightedText(value, searchTerm)
    );
  };

  //for password visibility
  const [editingPassword, setEditingPassword] = useState({});

  // Function to handle changes in editable fields
  const handleFieldChange = (e, externalIdForSorting, key) => {
    setSeparateDirtyFlag(true);
    setSortBy("");
    const updatedContents = sortedContents.map((content) => {
      if (content.externalIdForSorting === externalIdForSorting) {
        let newValue = e.target.value;

        // Check if the object type has the specified property
        if (content.hasOwnProperty(key)) {
          const isRequired = requiredFields?.includes(key);
          // Check if the property is required and has no value
          if (
            isRequired &&
            (newValue === undefined || newValue === null || newValue === "")
          ) {
            setFieldErrors((prevErrors) => [
              ...prevErrors,
              {
                externalIdForSorting,
                key,
                message: `The field ${key} is required`,
              },
            ]);
          } else {
            setFieldErrors((prevErrors) =>
              prevErrors.filter(
                (error) =>
                  error.externalIdForSorting !== externalIdForSorting ||
                  error.key !== key
              )
            );
          }
          // Check if the property type is "email"
          if (types[key] === "email") {
            // Validate the email format using a regular expression
            const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
            if (!emailRegex.test(newValue)) {
              // Check if the error already exists in the fieldErrors array
              const errorExists = fieldErrors.some(
                (error) =>
                  error.externalIdForSorting === externalIdForSorting &&
                  error.key === key
              );

              if (!errorExists) {
                // Add the error to the fieldErrors array
                setFieldErrors((prevErrors) => [
                  ...prevErrors,
                  {
                    externalIdForSorting,
                    key,
                    message: "Invalid email format",
                  },
                ]);
              }
            } else {
              // If the email format is valid, remove any existing error for this field
              setFieldErrors((prevErrors) =>
                prevErrors.filter(
                  (error) =>
                    error.externalIdForSorting !== externalIdForSorting ||
                    error.key !== key
                )
              );
            }
          }

          // Check if the property type is "url"
          if (types[key] === "url") {
            // Validate the URL format using a regular expression
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            if (!urlRegex.test(newValue)) {
              // Check if the error already exists in the fieldErrors array
              const errorExists = fieldErrors.some(
                (error) =>
                  error.externalIdForSorting === externalIdForSorting &&
                  error.key === key
              );

              if (!errorExists) {
                // Add the error to the fieldErrors array
                setFieldErrors((prevErrors) => [
                  ...prevErrors,
                  {
                    externalIdForSorting,
                    key,
                    message: "Invalid URL format",
                  },
                ]);
              }
            } else {
              // If the URL format is valid, remove any existing error for this field
              setFieldErrors((prevErrors) =>
                prevErrors.filter(
                  (error) =>
                    error.externalIdForSorting !== externalIdForSorting ||
                    error.key !== key
                )
              );
            }
          }

          // Check if the property type is "tel"
          if (types[key] === "tel") {
            // Validate the phone number format using a regular expression
            const phoneNumberRegex = /^\d{10}$/;
            if (!phoneNumberRegex.test(newValue)) {
              // Check if the error already exists in the fieldErrors array
              const errorExists = fieldErrors.some(
                (error) =>
                  error.externalIdForSorting === externalIdForSorting &&
                  error.key === key
              );

              if (!errorExists) {
                // Add the error to the fieldErrors array
                setFieldErrors((prevErrors) => [
                  ...prevErrors,
                  {
                    externalIdForSorting,
                    key,
                    message: "Invalid phone number",
                  },
                ]);
              }
            } else {
              // If the phone number format is valid, remove any existing error for this field
              setFieldErrors((prevErrors) =>
                prevErrors.filter(
                  (error) =>
                    error.externalIdForSorting !== externalIdForSorting ||
                    error.key !== key
                )
              );
            }
          }

          if (types[key] === "radio") {
            newValue = e.target.checked.toString();
          }
        }

        return {
          ...content,
          [key]: newValue,
        };
      }
      if (types[key] === "radio") {
        return {
          ...content,
          [key]: "false",
        };
      } else {
        return content;
      }
    });

    setSortedContents(updatedContents);
  };
  const renderEdit = () => {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {types[keyy] === "url" && (
            <IconButton
              onClick={() => {
                if (value && value.trim() !== "") {
                  // Check if the URL is non-empty before redirecting
                  window.open(value, "_blank");
                }
              }}
            >
              <LinkIcon />
            </IconButton>
          )}
          <StyledInput
            fieldErrors={fieldErrors}
            row={row}
            keyy={keyy}
            types={types}
            isColor={types[keyy] === "color" ? true : false}
            isCard={isCard}
            type={
              types[keyy] === "password"
                ? editingPassword[row.externalIdForSorting + keyy]
                  ? "text"
                  : "password"
                : types[keyy]
            }
            checked={typeof value === "boolean" ? value : value === "true"}
            value={value}
            onChange={(e) =>
              handleFieldChange(e, row.externalIdForSorting, keyy)
            }
            onFocus={() =>
              setEditingPassword((prevState) => ({
                ...prevState,
                [row.externalIdForSorting + keyy]: true,
              }))
            }
            onBlur={() =>
              setEditingPassword((prevState) => ({
                ...prevState,
                [row.externalIdForSorting + keyy]: false,
              }))
            }
          />
        </div>
        {fieldErrors.map((error) => {
          if (
            error.externalIdForSorting === row.externalIdForSorting &&
            error.key === keyy
          ) {
            return (
              <div
                key={`${keyy}-${row.externalIdForSorting}`}
                style={{ color: "red" }}
              >
                {error.message}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  return viewOrEdit === 0 ? renderView() : renderEdit();
}
export default RenderContentComponent;
