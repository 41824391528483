import React, { useEffect, useState } from "react";
import {
  SearchForPatients,
  GetEncountersForAPatient,
} from "../../../services/HealthServiceHelper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  TextField,
  Box,
  Grid,
  Pagination,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

import ListConnections from "./ListConnections";
import { ThemeProvider } from "@mui/material/styles";
import { v4 as uuid } from "uuid";

import theme from "../../../Theme";
function PatientsTab({
  setFirstname,
  setLastname,
  setDOB,
  setReference,
  setPatientTab,
  patientID,
  setPatientID,
}) {
  const cachedData = JSON.parse(localStorage.getItem("patientsFound"));
  const memberID = JSON.parse(localStorage.getItem("userInfo")).memberID;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(cachedData ? cachedData : null);
  const [showData, setShowData] = useState(cachedData ? true :false);
  const [state, setState] = useState({
    reference: "",
    firstName: "",
    lastName: "",
    DOB: "",
    provider: memberID,
  });

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }

  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    console.log("formattedDate ", formattedDate);
    return formattedDate;
  }
  //Search
  const [searchValue, setSearchValue] = useState("");
  console.log("Search Value ", searchValue);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  function handleForwardArrowClick() {
    setPatientTab(1);
  }
  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [searchValue]);

  // useEffect(() => {
  //   async function FillData() {
  //     setLoading(true);
  //     let temp = await SearchForPatients(state);
  //     console.log("temp from patient tab ", temp);
  //     setData(temp);
  //     setTotalPages(Math.ceil(temp.length / itemsPerPage));
  //     setLoading(false);
  //   }
  //   FillData();
  //   setCurrentPage(1);
  //   //for pagination
  // }, []);

  const renderEncounters = () => {
    if (!data || data === undefined) {
      return null; // or return a message or component indicating that data is null
    }
    // Apply search filter
    const filteredData = data.filter((patient) =>
      patient.firstName.toLowerCase().includes(searchValue.toLowerCase())
    );
    return filteredData
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((patient, index) => (
        <Grid key={uuid} item xs={12}>
          <ListConnections
            key={uuid}
            connection={patient}
            type={patient.connectionStatus}
            setPatientID={setPatientID}
            setPatientTab={setPatientTab}
          />
        </Grid>
      ));
  };

  async function handleSubmit() {
    let formattedDate = "";
    if (state.DOB) {
      formattedDate = formatDate(state.DOB);
    }
    // setFirstname(state.firstName);
    // setLastname(state.lastName);
    // setDOB(formattedDate);
    // setReference(state.reference);
    // setSearchForPatient(true);
    setShowData(true);
    setLoading(true);
    console.log("state ", state);
    let temp = await SearchForPatients(state);
    localStorage.setItem("patientsFound", JSON.stringify(temp));
    setData(temp);
    setTotalPages(Math.ceil(temp.length / itemsPerPage));
    setLoading(false);
    setPatientID(0);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box mb={2}>
        <div className="grid grid-cols-1 gap-4 mt-4">
          {patientID !== 0 && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <IconButton>
                  <ArrowBackIosNewIcon color="disabled" />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={handleForwardArrowClick}>
                  <ArrowForwardIosIcon color="primary" />
                </IconButton>
              </div>
            </div>
          )}
          <div>
            <TextField
              label="First Name"
              size="small"
              variant="outlined"
              name="firstName"
              value={state.firstName}
              onChange={handleChange}
              sx={{
                width: "48%",
                marginRight: "11px",
              }}
            />
            <TextField
              label="Last Name"
              size="small"
              variant="outlined"
              name="lastName"
              value={state.lastName}
              onChange={handleChange}
              sx={{
                width: "48%",
              }}
            />
          </div>
          <div>
            <TextField
              label="Date Of Birth"
              size="small"
              variant="outlined"
              type="Date"
              InputLabelProps={{ shrink: true }}
              name="DOB"
              value={state.DOB}
              onChange={handleChange}
              sx={{
                width: "48%",
                marginRight: "11px",
              }}
              // error={submit && !state.DOB}
            />
            <TextField
              label="Reference"
              size="small"
              variant="outlined"
              name="reference"
              value={state.reference}
              onChange={handleChange}
              sx={{
                width: "48%",
              }}
            />
          </div>
          <div style={{ marginTop: "10px", width: "100%" }}>
            <Button
              sx={{ fontSize: "1rem", width: "100%" }}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Box>

      {showData && (
        <>
          <Box mt={2}>
            {/* Loading either wheel or connections depending on API state */}
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <div className="spinner"></div>
              </Box>
            ) : (
              data !== undefined && (
                <Grid key={uuid} container spacing={2}>
                  {renderEncounters()}
                </Grid>
              )
            )}
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Box>
        </>
      )}
    </ThemeProvider>
  );
}
export default PatientsTab;
