import React, { useState } from "react";
import "./Dental.css";
import SingleTooth from "./SingleTooth";
function TopArch() {
  return (
    <>
      <SingleTooth
        isPath={false}
        toothNumber={16}
        transform={"matrix(1 0 0 1 312.8534 324.1021)"}
        points="404.3,293.7 408.7,299.3 408.7,308 405.3,318.7 401,329.7 392.3,339.7 382.7,341 
            369,339.7 359,335 354.7,327.7 354.3,316 358.3,304 363.7,294 368.7,294.7 378.7,296 389,296 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={15}
        transform={"matrix(1 0 0 1 315.3335 275.3333)"}
        points="362.3,247.3 357.3,251 357,259.3 358.7,268 359.7,279.7 361.3,286.7 365,291.7 
            371,294.3 392,295 404.3,293.7 410,280.7 412,263.3 407.3,246.7 401,240.3 396,239.7 389.3,243 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={14}
        transform={"matrix(1 0 0 1 311.3335 236)"}
        points="359.7,243.7 350.7,224 345.7,211.7 348.7,205 358.3,202.7 375.7,197 388.7,193 
            393,196 399.3,207 401.3,222.7 400,234.3 394.7,240.7 381.7,244.7 371,246 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={13}
        transform={"matrix(1 0 0 1 300.3335 200.6667)"}
        points="386,188.7 383.3,192.7 377.7,196 356.3,203.3 345.7,202.3 341.7,199.7 338.7,196.3 
            335,188.7 332,177 333.7,169.7 338,164.7 346.3,161 353.7,156.7 360.3,150.3 364,151 370.7,156.3 376.3,164.3 380,170.3 
            383.3,178.3 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={12}
        transform={"matrix(1 0 0 1 286.6669 172)"}
        points="358.7,134.3 360.3,145.7 357.3,152.7 352,157.3 346.3,161 336,164 329.7,163.3 
            321.7,157.7 314.3,149 310.7,139.3 310,133.7 312.3,127 318.3,125.7 326,122 332.7,116 334.7,114.3 337.7,117.3 343.3,119.7 
            348.7,122.7 354.3,127.7 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={11}
        transform={"matrix(1 0 0 1 270.2269 142.439)"}
        points="336,93.3 337.7,100 336,104.7 332.7,113.7 324.3,121.3 315.3,125.7 306.3,126 
            297.3,120.3 294,112 295.7,102.7 299,95 303.3,90 309.3,88 316.3,87.3 322.7,87.3 328,88.3 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={10}
        transform={"matrix(1 0 0 1 247.5099 118.9722)"}
        points="310.3,83.3 298,90.7 286,95 276.3,98.3 270.3,93.3 269,82.7 269,69.3 270,58.7 
            274.7,54.7 282,53 287.7,54.7 297.3,60.3 304,64.3 308.7,68.7 312.3,74 313,81 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={9}
        transform={"matrix(1 0 0 1 227.8432 112.9722)"}
        points="273.3,52 266.7,61.7 258.3,72.3 253.3,79.7 247.3,85 239,87.7 232.3,82 224.7,67 
            222,58.3 219,50 220,44.3 224.3,40.3 230,38.7 237.3,38.7 253,39.3 258.7,41.3 264.3,43.7 268.3,45.7 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={8}
        transform={"matrix(1 0 0 1 200.1766 112.9722)"}
        points="176.7,46.3 195,41 203.3,39.7 209.3,40.7 215.3,42.7 217,47 217.7,54.3 215,64.7 
            212.3,75.7 208,83 201.7,85.7 195.7,86.7 189.7,83.3 183.7,74.7 175,62 171.7,54 172.7,49.7 	"
      />

      <SingleTooth
        isPath={true}
        toothNumber={7}
        transform={"matrix(1 0 0 1 170.5099 117.6388)"}
        points="M167,55l6.7,6.3L174,68l0.3,8l1,10l-2,8.3l-4.7,4.3l-6.7,1.7l-8-4.3l-7.3-4.7l-9.3-4.7
            l-6.3-5.3l-1-4.3l1.3-5c0,0,3.3-6,4.3-6s5.3-6,6.3-6s10.3-4.7,10.3-4.7L167,55z"
      />

      <SingleTooth
        isPath={false}
        toothNumber={6}
        transform={"matrix(1 0 0 1 148.6667 134.167)"}
        points="126.3,82 134.3,86.3 139.7,92.3 144.7,104.7 145.7,115.3 143.7,120.7 138,124.3 
            131.3,125 121,125 114.7,119.3 110.3,112.3 108.3,104.7 108.7,94.7 110.7,88.7 116,84 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={5}
        transform={"matrix(1 0 0 1 131.3605 164.8335)"}
        points="109,116.7 116,122.3 122.7,125.3 127.7,131.3 128.3,141 122.7,153.7 114,161.7 
            105.7,162.3 96.7,161 85.7,156 82,150 81,139.3 86.3,128 93,121.3 100.7,117.3 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={4}
        transform={"matrix(1 0 0 1 119.3927 195.6387)"}
        points="82,155.3 102.3,163.3 108.7,172 109.3,182 104.7,192 100,199 94,203.7 85.3,201.7 
            73.7,201 64.3,196.7 60.3,190.7 59,183.3 61.7,175.3 66.3,167.7 71.3,161.3 	"
      />

      <SingleTooth
        isPath={true}
        toothNumber={3}
        transform={"matrix(1 0 0 1 103.8631 234.4391)"}
        points="M92.7,207.3l2,5.3l-1.7,8l-1.7,9l-4,8l-5,7.7l-11,4.7l-13.7,0.7l-10-7l-1.7-5L45,220l3-10.7
            l5-7.3l4-3.3l4.7-2.7l5.3,3.7l6.7,1.3c0,0,7.3,1.3,9.3,1.3s6.3,0.7,6.3,0.7L92.7,207.3z"
      />

      <SingleTooth
        isPath={false}
        toothNumber={2}
        transform={"matrix(1 0 0 1 96.2504 275.9999)"}
        points="79.7,288.3 71.7,291 55,293 40.3,291.3 36,287 33,273.7 36.3,260 42,248.7 44.7,244.7 
            50.3,246.7 56,249 65.3,250.7 74,249.7 80.3,249.7 82.3,254 85.3,259.3 87,267.7 87.7,274.7 85.3,282.7 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={1}
        transform={"matrix(1 0 0 1 93.9767 324.769)"}
        points="33,314.3 38,325.7 45.7,335.7 55.7,341.7 64.7,343 73.3,340 77.7,335.7 81.3,326.3 
            82,314.3 81.3,302 80.7,292.7 73.7,292 51.3,293.7 38.7,293.7 34,298 31.7,302.3 32,311 	"
      />
    </>
  );
}

export default TopArch;
