import React, { useState } from "react";
import theme from "../../Theme";
import { TextField, ThemeProvider, Button, MenuItem } from "@mui/material";
import { DialogActions } from "@material-ui/core";

function ThirdStep({ handleSubmit, handleBack, record, data }) {
  console.log("third record ", record);
  const [submit, setSubmit] = React.useState(false);

  const [state, setState] = React.useState({
    eventURL: record.EventURL !== undefined ? record.EventURL : "",
    location: record.Location !== undefined ? record.Location : "",
    venueDetails: record.VenueDetails !== undefined ? record.VenueDetails : "",
    onlineEvent:
      record.OnlineEvent !== undefined ? Boolean(record.OnlineEvent) : true,
    price: record.Price !== undefined ? parseFloat(record.Price) : 0,
    pricing: record.PricingName !== undefined ? record.PricingName : "Free",
    pricingResourceValue: record.Pricing !== undefined ? record.Pricing : "1",
    visibility:
      record.VisibilityName !== undefined ? record.VisibilityName : "",
    visibilityResourceValue:
      record.Visibility !== undefined ? record.Visibility : "",
  });
  console.log("state.OnlineEvent ", state.onlineEvent);
  function handleChange(event) {
    console.log("event.target.name ", event.target.name);
    console.log("event.target.value ", event.target.value);
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }
  function handleChangeGetResourceValue(event) {
    const value = event.target.value;
    const resourceValue = data.find(
      (option) => option.resourceName === value
    ).resourceValue;
    setState({
      ...state,
      [event.target.name]: value,
      [event.target.name + "ResourceValue"]: resourceValue,
    });
  }

  async function handleSubmitClick() {
    console.log("state ", state);
    setSubmit(true);
    let flag = true;
    let ThirdRecord = "";
    if (
      state.onlineEvent !== "" &&
      state.onlineEvent !== undefined &&
      state.pricing !== "" &&
      state.pricing !== undefined &&
      state.visibility !== "" &&
      state.visibility !== undefined
    ) {
      ThirdRecord = {
        OnlineEvent: Boolean(state.onlineEvent),
        Pricing: state.pricingResourceValue.toString(),
        Visibility: state.visibilityResourceValue.toString(),
        PricingName: state.pricing,
        VisibilityName: state.visibility,
        EventURL: "",
        Location: "",
        VenueDetails: "",
        Price: state.price,
      };
      if (
        (state.onlineEvent === "true" || state.onlineEvent === true) &&
        state.eventURL !== "" &&
        state.eventURL !== undefined
      ) {
        ThirdRecord.EventURL = state.eventURL;
      } else if (
        (state.onlineEvent === "false" || state.onlineEvent === false) &&
        state.location !== "" &&
        state.location !== undefined
      ) {
        ThirdRecord.Location = state.location;
        ThirdRecord.VenueDetails = state.venueDetails;
      } else {
        console.log("Second IF");
        flag = false;
      }
      if (state.pricing !== "Free" && state.price === "0") {
        console.log("Second IF");
        flag = false;
      }
      if (flag) {
        setSubmit(false);
        handleSubmit(ThirdRecord);
      } else {
        console.log("flag is false");
      }
    }
  }

  function filterDataByValue(filterValue) {
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.settingID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }
  return (
    <ThemeProvider theme={theme}>
      <TextField
        select
        required
        label="Online Event"
        size="small"
        variant="outlined"
        name="onlineEvent"
        value={state.onlineEvent}
        onChange={handleChange}
        error={submit && !state.onlineEvent}
      >
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </TextField>
      {state.onlineEvent === "true" || state.onlineEvent === true ? (
        <TextField
          required
          label="Event URL"
          size="small"
          variant="outlined"
          name="eventURL"
          value={state.eventURL}
          onChange={handleChange}
          error={submit && !state.eventURL}
        />
      ) : (
        <>
          <TextField
            required
            label="Location"
            size="small"
            variant="outlined"
            name="location"
            value={state.location}
            onChange={handleChange}
            error={submit && !state.location}
          />
          <TextField
            label="Venue Details"
            size="small"
            variant="outlined"
            name="venueDetails"
            value={state.venueDetails}
            onChange={handleChange}
          />
        </>
      )}
      <TextField
        select
        required
        label="Pricing"
        size="small"
        variant="outlined"
        name="pricing"
        value={state.pricing}
        onChange={handleChangeGetResourceValue}
        error={submit && !state.pricing}
      >
        {filterDataByValue("Pricing")}
      </TextField>
      {state.pricing !== "Free" && (
        <TextField
          required
          label="Price"
          size="small"
          variant="outlined"
          name="price"
          value={state.price}
          onChange={handleChange}
          error={submit && state.price === 0}
        />
      )}
      <TextField
        select
        required
        label="Visibility"
        size="small"
        variant="outlined"
        name="visibility"
        value={state.visibility}
        onChange={handleChangeGetResourceValue}
        error={submit && !state.visibility}
      >
        {filterDataByValue("Visibility")}
      </TextField>
      <DialogActions>
        <Button sx={{ fontSize: "1rem", width: "300px" }} onClick={handleBack}>
          Back
        </Button>
        <Button
          sx={{ fontSize: "1rem", width: "300px" }}
          variant="contained"
          onClick={handleSubmitClick}
        >
          Submit
        </Button>
      </DialogActions>
    </ThemeProvider>
  );
}
export default ThirdStep;
