import React from "react";
import { useState } from "react";
import { TextField, Button, MenuItem, Box } from "@mui/material";
import { Chip, Autocomplete } from "@mui/material";
import { DialogActions } from "@material-ui/core";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { GetCreatePostResources } from "../../helper/APIHelper";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";
import { v4 as uuid } from "uuid";
import ReactQuill from "react-quill";

// for rich textbox
const useStyles = makeStyles((theme) => ({
  quillContainer: {
    "& .ql-container.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      padding: "8px",
    },
    "& .ql-container.ql-snow:focus-within": {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.primary.main, 0.1)}`,
    },
    "& .ql-toolbar.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      padding: "4px",
    },
    "& .ql-container.ql-snow .ql-editor": {
      padding: "0",
    },
    "& .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar button:focus":
      {
        color: theme.palette.primary.main,
      },
    "& .ql-snow .ql-toolbar button svg": {
      fill: "currentColor",
    },
  },
  dialogAction: {
    "&.MuiDialogActions-root": {
      display: "flex",
      paddingTop: "35px",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

function FirstStep({ handleNext, handleBack, record }) {
  // Rich Text Box ----
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  const classes = useStyles(theme);

  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetCreatePostResources(false);
      setData(temp);
    }
    FillData();
  }, []);

  const [tags, setTags] = useState(record.Tags !== undefined ? record.Tags : []);
  const [richTextBox, setRichTextBox] = useState(record.Description !== undefined ? record.Description : "");

  const [submit, setSubmit] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    requiredRichTextBox: "",
  });
  const [error, setError] = useState("");
  const [state, setState] = useState({
    category: record.SubHeader !== undefined ? record.SubHeader : "",
    title: record.Header !== undefined ? record.Header : "",
    mediaURL: record.MediaURL !== undefined ? record.MediaURL : "",
  });

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }
  const handleTagInput = (event, option) => {
    if (
      option !== null &&
      option.resourceName &&
      !tags.includes(option.resourceName)
    ) {
      setTags([...tags, option.resourceName]);
    }
  };
  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  //Tag autocomplete to categorize by letter
  let options = [];
  if (data.Tags !== undefined) {
    options = data.Tags.map((option) => {
      const firstLetter = option.resourceName[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
  }

  async function handleOnNextClick() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userPkeyID = userInfo.userPkeyID;
    const userName = userInfo.username;
    setSubmit(true);
    let firstStep = "";
    if (
      state.category !== "" &&
      state.category !== undefined &&
      state.title !== "" &&
      state.title !== undefined
    ) {
      firstStep = {
        SubHeader: state.category,
        QLibID: "9",
        Header: state.title,
        CreateBy: userName,
        Description: richTextBox,
        userPkeyID: parseInt(userPkeyID),
        MediaURL: state.mediaURL,
        Tags: tags
      };
      console.log("firstStep ", firstStep);
      setSubmit(false)
      handleNext(firstStep);
    }
  }

  function filterDataByValue(filterValue) {
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.settingID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }

  return (
    <ThemeProvider theme={theme}>
      <TextField
        required
        label="Enter Title"
        size="small"
        variant="outlined"
        name="title"
        value={state.title}
        onChange={handleChange}
        error={submit && !state.title}
        // style={{ width: "85%" }}
      />

      <TextField
        required
        label="Select Category"
        select
        size="small"
        variant="outlined"
        name="category"
        value={state.category}
        onChange={handleChange}
        error={submit && !state.category}
        // style={{ width: "85%", marginTop: "15px", textAlign: "start" }}
      >
        {data.TopicGroups !== undefined &&
          data.TopicGroups.map((option) => (
            <MenuItem key={option.settingID} value={option.resourceName}>
              {option.resourceName}
            </MenuItem>
          ))}
      </TextField>

      <Autocomplete
        size="small"
        options={options.sort(
          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
        )}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.resourceName}
        renderInput={(params) => <TextField {...params} label="Tags" />}
        onChange={handleTagInput}
        variant="outlined"
        // sx={{ width: "85%", marginTop: "15px", marginLeft: "2.5rem" }}
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
        // marginLeft="2.0rem"
        // marginTop="10px"
      >
        {tags.map((tag) => (
          <Chip
            key={uuid()}
            label={tag}
            onDelete={handleDeleteTag(tag)}
            style={{ margin: "4px" }}
          />
        ))}
      </Box>
      <ReactQuill
        className={classes.quillContainer}
        theme="snow"
        value={richTextBox}
        onChange={setRichTextBox}
        modules={modules}
        formats={formats}
        style={{
          height: "150px",
          marginBottom: "32px",
          // marginTop: "-20px",
          // width: "85%",
          // marginLeft: "2.5rem",
        }}
      />
      <DialogActions className={classes.dialogAction}>
        <Button sx={{ fontSize: "1rem", width: "250px" }} onClick={handleBack}>
          Close
        </Button>
        <Button
          sx={{ fontSize: "1rem", width: "250px" }}
          variant="contained"
          onClick={handleOnNextClick}
        >
          Next
        </Button>
      </DialogActions>
    </ThemeProvider>
  );
}

export default FirstStep;
