import React from "react";
import { useState, useRef } from "react";
import { TextField, Button, MenuItem, Box } from "@mui/material";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import CustomPopUpForm from "../CustomPopUpForm";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { GetSettingsByKey } from "../../helper/APIHelper";
import CustomAlert from "../CustomAlert";
import { CloudUpload } from "@material-ui/icons";
import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { PostDocuments } from "../../services/HealthServiceHelper";

// uploading again doesn't reset upload files
const excludedFileNames = ["mp4", "mp3", "lock", "CON", "PRN", "AUX", "NUL"];
const excludedPrefixes = ["COM", "LPT", "_vti_", "~$"];

const DocumentResourcesKey = ["Folders", "OrgClass"];
function UploadDocument({
  formTitle,
  open,
  onClose,
  enableEditing,
  documentInitialValues,
  showSubmit,
  
}) {

  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(DocumentResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [state, setState] = React.useState({
    date:
      documentInitialValues !== ""
        ? documentInitialValues.fromDate.split("T").shift()
        : "",
    title:
      documentInitialValues !== "" ? documentInitialValues.description : "",
    folder:
      documentInitialValues !== "" ? documentInitialValues.encFolderName : "",
    folderValue:
      documentInitialValues !== "" ? documentInitialValues.encFolderID : "",
    folderqLibID: "",
    source:
      documentInitialValues !== "" ? documentInitialValues.sourceType : "",
    clinicName:
      documentInitialValues !== "" ? documentInitialValues.sourceName : "",
    description:
      documentInitialValues !== "" ? documentInitialValues.explanation : "",
    maxSize: 100000000, //100 MB
    maxNumber: 1,
  });
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    requiredFile: "",
  });

  function clearData() {
    setState({
      date: "",
      title: "",
      folder: "",
      folderValue: "",
      folderqLibID: "",
      source: "",
      clinicName: "",
      description: "",
    });
    setSubmit(false);
  }
  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
    console.log("state ", state);
    console.log("DATA ", data);
  }
  function handleFolderChange(event) {
    console.log("event ", event);
    const value = event.target.value;
    const resourceValue = data.find(
      (option) => option.resourceName === value
    ).resourceValue;
    const qLibID = data.find((option) => option.resourceName === value).qLibID;
    setState({
      ...state,
      folder: value,
      folderValue: resourceValue,
      folderqLibID: parseInt(qLibID),
    });
  }

  function validations() {
    let validationErrors = {};
    
    if (uploadedFiles.length === 0) {
      validationErrors.requiredFile = "File is required";
    }

    setValidationErrors(validationErrors);
    console.log("error ", validationErrors);
    console.log("ret ", Object.keys(validationErrors).length === 0);
    return Object.keys(validationErrors).length === 0;
  }

  const UploadDocuments = async (formData) => {
    const response = await PostDocuments(formData);
  };

  const currentUrl = window.location.href;
  const lastThreeDigits = currentUrl.replace(/\/$/, '').slice(-3);


  async function handleSubmit() {
    setSubmit(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const ContactID = parseInt(userInfo.contactID);
    const currentUrl = window.location.href;
    const lastThreeDigits = currentUrl.replace(/\/$/, '').slice(-3);
    let DocumentRecord = "";
    console.log("state ", state);
    console.log("uploadedFiles ", uploadedFiles);
    if (validations()) {
      if (
        state.date !== "" &&
        state.title !== "" &&
        state.folder !== "" &&
        state.folderValue !== "" &&
        state.source !== "" &&
        state.clinicName !== ""
      ) {
        DocumentRecord = {
          PatientID: ContactID,
          Title: state.title,
          Description: state.description,
          EncFolder: state.folder,
          EncFolderID: state.folderValue,
          QLibID: parseInt(state.folderqLibID),
          SourceType: state.source,
          CreatedBy: userInfo.username,
          SourceName: state.clinicName,
          EffectiveDate: state.date,
        };
        console.log("documentRecord ", DocumentRecord);
        const formData = new FormData();
        for (var i = 0; i < uploadedFiles.length; i++) {
          formData.append("files", uploadedFiles[i]);
        }
        formData.append("patientDocument", JSON.stringify(DocumentRecord));
        console.log("form File: ", formData);
        const response = await PostDocuments(formData);
        console.log("response ", response);
        if (response.ok === true) {
          setError("");
          clearData();
          handleEditContactInfoClick();
          onClose();
        } else {
          if (response.statusText === "") {
            setError("Your Request Was Not Fulfilled");
          } else {
            setError(response.statusText);
          }
          handleEditContactInfoClick();
        }
      }
    }
  }

  const handleCancel = () => {
    //clearData();
    onClose();
  };

  function filterDataByValue(filterValue) {
    console.log("Filter " + filterValue);
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.resourceID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }

  function handleFileUpload(files) {
    setError("");
    const existingFileNames = uploadedFiles.map((file) =>
      file.name.toLowerCase()
    );
    const validFiles = Array.from(files).filter((file) => {
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.split(".").pop();
      if (
        excludedFileNames.includes(fileExtension) ||
        excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
      ) {
        setError(`The File "${file.name}" Type Is Not Valid`);
      } else if (file.size > state.maxSize) {
        setError(`The File "${file.name}" Size Is Too Large`);
      } else if (existingFileNames.includes(fileName)) {
        setError(`The File "${file.name}" Has Already Been Uploaded`);
      } else {
        return (
          !excludedFileNames.includes(fileExtension) &&
          !excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
        );
      }
    });
    if (validFiles.length + uploadedFiles.length > state.maxNumber) {
      setError(`You can only upload up to "${state.maxNumber}" files`);
    } else {
      setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  }
  const handleDeleteFile = (fileToDelete) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles.filter((file) => file !== fileToDelete);
    });
  };

  function handleDrop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileUpload(files);
  }

  function handleFileInputChange(e) {
    const files = e.target.files;
    handleFileUpload(files);
  }

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  function byteToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type={error ? "error" : "success"}
        content={error ? error : "Form Submitted."}
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      {/* <Button variant="contained" color="primary"
                onClick={handleClickOpen}>
                Open Form
            </Button> */}
      <CustomPopUpForm
        open={open}
        onClose={onClose}
        title={formTitle}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        showSubmit={showSubmit}
      >
        <div className="grid grid-cols-1 gap-4">
          <TextField
            required
            label="Effective Date"
            size="small"
            variant="outlined"
            type="Date"
            InputLabelProps={{ shrink: true }}
            name="date"
            value={state.date}
            onChange={handleChange}
            error={submit && !state.date}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          />
          <TextField
            required
            label="Title"
            size="small"
            variant="outlined"
            name="title"
            value={state.title}
            onChange={handleChange}
            error={submit && !state.title}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          />
          <TextField
            required
            label="Folder"
            select
            size="small"
            variant="outlined"
            name="folder"
            value={state.folder}
            onChange={handleFolderChange}
            error={submit && !state.folder}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          >
            {data
              .filter((option) => option.resourceKey === "Folders")
              .map((option) => (
                <MenuItem key={option.resourceID} value={option.resourceName}>
                  {option.resourceName}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            required
            label="Source"
            select
            size="small"
            variant="outlined"
            name="source"
            value={state.source}
            onChange={handleChange}
            error={submit && !state.source}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          >
            {filterDataByValue("OrgClass")}
          </TextField>
          <TextField
            required
            size="small"
            variant="outlined"
            label={state.source === "" ? "Clinic Name" : `${state.source} Name`}
            name="clinicName"
            value={state.clinicName}
            onChange={handleChange}
            error={submit && !state.clinicName}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          ></TextField>
          <TextField
            multiline
            label="Description"
            size="small"
            variant="outlined"
            name="description"
            value={state.description}
            onChange={handleChange}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          />
          {enableEditing && (
            <>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
                multiple
              />
              <div
                onDragEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrop={handleDrop}
                style={{
                  border: `2px dashed ${error ? "red" : "gray"}`,
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "auto",
                  width: "100%",
                  margin: "auto",
                  marginBottom: "20px",
                  backgroundColor: "#f1f1f1",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <p style={{ margin: "0", fontSize: "18px" }}>
                  Drag and drop files here
                </p>
                <p style={{ margin: "0", fontSize: "14px" }}>OR</p>

                <Button
                  variant="contained"
                  color={
                    uploadedFiles.length === state.maxNumber
                      ? "error"
                      : "primary"
                  }
                  component="label"
                  htmlFor="fileInput"
                  size="small"
                  startIcon={<CloudUpload />}
                  style={{ width: "200px", height: "40px", marginTop: "10px" }}
                >
                  Browse
                </Button>
                <Box>
                  {error && (
                    <p
                      style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        color: "red",
                      }}
                    >
                      {error}
                    </p>
                  )}
                  
                  {validationErrors.requiredFile && (
                    <p
                      style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        color: "red",
                      }}
                    >
                      {validationErrors.requiredFile}
                    </p>
                  )}

                  {uploadedFiles.map((file) => (
                    <Card
                      key={file.name}
                      style={{
                        height: "auto",
                        width: "auto",
                        margin: "5px",
                        display: "inline-block",
                      }}
                    >
                      {file.type.startsWith("image/") ? (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <button
                            onClick={() => handleDeleteFile(file)}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <ClearIcon />
                          </button>
                          <CardMedia
                            component="img"
                            image={URL.createObjectURL(file)}
                            alt={file.name}
                            style={{ padding: 0, margin: 0 }}
                          />
                        </div>
                      ) : (
                        <CardContent>
                          <p>
                            <InsertDriveFileIcon />
                            {file.name}
                            <button
                              onClick={() => handleDeleteFile(file)}
                              style={{
                                marginLeft: "auto",
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              <ClearIcon />
                            </button>
                          </p>
                          <Typography
                            color="textSecondary"
                            style={{ marginLeft: "25px" }}
                          >
                            {byteToSize(file.size)}
                          </Typography>
                        </CardContent>
                      )}
                    </Card>
                  ))}
                </Box>
              </div>
            </>
          )}
        </div>
      </CustomPopUpForm>
    </ThemeProvider>
  );
}

export default UploadDocument;
