import { GetResourcesByID } from "../helper/APIHelper";
import React, { useState, useEffect } from "react";
import ResourceKeys from "../data/ResourceKeys.json";
import { Typography, Container, Box } from "@mui/material";
import theme from "../Theme";
import { ThemeProvider } from "@mui/material/styles";

function PolicyPage({ resourceID }) {
  let [privacyPolicy, setPrivacyPolicy] = useState();

  useEffect(() => {
    const response = getPrivacyPolicy(resourceID);
  }, []);

  const getPrivacyPolicy = async (resourceID) => {
    const response = await GetResourcesByID(resourceID);
    setPrivacyPolicy(response);
    return response;
  };

  console.log("Privacy policy: ", privacyPolicy);
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Box sx={{ bgcolor: theme.palette.white.main }}>
          {privacyPolicy !== undefined ? (
            <div>
              <Typography
                className="text-center"
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                {privacyPolicy.title}
              </Typography>
              <div
                style={{ margin: "2rem" }}
                dangerouslySetInnerHTML={{ __html: privacyPolicy.htmlText }}
              />
            </div>
          ) : (
            <h1>Loading...</h1>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
export default PolicyPage;
