import React from "react";
import { Chip, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import theme from "../../Theme";
import { v4 as uuid } from "uuid";
import TagIcon from "@mui/icons-material/Tag";
import { GetFilterContext } from "../feedComponents/FilterProvider";
import { useNavigate } from "react-router-dom";

function MultipleChipComponent({ filters }) {
  let filterContext = GetFilterContext();
  let navigate = useNavigate();

  function handleTagClicked(tag) {
    filterContext.clearFilterValue();
    filterContext.filter.tag = tag;
    navigate("/");
  }

  // style
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          paddingTop: "30px",
          flexWrap: "wrap",
          [theme.breakpoints.down("sm")]: {
            paddingRight: "20px",
          },
        }}
      >
        {filters.map((tag, index) => (
          <Chip
            key={uuid()}
            label={tag.resourceName}
            color="primary"
            icon={<TagIcon color="white" fontSize="small" />}
            onClick={() => handleTagClicked(tag.resourceName)}
            sx={{
              marginRight: "20px",
              padding:'1rem',
              width: "auto",
              height: "35px",
              fontWeight: "bold",
              marginBottom:'20px',
              [theme.breakpoints.down("sm")]: {
                margin: "4px",
                width: "auto",
                fontWeight: "normal",
              },
            }}
          />
        ))}
      </Box>
    </ThemeProvider>
  );
}

export default MultipleChipComponent;
