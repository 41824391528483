import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../Theme";
import { fontSize } from "@mui/system";

function CustomPopUpForm({ open, onClose, title, children, onSubmit, onCancel, showSubmit }) {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        width="70%"
        open={open}
        onClose={onClose}
        className=""
      >
        <DialogTitle>
          <Typography
            style={{ color: theme.palette.primary.main, fontWeight: 'bold' ,fontSize:'25px'}}
            className="text-center"
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 gap-4 mt-4" >
            {children}
          </div>
        </DialogContent>
          {
            showSubmit ? (
              <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <Button onClick={onCancel} sx={{ fontSize: '1rem', width: '500px' }}>
                  Cancel
                </Button>
                <Button onClick={onSubmit} sx={{ fontSize: '1rem', width: '500px' }} variant="contained">
                  Submit
                </Button>
              </DialogActions>
            ) : (
              <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button onClick={onCancel} sx={{ fontSize: '1rem', width: '500px' }}>
                  Cancel
                </Button>
              </DialogActions>
            )
          }
      </Dialog>
    </ThemeProvider>
  );
}
export default CustomPopUpForm;