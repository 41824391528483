import React, { useState } from "react";
import theme from "../../Theme";
import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { styled } from "@mui/system";
import { GetSettingsByKey } from "../../helper/APIHelper";
import { Living } from "@mui/icons-material";
import { GetFilterContext } from "../feedComponents/FilterProvider";
import { useNavigate } from "react-router-dom";

const CarouselWrapper = styled("div")({
  display: "flex",
  gap: "20px",
  overflowX: "scroll",
  padding: "16px 0",
});

const CarouselItem = styled(Box)(({ theme, active, isMobile }) => ({
  minWidth: isMobile ? "140px" : "180px",
  height: isMobile ? "200px" : "250px",
  borderRadius: "8px",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  marginRight: theme.spacing(0.2),
  border: active ? `2px solid ${theme.palette.primary.main}` : "none",
}));

const PanelCaption = styled(Typography)(({ theme, active }) => ({
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "100%",
  padding: "8px",
  textAlign: "center",
  backgroundColor: active
    ? theme.palette.primary.main
    : theme.palette.grey[200], // Use primary color if active
  color: active ? "white" : "black", // Use primary color if active,
}));

const CarouselImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  // borderRadius: '8px',
});

function ImageCarousel({
  activeState,
  onFilterStateChange,
  topicGroupsData,
  isMobile,
}) {
  const [activeItem, setActiveItem] = useState(null);
  let filterContext = GetFilterContext();
  let navigate = useNavigate();

  const handleImageClick = (index, caption) => {
    if (activeState === index) {
      setActiveItem(null);
      onFilterStateChange(null);
    } else {
      setActiveItem(index);
      onFilterStateChange(index);
      filterContext.clearFilterValue();
      filterContext.filter.topic = caption;
      navigate("/");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ padding: "20px" }}>
        <CarouselWrapper>
          {topicGroupsData.map((item, index) => (
            <CarouselItem
              key={index}
              active={index === activeState}
              onClick={() => handleImageClick(index, item.resourceName)}
              isMobile={isMobile}
            >
              <CarouselImage
                src={item.mediaURL}
                alt={"image " + index.toString()}
              />
              <PanelCaption active={index === activeState}>
                {item.resourceName}
              </PanelCaption>
            </CarouselItem>
          ))}
        </CarouselWrapper>
      </Box>
    </ThemeProvider>
  );
}

export default ImageCarousel;
