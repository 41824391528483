import React from "react";
import { useState, useRef } from "react";
import { TextField, Button, CardActions } from "@mui/material";
import CustomPopUpForm from "../CustomPopUpForm.js";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import CustomAlert from "../CustomAlert.js";
import { ChangePassword as ChangePasswordAPI } from "../../helper/APIHelper.js";
import CustomCard from "../CustomCard.js";

function ChangePassword() {
  var regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [state, setState] = React.useState({
    oldPass: "",
    newPass: "",
    confirmPass: "",
  });
  const [errors, setErrors] = useState({
    oldPass: "",
    newPass: "",
    confirmPass: "",
  });

  function clearData() {
    setState({
      oldPass: "",
      newPass: "",
      confirmPass: "",
    });
    setErrors({
      oldPass: "",
      newPass: "",
      confirmPass: "",
    });
    setSubmit(false);
  }

  function validateForm() {
    let errors = {};

    if (!state.oldPass) {
      errors.oldPass = "Old password is required";
    }

    if (!state.newPass) {
      errors.newPass = "New password is required";
    } else if (state.newPass.length < 8) {
      errors.newPass = "New password should be at least 8 characters long";
    } else if (!regularExpression.test(state.newPass)) {
      console.log("special");
      errors.newPass =
        "Password must have at least one number and one special character";
    }

    if (!state.confirmPass) {
      errors.confirmPass = "Please confirm your new password";
    } else if (state.newPass !== state.confirmPass) {
      errors.confirmPass =
        "New password and confirmation password do not match";
    }

    setErrors(errors);
    console.log("errors ", errors);
    console.log("ret ", Object.keys(errors).length === 0);
    return Object.keys(errors).length === 0;
  }

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }

  async function handleSubmit() {
    setSubmit(true);
    if (validateForm()) {
      let response = await ChangePasswordAPI(state.oldPass, state.newPass);
      let message = await response.text();
      console.log("Patient response: ", message);
      if (response.ok === true) {
        setError("");
        clearData();
        handleEditContactInfoClick();
      } else {
        setError(message);
        handleEditContactInfoClick();
      }
    }
  }

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };
  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type={error ? "error" : "success"}
        content={error ? error : "Form Submitted."}
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <CustomCard title="Change Password">
        <div
          className="grid grid-cols-1 gap-4 mr-5 ml-5"
          style={{ marginTop: "2rem" }}
        >
          <TextField
            required
            label="Old Password"
            size="small"
            variant="outlined"
            name="oldPass"
            value={state.oldPass}
            onChange={handleChange}
            error={submit && !state.oldPass}
            helperText={errors.oldPass}
            autoComplete="off"
          />
          <TextField
            required
            label="New Password"
            size="small"
            variant="outlined"
            name="newPass"
            type="password"
            value={state.newPass}
            onChange={handleChange}
            error={
              (submit && !state.newPass) ||
              (submit && state.newPass.length < 8) ||
              (submit && !regularExpression.test(state.newPass))
            }
            helperText={errors.newPass}
            autoComplete="off"
          />
          <TextField
            required
            label="Confirm Password"
            size="small"
            variant="outlined"
            name="confirmPass"
            type="password"
            value={state.confirmPass}
            onChange={handleChange}
            error={
              (submit && !state.confirmPass) ||
              (submit && state.newPass !== state.confirmPass)
            }
            helperText={errors.confirmPass}
            autoComplete="off"
          />
        </div>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0.7rem",
            marginRight: "0.7rem",
            marginLeft: "0.7rem",
          }}
        >
          <Button
            sx={{ fontSize: "1rem" , width:"50%" }}
            variant="outlined"
            onClick={clearData}
          >
            Cancel
          </Button>
          <Button
            sx={{ fontSize: "1rem" , width:"50%"}}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </CardActions>
      </CustomCard>
    </ThemeProvider>
  );
}

export default ChangePassword;
