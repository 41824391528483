import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Layout from "./components/layouts/Layout";
import Chat from "./pages/Chat";
import Events from "./pages/Events";
import Profile from "./pages/Profile";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import PopUpForm from "./components/PopUpForm";
import { AuthProvider } from "./components/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import Settings from "./pages/Settings";
import History from "./pages/History";
import Allergy from "./pages/Allergy";
import Medications from "./pages/Meds";
import Addons from "./pages/Addons";
import UploadDocument from "./pages/UploadDocument";
import ChangePassword from "./components/FormComponents/ChangePasswordPopupForm";
import EventPageDetails from "./pages/EventPageDetails";
import PostDetails from "./pages/PostDetails";
import MyPage from "./components/LandingPage/MyPage";
import LandingPage from "./pages/LandingPage/Landing";
import Agenda from "./components/Events/CalendarAndAgenda";
import ChooseSharedRecord from "./components/FormComponents/ChooseSharedRecords";
import SignUp from "./pages/SignUp/SignUp";
import Terms from "./pages/SignUp/Terms";
import ArticleEditor from "./pages/ArticleEditor";
import FeedbackForm from "./components/FormComponents/FeedbackForm";
import CreateNewPostPopupForm from "./components/FormComponents/CreateNewPostPopupForm";
import { FilterProvider } from "./components/feedComponents/FilterProvider";
import DentalArchComponent from "./components/Dental/DentalArchComponent";
import FullArch from "./components/Dental/FullArch";
import PolicyPage from "./pages/PolicyPage";
import ResourceKeys from "./data/ResourceKeys.json";
import NewEvent from "./components/Events/NewEvent.js";
import GroupedPanelsComponent from "./reusableComponents/GroupedPanelsComponent";
import PanelComponent from "./reusableComponents/PanelComponent";

import CreatePostForm from "./components/FormComponents/CreatePostForm";
import SearchUsers from "./pages/SearchUsers";
import TopBarLayout from "./components/layouts/TopBarLayout";
import TestPage from "./pages/TestPage";
import ContactPage from "./pages/ContactPage";
import Community from "./pages/Community.js";

function HomePageWrapper() {
  const isLoggedIn = JSON.parse(localStorage.getItem("userInfo"));

  const renderHomePage = () => {
    if (isLoggedIn) {
      localStorage.setItem("firstVisit", "false");
      return <Home />;
    } else if (localStorage.getItem("firstVisit") === "false") {
      return <Home />;
    } else {
      return <Navigate to="/Landing" />;
    }
  };

  return renderHomePage();
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [open, setOpen] = React.useState(true);

  function onClose() {
    setOpen(false);
  }

  // useEffect(() => {
  //   localStorage.setItem("firstVisit", "true");
  // }, []);
  const contents = [
    {
      encID: 6265548630,
      encDate: "00:00",
      provider: "true",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-22",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: "#e66465",
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
    {
      encID: 0,
      encDate: "00:00",
      provider: "true",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-22",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: "#0e6d73",
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
  ];
  const serviceContents = [
    {
      encID: 0,
      encDate: "00:00",
      provider: "true",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-22",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: "#0e6d73",
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
  ];
  const dropdownData = [
    {
      contents: [contents, serviceContents],
      viewType: 0,
      embeddedFunctions: ["Edit"],
      isViewOrEdit: 1,
      showPagination: true,
    },
  ];
  return (
    <AuthProvider>
      <FilterProvider>
        <BrowserRouter basename="/">
          <Routes>
            {/* Private Routes */}
            <Route element={<RequireAuth />}>
              <Route element={<TopBarLayout />}>
                <Route path="Events" element={<Events />} />
                <Route path="About" element={<About />} />
                <Route path="Profile" element={<Profile />} />
                <Route path="Community" element={<Community />} />
                {/* Updated route */}
                {/* <Route path="Profile/:userId" element={<Profile />} />{" "} */}
                {/* Updated route */}
              </Route>
            </Route>

            {/* Global Routes */}
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePageWrapper />} />
              <Route path="SearchUsers" element={<SearchUsers />} />
            </Route>
            <Route path="/" element={<TopBarLayout />}>
              <Route path="TestPage" element={<TestPage />} />
              <Route path="Landing" element={<LandingPage />} />
              <Route path="Chat" element={<Chat />} />
              <Route path="PopUpForm" element={<PopUpForm />} />
              <Route path="Settings" element={<Settings />} />
              <Route path="Medications" element={<Medications />} />
              <Route path="History" element={<History />} />
              <Route path="Allergy" element={<Allergy />} />
              <Route path="UploadDocument" element={<UploadDocument />} />
              <Route path="ChangePassword" element={<ChangePassword />} />
              <Route path="Addons" element={<Addons />} />
              <Route path="EventPageDetails" element={<EventPageDetails />} />
              <Route path="PostDetails/:FeedID" element={<PostDetails />} />
              <Route path="MyPage" element={<MyPage />} />
              <Route path="Agenda" element={<Agenda />} />
              <Route path="ArticleEditor" element={<ArticleEditor />} />
              <Route path="DentalArch" element={<DentalArchComponent />} />
              <Route path="FullArch" element={<FullArch />} />
              <Route path="PanelComponent" element={<PanelComponent />} />
              <Route
                path="Panels"
                element={<GroupedPanelsComponent dropdownData={dropdownData} />}
              />
              <Route path="ContactPage" element={<ContactPage />} />
              <Route
                path="NewEvent"
                element={<NewEvent open={open} onClose={onClose} />}
              />
              <Route
                path="PrivacyPolicy"
                element={
                  <PolicyPage
                    resourceID={ResourceKeys.resourceID.privacyPolicy}
                  />
                }
              />
              <Route
                path="TermOfUse"
                element={
                  <PolicyPage resourceID={ResourceKeys.resourceID.termOfUse} />
                }
              />
              <Route
                path="CookiePolicy"
                element={
                  <PolicyPage
                    resourceID={ResourceKeys.resourceID.cookiePolicy}
                  />
                }
              />
              <Route
                path="AdvertisingPolicy"
                element={
                  <PolicyPage
                    resourceID={ResourceKeys.resourceID.advertisingPolicy}
                  />
                }
              />
              <Route
                path="ChooseSharedRecord"
                element={<ChooseSharedRecord open={open} onClose={onClose} />}
              />
              <Route
                path="Feedback"
                element={<FeedbackForm open={open} onClose={onClose} />}
              />

              <Route
                path="NewPost"
                element={<CreatePostForm open={open} onClose={onClose} />}
              />

              <Route
                path="CreatePost"
                element={
                  <CreateNewPostPopupForm
                    formTitle="New Post"
                    open={open}
                    onClose={onClose}
                  />
                }
              />

              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="/Login" element={<Login />} />
            <Route path="/SignUp" element={<SignUp isProvider={false} />} />
            <Route path="/Terms" element={<Terms />} />
          </Routes>
        </BrowserRouter>
      </FilterProvider>
    </AuthProvider>
  );
}

export default App;
