import React, { useState } from "react";
import theme from "../../Theme";
import { TextField, ThemeProvider, FormControlLabel, Checkbox, CardActions, Button, MenuItem, Autocomplete } from "@mui/material";
import { Link } from "react-router-dom"
import validator from "validator";

function ThirdStep({ handleBack, handleSubmit , record}) {

  const [submit, setSubmit] = React.useState(false);
  const [error, setError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [state, setState] = React.useState({
    streetAddress: record ? record.address : '',
    suiteNumber: record ? record.addressComp : '',
    city: record ? record.city : '',
    state: record ? record.state : '',
    zipCode: record ? record.zipCode : '',
    checked: false,
  });

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value
    });
  }
  function handleCheckedChange() {
    setState({
      ...state,
      checked: !state.checked
    });
  }
  function handleStateChange(option) {
    setState({
      ...state,
      state: !state.state
    });
  }
  function validation (){
    let errors = "";
    if(state.zipCode === undefined){
      setState({
        ...state,
        zipCode:"",
      });
      errors = "Zip Code is required";
    }
    else if (!validator.isPostalCode(state.zipCode, "US")) {
      errors = "Invalid zip code";
    }

    setZipCodeError(errors);
    return (Object.keys(errors).length === 0)
  };

  function handleSubmitClick() {
    setSubmit(true);
    let ThirdRecord = '';
    if(state.checked === false){
      setError("Please Agree To Our Terms And Conditions")
    }
    else{
      setError("");
    }
    if(validation()){
      if (state.streetAddress !== '' && state.streetAddress !== undefined
        && state.city !== '' && state.city !== undefined
        && state.state !== '' && state.state !== undefined
        && state.zipCode !== '' && state.zipCode !== undefined
        && state.checked !== false
      ) {
        ThirdRecord = {
          address: state.streetAddress,
          addressComp: state.suiteNumber,
          city: state.city,
          state: state.state,
          zipCode: state.zipCode,
        }
        handleSubmit(ThirdRecord);
      }
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <TextField required label="Enter Street Address" size="small" variant="outlined"
        name="streetAddress"
        value={state.streetAddress}
        onChange={handleChange}
        error={submit && !state.streetAddress}
        style={{ width: '85%', marginTop: "10px" }}
      />

      <TextField label="Enter Suite Number" size="small" variant="outlined"
        name="suiteNumber"
        value={state.suiteNumber}
        onChange={handleChange}
        style={{ width: '85%', marginTop: "0px" }}
      />
      <Autocomplete
        required
        options={options}
        getOptionLabel={(option) => option}
        value={state.state}
        onChange={(event, value) => setState({...state, state: value})}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Enter State"
            size="small"
            variant="outlined"
            name="state"
            value={state.state}
            onChange={handleChange}
            error={submit && !state.state}
            style={{ width: '85%', marginTop: "-10px" , textAlign: 'start'}}
          />
        )}
      />

      <TextField required label="Enter City" size="small" variant="outlined"
        name="city"
        value={state.city}
        onChange={handleChange}
        error={submit && !state.city}
        style={{ width: '85%', marginTop: "-20px" }}
      />

      <TextField required label="Enter Zip Code" size="small" variant="outlined"
        name="zipCode"
        value={state.zipCode}
        onChange={handleChange}
        error={(submit && !state.zipCode) || (submit && zipCodeError)}
        helperText = {zipCodeError ? zipCodeError : "" }
        style={{ width: '85%', marginTop: "-30px" }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checked}
            onChange={handleCheckedChange}
            name="checked"
            color="primary"
          />
        }
        label={
          <span style={{ display: "flex", alignItems: "center" }}>
            {/* <a href="Terms" target="_blank" rel="noopener noreferrer">
              I agree to the terms and conditions.
            </a> */}
            <p style={{ fontSize: '14px' }}>
              By continuing, you agree to HealthBloQ's&nbsp;
            </p>
            <Link to="/Terms" target="_blank" style={{ color: 'blue', borderBottom: '1px solid transparent', fontSize: '14px' }}
              onMouseEnter={(e) => e.target.style.borderBottom = '1px solid blue'}
              onMouseLeave={(e) => e.target.style.borderBottom = '1px solid transparent'}
            >
              {/* <span
                style={{
                  borderBottom: '1px solid transparent',
                  transition: 'border-color 0.2s'
                }}
              > */}
              Terms and Conditions
              {/* </span> */}
            </Link>
          </span>
        }
        style={{ marginTop: "-40px", marginLeft: "30px", display: "flex", alignItems: "center" }}
      />
      {
        error && (
          <p style={{ fontSize: '14px', color: 'red' , display: "flex", marginLeft: "73px"}}>{error}</p>
        )
      }
      <CardActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <Button sx={{ fontSize: '1rem', width: '300px' }} onClick={handleBack}>
          Back
        </Button>
        <Button sx={{ fontSize: '1rem', width: '300px' }} variant="contained" onClick={handleSubmitClick}>
          Submit
        </Button>
      </CardActions>
      {/* <div style={{marginTop:"-70px"}}></div> */}
    </ThemeProvider>
  );
}
export default ThirdStep

const options = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];