import React, { useState } from "react";
import theme from "../../Theme";
import { TextField, ThemeProvider, Button } from "@mui/material";
import { DialogActions } from "@material-ui/core";

function SecondStep({ handleNext, handleBack, record }) {
  const [submit, setSubmit] = React.useState(false);
  const [startBiggerThanEnd, setStartBiggerThanEnd] = useState(false);
  const [state, setState] = React.useState({
    speaker: record.Speaker !== undefined ? record.Speaker : "",
    speakerBio: record.SpeakerBio !== undefined ? record.SpeakerBio : "",
    host: record.Host !== undefined ? record.Host : "",
    startDate:
      record.StartDate !== undefined ? record.StartDate.split(" ")[0] : "",
    startTime:
      record.StartDate !== undefined ? record.StartDate.split(" ")[1] : "",
    endDate: record.EndDate !== undefined ? record.EndDate.split(" ")[0] : "",
    endTime: record.EndDate !== undefined ? record.EndDate.split(" ")[1] : "",
  });

  const [error, setError] = useState({
    time: "",
  });

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }

  const handleStartDateChange = (event) => {
    if (state.endDate !== "" && event.target.value > state.endDate) {
      setStartBiggerThanEnd(true);
      setState({
        ...state,
        startDate: "",
      });
    } else {
      setStartBiggerThanEnd(false);
      setState({
        ...state,
        startDate: event.target.value,
      });
    }
  };
  const handleEndDateChange = (event) => {
    if (state.startDate > event.target.value) {
      setState({
        ...state,
        endDate: "",
      });
      setStartBiggerThanEnd(true);
    } else {
      setStartBiggerThanEnd(false);
      setState({
        ...state,
        endDate: event.target.value,
      });
    }
  };

  function validations() {
    let error = {};
    const start = new Date(state.startDate + "T" + state.startTime);
    const end = new Date(state.endDate + "T" + state.endTime);
    if (end <= start) {
      console.log("time1 is before time2");
      error.time = "Event Ends Before It Starts";
    }
    setError(error);
    console.log("error ", error);
    console.log("ret ", Object.keys(error).length === 0);
    return Object.keys(error).length === 0;
  }
  async function handleOnNextClick() {
    console.log("state ", state);
    setSubmit(true);
    let SecondRecord = "";
    if (validations()) {
      if (
        state.speaker !== "" &&
        state.speaker !== undefined &&
        state.speakerBio !== "" &&
        state.speakerBio !== undefined &&
        state.startDate !== "" &&
        state.startDate !== undefined &&
        state.endDate !== "" &&
        state.endDate !== undefined
      ) {
        SecondRecord = {
          Speaker: state.speaker,
          SpeakerBio: state.speakerBio,
          Host: state.host,
          StartDate: state.startDate + " " + state.startTime,
          EndDate: state.endDate + " " + state.endTime,
        };
        setSubmit(false);
        handleNext(SecondRecord);
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <TextField
        required
        label="Speaker"
        size="small"
        variant="outlined"
        name="speaker"
        value={state.speaker}
        onChange={handleChange}
        error={submit && !state.speaker}
      />
      <TextField
        required
        label="Speaker Bio"
        size="small"
        variant="outlined"
        name="speakerBio"
        value={state.speakerBio}
        onChange={handleChange}
        error={submit && !state.speakerBio}
      />
      <TextField
        label="Host"
        size="small"
        variant="outlined"
        name="host"
        value={state.host}
        onChange={handleChange}
      />
      <div style={{ flexDirection: "row", alignItems: "flex-start" }}>
        <TextField
          required
          label="Start Date"
          size="small"
          variant="outlined"
          type="Date"
          InputLabelProps={{ shrink: true }}
          name="startDate"
          value={state.startDate}
          onChange={handleStartDateChange}
          error={startBiggerThanEnd || (submit && !state.startDate)}
          helperText={
            startBiggerThanEnd ? "Event Ends Before It Starts" : ""
          }
          style={{ width: "48.5%", marginRight: "12px" }}
        />
        <TextField
          required
          size="small"
          label="Start time"
          variant="outlined"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          name="startTime"
          value={state.startTime}
          onChange={handleChange}
          error={(submit && !state.startTime) || error.time}
          helperText={error.time}
          style={{ width: "48.5%" }}
        />
      </div>
      <div style={{ flexDirection: "row", alignItems: "flex-start" }}>
        <TextField
          required
          label="End Date"
          size="small"
          variant="outlined"
          type="Date"
          InputLabelProps={{ shrink: true }}
          name="endDate"
          value={state.endDate}
          onChange={handleEndDateChange}
          error={startBiggerThanEnd || (submit && !state.endDate)}
          helperText={
            startBiggerThanEnd ? "Event Ends Before It Starts" : ""
          }
          style={{ width: "48.5%", marginRight: "12px" }}
        />
        <TextField
          required
          size="small"
          label="End time"
          variant="outlined"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          name="endTime"
          value={state.endTime}
          onChange={handleChange}
          error={(submit && !state.endTime) || error.time}
          helperText={error.time}
          style={{ width: "48.5%" }}
        />
      </div>
      <DialogActions>
        <Button sx={{ fontSize: "1rem", width: "300px" }} onClick={handleBack}>
          Back
        </Button>
        <Button
          sx={{ fontSize: "1rem", width: "300px" }}
          variant="contained"
          onClick={handleOnNextClick}
        >
          Next
        </Button>
      </DialogActions>
    </ThemeProvider>
  );
}
export default SecondStep;
