import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import "../App.css";
import { Grid, Container } from "@mui/material";
import { Skeleton } from "@mui/material";
import theme from "../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import Feed from "../components/feedComponents/Feed";
import AdBanner from "../components/AdBanner";
import CreateNewPostButton from "../components/CreateNewPostButton";

import Pharma from "../assets/Pharma.jpg";
import fitbitImage from "../assets/fitbitad.jpg";
import claritin from "../assets/claritin.png";
import CreateFeedbackButton from "../components/CreateFeedbackButton";

const adsList = [
  {
    image: Pharma,
    link: "https://www.example.com/ad1",
    tooltip: "Sample Ad 1",
  },
  {
    image: fitbitImage,
    link: "https://www.example.com/ad2",
    tooltip: "Sample Ad 2",
  },
  {
    image: claritin,
    link: "https://www.example.com/ad3",
    tooltip: "Sample Ad 3",
  },
];

function useCardStyle() {
  const [cardStyle, setCardStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth <= 1280) {
        setCardStyle({
          display: "none",
        });
      } else {
        setCardStyle({
          // marginRight: "auto",
          // marginLeft:'350px',
          position: "sticky",
          top: "86px",
          height: "600px",
          width: "40%",
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return cardStyle;
}

function Home() {
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % adsList.length);
    }, 20000); // 20 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentAd = adsList[currentAdIndex];
  const cardStyle = useCardStyle();

  const loading = !currentAd; // Set this to true while fetching data from the API

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ marginLeft: windowWidth <= 1280 ? "" : "80px" }}
        >
          <Grid item xs={12} md={8}>
            {loading ? (
              <>
                <Skeleton variant="rectangular" height={200} />
              </>
            ) : (
              <Feed margin="auto" onProfile={false} />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={cardStyle}>
              {loading ? (
                <Skeleton variant="rectangular" width="150px" height={200} />
              ) : (
                <AdBanner
                  image={currentAd.image}
                  link={currentAd.link}
                  tooltip={currentAd.tooltip}
                  imageWidth="150px"
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <CreateFeedbackButton />
    </ThemeProvider>
  );
}

export default Home;
