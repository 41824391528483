import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Paper,
  Typography,
  TextField,
  Button,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Chip,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";

import CustomPopUpForm from "../CustomPopUpForm";
import CustomAlert from "../CustomAlert";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
import ConnectionCardProfileInfo from "../profileComponents/connectionComponents/ConnectionCardProfileInfo";
import ConnectionStatus from "../../data/ConnectionStatus.json";
import { GetConnectionsByStatus } from "../../helper/APIHelper";
import { GrantFolderAccess } from "../../services/HealthServiceHelper";

const ShareRecordsPopupForm = ({ open, onClose, records, recordIDs }) => {
  const [error, setError] = React.useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [connections, setConnections] = useState([]);

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  useEffect(() => {
    const fetchConnectedConnections = async () => {
      const connectedConnections = await GetConnectionsByStatus(
        ConnectionStatus.status.Connected
      );
      setConnections(connectedConnections);
    };
    fetchConnectedConnections();
  }, []);

  // Call resetState when the open prop changes
  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open]);

  const resetState = () => {
    setSearchValue("");
    setSelectedConnections([]);
  };

  async function handleSubmit() {
    if (selectedConnections.length === 0) {
      // alertRef.current.showAlert(); // Show the alert if no connections are selected
      setError(
        "Please select <strong>at least one</strong> connection to share records with."
      );
      handleEditContactInfoClick();
    } else {
      let toMemberID = [];
      selectedConnections.forEach((connection) => {
        // console.log(connection);
        toMemberID.push(connection.memberID);
      });
      const folderIDs = recordIDs.map((integer) => String(integer));
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const memberID = userInfo.memberID;
      let shareRecord = {
        fromMemberID: memberID,
        folders: folderIDs,
        connections: toMemberID,
      };
      console.log("shareRecord ", shareRecord);
      const response = await GrantFolderAccess(shareRecord);
      console.log("response ", response);
      if (response.ok) {
        onClose();
        setError("");
        handleEditContactInfoClick();
      } else {
        setError("Unable to Share document please try again.");
        handleEditContactInfoClick();
      }
    }
  }

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleConnectionChange = (connection) => {
    if (
      !selectedConnections.find((item) => item.memberID === connection.memberID)
    ) {
      setSelectedConnections([...selectedConnections, connection]);
    }
    setSearchValue("");
  };

  const handleClearConnection = (connectionIndex) => {
    setSelectedConnections(
      selectedConnections.filter((_, index) => index !== connectionIndex)
    );
  };

  const renderSharedRecords = records.map((record, index) => (
    <React.Fragment key={index}>
      <ListItem>
        <ListItemIcon>
          <FolderIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={record} />
      </ListItem>
      {index !== records.length - 1 && <Divider />}
    </React.Fragment>
  ));

  const filteredConnections = connections
    .filter(
      (connection) =>
        connection.username.toLowerCase().includes(searchValue.toLowerCase()) ||
        (
          connection.firstName.toLowerCase() +
          " " +
          connection.lastName.toLowerCase()
        ).includes(searchValue.toLowerCase())
    )
    .slice(0, 10);

  const renderSelectedConnections = selectedConnections.map(
    (connection, connectionIndex) => (
      <Chip
        key={connectionIndex}
        label={connection.username}
        onDelete={() => handleClearConnection(connectionIndex)}
      />
    )
  );

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef}
        // title="Selection Error"
        // type="error"
        // content="Please select <strong>at least one</strong> connection to share records with."
        title={error ? "Error" : "Success"}
        type={error ? "error" : "success"}
        content={error ? error : "Form Submitted."}
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <CustomPopUpForm
        open={open}
        onClose={onClose}
        title="Give Access to Folder"
        onSubmit={handleSubmit}
        onCancel={onClose}
        showSubmit={true}
      >
        <div className="grid grid-cols-1 gap-4">
          <h3>Folders to be Shared:</h3>
          <div>{renderSharedRecords}</div>

          <TextField
            label="Search Connections"
            size="small"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {searchValue && (
            <Paper
              sx={{
                maxHeight: "200px",
                overflowY: "scroll",
                zIndex: 10,
                width: "100%",
              }}
            >
              {filteredConnections.map((connection) => (
                <MenuItem
                  key={connection.memberID}
                  onClick={() => handleConnectionChange(connection)}
                >
                  <ConnectionCardProfileInfo
                    connection={connection}
                    disableLinks={true}
                  />
                </MenuItem>
              ))}
            </Paper>
          )}

          <div style={{ marginTop: "10px" }}>{renderSelectedConnections}</div>
        </div>
      </CustomPopUpForm>
    </ThemeProvider>
  );
};

export default ShareRecordsPopupForm;
