import React from "react";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import { UpdateConnection } from "../../helper/APIHelper.js";
import Remove from "../../data/ConnectionStatus.json";
import CustomMessageBox from "../CustomMessageBox.js";

function DisconectPopupForm({
  open,
  onClose,
  selectedContact,
  setRequestStatus,
}) {
  console.log("SelectedContact: ", selectedContact);
  const handleSubmit = () => {
    UpdateConnection(selectedContact.conDetID, Remove.status.Remove);
    setRequestStatus("disconnect");
    onClose();
  };

  return (
    <>
      <CustomMessageBox
        open={open}
        onClose={onClose}
        icon={
          <ConnectWithoutContactIcon
            sx={{ fontSize: 40, marginRight: "10px" }}
          />
        }
        question="Are you sure you want to disconnect?"
        onConfirm={handleSubmit}
        title="Disconnect"
      />
    </>
  );
}

export default DisconectPopupForm;
