import React, { useState, useEffect } from "react";

import {
  TextField,
  Box,
  Grid,
  Pagination,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import { GetRecentEncoutersbyProvider } from "../../../services/HealthServiceHelper";
import theme from "../../../Theme";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import PatientInfoBanner from "./PatientInfoBanner";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function PatientsEncounters({
  providerID,
  patientID,
  setEncID,
  data,
  setData,
  setPatientTab,
  setPatientID,
  setAddEncounter,
}) {
  useEffect(() => {
    const cachedData = localStorage.getItem("patientEncounters");

    if (cachedData) {
      setData(JSON.parse(cachedData));
      setTotalPages(Math.ceil(JSON.parse(cachedData).length / itemsPerPage));
      setLoading(false);
    } else {
      fetchData();
    }
    setCurrentPage(1);
  }, [providerID, patientID]);

    const fetchData = async () => {
    setLoading(true);
    let temp = await GetRecentEncoutersbyProvider(providerID, patientID);
    setData(temp);
    setTotalPages(Math.ceil(temp.length / itemsPerPage));
    setLoading(false);
    localStorage.setItem("patientEncounters", JSON.stringify(temp));
  }

  // Loading state - used for loading wheel
  const [loading, setLoading] = useState(true);

  //Search
  const [searchValue, setSearchValue] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const renderLink = (content, to) => {
    return <Link to={to}>{content}</Link>;
  };
  const renderEncounters = () => {
    if (data === null || data === undefined || data.length === 0) {
      return (
        <Grid item xs={12} lg={9}>
          <p>No Encounters</p>
        </Grid>
      );
    }
    // Apply search filter
    const filteredData = data.filter((patient) =>
      patient.encType.toLowerCase().includes(searchValue.toLowerCase())
    );
    return filteredData
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((patient, index) => (
        <Grid key={index} item xs={12}>
          <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                marginLeft: "24px",
                flexGrow: 1,
                width: "100%",
                justifyContent: "center",
              }}
              onClick={() => setEncID(patient.encID)}
            >
              <Typography
                component="div"
                sx={{ textDecoration: "none", color: "inherit", marginRight:"1rem" }}
              >
                Encounter Type : {patient.encType}
              </Typography>
              <Typography
                sx={{ color: "text.secondary", fontSize: "0.875rem" , marginRight:"1rem"}}
              >
                Creation Date : {patient.createdDate.split("T").shift()}
              </Typography>
              <Chip
                key={uuid}
                label={patient.encStatus}
                variant="outlined"
                sx={{
                  height: "28px",
                  backgroundColor: "white",
                  color: "#7a7a7a",
                  border: "1px color gray",
                  marginBottom: "15px",
                  textAlign: "left",
                  margin: "0.3rem",
                }}
              />
            </Box>
          </Box>
          <hr
            key={index}
            style={{
              borderColor: "#B6B8BB",
              borderWidth: "1px",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          />
        </Grid>
      ));
  };

  function handleAddEncounterButtonClick() {
    console.log("add encounter ");
    setAddEncounter(true);
    setEncID("");
  }
  function handleForwardArrowClick() {
    setPatientTab(0);
  }
  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <IconButton onClick={handleForwardArrowClick}>
            <ArrowBackIosNewIcon color="primary" />
          </IconButton>
        </div>
        <div>
          <IconButton>
            <ArrowForwardIosIcon color="disabled" />
          </IconButton>
        </div>
      </div>
      <Box mb={2}>
        {data !== null &&
          data !== undefined &&
          data.length !== 0 &&
          patientID !== 0 && 
          !loading && (
            <div
              style={{
                justifyContent: "center",
                textAlign: "-webkit-center",
                marginBottom: "1rem",
              }}
            >
              <Grid key={uuid} item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ width: "100%", justifyContent: "center" }}
                >
                  <PatientInfoBanner PatientInfo={data[0]} />
                </Box>
              </Grid>
            </div>
          )}
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            size="small"
            label="Search Encounters"
            fullWidth
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
          <IconButton
            onClick={handleAddEncounterButtonClick}
            sx={{
              backgroundColor: "gray.main",
              ml: 2,
              "&:hover": {
                backgroundColor: "gray.light",
              },
            }}
          >
            <AddIcon sx={{ color: "common.white" }} />
          </IconButton>
        </Box>
      </Box>

      <Box mt={2}>
        {/* Loading either wheel or connections depending on API state */}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="spinner"></div>
          </Box>
        ) : (
          data !== undefined && (
            <Grid container spacing={2}>
              {renderEncounters()}
            </Grid>
          )
        )}
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
    </ThemeProvider>
  );
}

export default PatientsEncounters;
