import React from "react";
import { AppBar, Tabs, Tab } from "@mui/material";

function TabComponent({
  tabIndex,
  handleTabChange,
  tabNames ,
}) {
  return (
    <AppBar position="static" color="white" sx={{marginBottom:'1rem'}}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        {
          tabNames && (
            tabNames.map((tabName , index)=> (
              <Tab key={index} label={tabName}/>
            ))
          )
        }
      </Tabs>
    </AppBar>
  );
}

export default TabComponent;