import React from "react";
import { Link } from "react-router-dom";

import { Typography, Box } from "@mui/material";
import { Share } from "@mui/icons-material";

const ConnectionProfileInfo = ({
  connection,
  disableLinks = false,
  setEncID,
  setPatientID,
  setPatientTab,
}) => {
  function handleOnClick() {
    if (setEncID !== undefined) {
      setEncID(connection.encID);
    } else if (setPatientID !== undefined) {
      setPatientID(connection.personID);
      setPatientTab(1);
      localStorage.removeItem("patientEncounters");
    }
  }
  const renderLink = (content, to) => {
    return disableLinks ? (
      content
    ) : (
      <Link to={to} state={{ userID: connection.memberID }}>
        {content}
      </Link>
    );
  };

  return (
    <>
      {renderLink(
        <img
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            objectFit: "cover",
            marginLeft: "16px",
            maxWidth: "300px",
          }}
          src={connection.profilePicture}
          alt={connection.username}
        />,
        `/Profile/`
      )}
      <Box sx={{ marginLeft: "24px", flexGrow: 1 }} onClick={handleOnClick}>
        <Typography
          component="div"
          sx={{ textDecoration: "none", color: "inherit" }}
        >
          {connection.firstName + " " + connection.lastName}
        </Typography>
        <Typography sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
          {connection.username}
        </Typography>
        {/* {connection.refStatus && (
          <>
            <Typography sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
              {connection.refStatus}
            </Typography>
          </>
        )} */}
        {/* <Typography sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
            {connection.startDate.substring(0, 10)}
          </Typography> */}
        {/* {connection.sharedRecords &&
          renderLink(
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: "text.secondary",
                fontSize: "0.875rem",
              }}
            >
              Sharing Records with User
              <Share sx={{ marginRight: "4px", fontSize: "1rem" }} />
            </Typography>,
            `/profile/${connection.memberID}`
          )} */}
      </Box>
    </>
  );
};

export default ConnectionProfileInfo;
