import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import theme from "../Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import HeatlhBloqIcon from "../assets/healthbloqicon.png";
import { useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AppsIcon from "@mui/icons-material/Apps";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "../components/AuthProvider";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import magnifier from "../assets/magnifier.svg";
import calendar from "../assets/calendar.svg";
import about2 from "../assets/about2.svg";
import network2 from "../assets/network2.svg";
import MultipleChipForLeftNavBar from "./MultipleChipForLeftNavBar";
import FeedbackForm from "../components/FormComponents/FeedbackForm";
import FooterComponent from "./FooterComponent";
import { v4 as uuid } from "uuid";
import SearchBar from "./SearchBar";
import { GetUsersByName } from "../helper/APIHelper";
import ConnectionCard from "./profileComponents/connectionComponents/ConnectionCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    marginLeft: theme.spacing(1),
  },
}));

export default function TopBar(props) {
  const [open, setOpen] = React.useState(false);

  function onClose() {
    setOpen(false);
  }

  const [isLoggedIn, setIsLoggedIn] = React.useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const location = useLocation();
  let auth = useAuth();

  //TODO: Fix this please. This suppose to happened in AuthProvider and not here.
  auth.user = JSON.parse(localStorage.getItem("userInfo"));
  let navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let memberID = userInfo !== null ? userInfo.memberID : "";

  // Render menu on the left using 3 arrays. Menu_values to store
  // captions, elements to store icons and menu path to store the string path.
  const MENU_VALUES = [
    "Discover HealthbloQ",
    "Find Events",
    "About Us",
    "Find Users",
  ];
  const RIGHT_MENU_VALUE = ["My Profile"];
  //const unique_id = uuid(); // Unique ID for Elements array
  const ELEMENTS = [
    <img src={magnifier} alt="Magnifier Icon" style={{ width: "30px" }} />,
    <img src={calendar} alt="Calendar Icon" style={{ width: "30px" }} />,
    <img src={about2} alt="About Icon" style={{ width: "30px" }} />,
    <img src={network2} alt="Network Icon" style={{ width: "30px" }} />,
  ];
  const MENU_PATH = ["/Landing", "/Agenda", "/About", "/SearchUsers"];
  //const RIGHT_MENU_PATH = ["/Profile/" + memberID, "/Settings"];
  const RIGHT_MENU_PATH = ["/Profile/", "/Settings"];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [profileMenuExpanded, setProfileMenuExpanded] = useState(false);
  const [users, setUsers] = useState([]);

  const handleProfileMenuExpand = () => {
    setProfileMenuExpanded(!profileMenuExpanded);
  };

  const classes = useStyles();

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogoutSubmit = (e) => {
    e.preventDefault();
    auth.signout(() => navigate("/Login"));
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    navigate("/Login");
  };

  // Varibles for top-right menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = "primary-search-account-menu";
  const searchRef = useRef();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const buttonStyles = {
    backgroundColor: "#A8A8A8",
    color: "#ffffff",
    margin: "5px",
    "&:hover": {
      backgroundColor: "#A8A8A8",
    },
  };

  function handleLeftNavBar(text, index) {
    navigate(`${MENU_PATH[index]}`);
  }

  let loginButton;
  if (auth.user !== null) {
    loginButton = <MenuItem onClick={handleLogoutSubmit}>Logout</MenuItem>;
  } else {
    loginButton = <MenuItem onClick={handleLoginClick}>Login</MenuItem>;
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {RIGHT_MENU_VALUE.map((text, index) => (
        <MenuItem key={uuid()} onClick={handleMenuClose}>
          <Link to={RIGHT_MENU_PATH[index]}>{text}</Link>
        </MenuItem>
      ))}
      {/* <MenuItem onClick={handleLogoutSubmit}>Logout</MenuItem> */}
      {loginButton}
    </Menu>
  );

  const chatIconButton = (
    <Link to="/Chat">
      <IconButton
        size="large"
        aria-label="show 4 new messages"
        //color="primary"
        sx={buttonStyles}
      >
        <Badge badgeContent={4} color="error">
          <ChatBubbleIcon />
        </Badge>
      </IconButton>
    </Link>
  );

  const handlePatientSignUpClick = (event) => {
    event.preventDefault();
    navigate("/SignUp");
  };
  const handlePatientLogInClick = (event) => {
    event.preventDefault();
    navigate("/Login");
  };

  // Icon buttons on the right
  const topRightMenu = (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        {auth.user !== null ? chatIconButton : null}
        <Link to="/Addons">
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            //color="inherit"
            sx={buttonStyles}
          >
            <Badge badgeContent={17} color="error">
              <AppsIcon />
            </Badge>
          </IconButton>
        </Link>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          //color="inherit"
          sx={buttonStyles}
        >
          <AccountCircle />
        </IconButton>
      </Box>
    </>
  );

  const LandingPageRightMenu = (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        {/* <Button color="primary" onClick={handleProviderSignUpClick}>
          Provider Sign Up
        </Button> */}
        <Button
          color="primary"
          variant="outlined"
          onClick={handlePatientLogInClick}
          sx={{ marginRight: "10px" }}
        >
          Log In
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handlePatientSignUpClick}
        >
          Sign Up
        </Button>
      </Box>
    </>
  );

  function IsLandingPage() {
    if (location.pathname === "/Landing") {
      return true;
    }
    return false;
  }

  const mobileMenuId = "primary-search-account-menu-mobile";
  const topRightMobileMenu = (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        // color="inherit"
        sx={buttonStyles}
      >
        <MoreIcon />
      </IconButton>
    </Box>
  );

  const chatMobileMenuItems = (
    <Link to="/Chat">
      <MenuItem onClick={handleMobileMenuClose}>
        <IconButton size="large" aria-label="show 4 new chats" color="inherit">
          <Badge badgeContent={4} color="error">
            <ChatBubbleIcon />
          </Badge>
        </IconButton>
        <p>Chat</p>
      </MenuItem>
    </Link>
  );

  // Show Login and Logout Button on Mobile
  const NotSignInMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        style: { minWidth: "8rem" },
      }}
    >
      <Link to="/Login">
        <MenuItem>
          <ListItemText>Login</ListItemText>
        </MenuItem>
      </Link>
      <Link to="/Signup">
        <MenuItem>
          <ListItemText>SignUp</ListItemText>
        </MenuItem>
      </Link>
    </Menu>
  );
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        style: { minWidth: "250px" },
      }}
    >
      {auth.user !== null ? chatMobileMenuItems : null}
      <Link to="/Apps">
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <AppsIcon />
            </Badge>
          </IconButton>
          <p>Apps</p>
        </MenuItem>
      </Link>
      <MenuItem onClick={handleProfileMenuExpand}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
        {profileMenuExpanded ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={profileMenuExpanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {RIGHT_MENU_VALUE.map((submenuText, submenuIndex) => (
            <Link key={uuid()} to={RIGHT_MENU_PATH[submenuIndex]}>
              <MenuItem>
                <ListItemText
                  primary={submenuText}
                  onClick={handleMobileMenuClose}
                />
              </MenuItem>
            </Link>
          ))}
          {loginButton}
        </List>
      </Collapse>
    </Menu>
  );

  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <div className={classes.container}>
            <Link to="/">
              <img
                src={HeatlhBloqIcon}
                height={50}
                width={50}
                alt="healthbloq icon"
              />
            </Link>
            <Link to="/" className={classes.link}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ color: "#0E6D73" }}
              >
                HealthBloQ
              </Typography>
            </Link>
          </div>
          {/* {logoutButton} */}
          {!isLoggedIn ? LandingPageRightMenu : topRightMenu}
          {/* {LandingPageRightMenu} */}
          {topRightMobileMenu}
        </Toolbar>
      </AppBar>
      {renderMenu}
      {!isLoggedIn ? NotSignInMobileMenu : renderMobileMenu}
      {open && <FeedbackForm open={open} onClose={onClose} />}
    </ThemeProvider>
  );
}
