import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Theme";

const ArticleCard = ({ feedID ,image, title, description , size }) => {
  return (
    <ThemeProvider theme={theme}>
      <Card id={feedID} sx={{ display: "flex", margin: "20px" , height:'auto' ,maxHeight:'800px' , borderRadius:'16px'}}>
        <Box sx={{ width: `${size}px`, margin: "2rem" }}>
          <CardMedia
            component="img"
            image={image}
            alt="landing page image"
            // sx={{  objectFit: "cover", }}
            style={{
              height: "auto",
              maxHeight: `${size}px`,
              width: "auto",
              maxWidth: `${size}px`,
            }}
          />
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: "25px",
            }}
            className="text-center"
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            borderLeft: "1px solid gray",
            marginTop: "2rem",
            marginBottom: "2rem",
            flexGrow: 1,
            overflowY: "scroll",
          }}
        >
          <CardContent>
            <Typography
              // sx={{ overflowX: "scroll" }}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: description }}
            ></Typography>
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default ArticleCard;
