import React from "react";
import  healthbloqicon from "../assets/healthbloqicon.png";

function NotFound() {
  return (
    <div style={styles.container}>
      <img src={healthbloqicon} alt="Error" style={styles.errorImage} />
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.text}>The page you are looking for does not exist.</p>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  },
  errorImage: {
    width: "20rem",
    height: "auto",
  },
  heading: {
    fontSize: "2rem",
    margin: "1rem 0",
  },
  text: {
    fontSize: "1.2rem",
    margin: "0.5rem 0",
  },
};

export default NotFound;
