import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Chip,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Grid,
  Button,
  Skeleton,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/system";
import { GetFilters } from "../../helper/APIHelper.js";
import ClearIcon from "@mui/icons-material/Clear";

import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import feedFilterDataOrig from "../../data/feedFilterData";
import captionsData from "../../data/captionsData";
import { Clear } from "@material-ui/icons";
import { GetFilterContext } from "../feedComponents/FilterProvider";

const sampleImage1 = "https://source.unsplash.com/random/500x500";
const sampleImage2 = "https://source.unsplash.com/random/501x501";
const topicGroupsResourcesKey = ["TopicGroups"];
const librariesResourcesKey = ["Libraries"];

const CarouselWrapper = styled("div")({
  display: "flex",
  gap: "16px",
  overflowX: "scroll",
  padding: "16px 0",
});

const CarouselItem = styled(Box)(({ theme, active }) => ({
  minWidth: "160px",
  height: "200px",
  borderRadius: "8px",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  marginRight: theme.spacing(0.2),
  border: active ? `2px solid ${theme.palette.primary.main}` : "none",
}));

// Adjust the CarouselItem styling for text-only carousel
const TextCarouselItem = styled(Box)(({ theme, active }) => ({
  minWidth: "auto", // Change minWidth to auto
  minHeight: "30px", // Change the height to minHeight
  borderRadius: "8px",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  marginRight: theme.spacing(0.2),
  padding: "3px",
  display: "flex", // Add flex for centering the text
  alignItems: "center", // Add alignItems to center the text vertically
  justifyContent: "center", // Add justifyContent to center the text horizontally
  flexShrink: 0, // Prevent the text items from shrinking
  flexGrow: 0, // Prevent the text items from growing
}));

const CarouselImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  // borderRadius: '8px',
});

const PanelCaption = styled(Typography)(({ theme, active }) => ({
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "100%",
  padding: "8px",
  textAlign: "center",
  backgroundColor: active
    ? theme.palette.primary.main
    : theme.palette.grey[200], // Use primary color if active
  color: active ? "white" : "black", // Use primary color if active,
}));

const TextPanelCaption = styled(Typography)(({ theme, active }) => ({
  // Remove position, bottom, left, and width properties
  padding: "8px",
  borderRadius: "8px",
  textAlign: "center",
  backgroundColor: active
    ? theme.palette.primary.main
    : theme.palette.grey[200], // Use primary color if active
  color: active ? "white" : "black", // Use primary color if active
  whiteSpace: "nowrap", // Prevent the text from wrapping to the next line
}));

function useCardStyle() {
  const [cardStyle, setCardStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      setCardStyle({
        width: "100%",
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return cardStyle;
}

function FeedFilter({
  activeState,
  onFilterStateChange,
  filterTagName,
  filters,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [loading, setLoading] = useState(true);
  let filterContext = GetFilterContext();
  let defaultFilterValue = {
    index: 0,
    count: 5,
    topic: "",
    library: "",
    libraryCaption: "",
    tag: "",
    search: "",
    memberID: "",
    byMemberID: "",
  };
  //const [filterObj, setFilterObj] = useState(defaultFilterValue);
  const [filterObj, setFilterObj] = useState(filterContext.filter);
  const [topicGroupsData, setTopicGroupsData] = useState([]);
  const [librariesData, setLibrariesData] = useState([]);
  useEffect(() => {
    GetFeedFilters();
  }, []);

  useEffect(() => {
    SyncLandingPageCarousel();
  }, [topicGroupsData]);

  async function GetFeedFilters() {
    let temp = await GetFilters(false, 0);
    setTopicGroupsData(temp.TopicGroups);
    setLibrariesData(temp.Libraries);
    setLoading(false);
  }

  const cardStyle = useCardStyle();

  // handle tag name updates for chip
  useEffect(() => {
    if (filterTagName) {
      const newFilterObj = {
        ...filterObj,
        tag: filterTagName,
        library: "", // Clear the library filter
        libraryCaption: "", // Clear the libraryCaption filter
        topic: "", // Clear the topic filter
      };
      setFilterObj(newFilterObj);
      filters(newFilterObj);
      setActiveItem(null);
      onFilterStateChange(null);
    }
  }, [filterTagName]);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    filterObj.search = searchValue;
    filters(filterObj);
  };

  const handleImageClick = (index, caption) => {
    if (activeState === index) {
      setActiveItem(null);
      onFilterStateChange(null);
      filters(defaultFilterValue);
      filterObj.topic = "";
    } else {
      setActiveItem(index);
      filterObj.library = ""; // Clear the library filter
      filterObj.libraryCaption = ""; // Clear the libraryCaption filter
      filterObj.tag = ""; // Clear the tag filter
      filterObj.topic = caption;
      filters(filterObj);
      onFilterStateChange(index);
    }
  };

  const handleLibraryClick = (index, libraryID, libraryCaption) => {
    if (activeState == index) {
      setActiveItem(null);
      onFilterStateChange(null);
      filters(defaultFilterValue);
      filterObj.library = "";
      filterObj.libraryCaption = "";
    } else {
      setActiveItem(index);
      filterObj.topic = ""; // Clear the topic filter
      filterObj.tag = ""; // Clear the tag filter
      filterObj.library = libraryID;
      filterObj.libraryCaption = libraryCaption;
      filters(filterObj);
      onFilterStateChange(index);
    }
  };

  let values = [
    filterObj.libraryCaption,
    filterObj.tag,
    filterObj.topic,
    filterObj.search,
  ];

  const renderFilter = values.map(
    (filterText, filterIndex) =>
      filterText !== "" && (
        <Chip
          key={filterIndex}
          label={filterText}
          onDelete={() => handleClearFilter(filterIndex)}
        />
      )
  );

  function handleClearFilter(filterIndex) {
    const newFilterObj = { ...filterObj };
    filterContext.clearFilterValue();
    switch (filterIndex) {
      case 0:
        newFilterObj.libraryCaption = "";
        newFilterObj.library = "";
        break;
      case 1:
        newFilterObj.tag = "";
        break;
      case 2:
        newFilterObj.topic = "";
        break;
      case 3:
        newFilterObj.search = "";
        break;
      default:
        break;
    }
    setFilterObj(newFilterObj);
    filters(newFilterObj);
    setActiveItem(null);
    onFilterStateChange(null);
    setSearchValue("");
  }

  const findTopicIndex = (item) => {
    if (item !== null) {
      return item.resourceName === filterContext.filter.topic;
    }
  };

  function SyncLandingPageCarousel() {
    let topicIndex = topicGroupsData.findIndex(findTopicIndex);
    activeState = topicIndex;
    setActiveItem(topicIndex);
    onFilterStateChange(topicIndex);
    filters(filterContext.filter);
  }
  //
  return (
    <Card style={cardStyle}>
      <CardContent>
        {/* Search bar */}
        <Box mb={2}>
          <form onSubmit={handleSearchSubmit}>
            <TextField
              label="Search"
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>

        {/*Show filters as buttons when they are seleted */}
        <Box>{renderFilter}</Box>

        {/* Carousel with images */}
        <Box mb={2}>
          <CarouselWrapper>
            {loading
              ? [...Array(5)].map((_, index) => (
                  <CarouselItem key={`loading-${index}`}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    />
                  </CarouselItem>
                ))
              : topicGroupsData.map((item, index) => (
                  <CarouselItem
                    key={index}
                    active={index === activeState}
                    onClick={() => handleImageClick(index, item.resourceName)}
                  >
                    <CarouselImage
                      src={item.mediaURL}
                      alt={"image " + index.toString()}
                    />
                    <PanelCaption active={index === activeState}>
                      {item.resourceName}
                    </PanelCaption>
                  </CarouselItem>
                ))}
          </CarouselWrapper>
        </Box>

        {/* Carousel with text */}
        <Box mb={2}>
          <CarouselWrapper>
            {/* {captionsData.map((caption, index) => { */}
            {loading
              ? [...Array(5)].map((_, index) => (
                  <TextCarouselItem key={`loading-${index}`}>
                    <Skeleton variant="text" width="100px" />
                  </TextCarouselItem>
                ))
              : librariesData.map((item, index) => {
                  const adjustedIndex = topicGroupsData.length + index;
                  return (
                    <TextCarouselItem
                      key={`text-${index}`}
                      active={adjustedIndex === activeState}
                      onClick={() =>
                        handleLibraryClick(
                          adjustedIndex,
                          item.qLibID,
                          item.resourceName
                        )
                      }
                    >
                      <TextPanelCaption active={adjustedIndex === activeState}>
                        {item.resourceName}
                      </TextPanelCaption>
                    </TextCarouselItem>
                  );
                })}
          </CarouselWrapper>
        </Box>
      </CardContent>
    </Card>
  );
}

export default FeedFilter;
