import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../Theme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function CustomStepperPopUp(props) {
  const { open, onClose, title, children } = props;

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          margin: "auto",
          maxWidth: "550px",
        }}
      >
        <DialogTitle>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: "25px",
            }}
            className="text-center"
          >
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 gap-4 ">{children}</div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default CustomStepperPopUp;
