import React, { useState, useEffect } from "react";

import {
  TextField,
  Box,
  Card,
  CardContent,
  Pagination,
  IconButton,
  Grid,
} from "@mui/material";

import ConnectionCard from "./connectionComponents/ConnectionCard.js";
import FilterDialog from "./connectionComponents/FilterDialog.js";
import ConnectionTabs from "./connectionComponents/ConnectionTabs.js";
import ConnectionPagination from "./connectionComponents/ConnectionPagination.js";

import { MoreVert, PersonAdd, Share, FilterList } from "@mui/icons-material";
import { red, green } from "@mui/material/colors";
import WebSiteTheme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";

//import data from "../../data/connectionsData";
import ConnectionStatus from "../../data/ConnectionStatus.json";

import CustomPopUpForm from "../CustomPopUpForm";
import {
  GetConnections,
  GetConnectionsByStatus,
  GetUserConnections,
  GetAllMyConnections,
} from "../../helper/APIHelper.js";
import { gridColumnGroupsLookupSelector } from "@mui/x-data-grid";

function Connections({ memberID }) {
  //prefix for local storage items for this component
  const localStoragePrefix = "Connections_";

  // Loading state - used for loading wheel
  const [loading, setLoading] = useState(true);

  // Use to check if this is the page of the user that signed in.
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //tab vars
  const [tabIndex, setTabIndex] = useState(0);
  const [tabConnectionCount, setTabConnectionCount] = useState({
    connections: 0,
    receivedRequests: 0,
    sentRequests: 0,
  });

  // Pagination Vars
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  // Search Vars
  const [searchValue, setSearchValue] = useState("");

  //Filter vars
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [sortBy, setSortBy] = useState("startDate");
  const [sortAscending, setSortAscending] = useState(true);

  const [data, setData] = useState({
    connections: [],
    receivedRequests: [],
    sentRequests: [],
  });
  const [filteredData, setFilteredData] = useState({
    connections: [],
    receivedRequests: [],
    sentRequests: [],
  });

  //Initialized connection data
  useEffect(() => {
    if (userInfo.memberID === memberID) {
      GetConnectionData(false);
    } else {
      GetOtherUserConnections(memberID);
    }
    // Clear local storage when the component is unmounted
    return () => {
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key.startsWith(localStoragePrefix)) {
          localStorage.removeItem(key);
        }
      }
    };
  }, []);

  //Refresh connections data when change tab
  // useEffect(() => {
  //   console.log("Refresh on tab index");
  //   GetConnectionData(false);
  // }, [tabIndex]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredData]);

  useEffect(() => {
    setTabConnectionCount({
      connections: filteredData.connections.length,
      receivedRequests: filteredData.receivedRequests.length,
      sentRequests: filteredData.sentRequests.length,
    });
  }, [filteredData]);

  // Return false if at least one set of connections is not in storage
  function IsConnectionDataInStorage() {
    let result =
      localStorage.getItem(`${localStoragePrefix}connectedConections`) &&
      localStorage.getItem(`${localStoragePrefix}pendingConnections`) &&
      localStorage.getItem(`${localStoragePrefix}sentConnections`);

    return result;
  }

  const GetConnectionData = async (refreshFlag = false) => {
    setLoading(true);
    let connectedConections, pendingConnections, sentConnections;
    if (refreshFlag === false && IsConnectionDataInStorage()) {
      // If data is in local storage, parse and assign it to connectedConections
      connectedConections = JSON.parse(
        localStorage.getItem(`${localStoragePrefix}connectedConections`)
      );
      sentConnections = JSON.parse(
        localStorage.getItem(`${localStoragePrefix}sentConnections`)
      );
      pendingConnections = JSON.parse(
        localStorage.getItem(`${localStoragePrefix}pendingConnections`)
      );
    } else {
      // If data is not in local storage, fetch connected connections data using the API
      const response = await GetAllMyConnections();
      console.log("GetAllMyConnections ", response);
      connectedConections = response.connected;
      pendingConnections = response.received;
      sentConnections = response.sent;
      // Store the fetched connected connections data in local storage
      localStorage.setItem(
        `${localStoragePrefix}connectedConections`,
        JSON.stringify(connectedConections)
      );
      localStorage.setItem(
        `${localStoragePrefix}sentConnections`,
        JSON.stringify(sentConnections)
      );
      localStorage.setItem(
        `${localStoragePrefix}pendingConnections`,
        JSON.stringify(pendingConnections)
      );

      setData({
        connections: connectedConections,
        receivedRequests: pendingConnections,
        sentRequests: sentConnections,
      });

      setFilteredData({
        connections: filterConnections(connectedConections),
        receivedRequests: filterConnections(pendingConnections),
        sentRequests: filterConnections(sentConnections),
      });

      // Turn off the loading wheel
      setLoading(false);
    }
  };

  const GetOtherUserConnections = async (memberID) => {
    let connectedConections;
    setLoading(true);
    connectedConections = await GetUserConnections(memberID);
    // Store the fetched connected connections data in local storage
    localStorage.setItem(
      `${localStoragePrefix}connectedConections`,
      JSON.stringify(connectedConections)
    );
    // Clear Pending Connections and sent connections of the currently login user
    localStorage.removeItem(`${localStoragePrefix}pendingConnections`);
    localStorage.removeItem(`${localStoragePrefix}sentConnections`);

    setData({
      connections: connectedConections,
      receivedRequests: [],
      sentRequests: [],
    });
    setFilteredData({
      connections: filterConnections(connectedConections),
      receivedRequests: [],
      sentRequests: [],
    });

    // Turn off the loading wheel
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleFilterButtonClick = () => {
    setFilterDialogOpen(true);
  };

  const renderConnections = (connections, type) =>
    connections
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((connection, index) => (
        <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
          <ConnectionCard
            connection={connection}
            type={type}
            refreshConnections={GetOtherUserConnections}
          />
        </Grid>
      ));

  const filterConnections = (
    connections,
    roleFromFilter,
    filterSortBy,
    filterSortAscending
  ) => {
    //handle empty connections array
    if (!Array.isArray(connections) || connections.length === 0) {
      return [];
    }
    let filteredConnections = connections;
    if (searchValue) {
      filteredConnections = filteredConnections.filter((connection) =>
        [connection.firstName, connection.lastName, connection.username].some(
          (field) => field.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
    // console.log("STATUS FILTER BEFORE IF ", statusFilter);
    // if (statusFilter) {
    //   console.log("STATUS FILTER", statusFilter);
    //   filteredConnections = filteredConnections.filter(
    //     (connection) => connection.conDetStatus === statusFilter
    //   );
    //   console.log("filtered connections in status filter ", filteredConnections);
    // }
    if (roleFromFilter) {
      let roleNumber = 2;
      if (roleFromFilter === "user") {
        roleNumber = 3;
      }
      filteredConnections = filteredConnections.filter(
        (connection) => connection.role === roleNumber
      );
    }

    filteredConnections.sort((a, b) => {
      let aValue, bValue;

      if (filterSortBy === "firstName" || filterSortBy === "lastName") {
        aValue = a[filterSortBy].toLowerCase();
        bValue = b[filterSortBy].toLowerCase();
      } else if (filterSortBy === "startDate") {
        aValue = new Date(a.startDate).getTime();
        bValue = new Date(b.startDate).getTime();
      } else {
        aValue = a[filterSortBy];
        bValue = b[filterSortBy];
      }

      if (aValue > bValue) {
        return filterSortAscending ? 1 : -1;
      } else if (aValue < bValue) {
        return filterSortAscending ? -1 : 1;
      }
      return 0;
    });
    return filteredConnections;
  };
  function handleFilterSubmit(
    roleFromFilter,
    filterSortBy,
    filterSortAscending
  ) {
    setFilteredData({
      connections: filterConnections(
        data.connections,
        roleFromFilter,
        filterSortBy,
        filterSortAscending
      ),
      receivedRequests: filterConnections(
        data.receivedRequests,
        roleFromFilter,
        filterSortBy,
        filterSortAscending
      ),
      sentRequests: filterConnections(
        data.sentRequests,
        roleFromFilter,
        filterSortBy,
        filterSortAscending
      ),
    });
    setFilterDialogOpen(false);
  }

  const IsOwnProfile = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.memberID === memberID) {
      return true;
    }
    return false;
  };

  function handleSearchBar(e) {
    let search = e.target.value;
    setSearchValue(e.target.value);
    if (search === "") {
      setSearchValue("");
    }
    setFilteredData({
      connections: data.connections.filter((connection) =>
        [connection.firstName, connection.lastName, connection.username].some(
          (field) => field.toLowerCase().includes(search.toLowerCase())
        )
      ),
      sentRequests: data.sentRequests.filter((connection) =>
        [connection.firstName, connection.lastName, connection.username].some(
          (field) => field.toLowerCase().includes(search.toLowerCase())
        )
      ),
      receivedRequests: data.receivedRequests.filter((connection) =>
        [connection.firstName, connection.lastName, connection.username].some(
          (field) => field.toLowerCase().includes(search.toLowerCase())
        )
      ),
    });
  }

  return (
    <ThemeProvider theme={WebSiteTheme}>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <IconButton
                onClick={handleFilterButtonClick}
                sx={{
                  backgroundColor: "primary.main",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                <FilterList sx={{ color: "common.white" }} />
              </IconButton>
              <TextField
                label="Search Connections"
                fullWidth
                value={searchValue}
                // onChange={(e) => setSearchValue(e.target.value)}
                onChange={handleSearchBar}
              />
            </Box>
          </Box>
          <ConnectionTabs
            isOwnConnectionPage={IsOwnProfile()}
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            connectionsCount={tabConnectionCount.connections}
            receivedRequestsCount={tabConnectionCount.receivedRequests}
            sentRequestsCount={tabConnectionCount.sentRequests}
          />
          <Box mt={2}>
            {/* Loading either wheel or connections depending on API state */}
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <div className="spinner"></div>
              </Box>
            ) : (
              <Grid container spacing={2}>
                {tabIndex === 0 &&
                  renderConnections(filteredData.connections, "connections")}
                {tabIndex === 1 &&
                  renderConnections(
                    filteredData.receivedRequests,
                    "receivedRequests"
                  )}
                {tabIndex === 2 &&
                  renderConnections(filteredData.sentRequests, "sentRequests")}
              </Grid>
            )}
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <ConnectionPagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              filteredDataLength={
                filteredData[
                  tabIndex === 0
                    ? "connections"
                    : tabIndex === 1
                    ? "receivedRequests"
                    : "sentRequests"
                ].length
              }
              tabIndex={tabIndex}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        </CardContent>
      </Card>
      <FilterDialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        onSubmit={handleFilterSubmit}
        onCancel={() => setFilterDialogOpen(false)}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        roleFilter={roleFilter}
        setRoleFilter={setRoleFilter}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortAscending={sortAscending}
        setSortAscending={setSortAscending}
        encounterFilter={false}
      />
    </ThemeProvider>
  );
}

export default Connections;
