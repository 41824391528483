import React, { useState, useEffect } from "react";
import theme from "../../Theme";
import ImageCarousel from "../../components/LandingPage/ImageCarousel";
import MultipleChipComponent from "../../components/LandingPage/MultipleChipComponent";
import MobileCircleComponent from "../../components/LandingPage/MobileCircleComponent";
import CardComponent from "../../components/LandingPage/CardComponent";
import MiniCardComponent from "../../components/LandingPage/MiniCardComponent";
import { v4 as uuid } from "uuid";
import MobileProviderComponent from "../../components/LandingPage/MobileProviderComponent";
import LandingFooterMobile from "../../components/LandingPage/LandingFooterMobile";

import MobileArticleCard from "../../components/LandingPage/MobileArticleCard";

function MobileLandingPage({
  landingSectionsData,
  topicGroupsData,
  tags,
  FeedFilterChildState,
  handleFilterStateChange,
  lightBgColor,
  darkBgColor,
}) {
  return (
    <div style={{backgroundColor: lightBgColor}}>
      <div style={{ backgroundColor: lightBgColor, paddingBottom: "50px" }}>
        <div style={{ padding: "20px" }}>
          <h1
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: theme.palette.primary.main,
              paddingBottom: "10px",
            }}
          >
            Living Healthy
          </h1>
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "0 0 1px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          >
            <ImageCarousel
              activeState={FeedFilterChildState}
              onFilterStateChange={handleFilterStateChange}
              topicGroupsData={topicGroupsData}
              isMobile={true}
              //
            />
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <MultipleChipComponent filters={tags} />
          </div>
        </div>
      </div>
      {/* <div style={{ backgroundColor: "#82ABB1", justifyContent: "center", display: "flex" }}> */}
      {landingSectionsData
        .sort((a, b) => a.sortId - b.sortId)
        .map((section, index) => (
          <div key={uuid()}>
            <div
              key={uuid()}
              style={{
                // marginTop: "30px",
                // flexWrap: "wrap",
                backgroundColor: "#82ABB1",
              }}
            >
              {section.defView === 8 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <MobileCircleComponent
                      key={uuid()}
                      src={item.mediaURL}
                      alt={item.header}
                      size={350}
                      position={"right"}
                      title={item.header}
                      text={item.description}
                      textSize={20}
                      itemID={item.feedID}
                    />
                  ))}
            </div>

            {section.defView === 9 && (
              <div key={uuid()}>
                <h2
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: "20px",
                    marginRight: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  {section.title}
                </h2>
                <hr
                  style={{
                    borderColor: "#B6B8BB",
                    borderWidth: "1px",
                  }}
                />
              </div>
            )}

            <div
              key={uuid()}
              style={{
                flexWrap: "wrap",
                backgroundColor: lightBgColor,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "20px",
              }}
            >
              {section.defView === 9 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <CardComponent
                      key={uuid()}
                      image={item.mediaURL}
                      size={350}
                      position={"top"}
                      title={item.header}
                      text={item.description}
                      textSize={15}
                      itemID={item.feedID}
                      article={item.link}
                    />
                  ))}
            </div>

            {section.defView === 10 && (
              <div key={uuid()}>
                <h2
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: "20px",
                    marginRight: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  {section.title}
                </h2>
                <hr
                  style={{
                    borderColor: "#B6B8BB",
                    borderWidth: "1px",
                    marginBottom: "20px",
                  }}
                />
              </div>
            )}

            <div
              key={uuid()}
              style={{
                flexWrap: "wrap",
                backgroundColor: lightBgColor,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "20px",
              }}
            >
              {section.defView === 10 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <MiniCardComponent
                      key={uuid()}
                      image={item.mediaURL}
                      size={350}
                      position={"left"}
                      title={item.header}
                      textSize={15}
                      isMobile={true}
                      itemID={item.feedID}
                    />
                  ))}
            </div>

            <div
              key={uuid()}
              style={{
                flexWrap: "wrap",
                backgroundColor: lightBgColor,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "20px",
              }}
            >
              {section.defView === 10 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <MobileArticleCard
                      feedID={item.feedID}
                      image={item.mediaURL}
                      title={item.header}
                      description={item.description}
                      size={100}
                    />
                  ))}
            </div>

            {section.defView === 11 && (
              <div key={uuid()}>
                <h2
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: "20px",
                    marginRight: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  {section.title}
                </h2>
                <hr
                  style={{
                    borderColor: "#B6B8BB",
                    borderWidth: "1px",
                    marginBottom: "20px",
                  }}
                />
              </div>
            )}

            {section.defView === 11 && (
              <MobileProviderComponent items={section.items} />
            )}
          </div>
        ))}
      <div style={{ backgroundColor: darkBgColor, paddingTop: "20px" }}>
        <LandingFooterMobile />
      </div>
    </div>
  );
}
export default MobileLandingPage;
