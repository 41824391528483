import { ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import theme from "../Theme";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SingleDropDown from "./SingleDropDown";
import { IconButton } from "@mui/material";
function PanelComponent() {
  //set types for specific fields please enter html types
  const editableFields = [
    "encID",
    "encDate",
    "provider",
    "encDetType",
    "description",
    "fromDate",
    "serviceProvider",
    "toDate",
    "value",
    "description1",
    "codeID",
  ];
  var types = {
    encDetType: "url",
    description: "email",
    encDate: "time",
    fromDate: "date",
    toDate: "datetime-local",
    encID: "number",
    serviceProvider: "password",
    provider: "radio",
    value: "month",
    codeID: "color",
  };
  //Captions
  var captions = {
    description1: "Value Description",
    explanation: "Value Explanation",
  };
  //hidden
  const hiddenFields = ["range"];
  //Merge
  const mergeFields = ["description1"];
  const mergeCounts = [2];
  //Functions
  //usign keywords like add edit delete will override the embeddedFunctions
  function addFunction() {
    console.log("Add was clicked");
  }
  function editFunction() {
    console.log("edit was clicked");
  }
  function deleteFunction() {
    console.log("delete was clicked");
  }

  function otherFunction() {
    console.log("other was clicked");
  }

  const functionNames1 = ["Add"];
  const functionIcons1 = [AddIcon];
  const functionsArray1 = [addFunction];

  //NoIcon and None are keywords
  const functionNames = ["Add1", "Other1", "Edit1", "Delete1"];
  const functionIcons = [AddIcon, "NoIcon", EditIcon, DeleteOutlineIcon];
  // const functionIcons = ["None"];
  const functionsArray = [
    addFunction,
    otherFunction,
    editFunction,
    deleteFunction,
  ];

  //Choose either Edit | Add | Delete
  const embeddedFunctions = ["Edit"];
  const embeddedFunctionsOne = ["Edit", "Add", "Delete"];
  //Panel/Accordion header names
  const headers = ["Diagnoses", "Services"];
  //contents of the first panel
  const contents = [
    {
      encID: 6265548630,
      encDate: "00:00",
      provider: "true",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-22",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: "#e66465",
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
    {
      encID: 1,
      encDate: "01:00",
      provider: "false",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "",
      toDate: "2023-06-05T00:00:00",
      value: "A421",
      description1: "Abdominal actinomycosis",
      explanation: "Additional details",
      range: "",
      codeID: "#f6b73c",
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2915,
    },
    {
      encID: 2,
      encDate: "02:00",
      provider: "false",
      encDetType: "Diagnosis",
      description: "List of diagnosis",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "",
      toDate: "2023-06-05T00:00:00",
      value: "A421",
      description1: "Abdominal actinomycosis",
      explanation: "Additional details",
      range: "",
      codeID: 261,
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2915,
    },
    {
      encID: 3,
      encDate: "03:00",
      provider: "false",
      encDetType: "Diagnosis",
      description: "List of diagnosis",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: 163,
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
    {
      encID: 4,
      encDate: "04:00",
      provider: "false",
      encDetType: "Service",
      description: "List of services",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "",
      toDate: "2023-06-05T00:00:00",
      value: "23920",
      description1: "Disarticulation of shoulder",
      explanation: "Some info",
      range: "1,2",
      codeID: 11,
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2916,
    },
  ];
  //contents of the second panel
  const serviceContents = [
    {
      encID: 0,
      encDate: "00:00",
      provider: "true",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-22",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: "#0e6d73",
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
    {
      encID: 1,
      encDate: "2023-06-05T00:00:00",
      provider: "true",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00:00",
      value: "A421 ",
      description1:
        "Abdominal actinomycosis Abdominal actinomycosisAbdominal actinomycosisAbdominal actinomycosisAbdominal actinomycosisAbdominal actinomycosisAbdominal actinomycosisAbdominal actinomycosis Abdominal actinomycosis Abdominal actinomycosisAbdominal actinomycosisAbdominal actinomycosisAbdominal ",
      explanation: "Additional details",
      range: "",
      codeID: 261,
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2915,
    },
    {
      encID: 2,
      encDate: "2023-06-05T00:00:00",
      provider: "true",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "",
      toDate: "2023-06-05T00:00:00",
      value: "23920",
      description1: "Disarticulation of shoulder",
      explanation: "Some info",
      range: "1,2",
      codeID: 11,
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2916,
    },
    {
      encID: 3,
      encDate: "2023-06-05T00:00:00",
      provider: "false",
      encDetType: "https://hbq.agiline.com/",
      description: "johnnycash@cashmoney.com",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: 163,
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
    {
      encID: 4,
      encDate: "2023-06-05T00:00:00",
      provider: "true",
      encDetType: "Diagnosis",
      description: "List of diagnosis",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "",
      toDate: "2023-06-05T00:00:00",
      value: "A421",
      description1: "Abdominal actinomycosis",
      explanation: "Additional details",
      range: "",
      codeID: 261,
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2915,
    },
    {
      encID: 1359,
      encDate: "2023-06-05T00:00:00",
      provider: "DR ROBERT DONATIELLO",
      encDetType: "Diagnosis",
      description: "List of diagnosis",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "",
      toDate: "2023-06-05T00:00:00",
      value: "A421",
      description1: "Abdominal actinomycosis",
      explanation: "Additional details",
      range: "",
      codeID: 261,
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2915,
    },
    {
      encID: 1359,
      encDate: "2023-06-05T00:00:00",
      provider: "DR ROBERT DONATIELLO",
      encDetType: "Diagnosis",
      description: "List of diagnosis",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "DR ROBERT HENINGER",
      toDate: "2023-06-05T00:00:00",
      value: "A251",
      description1: "Streptobacillosis",
      explanation: "More details",
      range: "",
      codeID: 163,
      providerID: 443,
      serviceProviderID: "444",
      encDetID: 2914,
    },
    {
      encID: 1359,
      encDate: "2023-06-05T00:00:00",
      provider: "DR ROBERT DONATIELLO",
      encDetType: "Service",
      description: "List of services",
      fromDate: "2023-06-05T00:00:00",
      serviceProvider: "",
      toDate: "2023-06-05T00:00:00",
      value: "23920",
      description1: "Disarticulation of shoulder",
      explanation: "Some info",
      range: "1,2",
      codeID: 11,
      providerID: 443,
      serviceProviderID: "0",
      encDetID: 2916,
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "1rem", width: "100%" }}>
        <SingleDropDown
          contents={contents}
          captions={captions}
          viewType={0}
          headerName={headers[0]}
          disabled={false}
          isExpanded={false}
          showPagination={true}
          itemsPerPage={2}
          paginationColor="#FFD1DC"
          functionNames={functionNames}
          functionIcons={functionIcons}
          functionsArray={functionsArray}
          width="100%"
          headerColor="#FFD1DC"
          hoverColor="#FFC1CC"
          IconColor="white"
          hiddenFields={hiddenFields}
          autoDetectType={true}
          isViewOrEdit={1}
          editableFields={editableFields}
          requiredFields ={["encID" , "description"]}
          types={types}
          showSearchBar={true}
          embeddedFunctions={embeddedFunctionsOne}
        />
        <SingleDropDown
          contents={contents}
          captions={captions}
          viewType={0}
          headerName={headers[0]}
          disabled={false}
          isExpanded={false}
          showPagination={true}
          itemsPerPage={2}
          paginationColor={theme.palette.gray.main}
          functionNames={functionNames}
          functionIcons={functionIcons}
          functionsArray={functionsArray}
          width="100%"
          headerColor={theme.palette.gray.main}
          hoverColor={theme.palette.gray.light}
          IconColor={theme.palette.white.main}
          hiddenFields={hiddenFields}
          autoDetectType={true}
          isViewOrEdit={0}
          editableFields={editableFields}
          types={types}
          showSearchBar={true}
          embeddedFunctions={embeddedFunctions}
        />
        <SingleDropDown
          contents={serviceContents}
          captions={captions}
          viewType={1}
          bootstrapZones={[4, 4, 2, 2]}
          headerName={headers[1]}
          disabled={false}
          isExpanded={false}
          showPagination={true}
          itemsPerPage={2}
          paginationColor="black"
          functionNames={functionNames1}
          functionIcons={functionIcons1}
          functionsArray={functionsArray1}
          width="100%"
          headerColor="secondary.main"
          hoverColor="secondary.light"
          IconColor="pink"
          mergeFields={mergeFields}
          mergeCounts={mergeCounts}
          hiddenFields={hiddenFields}
          cardViewType={1}
          autoDetectType={true}
          isViewOrEdit={1}
          editableFields={editableFields}
          types={types}
          showSearchBar={true}
          embeddedFunctions={embeddedFunctions}
        />
        <SingleDropDown
          contents={serviceContents}
          captions={captions}
          viewType={1}
          bootstrapZones={[4, 4, 2, 2]}
          headerName={headers[1]}
          disabled={false}
          isExpanded={false}
          showPagination={true}
          itemsPerPage={2}
          paginationColor="black"
          // functionNames={functionNames1}
          // functionIcons={functionIcons1}
          // functionsArray={functionsArray1}
          width="100%"
          headerColor="black.main"
          hoverColor="#212325"
          IconColor="#FFFFFF"
          mergeFields={mergeFields}
          mergeCounts={mergeCounts}
          hiddenFields={hiddenFields}
          cardViewType={0}
          autoDetectType={true}
          isViewOrEdit={0}
          editableFields={editableFields}
          types={types}
          showSearchBar={true}
          embeddedFunctions={embeddedFunctions}
          requiredFields ={["encID" , "description"]}
        />
      </div>
    </ThemeProvider>
  );
}
export default PanelComponent;
