import React, { useEffect, useState } from "react";

import {
  Card,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import SendIcon from "@mui/icons-material/Send";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { v4 as uuid } from "uuid";

import {
  GetAllergyRecords,
  GetFamilyHistory,
  GetMedicalRecords,
  GetDocuments,
  ArchiveRecord,
} from "../../../services/HealthServiceHelper";
import RecordViewerCard from "./RecordViewerCard";
import "../../../assets/Spinner.css";

import { Pagination } from "@mui/material";

import theme from "../../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import TabComponent from "../../TabComponent";

function RecordViewer({
  documentTitle,
  recordCaption,
  folderName,
  setRecordsToSend,
  onSendButtonClick,
  memberID,
  username,
}) {
  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalArchivedPages, setTotalArchivedPages] = useState(1);
  const [currentArchivedPage, setCurrentArchivedPage] = useState(1);
  const itemsPerPage = 5;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleArchivedPageChange = (event, value) => {
    setCurrentArchivedPage(value);
  };
  useEffect(() => {
    setCurrentPage(1);
    setCurrentArchivedPage(1);
  }, [recordCaption]);

  const [checkedRecords, setCheckedRecords] = useState([]);

  const handleToggle = (value) => {
    const currentIndex = checkedRecords.indexOf(value);
    const newChecked = [...checkedRecords];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedRecords(newChecked);
  };

  const handleShareRecords = (checkedRecords) => {
    setRecordsToSend(checkedRecords);
  };

  const [data, setData] = useState(null);
  const [archivedData, setArchivedData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Filter State Vars
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRecords, setSelectedRecords] = useState(new Set());

  const [shareMode, setShareMode] = useState(false);
  const [archiveView, setArchiveView] = useState(false);

  function handleArchiveView() {
    setArchiveView(!archiveView);
  }

  // Hooks to manage Filter State Vars
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleShareClick = () => {
    setShareMode(!shareMode);
  };

  const searchFilter = (row) => {
    if (!searchTerm) return true;
    const term = searchTerm.toLowerCase();
    return Object.values(row).some((value) =>
      String(value).toLowerCase().includes(term)
    );
  };

  const handleRecordSelection = (index) => {
    const newSelectedRecords = new Set(selectedRecords);
    if (selectedRecords.has(index)) {
      newSelectedRecords.delete(index);
    } else {
      newSelectedRecords.add(index);
    }
    setSelectedRecords(newSelectedRecords);
  };

  const handleSendClick = () => {
    console.log(
      "Selected Records:",
      Array.from(selectedRecords).map((index) => data[index])
    );
    handleShareRecords(Array.from(selectedRecords).map((index) => data[index]));
    setShareMode(false);
    onSendButtonClick(selectedRecords);
  };

  // let requestorID = "3-403";
  // let username = "w.wolf";
  // let folderName = "Lab Results";

  const localStoragePrefix = "RecordViewer_";
  const archiveLocalStoragePrefix = "ArchivedRecordViewer_";

  //allergies
  async function GetAllergies(patientID, isInactive) {
    let response = await GetAllergyRecords(patientID, isInactive);
    // console.log("Response: ", response);
    return response;
  }

  //family history
  async function GetHistory(patientID, isInactive) {
    let response = await GetFamilyHistory(patientID, isInactive);
    // console.log("Response: ", response);
    return response;
  }

  //medication
  async function GetMedical(patientID, isInactive) {
    let response = await GetMedicalRecords(patientID, isInactive);
    // console.log("Response: ", response);
    return response;
  }

  //other documents
  async function HandleGetDocuments(username, folderName, isInactive) {
    // console.log("username: ", username);
    // console.log("folderName: ", folderName);
    let response = await GetDocuments(username, folderName, isInactive);
    // console.log("Response: ", response);
    return response;
  }

  // local storage cleanup on reload
  useEffect(() => {
    const cleanup = () => {
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (
          key.startsWith(localStoragePrefix) ||
          key.startsWith(archiveLocalStoragePrefix)
        ) {
          localStorage.removeItem(key);
        }
      }
    };

    return cleanup;
  }, []);

  //Logic to decide which API to call
  useEffect(() => {
    if (recordCaption && typeof recordCaption === "string") {
      const fetchData = async () => {
        setLoading(true);
        let response;
        const localStorageKey = `${localStoragePrefix}${recordCaption}`;

        if (localStorage.getItem(localStorageKey)) {
          response = JSON.parse(localStorage.getItem(localStorageKey));
        } else {
          switch (recordCaption) {
            case "Allergies":
              response = await GetAllergies(memberID, false);
              break;
            case "Family History":
              response = await GetHistory(memberID, false);
              break;
            case "Medications":
              response = await GetMedical(memberID, false);
              break;
            default:
              response = await HandleGetDocuments(username, folderName, false);
              break;
          }
          localStorage.setItem(localStorageKey, JSON.stringify(response));
        }
        setData(response);
        setLoading(false);
        //for pagination
        setTotalPages(Math.ceil(response.length / itemsPerPage));
      };

      fetchData();
    }
  }, [recordCaption]);

  //load archived data
  useEffect(() => {
    if (recordCaption && typeof recordCaption === "string") {
      const fetchData = async () => {
        setLoading(true);
        let response;
        const localStorageKey = `${archiveLocalStoragePrefix}${recordCaption}`;

        if (localStorage.getItem(localStorageKey)) {
          response = JSON.parse(localStorage.getItem(localStorageKey));
        } else {
          switch (recordCaption) {
            case "Allergies":
              response = await GetAllergies(memberID, true);
              break;
            case "Family History":
              response = await GetHistory(memberID, true);
              break;
            case "Medications":
              response = await GetMedical(memberID, true);
              break;
            default:
              response = await HandleGetDocuments(username, folderName, true);
              break;
          }
          localStorage.setItem(localStorageKey, JSON.stringify(response));
        }
        console.log("archived data ", archivedData);
        setArchivedData(response);
        setLoading(false);
        //for pagination
        setTotalArchivedPages(Math.ceil(response.length / itemsPerPage));
      };

      fetchData();
    }
  }, [recordCaption]);

  //tab variables
  const tabNames = [`${recordCaption}`, "Archived"];
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      setArchiveView(true);
    } else {
      setArchiveView(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {!recordCaption ? (
        <Typography variant="body1">Please select a folder</Typography>
      ) : (
        <>
          <Card style={{ marginBottom: "16px", padding: "16px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item xs={1}>
                <IconButton
                  onClick={handleArchiveView}
                  sx={{
                    backgroundColor: archiveView
                      ? "primary.main"
                      : "rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      backgroundColor: archiveView
                        ? "primary.dark"
                        : "rgba(0, 0, 0, 0.3)",
                    },
                  }}
                >
                  <FolderZipIcon sx={{ color: "common.white" }} />
                </IconButton>
              </Grid> */}

              {/* Search bar needs to be bigger without the share button*/}
              {/* <Grid item xs={shareMode ? 10 : 11}>
                <TextField
                  fullWidth
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={1}>
                <IconButton
                  edge="end"
                  onClick={handleShareClick}
                  sx={{
                    backgroundColor: shareMode
                      ? "primary.main"
                      : "rgba(0, 0, 0, 0.2)",
                    mr: 1,
                    "&:hover": {
                      backgroundColor: shareMode
                        ? "primary.dark"
                        : "rgba(0, 0, 0, 0.3)",
                    },
                  }}
                >
                  <ShareIcon sx={{ color: "common.white" }} />
                </IconButton>
              </Grid> */}
              {shareMode && (
                <Grid item xs={1}>
                  <IconButton
                    edge="end"
                    onClick={handleSendClick}
                    sx={{
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                  >
                    <SendIcon sx={{ color: "common.white" }} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Card>
          <TabComponent
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            tabNames={tabNames}
          />
          {loading ? (
            <div className="spinner m-auto"></div>
          ) : !archiveView && data && data.length > 0 ? (
            data
              .filter(searchFilter) // Filter the data based on searchTerm
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              ) // Select rows for the current page
              .map((row, rowIndex) => (
                <>
                  <RecordViewerCard
                    documentTitle={documentTitle}
                    key={rowIndex}
                    row={row}
                    recordType={recordCaption}
                    onRecordSelection={() => handleRecordSelection(rowIndex)}
                    isSelected={selectedRecords.has(rowIndex)}
                    shareMode={shareMode}
                    tabIndex={tabIndex}
                    setData={setData}
                    setArchivedData={setArchivedData}
                  />
                </>
              ))
          ) : archiveView && archivedData && archivedData.length > 0 ? (
            archivedData
              .filter(searchFilter) // Filter the data based on searchTerm
              .slice(
                (currentArchivedPage - 1) * itemsPerPage,
                currentArchivedPage * itemsPerPage
              ) // Select rows for the current page
              .map((row, rowIndex) => (
                <RecordViewerCard
                  documentTitle={documentTitle}
                  key={rowIndex}
                  row={row}
                  recordType={recordCaption}
                  onRecordSelection={() => handleRecordSelection(rowIndex)}
                  isSelected={selectedRecords.has(rowIndex)}
                  shareMode={shareMode}
                  tabIndex={tabIndex}
                  setData={setData}
                  setArchivedData={setArchivedData}
                />
              ))
          ) : (
            <Typography variant="body1">No data available</Typography>
          )}
          {archiveView ? (
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                count={totalArchivedPages}
                page={currentArchivedPage}
                onChange={handleArchivedPageChange}
                color="primary"
                size="large"
              />
            </Box>
          ) : (
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                size="large"
              />
            </Box>
          )}
        </>
      )}
    </ThemeProvider>
  );
}

export default RecordViewer;
