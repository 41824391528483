import React, { useState, useEffect } from "react";

import {
  TextField,
  Box,
  Grid,
  Pagination,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import { GetRecentEncoutersbyProvider } from "../../../services/HealthServiceHelper";
import theme from "../../../Theme";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

function RecentEncounters({
  providerID,
  patientID,
  setEncID,
  data,
  setData,
  setPatientID,
  setAddEncounter,
}) {
  useEffect(() => {
    const cachedData = localStorage.getItem("recentEncounters");

    if (cachedData) {
      setData(JSON.parse(cachedData));
      setTotalPages(Math.ceil(JSON.parse(cachedData).length / itemsPerPage));
      setLoading(false);
    } else {
      fetchData();
    }
    setCurrentPage(1);
  }, [providerID, patientID]);

  const fetchData = async () => {
    setLoading(true);
    let temp = await GetRecentEncoutersbyProvider(providerID, 0);
    setData(temp);
    setTotalPages(Math.ceil(temp.length / itemsPerPage));
    setLoading(false);
    localStorage.setItem("recentEncounters", JSON.stringify(temp));
  }

  // Loading state - used for loading wheel
  const [loading, setLoading] = useState(true);

  //Search
  const [searchValue, setSearchValue] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const renderLink = (content, to) => {
    return <Link to={to}>{content}</Link>;
  };
  const renderEncounters = () => {
    if (data === null || data === undefined || data.length === 0) {
      return (
        <Grid item xs={12} lg={9}>
          <p>No Encounters</p>
        </Grid>
      );
    }
    // Apply search filter
    const filteredData = data.filter((patient) =>
      patient.encType.toLowerCase().includes(searchValue.toLowerCase())
    );
    return filteredData
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((patient, index) => (
        <Grid key={uuid} item xs={12}>
          <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
            {renderLink(
              <img
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginLeft: "16px",
                  maxWidth: "300px",
                }}
                src={patient.profilePicture}
                alt={patient.username}
              />,
              `/profile/${patient.memberID}`
            )}
            <Box
              sx={{ marginLeft: "24px", flexGrow: 1 }}
              onClick={() => setEncID(patient.encID)}
            >
              <Typography
                component="div"
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                Encounter Type : {patient.encType}
              </Typography>
              <Typography
                sx={{ color: "text.secondary", fontSize: "0.875rem" }}
              >
                Creation Date : {patient.createdDate.split("T").shift()}
              </Typography>
              {patientID === 0 && (
                <>
                  <Typography
                    sx={{ color: "text.secondary", fontSize: "0.875rem" }}
                  >
                    Patient Name : {patient.firstName + " " + patient.lastName}
                  </Typography>
                  <Typography
                    sx={{ color: "text.secondary", fontSize: "0.875rem" }}
                  >
                    Patient Username : {patient.username}
                  </Typography>
                </>
              )}
              <Chip
                key={uuid}
                label={patient.encStatus}
                variant="outlined"
                sx={{
                  height: "28px",
                  backgroundColor: "white",
                  color: "#7a7a7a",
                  border: "1px color gray",
                  marginBottom: "15px",
                  textAlign: "left",
                  margin: "0.3rem",
                }}
              />
            </Box>
          </Box>
          <hr
            style={{
              borderColor: "#B6B8BB",
              borderWidth: "1px",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          />
        </Grid>
      ));
  };

  function handleViewRecentEncountersClick() {
    setPatientID(0);
  }
  function handleAddEncounterButtonClick() {
    console.log("add encounter ");
    setAddEncounter(true);
    setEncID("");
  }

  return (
    <ThemeProvider theme={theme}>
      <Box mb={2}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            size="small"
            label="Search Encounters"
            fullWidth
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
          <IconButton
            onClick={handleAddEncounterButtonClick}
            sx={{
              backgroundColor: "gray.main",
              ml: 2,
              "&:hover": {
                backgroundColor: "gray.light",
              },
            }}
          >
            <AddIcon sx={{ color: "common.white" }} />
          </IconButton>
        </Box>
      </Box>

      <Box mt={2}>
        {/* Loading either wheel or connections depending on API state */}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="spinner"></div>
          </Box>
        ) : (
          data !== undefined && (
            <Grid container spacing={2}>
              {renderEncounters()}
            </Grid>
          )
        )}
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
    </ThemeProvider>
  );
}

export default RecentEncounters;
