// import APIProviders from "../data/data.json";
let APIProviders = window.APIProvider;

function isAPIProvider(apiProvider) {
  console.log("Find HealthBloq Provider is called");
  return apiProvider.name === "APIProvider";
}
// const APIProvider = APIProviders.find(isAPIProvider);
// const HEALTH_BLOQ_API_INDEX = 0;
// const HealthBloqAPI = APIProviders[HEALTH_BLOQ_API_INDEX];
// console.log("Get HealthBloqAPI: ", HealthBloqAPI);

let userInfo = JSON.parse(localStorage.getItem("userInfo"));
const DEFAULT_USERID = "-1";

let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
    MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {
    MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
  },
  mode: "cors",
};

function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

function GetAPI() {
  return APIProviders;
}

function GetSessionUser() {
  return userInfo;
}

function GetDefaultUserID() {
  return DEFAULT_USERID;
}

function IsLogin() {
  if (userInfo !== null) {
    return true;
  }
  return false;
}

async function LoginHelper(username, password) {
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: "Basic " + btoa(username + ":" + password),
      // Cred: "Basic " + btoa(username + ":" + password),
    },
    mode: "cors",
  };
  var url = APIProviders.baseURL + APIProviders.recordPath.login;
  // console.log(
  //   "Sending username: " +
  //     username +
  //     " and password: " +
  //     password +
  //     " to URL: " +
  //     url
  // );
  var response = await fetch(url, requestOptions);
  var loginResponse = await response.json();

  // WARNING: Using MemberID as a token. Check to see if memberID is not null then save to local storage
  if (loginResponse !== null && loginResponse.memberID !== null) {
    console.log("login response ", loginResponse);
    localStorage.setItem("userInfo", JSON.stringify(loginResponse));
    localStorage.setItem(
      "userEmail",
      JSON.stringify(loginResponse.eMail).slice(1, -1)
    );
    console.log("user Info ", localStorage.getItem("userInfo"));
    console.log("user Email ", localStorage.getItem("userEmail"));

    userInfo = loginResponse;
    UpdateRequestOptions(userInfo);
    return true;
  }
  return false;
}

// Signout to make sure that the userInfo (token) is removed from local storage
async function LogoutHelper() {
  userInfo = null;
  UpdateRequestOptionsToLogout();
  localStorage.setItem("userEmail", "");
  return true;
}

async function GetMedicationName(entry) {
  console.log("Entry " + entry);
  const requestOptions = {
    method: "GET",
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.MedicationName +
      "?entry=" +
      entry,
    GET_HEADER
  );
  const newData = await response.json();
  return newData;
}
async function GetCodes(entry) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.FindCodes +
      "?entry=" +
      entry,
    GET_HEADER
  );
  const newData = await response.json();
  return newData;
}

//API call
async function GetFeedData(index) {
  const NUM_FEEDS = 5;

  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getDummyFeed +
      "?index=" +
      index +
      "&num=" +
      NUM_FEEDS,
    GET_HEADER
  );
  const newData = await response.json();
  return newData;
}

//Get feed post details
async function GetFeedPostDetails(feedID) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getPostDetails +
      "?feedID=" +
      feedID,
    GET_HEADER
  );
  const newData = await response.json();
  return newData;
}

async function GetFeedDataFilter(feedFilter) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: JSON.stringify(feedFilter),
  };

  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getFeed,
    request
  );
  const newData = await response.json();
  return newData;
}

async function GetRecordFolders(memberID, requestorID) {
  //console.log("url: ", url);
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getMedicalFolders +
      "?memberID=" +
      memberID +
      "&requestorID=" +
      requestorID,
    GET_HEADER
  );
  const recordFolders = await response.json();
  return recordFolders;
}

async function GetEvents() {
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getEvents,
    GET_HEADER
  );
  const events = await response.json();
  return events;
}

async function PostEvent(eventRecord) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: JSON.stringify(eventRecord),
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.createEvent,
    request
  );
  console.log("Post Event Response: ", response);
  return response;
}

async function PostFeedback(feedback) {
  const request = {
    method: "POST",
    headers: {
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: feedback,
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.postFeedback,
    request
  );
  console.log("Post feedback response: ", response);
  return response;
}

async function ChangePassword(oldPassword, newPassword) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.changePassword +
      "?oldPassword=" +
      btoa(oldPassword) +
      "&newPassword=" +
      btoa(newPassword),
    request
  );
  console.log("Change Password: ", response);
  return response;
}

async function GetConnections() {
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getConnections,
    GET_HEADER
  );
  const connections = await response.json();
  return connections;
}

async function GetConnectionsByType(type, status) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getConnectionByType +
      "?type=" +
      type +
      "&status=" +
      status,
    GET_HEADER
  );
  const connections = await response.json();
  return connections;
}

async function GetConnectionsByStatus(status) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getConnectionByStatus +
      "?status=" +
      status,
    GET_HEADER
  );
  const connections = await response.json();
  return connections;
}

async function GetUserConnections(targetMemberID) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getUserConnections +
      "?targetMemberID=" +
      targetMemberID,
    GET_HEADER
  );
  const connections = await response.json();
  return connections;
}

async function GetAllMyConnections() {
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getAllMyConnections,
    GET_HEADER
  );
  const connections = await response.json();
  return connections;
}

async function GetUserInfo(toMemberID) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getUserInfo +
      "?toMemberID=" +
      toMemberID,
    GET_HEADER
  );
  const connections = await response.json();
  return connections;
}

async function CreateConnectionRequest(toMemberID) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.createConnectionRequest +
      "?toMemberID=" +
      toMemberID,
    request
  );
  return response;
}

async function UpdateConnection(conDetID, conDetStatus) {
  const request = {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.updateConnection +
      "?conDetID=" +
      conDetID +
      "&conDetStatus=" +
      conDetStatus,
    request
  );
  return response;
}

async function CreatePost(postForm) {
  const request = {
    method: "POST",
    headers: {
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: postForm,
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.createPost,
    request
  );
  console.log("Create New Post Response: ", response);
  return response;
}

async function CreateEvent(eventForm) {
  const request = {
    method: "POST",
    headers: {
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: eventForm,
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.createEvent,
    request
  );
  console.log("Create New Event Response: ", response);
  return response;
}

async function DeletePost(feedItem) {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.DeletePost,
    requestOptions
  );
  return response;
}

async function RegisterPatient(registrationForm) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: JSON.stringify(registrationForm),
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.registerPatient,
    request
  );
  console.log("Post medical Response: ", response);
  return response;
}

// INFO: Method to submit object along with uploading files
// async function RegisterProvider(registrationForm) {
//   console.log("registration Form ", registrationForm);
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
//     },
//     mode: "cors",
//     body: registrationForm,
//   };
//   const response = await fetch(
//     APIProvider.baseURL + APIProvider.recordPath.registerProvider,
//     requestOptions
//   );
//   console.log("Register Provider Response: ", response);
// }

async function RegisterProvider(registrationForm) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: JSON.stringify(registrationForm),
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.registerProvider,
    request
  );
  console.log("Post medical Response: ", response);
  return response;
}

async function ValidateUser(username, email) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.validateUser +
      "?username=" +
      username +
      "&email=" +
      email,
    GET_HEADER
  );
  const userExists = await response.json();
  return userExists;
}

async function ValidateUsername(username) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    // body: JSON.stringify(username),
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.validateUsername +
      "?username=" +
      username,
    request
  );
  if (response.ok === false) {
    // const userExists = await response.text();
    // console.log("User Exists ",userExists)
    // return userExists;
    return "Username Already Exists";
  } else {
    return "";
  }
}

async function ValidateEmail(email) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.validateEmail +
      "?email=" +
      email,
    request
  );
  if (response.ok === false) {
    // const userExists = await response.text();
    // console.log("User Exists ",userExists)
    // return userExists;
    return "Email Already Exists";
  } else {
    return "";
  }
}

async function GetLandingPage() {
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getLanding,
    GET_HEADER
  );
  const landing = await response.json();
  return landing;
}
async function GetLandingPageSections() {
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getLandingSections,
    GET_HEADER
  );
  const landing = await response.json();
  console.log("GetLandingPageSections ", landing);
  return landing;
}

async function GetLandingPageFilters() {
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getLandingPageFilters,
    GET_HEADER
  );
  const landing = await response.json();
  console.log("GetLandingPageFilters ", landing);
  return landing;
}

async function GetLandingFormResources() {
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.getLandingFormResources,
    GET_HEADER
  );
  const landing = await response.json();
  return landing;
}

async function GetFilters(isLanding, tagCount) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getFilters +
      "?isLanding=" +
      isLanding +
      "&tagCount=" +
      tagCount,
    GET_HEADER
  );

  const result = await response.json();
  return result;
}

// params: settings takes in an array of resources [topicgroups, libraries]
async function GetSettingsByKey(settings) {
  let settingsQuery = "";
  for (let i = 0; i < settings.length; i++) {
    if (i !== settings.length - 1)
      settingsQuery = settingsQuery + settings[i] + ",";
    else settingsQuery = settingsQuery + settings[i];
  }
  const requestOptions = {
    method: "GET",
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getSettingsByKey +
      "?key=" +
      settingsQuery,
    GET_HEADER
  );
  const newData = await response.json();
  return newData;
}

// params: settings takes in an array of resources [topicgroups, libraries]
// *** Note *** This will return resources grouped by key
// (i.e. "TopicGroups" : List<Settings>, "Folders" : List<Settings> etc...)
async function GetResourcesByKey(settings) {
  let settingsQuery = "";
  for (let i = 0; i < settings.length; i++) {
    if (i !== settings.length - 1)
      settingsQuery = settingsQuery + settings[i] + ",";
    else settingsQuery = settingsQuery + settings[i];
  }
  const requestOptions = {
    method: "GET",
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getResourcesByKey +
      "?key=" +
      settingsQuery,
    GET_HEADER
  );
  const newData = await response.json();
  return newData;
}

async function GetCreatePostResources(isLanding) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
  };
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getCreatePostResources +
      "?isLanding=" +
      isLanding,
    GET_HEADER
  );

  const newData = await response.json();

  return newData;
}
//
async function GetResourcesByID(resourceID) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.getResourceByID +
      "?id=" +
      resourceID,
    GET_HEADER
  );

  const newData = await response.json();

  return newData;
}

async function GetUsersByName(search) {
  const response = await fetch(
    APIProviders.baseURL +
      APIProviders.recordPath.GetUsersByName +
      "?search=" +
      search,
    GET_HEADER
  );

  const newData = await response.json();

  return newData;
}

async function UpdateContactInfo(contactInfo) {
  const request = {
    method: "POST",
    headers: {
      MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
    },
    mode: "cors",
    body: JSON.stringify(contactInfo),
  };
  const response = await fetch(
    APIProviders.baseURL + APIProviders.recordPath.UpdateContactInfo,
    request
  );
  console.log("Update Contact Info Response: ", response);
  return response;
}
async function GetAllCommunities() {
  const requestOptions = {
    method: "GET",
    mode: "cors",
  };
  let userInfoString = localStorage.getItem("userInfo");
  let userInfo = JSON.parse(userInfoString);
  let memberID = userInfo.memberID;
  if (memberID) {
    const response = await fetch(
      APIProviders.baseURL +
        APIProviders.recordPath.GetAllCommunities +
        "?memberID=" +
        memberID,
      GET_HEADER
    );
    const newData = await response.json();

    return newData;
  }
}
function UpdateRequestOptions(userInfo) {
  GET_HEADER.headers.MemberID = userInfo.memberID;
  POST_HEADER.headers.MemberID = userInfo.memberID;
}

function UpdateRequestOptionsToLogout() {
  GET_HEADER.headers.MemberID = DEFAULT_USERID;
  POST_HEADER.headers.MemberID = DEFAULT_USERID;
}

export {
  GetAPI,
  GetRequestHeader,
  PostRequestHeader,
  GetSessionUser,
  GetDefaultUserID,
  LoginHelper,
  LogoutHelper,
  GetFeedData,
  GetFeedDataFilter,
  GetFeedPostDetails,
  DeletePost,
  GetSettingsByKey,
  IsLogin,
  GetMedicationName,
  GetRecordFolders,
  GetConnections,
  GetConnectionsByStatus,
  GetConnectionsByType,
  GetAllMyConnections,
  GetUserInfo,
  GetUserConnections,
  GetEvents,
  CreateConnectionRequest,
  ChangePassword,
  UpdateConnection,
  RegisterPatient,
  RegisterProvider,
  CreatePost,
  CreateEvent,
  PostFeedback,
  ValidateUsername,
  ValidateEmail,
  GetLandingPage,
  GetLandingPageSections,
  GetLandingPageFilters,
  GetLandingFormResources,
  GetFilters,
  GetResourcesByKey,
  GetCreatePostResources,
  GetResourcesByID,
  PostEvent,
  GetCodes,
  GetUsersByName,
  UpdateContactInfo,
  GetAllCommunities,
};
