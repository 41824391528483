import React from "react";
import theme from "../../Theme";
import {
  Card,
  Button,
  CardHeader,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Navigate, Link } from "react-router-dom";
import { width } from "@mui/system";
import { useAuth } from "../../components/AuthProvider";
import Role from "../../data/role.json";

const CardComponent = ({
  image,
  title,
  text,
  textSize,
  position,
  size,
  itemID,
  article,
}) => {
  const EDIT_URL =
    "https://aiworksqa.agiline.com//global/index?globalurlid=787048E1-D78C-4E6F-BEB2-D9ED05BD6266&param1=";
  const screenHeight = window.innerHeight;
  // const cardHeightTop = screenHeight*50/100;
  // const imageHeightTop = cardHeightTop/2;
  const cardHeightLeft = (screenHeight * 30) / 100;
  // console.log("cardHeightLeft ",cardHeightLeft);

  const screenWidth = window.innerWidth;
  const cardWidthTop = (screenWidth * 30) / 100;
  // const cardWidthLeft = screenWidth*50/100;
  // const ImageWidthLeft = cardWidthLeft/2;

  const style = {
    // width: position === 'left' ? `${cardWidthLeft}px`:  `${cardWidthTop}`,
    // height: position === 'left' ? `${cardHeightLeft}px` : `${cardHeightTop}px`,
    position: "relative",
    width: position === "left" ? `${size}px` : `${size}px`,
    height: position === "left" ? `${cardHeightLeft}px` : `${size}px`,
    backgroundColor: "white",
    display: "flex",
    justifyContent: position === "left" ? "flex-start" : "flex-start",
    // alignItems: position === 'left' ? 'center' : 'flex-start',
    alignItems: "flex-start",
    flexDirection: position === "left" ? "row" : "column",
    marginBottom: "30px",
    // marginLeft:'20px',
  };

  const imgStyle = {
    // width: position === 'left' ?  `${ImageWidthLeft}px` : '600px',
    // height: position === 'left' ?  `300px`:`${imageHeightTop}px`,
    width: position === "left" ? `${size / 2}px` : `${size}px`,
    height: position === "left" ? `${cardHeightLeft}px` : `${size / 2}px`,
    objectFit: "cover",
    // height: position === 'left' ?  '100%':`${imageHeight}px`,
    objectPosition: "center",
    marginRight: position === "left" ? "10px" : "0",
    marginBottom: position === "left" ? "0" : "10px",
  };

  const titleStyle = {
    fontSize: `${textSize}px`,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  };
  const textStyle = {
    fontSize: `${textSize * 0.75}px`,
    maxHeight: "4rem",
    overflow: "hidden",
  };

  const [showFullText, setShowFullText] = React.useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const handleEditClick = () => {
    <Navigate to={EDIT_URL + itemID} target="_blank" />;
    console.log("Edit button click with itemID: ", itemID);
  };

  const maxCharacters = 300;
  let auth = useAuth();

  const isAdmin = () => {
    let result =
      auth.user !== null &&
      auth.user !== undefined &&
      auth.user.isAdmin === true;
    return result;
  };

  const handleGoToArticleClick = () => {
    // Open the link in a new tab or window
    window.open(article.toString(), "_blank");
  };
  return (
    <Card style={style}>
      <CardMedia
        component="img"
        style={imgStyle}
        image={image}
        alt="landing page image"
      />
      {/* <img src={image} alt="" style={imgStyle} /> */}
      <CardContent>
        <div style={{ padding: "10px" }}>
          <p style={titleStyle}>{title}</p>
          {/* <p style={textStyle} dangerouslySetInnerHTML={{ __html: text }}></p> */}

          <div
            style={textStyle}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          {/* <div
            style={textStyle}
            dangerouslySetInnerHTML={{
              __html: showFullText
                ? text
                : `${text.substr(0, maxCharacters)}...`,
            }}
          /> */}
          <Button
            style={textStyle}
            sx={{
              fontSize: "0.8rem",
              padding: 0,
              fontWeight: "bold",
              color: "grey",
            }}
            onClick={handleGoToArticleClick}
          >
            {"Go To Article"}
          </Button>
          {/* <IconButton
            style={{ position: "absolute", top: 0, right: 0 }}
            aria-label="settings"
            onClick={handleEditClick}
          >
            <EditIcon />
          </IconButton> */}
          {isAdmin() && (
            <Link
              to={EDIT_URL + itemID}
              target="_blank"
              style={{ position: "absolute", top: 10, right: 10 }}
            >
              <EditIcon />
            </Link>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
