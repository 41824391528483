import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import RecentEncounters from "./RecentEncounters";
import PatientsTab from "./PatientsTab";

import PersonIcon from "@mui/icons-material/Person";
import PatientFound from "./PatientFound";

import EncounterPopupForm from "../../FormComponents/EncounterPopupForm";
import ConnectionsWithoutTab from "../ConnectionsWithoutTabs";
import Encounters from "./Encounters";
import PatientsEncounter from "./PatientsEncounters";
import { SearchForPatients } from "../../../services/HealthServiceHelper";
function MenuFolderSelector() {
  //from PatientTab to Encounter Tab
  const [patientID, setPatientID] = useState(0);

  //tab
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const memberID = JSON.parse(localStorage.getItem("userInfo")).memberID;
  const providerID = JSON.parse(localStorage.getItem("userInfo")).contactID;
  //search
  const [searchTerm, setSearchTerm] = useState("");
  const [encID, setEncID] = useState("");
  const [data, setData] = useState(null);
  const [patientTab, setPatientTab] = useState(0);

  const searchFilter = (Connection) => {
    if (!searchTerm) return true;
    const term = searchTerm.toLowerCase();
    return Object.values(Connection).some((value) =>
      String(value).toLowerCase().includes(term)
    );
  };

  const [open, setOpen] = useState(false);
  function onClose() {
    setOpen(false);
  }
  function handleSearchButtonClick() {
    setOpen(true);
  }
  let source =
    "https://aiworksqa.agiline.com/global/index?globalurlid=39BAB808-BE41-48C9-AB7C-B22B21F33C85&param3=";
  let addEnc =
    "https://aiworksqa.agiline.com/global/index?globalurlid=39BAB808-BE41-48C9-AB7C-B22B21F33C85&param1=";
  let restAddEnc = "&param2=";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [DOB, setDOB] = useState("");
  const [reference, setReference] = useState("");
  const [addEncounter, setAddEncounter] = useState(false);

  useEffect(() => {
    console.log("current tab ", currentTab);
    setEncID("");
  }, [currentTab]);
  return (
    //left card
    <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tabs value={currentTab} onChange={handleTabChange}>
                  <Tab label="Encounters" sx={{ fontSize: "0.9rem" }} />
                  <Tab label="Patients" sx={{ fontSize: "0.9rem" }} />
                  {/* <Tab label="Found" sx={{ fontSize: "0.9rem" }} /> */}
                </Tabs>
              </div>
              {/* <IconButton
                onClick={handleSearchButtonClick}
                sx={{
                  backgroundColor: "gray.main",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "gray.light",
                  },
                }}
              >
                <QuestionMarkIcon sx={{ color: "common.white" }} />
              </IconButton> */}
              {/* {open && (
                <EncounterPopupForm
                  open={open}
                  onClose={onClose}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setDOB={setDOB}
                  setReference={setReference}
                />
              )} */}
            </div>
            {currentTab === 0 ? (
              <div style={{ marginTop: "1rem" }}>
                <RecentEncounters
                  providerID={providerID}
                  patientID={patientID}
                  setEncID={setEncID}
                  data={data}
                  setData={setData}
                  setPatientID={setPatientID}
                  setAddEncounter={setAddEncounter}
                />
              </div>
            ) : currentTab === 1 ? (
              <div style={{ marginTop: "1rem" }}>
                {patientTab === 0 ? (
                  <PatientsTab
                    setFirstname={setFirstName}
                    setLastname={setLastName}
                    setDOB={setDOB}
                    setReference={setReference}
                    setPatientTab={setPatientTab}
                    patientID={patientID}
                    setPatientID={setPatientID}
                  />
                ) : (
                  <PatientsEncounter
                    providerID={providerID}
                    patientID={patientID}
                    setEncID={setEncID}
                    data={data}
                    setData={setData}
                    setPatientTab={setPatientTab}
                    setPatientID={setPatientID}
                    setAddEncounter={setAddEncounter}
                  />
                )}
                {/* <PatientsEncounter
                  providerID={providerID}
                  patientID={patientID}
                  setEncID = {setEncID}
                  data={data}
                  setData={setData}
                /> */}
              </div>
            ) : (
              // ) : searchForPatient && currentTab === 2 ? (
              //   <PatientFound
              //     firstname={firstName}
              //     lastname={lastName}
              //     dob={DOB}
              //     reference={reference}
              //   />
              <></>
            )}
          </CardContent>
        </Card>
      </Grid>
      {/* right card */}
      {(encID && currentTab === 0) || (encID && patientTab === 1) ? (
        <Grid item xs={12} lg={9}>
          <Card sx={{ minHeight: "3rem" }}>
            <Encounters
              encID={encID}
              data={data.find((item) => item.encID === encID)}
            />
          </Card>
        </Grid>
      ) : // <Grid item xs={12} lg={9}>
      //   <iframe
      //     style={{ minHeight: "50rem", minWidth: "100%" }}
      //     src={source + encID}
      //     title="form"
      //   ></iframe>
      // </Grid>
      addEncounter === true ? (
        <Grid item xs={12} lg={9}>
          <iframe
            style={{ minHeight: "50rem", minWidth: "100%" }}
            src={addEnc + providerID + restAddEnc + patientID}
            title="form"
          ></iframe>
        </Grid>
      ) : currentTab === 1 ? (
        <Grid item xs={12} lg={9}>
          <p>Please select and encounter</p>
        </Grid>
      ) : currentTab === 0 ? (
        <Grid item xs={12} lg={9}>
          <p>Please select a patient</p>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
export default MenuFolderSelector;
