// ActionQueue.js
let actionsQueue = [];

export function addActionToQueue(actionType, data) {
    actionsQueue.push({ actionType, data });
}

async function CreateConnectionRequest(obj) {
    if (obj.length > 0) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const blob = new Blob([JSON.stringify(obj)], { type: "application/json" });

        const sent = await fetch("https://localhost:7152/PushBatchUpdate", {
            method: "POST",
            keepalive: true,
            headers: headers,
            body: blob,
            mode: "cors",
        });

        if (sent.ok) {
            // Clear the actions queue
            actionsQueue = [];
        } else {
            console.error("Failed to send request");
        }
    }
}

export function processQueue() {
    if (actionsQueue.length > 0) {
        CreateConnectionRequest(actionsQueue);

        // Clear the actions queue
        actionsQueue = [];
    }
}

// Send queued actions to the server every 2 minutes (120000 ms)
setInterval(() => {
    processQueue();
}, 120000);

// Trigger processQueueLog when the page is about to be closed
window.addEventListener('beforeunload', (event) => {
    processQueue();
});