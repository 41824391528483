import React, { useState } from "react";
import { Grid } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ClickableList from "../components/ClickableList";
import EditIcon from "@mui/icons-material/Edit";
import SingleDropDown from "../reusableComponents/SingleDropDown";
import { abbreviationToState } from "../helper/StateUtils";
import { getDataVisibilityString } from "../helper/DataVisibilityEnum";
import ChangePassword from "../components/FormComponents/ChangePasswordForm";
function SettingsPage() {
  //Captions for display
  var firstCaptions = {
    address: "Address Line 1",
    city: "City",
    addressComp: "Address Line 2",
    zipCode: "Zip Code",
    state: "State",
  };
  var secondCaptions = {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    mobilePhone: "Mobile Phone",
    phone: "Work Phone",
    eMail: "Email",
  };
  var thirdCaptions = {
    username: "Username",
    dataVisibility: "Visibility",
    bio: "Bio",
  };
  //Content of the left list
  const [records, setRecords] = useState([
    "Contact Info",
    "Change Password",
    "Interests",
  ]);
  //determine what to show in the right menu depending on user's choice
  const [currentTab, setCurrentTab] = useState(-1);
  function handleSelect(index) {
    setCurrentTab(index);
  }
  //User's Info divided into sections
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log("userInfo ", userInfo);
  const memberID = userInfo.memberID;
  const [firstRecord, setFirstRecord] = useState({
    address: userInfo.address ? userInfo.address : "",
    city: userInfo.city ? userInfo.city : "",
    addressComp: userInfo.addressComp ? userInfo.addressComp : "",
    zipCode: userInfo.zipCode ? userInfo.zipCode : "",
    state: userInfo.state ? abbreviationToState(userInfo.state) : "",
  });
  const [secondRecord, setSecondRecord] = useState({
    firstName: userInfo.firstName ? userInfo.firstName : "",
    middleName: userInfo.middleName ? userInfo.middleName : "",
    lastName: userInfo.lastName ? userInfo.lastName : "",
    mobilePhone: userInfo.mobilePhone ? userInfo.mobilePhone : "",
    phone: userInfo.phone ? userInfo.phone : "",
    eMail: userInfo.eMail ? userInfo.eMail : "",
    Website: "https://hbq.agiline.com/",
  });
  const [thirdRecord, setThirdRecord] = useState({
    username: userInfo.username ? userInfo.username : "",
    dataVisibility: userInfo.dataVisibility
      ? getDataVisibilityString(userInfo.dataVisibility)
      : "",
    bio: userInfo.bio ? userInfo.bio : "",
  });

  function editFunction() {
    console.log("edit was clicked");
  }
  function getEditableFields(obj) {
    return Object.keys(obj).map((key) => `${key}`);
  }

  console.log("firstRecord ", firstRecord);
  console.log("secondRecord ", secondRecord);
  console.log("thirdRecord ", thirdRecord);

  const embeddedFunctions = ["Edit"];
  // React.useEffect(() => {
  //   const saveToDatabase = async () => {
  //     try {
  //       // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
  //       const response = await post(thirdRecord);
  //       console.log("Data saved successfully:", response.data);
  //     } catch (error) {
  //       console.error("Error saving data:", error);
  //     }
  //   };

  //   // Call the function to save to database
  //   saveToDatabase();
  // }, [thirdRecord]);
  return (
    //left Menu
    <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
      <Grid item xs={12} sm={12} md={4}>
        <ClickableList
          title={"Settings"}
          records={records}
          onSelect={handleSelect}
          Icon={SettingsIcon}
        />
      </Grid>
      {/* Right Menu */}
      <Grid item xs={12} sm={12} md={8}>
        {currentTab === 0 && (
          <>
            <SingleDropDown
              contents={[thirdRecord]}
              setContents={setThirdRecord}
              captions={thirdCaptions}
              viewType={1}
              bootstrapZones={[6, 6]}
              headerName={"Credentials"}
              disabled={false}
              isExpanded={false}
              showPagination={false}
              itemsPerPage={2}
              paginationColor="gray"
              width="100%"
              headerColor="gray.main"
              hoverColor="gray.light"
              IconColor="#FFFFFF"
              mergeFields={["bio"]}
              mergeCounts={[2]}
              cardViewType={0}
              isViewOrEdit={0}
              editableFields={getEditableFields(thirdRecord)}
              embeddedFunctions={embeddedFunctions}
              isSavedAsArray={false}
            />
            <SingleDropDown
              contents={[secondRecord]}
              setContents={setSecondRecord}
              captions={secondCaptions}
              viewType={1}
              bootstrapZones={[6, 6]}
              headerName={"Contact Info"}
              disabled={false}
              isExpanded={false}
              showPagination={false}
              itemsPerPage={2}
              paginationColor="gray"
              width="100%"
              headerColor="gray.main"
              hoverColor="gray.light"
              IconColor="#FFFFFF"
              cardViewType={0}
              isViewOrEdit={0}
              editableFields={getEditableFields(secondRecord)}
              embeddedFunctions={embeddedFunctions}
              isSavedAsArray={false}
            />
            <SingleDropDown
              contents={[firstRecord]}
              setContents={setFirstRecord}
              captions={firstCaptions}
              viewType={1}
              bootstrapZones={[6, 6]}
              headerName={"Address"}
              disabled={false}
              isExpanded={false}
              showPagination={false}
              itemsPerPage={2}
              paginationColor="gray"
              width="100%"
              headerColor="gray.main"
              hoverColor="gray.light"
              IconColor="#FFFFFF"
              cardViewType={0}
              isViewOrEdit={0}
              editableFields={getEditableFields(firstRecord)}
              embeddedFunctions={embeddedFunctions}
              isSavedAsArray={false}
            />
          </>
        )}
        {currentTab === 1 && <ChangePassword />}
      </Grid>
    </Grid>
  );
}
export default SettingsPage;
