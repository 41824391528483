import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import theme from "../../../Theme";

const useStyles = makeStyles(() => ({
  diagnosisContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  detail: {
    marginBottom: theme.spacing(1),
  },
}));

const MAX_WORDS = 8;
function EncounterLeftMenu({
  index,
  setEncounterRightMenu,
  title,
  fromDate,
  toDate,
  codeID,
  description1,
  explanation,
  value,
  range,
  setEditButton,
  setEditOrView,
}) {
  const classes = useStyles();

  const truncateText = (text) => {
    const words = text.split(" ");
    if (words.length <= MAX_WORDS) {
      return text;
    }
    return words.slice(0, MAX_WORDS).join(" ") + "...";
  };
  function handleOnBoxClick(event) {
    event.stopPropagation();
    setEncounterRightMenu(index);
    setEditOrView(0);
  }
  function handleEditButton(event){
    console.log("Clicked");
    event.stopPropagation();
    setEditButton(index);
    setEditOrView(1);
  }

  return (
    <Box className={classes.diagnosisContainer} onClick={handleOnBoxClick}>
      <Box>
        <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
          <Typography variant="h5" className={classes.heading}>
            {title}
          </Typography>
          <IconButton
            color="primary"
            aria-label="edit"
            sx={{ justifyContent: "flex-end" }}
            onClick={handleEditButton}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box className={classes.detail}>
          {fromDate && (
            <Typography variant="subtitle1">From Date: {fromDate}</Typography>
          )}

          {toDate && (
            <Typography variant="subtitle1">To Date: {toDate}</Typography>
          )}

          {codeID && (
            <Typography variant="subtitle1">Code ID: {codeID}</Typography>
          )}

          {value && <Typography variant="subtitle1">Value: {value}</Typography>}

          {description1 && (
            <Typography variant="subtitle1">
              Description: {truncateText(description1)}
            </Typography>
          )}

          {explanation && (
            <Typography variant="subtitle1">
              Explanation: {truncateText(explanation)}
            </Typography>
          )}

          {range && <Typography variant="subtitle1">Range: {range}</Typography>}
        </Box>
      </Box>
    </Box>
  );
}

export default EncounterLeftMenu;
