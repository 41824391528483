import React from 'react';
import { useState ,useRef } from 'react';
import {TextField, Button,MenuItem} from '@mui/material'
import CustomPopUpForm from '../CustomPopUpForm';
import theme from '../../Theme.js';
import { ThemeProvider } from "@mui/material/styles";
import { GetSettingsByKey } from "../../helper/APIHelper";
import {PostAllergyRecord} from "../../services/HealthServiceHelper";
import CustomAlert from '../CustomAlert';

const allergyResourcesKey = ["AllergyStatus", "AllergyType", "Reaction" , "Severity"];
function AllergyPopupForm({ open, onClose ,enableEditing ,allergyInitialValues , showSubmit}) {
    const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(allergyResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [submit, setSubmit] = useState (false);
  const [error, setError] = useState("");
  console.log(" Allergy initial values ",allergyInitialValues);
  const [state, setState] = React.useState({
    allergy: allergyInitialValues !== "" ? allergyInitialValues.allergen :"",
    allergyType: allergyInitialValues !== "" ? allergyInitialValues.allergyType :"",
    onSet: allergyInitialValues !== "" ? allergyInitialValues.onset.split("T").shift() :"",
    reaction: allergyInitialValues !== "" ? allergyInitialValues.reaction :"",
    severity: allergyInitialValues !== "" ? allergyInitialValues.severity :"",
    status: allergyInitialValues !== "" ? allergyInitialValues.status :"",
    comments: allergyInitialValues !== "" ? allergyInitialValues.comments :"",
  })


  function clearData (){
    setState({
      allergy:"",
      allergyType:"",
      onSet:"",
      reaction:"",
      severity:"",
      status:"",
      comments:""
    })
    setSubmit(false);
  }
  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value
    });
  }
  async function handleSubmit (){
    console.log(state);
    setSubmit(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const ContactID = userInfo.contactID;
    console.log("Patient ID "+ContactID);
    let allergyRecord ='';
    if(state.allergy !== '' && state.allergyType !== '' && state.onSet !== '' && state.reaction !== '' && state.severity !== '' && state.status !== ''){
      allergyRecord = {
        patientID: ContactID,
        allergen: state.allergy,
        allergyType: state.allergyType,
        reaction: state.reaction,
        severity: state.severity,
        status: state.status,
        onset: state.onSet,
        comments: state.comments
      };
      const response = await PostAllergyRecord(allergyRecord);
      console.log("response ",response);
      if(response.ok === true){
        setError("");
        clearData();
        handleEditContactInfoClick();
        onClose();
      }
      else{
        if(response.statusText === ""){
          setError("Your Request Was Not Fulfilled")
        }
        else{
          setError(response.statusText);
        }
        handleEditContactInfoClick();
      }
    }
  };

  const handleCancel = () => {
    //clearData();
    // handleClose();
    onClose();
  };

  function filterDataByValue(filterValue) {
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.resourceID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
      alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

    return (
      <ThemeProvider theme={theme}>
        <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type= {error ? "error" : "success"}
        content={error ? error :"Form Submitted."}
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
     <CustomPopUpForm
      open={open}
      onClose={onClose}
      title="Allergy"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      showSubmit={showSubmit}
    >
        <div className="grid grid-cols-1 gap-4">
          <TextField required label="Enter Allergy" size="small" variant="outlined"
            name="allergy"
            value={state.allergy}
            onChange = {handleChange}
            error = {submit && !state.allergy}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          />
          <TextField required label="Select Allergy Type" select size="small" variant="outlined"
             name="allergyType"
             value={state.allergyType}
             onChange = {handleChange}
             error = {submit && !state.allergyType}
             disabled={!enableEditing}
             sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          >
            {filterDataByValue("AllergyType")}
          </TextField>
          <TextField required label="Onset" size="small" variant="outlined" type="Date" InputLabelProps={{ shrink: true }}
             name="onSet"
             value={state.onSet}
             onChange = {handleChange}
             error = {submit && !state.onSet}
             disabled={!enableEditing}
             sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          />
          <TextField required label="Reaction" select size="small" variant="outlined" 
             name="reaction"
             value={state.reaction}
             onChange = {handleChange}
             error = {submit && !state.reaction}
             disabled={!enableEditing}
             sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          >
            {filterDataByValue("Reaction")}
          </TextField>
          <TextField required label="Severity Level"select size="small" variant="outlined" 
             name="severity"
             value={state.severity}
             onChange = {handleChange}
             error = {submit && !state.severity}
             disabled={!enableEditing}
             sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          >
            {filterDataByValue("Severity")}
          </TextField>
          <TextField required label="Current Status"select size="small" variant="outlined" 
             name="status"
             value={state.status}
             onChange = {handleChange}
             error = {submit && !state.status}
             disabled={!enableEditing}
             sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          >
            {filterDataByValue("AllergyStatus")}
          </TextField>
          <TextField multiline label="Notes And Comments" size="small" variant="outlined" 
             name="comments"
             value={state.comments}
             onChange = {handleChange}
             disabled={!enableEditing}
             sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          />
        </div>
     </CustomPopUpForm>
     </ThemeProvider>
    );
}

export default AllergyPopupForm;