import React from "react";
import { Pagination } from "@mui/material";

function ConnectionPagination({
  currentPage,
  handlePageChange,
  itemsPerPage,
  filteredDataLength, // Replace 'data' with 'filteredDataLength'
  tabIndex,
}) {
  const totalPages = Math.ceil(filteredDataLength / itemsPerPage);

  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
      color="primary"
      size="large"
    />
  );
}

export default ConnectionPagination;
