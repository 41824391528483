import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";

import EventHeader from "../components/EventHeader";
import Bio from "../components/profileComponents/Bio";
import PhotosGridCard from "../components/profileComponents/PhotosGridCard";
import MenuFolderSelector from "../components/profileComponents/recordComponents/MenuFolderSelector";
import RecordViewer from "../components/profileComponents/recordComponents/RecordViewer";

function EventPageDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 10, paddingTop: 0 }}>
        <EventHeader
          toggle={false}
          name={"name"}
          address={"address"}
          email={"email"}
          coverPhotoURL={
            "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg"
          }
          profilePictureURL={
            "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
          }
        />
        <div>
          <Grid container spacing={3}>
            {!isMobile && (
              <Grid item md={5}>
                <Box
                  sx={{
                    position: "sticky",
                    top: "86px",
                    marginTop: "30px",
                  }}
                >
                  <Bio />
                  <PhotosGridCard
                    imageURLs={[
                      "https://picsum.photos/300/300?random=1",
                      "https://picsum.photos/400/400?random=1",
                      "https://picsum.photos/500/500?random=1",
                      "https://picsum.photos/400/500?random=1",
                      "https://picsum.photos/200/200?random=1",
                      "https://picsum.photos/100/200?random=1",
                      "https://picsum.photos/100/100?random=1",
                      "https://picsum.photos/600/400?random=1",
                      "https://picsum.photos/400/600?random=1",
                      "https://picsum.photos/600/600?random=1",
                      "https://picsum.photos/600/700?random=1",
                      "https://picsum.photos/700/700?random=1",
                    ]}
                    // imageURLs={[
                    //   "https://source.unsplash.com/random/300x300",
                    //   "https://source.unsplash.com/random/400x400",
                    //   "https://source.unsplash.com/random/500x400",
                    //   "https://source.unsplash.com/random/400x500",
                    //   "https://source.unsplash.com/random/500x500",
                    //   "https://source.unsplash.com/random/600x500",
                    //   "https://source.unsplash.com/random/500x600",
                    //   "https://source.unsplash.com/random/600x600",
                    //   "https://source.unsplash.com/random/600x700",
                    //   "https://source.unsplash.com/random/700x600",
                    //   "https://source.unsplash.com/random/700x700",
                    //   "https://source.unsplash.com/random/700x800",
                    // ]}
                  />
                </Box>
              </Grid>
            )}
            <Grid sx={{ marginTop: "30px" }} item xs={12} md={7}>
              <Bio />
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
}

export default EventPageDetails;
