import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  ThemeProvider,
  TextField,
  MenuItem,
  IconButton,
  Box,
  Chip,
  Autocomplete,
} from "@mui/material";
import theme from "../../Theme.js";
import CustomPopUpForm from "../CustomPopUpForm";
import CustomAlert from "../CustomAlert";
import {
  Add as AddIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  Videocam as VideoIcon,
  CloudUpload,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { GetSettingsByKey, GetCreatePostResources } from "../../helper/APIHelper";

const categories = ["Category 1", "Category 2", "Category 3"];

const communities = ["Community 1", "Community 2", "Community 3"];

const events = ["Event 1", "Event 2", "Event 3"];

// Rich Text Box ----
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
// ------------------

const useStyles = makeStyles((theme) => ({
  quillContainer: {
    "& .ql-container.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      padding: "8px",
    },
    "& .ql-container.ql-snow:focus-within": {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.primary.main, 0.1)}`,
    },
    "& .ql-toolbar.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      padding: "4px",
    },
    "& .ql-container.ql-snow .ql-editor": {
      padding: "0",
    },
    "& .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar button:focus":
      {
        color: theme.palette.primary.main,
      },
    "& .ql-snow .ql-toolbar button svg": {
      fill: "currentColor",
    },
  },
}));

function CreateNewPostPopupForm({ formTitle, open, onClose }) {
  const [title, setTitle] = useState("");
  //const [postContent, setPostContent] = useState("");
  const postContent = useRef("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [category, setCategory] = useState("");
  const [linkType, setLinkType] = useState("");
  const [urlOrUpload, setUrlOrUpload] = useState("");
  const [linkedCommunity, setLinkedCommunity] = useState("");
  const [linkedEvent, setLinkedEvent] = useState("");
  const [data, setData] = useState([]);
  const [topicGroupsData, setTopicGroupsData] = useState([]);
  const [librariesData, setLibrariesData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const medicationResourcesKey = ["Libraries", "TopicGroups", "Tags"];

  const alertRef = useRef();
  const classes = useStyles(theme);

  useEffect(() => {
    FillData();
  }, []);

  const handleTagInput = (event, option) => {
    if (option !== null) {
      setTagInput(option.resourceName);
    }
  };

  const handleAddTag = () => {
    if (tagInput && !tags.includes(tagInput)) {
      setTags([...tags, tagInput]);
      setTagInput("");
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleCreateNewPost = () => {
    // Handle the form submission here (use the UploadDocument form as a reference)
    alertRef.current.showAlert();
  };

  async function FillData() {
   // let temp = await GetCreatePostResources(false);


    let temp = await GetSettingsByKey(medicationResourcesKey);
    setData(temp);
  }

  function filterDataByValue(filterValue) {
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.resourceID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }

  const options = data
    .filter((option) => option.resourceKey === "Tags")
    .map((option) => {
      const firstLetter = option.resourceName[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef}
        title="Success"
        type="success"
        content="New post created."
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <CustomPopUpForm
        open={open}
        onClose={onClose}
        title={formTitle}
        onSubmit={handleCreateNewPost}
        onCancel={onClose}
        showSubmit={true}
      >
        {/* Form content goes here (use the UploadDocument form as a reference) */}
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <ReactQuill
          className={classes.quillContainer}
          theme="snow"
          value={postContent}
          //onChange={setPostContent}
          modules={modules}
          formats={formats}
          style={{ height: "150px", marginBottom: "32px" }}
        />
        <TextField
          select
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          fullWidth
          margin="dense"
          variant="outlined"
          size="small"
        >
          {filterDataByValue("TopicGroups")}
          {/* {categories.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))} */}
        </TextField>
        {/* <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
          <IconButton>
            <ImageIcon />
          </IconButton>
          <IconButton>
            <PdfIcon />
          </IconButton>
          <IconButton>
            <VideoIcon />
          </IconButton>
        </Box> */}
        <TextField
          select
          label="Link Type"
          value={linkType}
          onChange={(e) => setLinkType(e.target.value)}
          fullWidth
          margin="dense"
          variant="outlined"
          size="small"
        >
          {/* <MenuItem value="URL">URL</MenuItem>
          <MenuItem value="community">Community</MenuItem>
          <MenuItem value="event">Event</MenuItem> */}
          {filterDataByValue("Libraries")}
        </TextField>
        {linkType === "URL" && (
          <TextField
            margin="dense"
            id="url"
            label="URL"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
          />
        )}
        {linkType === "community" && (
          <TextField
            select
            label="Community"
            value={linkedCommunity}
            onChange={(e) => setLinkedCommunity(e.target.value)}
            fullWidth
            margin="dense"
            variant="outlined"
            size="small"
          >
            {communities.map((community) => (
              <MenuItem key={community} value={community}>
                {community}
              </MenuItem>
            ))}
          </TextField>
        )}
        {linkType === "event" && (
          <TextField
            select
            label="Event"
            value={linkedEvent}
            onChange={(e) => setLinkedEvent(e.target.value)}
            fullWidth
            margin="dense"
            variant="outlined"
            size="small"
          >
            {events.map((event) => (
              <MenuItem key={event} value={event}>
                {event}
              </MenuItem>
            ))}
          </TextField>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Autocomplete
            size="small"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.resourceName}
            renderInput={(params) => <TextField {...params} label="Tags" />}
            onChange={handleTagInput}
            sx={{ width: "50%" }}
          />

          <IconButton onClick={handleAddTag}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
        >
          {tags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              onDelete={handleDeleteTag(tag)}
              style={{ margin: "4px" }}
            />
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mt={2}
        >
          <Button>Preview Post</Button>
        </Box>
      </CustomPopUpForm>
    </ThemeProvider>
  );
}

export default CreateNewPostPopupForm;
