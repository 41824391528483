import React from "react";
import ConnectionProfileInfo from "./ConnectionProfileInfo";
import { Button, Box } from "@mui/material";

function AddConnectionComponent({ connection }) {
  return (
    <>
      <p>
        Not adding patient as a connection will deny you access of sharing their
        records
      </p>
      <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
        <ConnectionProfileInfo connection={connection} />
        {/* <div sx={{ display: "inline-flex", gap: "0.5rem", marginRight: "1rem" }}> */}
        <Button
          variant="contained"
          color="success"
          size="small"
          sx={{
            marginRight: "1rem",
          }}
          //   onClick={handleAcceptClick}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          //   onClick={handleDenyClick}
        >
          Deny
        </Button>
        {/* </div> */}
      </Box>
    </>
  );
}

export default AddConnectionComponent;
