import React, { useState } from "react";
import theme from "../../Theme";
import {
  TextField,
  ThemeProvider,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  MenuItem,
} from "@mui/material";
import { CardActions } from "@mui/material";
import { ValidateUsername, GetResourcesByKey } from "../../helper/APIHelper";

const resourceKeys = ["DataVisibility"];

function FirstStep({ handleNext, handleBack, isProvider, record }) {
  var regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetResourcesByKey(resourceKeys);
      console.log("temp ", temp);
      setData(temp);
    }
    FillData();
  }, []);

  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submit, setSubmit] = React.useState(false);
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
    usernameExists: "",
  });

  const [state, setState] = React.useState({
    username: record.username !== undefined ? record.username : "",
    password: record.password !== undefined ? record.password : "",
    confirmPassword:
      record.confirmPassword !== undefined ? record.confirmPassword : "",
    IDType:
      record.identificationType !== undefined ? record.identificationType : "",
    IDNumber:
      record.identificationNumber !== undefined
        ? record.identificationNumber
        : "",
    bio: record.bio !== undefined ? record.bio : "",
    visibility: record.visibility !== undefined ? record.visibility: "" ,
    visibilityResourceValue: record.dataVisibility !== undefined ? parseInt(record.dataVisibility): "" ,
  });

  function handleChangeGetResourceValue(event) {
    const value = event.target.value;
    const resourceValue = data.DataVisibility.find(
      (option) => option.resourceName === value
    ).resourceValue;
    setState({
      ...state,
      [event.target.name]: value,
      [event.target.name + "ResourceValue"]: resourceValue,
    });
  }
  function filterDataByValue(filterValue) {
    if (data.DataVisibility !== undefined) {
      return data.DataVisibility.map((option) => (
        <MenuItem key={option.settingID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
    } else {
      return <MenuItem value=""></MenuItem>;
    }
  }

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }

  async function validateForm() {
    let error = {};

    if (!state.password) {
      error.password = "Password is required";
    }

    if (!state.password) {
      error.password = "Password is required";
    } else if (state.password.length < 8) {
      error.password = "Password should be at least 8 characters long";
    } else if (!regularExpression.test(state.password)) {
      error.password =
        "Password must have at least one number and one special character";
    }

    if (!state.confirmPassword) {
      error.confirmPassword = "Please confirm your password";
    } else if (state.password !== state.confirmPassword) {
      error.confirmPassword = "Password and confirm password do not match";
    }

    if (state.username !== undefined && state.username !== "") {
      const validateUser = await ValidateUsername(state.username);
      if ((await validateUser) !== "")
        error.usernameExists = await validateUser;
    }

    await setError(error);
    console.log("error ", error);
    console.log("ret ", Object.keys(error).length === 0);
    return (await Object.keys(error).length) === 0;
  }

  async function handleOnNextClick() {
    console.log("state ", state);
    setSubmit(true);
    let FirstRecord = "";
    if (await validateForm()) {
      setError("");
      if (
        state.username !== undefined &&
        state.username !== "" &&
        state.password !== undefined &&
        state.password !== "" &&
        state.confirmPassword !== undefined &&
        state.confirmPassword !== "" &&
        (!isProvider || (isProvider && state.IDNumber !== undefined)) &&
        (!isProvider || (isProvider && state.IDType !== undefined)) &&
        (!isProvider || (isProvider && state.IDNumber !== "")) &&
        (!isProvider || (isProvider && state.IDType !== ""))
      ) {
        if (isProvider) {
          FirstRecord = {
            username: state.username,
            password: state.password,
            confirmPassword: state.confirmPassword,
            identificationType: state.IDType,
            identificationNumber: state.IDNumber,
            bio: state.bio,
            visibility: state.visibility,
            dataVisibility: parseInt(state.visibilityResourceValue),
          };
        } else {
          FirstRecord = {
            username: state.username,
            password: state.password,
            confirmPassword: state.confirmPassword,
            bio: state.bio,
            visibility: state.visibility,
            dataVisibility: parseInt(state.visibilityResourceValue),
          };
        }
        handleNext(FirstRecord);
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <TextField
        required
        label="Enter Username"
        size="small"
        variant="outlined"
        name="username"
        type="username"
        value={state.username}
        onChange={handleChange}
        error={(submit && !state.username) || (submit && error.usernameExists)}
        helperText={error.usernameExists}
        style={{ marginTop: "10px", width: "85%" }}
      />

      <TextField
        required
        label="Enter Password"
        size="small"
        variant="outlined"
        name="password"
        type="password"
        value={state.password}
        onChange={handleChange}
        error={(submit && !state.password) || error.password}
        helperText={error.password}
        style={{ marginTop: "3px", width: "85%" }}
      />

      <TextField
        required
        label="Confirm Password"
        size="small"
        variant="outlined"
        name="confirmPassword"
        type="password"
        value={state.confirmPassword}
        onChange={handleChange}
        error={(submit && !state.confirmPassword) || error.confirmPassword}
        helperText={error.confirmPassword}
        style={{ marginTop: "-5px", width: "85%" }}
      />
      <TextField
        label="Add Bio"
        size="small"
        variant="outlined"
        name="bio"
        multiline
        value={state.bio}
        onChange={handleChange}
        style={{ marginTop: "-10px", width: "85%" }}
      />
      {isProvider && (
        <div>
          <TextField
            required
            label="Identification Type"
            select
            size="small"
            variant="outlined"
            name="IDType"
            value={state.IDType}
            onChange={handleChange}
            sx={{
              textAlign: "start",
              marginTop: "-15px",
              width: "40%",
              marginRight: "10px",
            }}
            error={submit && !state.IDType}
          >
            <MenuItem value="MPN">MPN</MenuItem>
            <MenuItem value="CCN">CCN</MenuItem>
            <MenuItem value="NPI">NPI</MenuItem>
          </TextField>
          <TextField
            required
            label="Identification Number"
            size="small"
            variant="outlined"
            name="IDNumber"
            value={state.IDNumber}
            onChange={handleChange}
            sx={{ marginTop: "-15px", width: "42%" }}
            error={submit && !state.IDNumber}
          ></TextField>
        </div>
      )}

      <TextField
        required
        label="Visibility"
        select
        size="small"
        variant="outlined"
        name="visibility"
        value={state.visibility}
        onChange={handleChangeGetResourceValue}
        error={submit && !state.visibility}
        style={{ marginTop: "-20px", width: "85%" , textAlign:"start" }}
      >
        {filterDataByValue("DataVisibility")}
      </TextField>

      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "-20px",
        }}
      >
        <Button sx={{ fontSize: "1rem", width: "300px" }} onClick={handleBack}>
          Back
        </Button>
        <Button
          sx={{ fontSize: "1rem", width: "300px" }}
          variant="contained"
          onClick={handleOnNextClick}
        >
          Next
        </Button>
      </CardActions>
      {/* </Paper> */}
    </ThemeProvider>
  );
}
export default FirstStep;
