import img from "../assets/healthbloqicon.png";
import banner from "../assets/banner.png";
import Box from "@mui/material/Box";
import theme from "../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";
// import CreateFeedbackButton from "../components/CreateFeedbackButton";
// <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
{
  /* <h1>About Page</h1>
      <img src={img} alt="icon for healthbloq" /> */
}
function About() {
  return (
    <div>
      <img src={banner} width={"100%"} alt="icon for healthbloq" />
      <Typography
        variant="h3"
        style={{
          position: "absolute",
          marginTop: "10px",
          marginLeft: "30px",
          color: theme.palette.primary.main,
          fontWeight: "bold",
        }}
      >
        About us
      </Typography>
      <br />

      <br />

      <div style={{ padding: 30 }}>
        <p>
          Welcome to <b>HealthBloQ®</b>, an innovative platform that is
          transforming the way patients and healthcare providers manage medical
          information. Our mission is to <b> empower patients</b> by giving them
          control of their data and <b>democratizing access to health </b>and
          wellness services worldwide.
        </p>
        <br />
        <p>
          At HealthBloQ®, we believe that patients should be able to centralize
          and own their data from all siloed Electronic Medical Records (EMRs).
          That's why we provide an interactive, open-source platform for
          patients and health and wellness seekers worldwide, offering open
          access to world experts in medicine, research, MedTech, and all
          aspects of health and wellness.
        </p>
        <p>
          We understand the importance of access to healthcare providers,
          regardless of geographical location or insurance restrictions. That's
          why HealthBloQ® enables patients to access healthcare providers
          worldwide, and empowers healthcare providers with a platform to engage
          with colleagues and health and wellness seekers.
        </p>
        <br />
        <p>
          Our platform also offers a live record of biometric vitals from
          wearables, enabling patients to monitor their health more efficiently.
          With API integration into our platform, HealthBloQ® allows patients to
          onboard vitals and biometrics, providing a comprehensive view of their
          health data.
        </p>
        <p>
          We believe that patient empowerment is crucial to improving healthcare
          outcomes, which is why HealthBloQ® offers patients equitable access to
          healthcare worldwide. With HealthBloQ®, patients are in charge of
          their medical persona, and their medical record is their investment to
          have access to when they need it.
        </p>
        <p>
          At HealthBloQ®, we aim to transfer the power from health systems and
          EMRs back to the patient, centralizing fragmented medical,
          pharmaceutical, and imaging data into their data-centric platform. Our
          platform is the patient's medical information "bank account," and just
          like controlling deposited money, patients can own and centralize
          their medical information. The HealthBloQ® app serves as an ATM,
          providing patients with access to their information anytime.
        </p>
        <br />
        <p>
          Our platform leverages AI/ML to analyze patient data to help predict
          worsening disease and provide remote monitoring and care for patients.
          For healthcare providers, HealthBloQ® offers a platform for patient
          engagement, including one-on-one consults, educational material, and
          interaction with patients and health care seekers in a
          subscription-tiered fashion. Our platform also enables healthcare
          providers to offer a second opinion for outpatients and inpatients
          alike. In the future, HealthBloQ® plans to expand its platform to
          include Hospital-at-Home for remote patients.
        </p>
        <br />
        <p>
          At HealthBloQ®, we are committed to revolutionizing the healthcare
          industry by empowering patients, democratizing access to health and
          wellness services, and providing a platform for healthcare providers
          to engage with their patients. We are proud to offer a comprehensive,
          innovative platform that puts the power back in the hands of patients.
        </p>
      </div>
      {/* <CreateFeedbackButton /> */}
    </div>
    // </Box>
  );
}

export default About;
