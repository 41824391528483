import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import {
  Chip,
  Card,
  Button,
  CardHeader,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";

import { ThumbUp, Message, Share } from "@mui/icons-material";

import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import ReactPlayer from "react-player";

import { addActionToQueue } from "../../helper/ActionQueue";
import DialogImageBox from "../DialogImageBox";

function ReturnVideo(props) {
  //return html video if props.Link ends in .mp4 or another video format
  if (props.Link !== null && props.Link.endsWith(".mp4")) {
    return (
      <video width="auto" height="600" controls>
        <source src={props.Link} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } //return react player video if props.Link is a youtube link
  else if (props.Link !== null && props.Link.includes("youtube")) {
    return <ReactPlayer width="320" controls={true} url={props.Link} />;
  } else {
    return <div>Error Loading Video</div>;
  }
}

function useCardStyle(onProfile) {
  const [cardStyle, setCardStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1280) {
        setCardStyle({
          width: onProfile ? "90%" : "100%",
          borderRadius: "11px",
        });
      } else {
        setCardStyle({
          width: onProfile ? "90%" : "100%",
          borderRadius: "11px",
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onProfile]);

  return cardStyle;
}

function TextPost(props) {

  const [liked, setLiked] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLike = () => {
    setLiked(!liked);
    const FeedID = props.FeedID;
    addActionToQueue(liked ? "unlike" : "like", { FeedID });
  };

  const handleComment = () => {
    if (props.onFeed) {
      sessionStorage.setItem("scrollPosition", window.scrollY);
      navigate(`/PostDetails/${props.FeedID}`, { state: { from: location } });
    }
  };

  // Image Dialog state variables and logic
  const [DialogImageBoxOpen, setDialogImageBoxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const handleDialogImageBoxOpen = () => {
    setDialogImageBoxOpen(true);
  };

  const handleDialogImageBoxClose = () => {
    setDialogImageBoxOpen(false);
  };

  const images = [
    {
      url: props.Image,
      title: props.Title,
    },
  ];

  // Link to Profile Page if real user
  const renderLink = (content, to, memberID) => {
    // if member id is -1 or null or undefined or not a number, return content
    if (!memberID || memberID == "-1") {
      return content;
    }

    return React.cloneElement(content, {
      onClick: () => navigate(to, { state: { userID: memberID } }),
      style: {
        cursor: "pointer",
      },
    });
  };

  const cardStyle = useCardStyle(props.onProfile);
  switch (props.DefView) {
    case 0: // Simple text post (No images included)
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
            />
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            <CardContent>
              {/* {props.Description} */}
              <Typography variant="h6" component="h6">
                {props.Title}
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: props.Description }} />
            </CardContent>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    case 1: // Link post
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
              sx={{ paddingBottom: 0 }}
            />
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "0.7rem",
                  paddingTop: "0.7rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            {/* <Typography variant="h5" style={{color: theme.palette.primary.main}} className="text-center">Dialog Title</Typography> */}
            <CardActionArea
              className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
              href={props.Link}
              target="_blank"
              // style={cardStyle}
            >
              <CardContent>
                <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                  {props.Title}
                </Typography>
                {/* {props.Description} */}
                <div dangerouslySetInnerHTML={{ __html: props.Description }} />
              </CardContent>
              <div style={{ padding: "10px" }}>
                <CardMedia
                  component="img"
                  image={props.MediaURL}
                  style={{
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                  }}
                  // onClick={handleDialogImageBoxOpen}
                />
              </div>
            </CardActionArea>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{
                    color: liked ? theme.palette.primary.main : "inherit",
                  }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    case 2: // PDF post
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
              sx={{ paddingBottom: 0 }}
            />
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "0.7rem",
                  paddingTop: "0.7rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            {/* <Typography variant="h5" style={{color: theme.palette.primary.main}} className="text-center">Dialog Title</Typography> */}
            <CardActionArea
              className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
              href={props.Link}
              target="_blank"
              // style={cardStyle}
            >
              <CardContent>
                <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                  {props.Title}
                </Typography>
                {/* {props.Description} */}
                <div dangerouslySetInnerHTML={{ __html: props.Description }} />
                <div style={{ padding: "10px" }}>
                  <CardMedia
                    component="img"
                    image={props.MediaURL}
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      cursor: "pointer",
                    }}
                    // onClick={handleDialogImageBoxOpen}
                  />
                </div>
              </CardContent>
            </CardActionArea>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    case 3: // Video post
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
              sx={{ paddingBottom: 0 }}
            />
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "0.7rem",
                  paddingTop: "0.7rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            {/* <Typography variant="h5" style={{color: theme.palette.primary.main}} className="text-center">Dialog Title</Typography> */}
            <CardActionArea
              className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
              href={props.Link}
              target="_blank"
              // style={cardStyle}
            >
              <CardContent>
                <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                  {props.Title}
                </Typography>
                {/* {props.Description} */}
                <div dangerouslySetInnerHTML={{ __html: props.Description }} />
                {ReturnVideo(props)}
              </CardContent>
            </CardActionArea>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    case 4: // Events post
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
              sx={{ paddingBottom: 0 }}
            />
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "0.7rem",
                  paddingTop: "0.7rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            {/* <Typography variant="h5" style={{color: theme.palette.primary.main}} className="text-center">Dialog Title</Typography> */}
            <CardContent>
              <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                {props.Title}
              </Typography>
              {/* {props.Description} */}
              <div dangerouslySetInnerHTML={{ __html: props.Description }} />
              {/* <Link className="text-base" href="#eventPage">
                Event Link
              </Link> */}
              {props.MediaURL && (
                <div>
                  <CardMedia
                    component="img"
                    image={props.MediaURL}
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      cursor: "pointer",
                    }}
                    onClick={handleDialogImageBoxOpen}
                  />
                </div>
              )}
              {DialogImageBoxOpen && images[0].url && images[0].title && (
                <DialogImageBox
                  open={true}
                  handleClose={handleDialogImageBoxClose}
                  images={images}
                  initialImageIndex={imageIndex}
                />
              )}
            </CardContent>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    case 5: // Streaming post
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
              sx={{ paddingBottom: 0 }}
            />
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "0.7rem",
                  paddingTop: "0.7rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            {/* <Typography variant="h5" style={{color: theme.palette.primary.main}} className="text-center">Dialog Title</Typography> */}
            <CardContent>
              <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                {props.Title}
              </Typography>
              {/* {props.Description} */}
              <div dangerouslySetInnerHTML={{ __html: props.Description }} />
              <div style={{ padding: "10px" }}>
                <CardMedia
                  component="img"
                  image={props.MediaURL}
                  style={{
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                  }}
                  // onClick={handleDialogImageBoxOpen}
                />
              </div>
              <Link className="text-base" href="#meetingLink">
                Meeting Link
              </Link>
            </CardContent>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    case 6: // Images post
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
              sx={{ paddingBottom: 0 }}
            />
            {/* <Typography variant="h5" style={{color: theme.palette.primary.main}} className="text-center">Dialog Title</Typography> */}
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "0.7rem",
                  paddingTop: "0.7rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            <CardContent>
              <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                {props.Title}
              </Typography>
              {/* {props.Description} */}
              <div dangerouslySetInnerHTML={{ __html: props.Description }} />
              {DialogImageBoxOpen && images[0].url && images[0].title && (
                <DialogImageBox
                  open={true}
                  handleClose={handleDialogImageBoxClose}
                  images={images}
                  initialImageIndex={imageIndex}
                />
              )}
              <div>
                <CardMedia
                  component="img"
                  image={props.MediaURL}
                  style={{
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                  }}
                  onClick={handleDialogImageBoxOpen}
                />
              </div>
            </CardContent>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    case 7: // Community post
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <CardHeader
              avatar={renderLink(
                <Avatar
                  alt={props.Owner}
                  src={
                    // "https://source.unsplash.com/random/" +
                    // Math.floor(Math.random() * 10) +
                    // "00x" +
                    // Math.floor(Math.random() * 10) +
                    // "00"
                    props.profilePicture
                  }
                />,
                `/Profile/`,
                props.memberID
              )}
              title={renderLink(
                <Typography
                  component="a"
                  sx={{
                    fontSize: "1.2rem",
                    textDecoration: "none",
                    color: "primary",
                  }}
                >
                  {props.Owner}
                </Typography>,
                `/Profile/`,
                props.memberID
              )}
              subheader={`${props.PubDate} • ${props.TimeElapsed}`}
            />
            {props.tags && props.tags.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem",
                }}
              >
                {props.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={
                      props.onFeed
                        ? () => props.onTagClick(tag)
                        : () => console.log("Not on feed")
                    }
                    className="mx-1 my-1"
                    color="primary"
                    sx={{ marginRight: "4px" }}
                  />
                ))}
              </div>
            ) : null}
            {/* <Typography variant="h5" style={{color: theme.palette.primary.main}} className="text-center">Dialog Title</Typography> */}
            <CardContent>
              <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                {props.Title}
              </Typography>
              {/* {props.Description} */}
              <div dangerouslySetInnerHTML={{ __html: props.Description }} />
              <Link className="text-base" href="#communityPage">
                Community Link
              </Link>
              <div style={{ padding: "10px" }}>
                <CardMedia
                  component="img"
                  image={props.MediaURL}
                  style={{
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                  }}
                  // onClick={handleDialogImageBoxOpen}
                />
              </div>
            </CardContent>
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
    default: // Error
      return (
        <ThemeProvider theme={theme}>
          <Card
            className={`m-auto ${props.onFeed ? "mt-8" : ""} grid p-1`}
            style={cardStyle}
          >
            <div className="grid grid-cols-3 mt-4 justify-items-center">
              <IconButton onClick={handleLike}>
                <ThumbUp
                  sx={{ color: liked ? theme.palette.primary.main : "inherit" }}
                />
              </IconButton>
              <IconButton onClick={handleComment}>
                <Message />
              </IconButton>
              <IconButton>
                <Share />
              </IconButton>
            </div>
          </Card>
        </ThemeProvider>
      );
  }
}

export default TextPost;
