import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Theme";

const MobileArticleCard = ({ feedID, image, title, description }) => {
  return (
    <ThemeProvider theme={theme}>
      <Card
        id={feedID}
        sx={{
          // display: "flex",
          margin: "20px",
          paddingTop:'2rem',
          height: "auto",
          maxHeight: "600px",
          borderRadius: "16px",
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            marginTop: "2rem",
            marginBottom: "2rem",
            flexGrow: 1,
            overflowY: "scroll",
          }}
        > */}
          <CardMedia
            component="img"
            image={image}
            alt="landing page image"
            // sx={{  objectFit: "cover", }}
            style={{
              height: "auto",
              maxHeight: `200px`,
              width: "auto",
              maxWidth: `250px`,
              margin: "0 auto", // Center the image horizontally
              paddingLeft:'10px',

            }}
          />
          <CardContent>
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                fontSize: "25px",
              }}
              className="text-center"
            >
              {title}
            </Typography>
            <Typography
              // sx={{ overflowX: "scroll" }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></Typography>
          </CardContent>
        {/* </Box> */}
      </Card>
    </ThemeProvider>
  );
};

export default MobileArticleCard;
