import React, { useState, useEffect, useRef } from "react";
import {
  Card, 
  CardHeader, 
  CardContent, 
  Avatar,
  Paper,
  Typography,
  TextField,
  Button,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Chip,
} from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';

import CustomPopUpForm from "../CustomPopUpForm";
import CustomAlert from "../CustomAlert";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
import ConnectionCardProfileInfo from "../profileComponents/connectionComponents/ConnectionCardProfileInfo";
import ConnectionStatus from "../../data/ConnectionStatus.json";
import { GetConnectionsByStatus } from "../../helper/APIHelper";

const ShareIndividualRecordsPopupForm = ({ open, onClose, records, folderName }) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [connections, setConnections] = useState([]);

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component

  useEffect(() => {
    const fetchConnectedConnections = async () => {
      const connectedConnections = await GetConnectionsByStatus(
        ConnectionStatus.status.Connected
      );
      setConnections(connectedConnections);
    };
    fetchConnectedConnections();
  }, []);

  // Call resetState when the open prop changes
  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open]);
  

  const resetState = () => {
    setSearchValue("");
    setSelectedConnections([]);
  };

  const handleSubmit = () => {
    if (selectedConnections.length === 0) {
      alertRef.current.showAlert(); // Show the alert if no connections are selected
    } else {
      selectedConnections.forEach((connection) => {
        console.log(connection);
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleConnectionChange = (connection) => {
    if (!selectedConnections.find((item) => item.memberID === connection.memberID)) {
      setSelectedConnections([...selectedConnections, connection]);
    }
    setSearchValue("");
  };

  const handleClearConnection = (connectionIndex) => {
    setSelectedConnections(selectedConnections.filter((_, index) => index !== connectionIndex));
  };

  const getPrimaryField = (record) => {
    switch (folderName) {
      case "Allergies":
        return record.allergen;
      case "Family History":
        return record.relation;
      case "Medications":
        return record.medication;
      default:
        return record.description;
    }
  };
  
  const renderSharedRecords = records.map((record, index) => (
    <React.Fragment key={index}>
      <ListItem>
        <ListItemIcon>
          <DescriptionIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={getPrimaryField(record)} />
      </ListItem>
      {index !== records.length - 1 && <Divider />}
    </React.Fragment>
  ));
  


  const filteredConnections = connections
  .filter((connection) =>
    connection.username.toLowerCase().includes(searchValue.toLowerCase()) ||
    (connection.firstName.toLowerCase() + ' ' + connection.lastName.toLowerCase()).includes(searchValue.toLowerCase())
  )
  .slice(0, 10);

  const renderSelectedConnections = selectedConnections.map(
    (connection, connectionIndex) => (
      <Chip
        key={connectionIndex}
        label={connection.username}
        onDelete={() => handleClearConnection(connectionIndex)}
      />
    )
  );

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef}
        title="Selection Error"
        type="error"
        content="Please select <strong>at least one</strong> connection to share records with."
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <CustomPopUpForm
        open={open}
        onClose={onClose}
        title="Share Records"
        onSubmit={handleSubmit}
        onCancel={onClose}
      >
        <div className="grid grid-cols-1 gap-4">
          <h3>Record Items to be Shared:</h3>
          <div>{renderSharedRecords}</div>

          <TextField
            label="Search Connections"
            size="small"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {searchValue && (
            <Paper
              sx={{
                maxHeight: "200px",
                overflowY: "scroll",
                zIndex: 10,
                width: "100%",
              }}
            >
              {filteredConnections.map((connection) => (
                <MenuItem
                  key={connection.memberID}
                  onClick={() => handleConnectionChange(connection)}
                >
                  <ConnectionCardProfileInfo connection={connection} disableLinks={true}/>
                </MenuItem>
              ))}
            </Paper>
          )}

          <div style={{ marginTop: "10px" }}>{renderSelectedConnections}</div>
        </div>
      </CustomPopUpForm>
    </ThemeProvider>
  );
};

export default ShareIndividualRecordsPopupForm;
