import React from "react";
import theme from "../../Theme";
import { Navigate, Link } from "react-router-dom";
import {
  Card,
  Button,
  CardHeader,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { width } from "@mui/system";
import { useAuth } from "../../components/AuthProvider";
import Role from "../../data/role.json";

const MiniCardComponent = ({
  image,
  title,
  textSize,
  position,
  size,
  isMobile,
  itemID,
}) => {
  const EDIT_URL =
    "https://aiworksqa.agiline.com//global/index?globalurlid=787048E1-D78C-4E6F-BEB2-D9ED05BD6266&param1=";
  const screenHeight = window.innerHeight;
  const cardHeightLeft = (screenHeight * 16) / 100;
  const style = {
    position: "relative",
    width: position === "left" ? `${size}px` : `${size}px`,
    height: position === "left" ? `${cardHeightLeft}px` : `${size}px`,
    backgroundColor: "white",
    display: "flex",
    justifyContent: position === "left" ? "flex-start" : "flex-start",
    // alignItems: position === "left" ? "center" : "flex-start",
    // flexDirection: position === "left" ? "row" : "column",
    marginBottom: "60px",
    marginRight: !isMobile ? "30px" : "0",
  };

  const imgStyle = {
    maxWidth: position === "left" ? `${size / 2}px` : `${size}px`,
    maxHeight: position === "left" ? `${cardHeightLeft}px` : `${size / 2}px`,
    minWidth: position === "left" ? `${size / 2}px` : `${size}px`,
    minHeight: position === "left" ? `${cardHeightLeft}px` : `${size / 2}px`,
    // objectFit: "fill",
    objectFit: "cover",
    objectPosition: "center",
    marginRight: position === "left" ? "10px" : "0",
    marginBottom: position === "left" ? "0" : "10px",
  };

  const titleStyle = {
    fontSize: `${textSize}px`,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  };

  const handleEditClick = () => {
    <Navigate to={EDIT_URL + itemID} target="_blank" />;
    console.log("Edit button click with itemID: ", itemID);
  };

  let auth = useAuth();

  const isAdmin = () => {
    let result =
      auth.user !== null &&
      auth.user !== undefined &&
      auth.user.isAdmin === true;
    return result;
  };

  var myfun = function (ele) {
    let offsetTop = document.getElementById(ele).offsetTop;
    window.scrollTo({
      top: offsetTop - 100,
      behavior: "smooth",
    });
  };

  function handleCardClick (){
    myfun(itemID);
    console.log("itemID ",itemID);
  }
  return (
    <Card style={style} onClick={handleCardClick}>
      <CardMedia
        component="img"
        style={imgStyle}
        image={image}
        alt="landing page image"
      />
      {/* <img src={image} alt="" style={imgStyle} /> */}
      <CardContent>
        <div>
          <p style={titleStyle}>{title}</p>
        </div>
        {/* <IconButton
          style={{ position: "absolute", top: 0, right: 0 }}
          aria-label="settings"
          onClick={handleEditClick}
        >
          <EditIcon />
        </IconButton> */}
        {isAdmin() ? (
          <Link
            to={EDIT_URL + itemID}
            target="_blank"
            style={{ position: "absolute", top: 1, right: 1 }}
          >
            <EditIcon />
          </Link>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};

export default MiniCardComponent;
