import { useAuth } from "../components/AuthProvider";
import { useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";

function RequireAuth() {
  let auth = useAuth();
  auth.user = localStorage.getItem("userInfo");
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}
export default RequireAuth;
