const generateProfileImageUrls = (baseUrl, count) => {
  const urls = [];
  for (let i = 0; i < count; i++) {
    urls.push(`${baseUrl}/${300 + i}x${300 + i}`);
  }
  return urls;
};

const profileImageUrls = generateProfileImageUrls("https://source.unsplash.com/random", 50);

const createConnections = (count) => {
  const connections = [];
  for (let i = 0; i < count; i++) {
    connections.push({
      caption: "image "+i.toString(),
      image: profileImageUrls[i],
    });
  }
  return connections;
};

const feedFilterData = createConnections(20);

export default feedFilterData;
