import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Slider,
} from '@mui/material';
import {
  Close as CloseIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import'../App.css'
function DialogImageBox({ open, handleClose, images, initialImageIndex = 0 }) {
  console.log("image ", images);
  console.log("images[0] ",images[0]);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
  const [zoomLevel, setZoomLevel] = useState(100);

  // const handleZoomIn = () => {
  //   setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 10, 200));
  // };

  // const handleZoomOut = () => {
  //   setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 10, 100));
  // };

  // const handleNextImage = () => {
  //   setCurrentImageIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
  // };

  // const handlePreviousImage = () => {
  //   setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  // };

  // const downloadLinkRef = useRef();

  // const handleDownload = () => {
  //   if (downloadLinkRef.current) {
  //       console.log(images[currentImageIndex].url)
  //       console.log(images[currentImageIndex].title)
  //       console.log(images)
  //     downloadLinkRef.current.href = images[currentImageIndex].url;
  //     downloadLinkRef.current.download = images[currentImageIndex].title;
  //     downloadLinkRef.current.click();
  //   }
  // };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          margin='10px'
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 10}}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={images[currentImageIndex].url}
            alt={images[currentImageIndex].title}
            style={{
              width: `${zoomLevel}%`,
              transition: 'width 0.3s',
            }}
          />
          <Typography variant="h6" gutterBottom>
            {images[currentImageIndex].title}
          </Typography>
          {/* <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={handlePreviousImage}
              disabled={currentImageIndex === 0}
              aria-label="previous image"
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              onClick={handleZoomOut}
              disabled={zoomLevel === 100}
              aria-label="zoom out"
            >
              <ZoomOutIcon />
            </IconButton>
            <Slider
              value={zoomLevel}
              onChange={(_, value) => setZoomLevel(value)}
              min={100}
              max={200}
              sx={{ width: 200 }}
            />
            <IconButton
              onClick={handleZoomIn}
              disabled={zoomLevel === 200}
              aria-label="zoom in"
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              onClick={handleNextImage}
              disabled={currentImageIndex === images.length - 1}
              aria-label="next image"
            >
            <ArrowForwardIosIcon />
            </IconButton>
            <IconButton onClick={handleDownload} aria-label="download image">
              <GetAppIcon />
            </IconButton>
          </Box> */}
        </Box>
      </DialogContent>
      {/* <a
        ref={downloadLinkRef}
        href="#"
        download=""
        style={{ display: "none" }}
      /> */}
    </Dialog>
  );
};

export default DialogImageBox;
