import React from 'react';
import { useState , useRef} from 'react';
import {TextField, Button,MenuItem} from '@mui/material'
import CustomPopUpForm from '../CustomPopUpForm';
import theme from '../../Theme.js';
import { ThemeProvider } from "@mui/material/styles";
import { GetSettingsByKey } from "../../helper/APIHelper";
import CustomAlert from '../CustomAlert';
import { PostHistoryRecord } from '../../services/HealthServiceHelper';
const historyResourcesKey = ["HistoryType", "Relation"];
function HistoryPopupForm({ open, onClose ,enableEditing ,historyInitialValues , showSubmit}) {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(historyResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [submit, setSubmit] = useState (false);
  const [error, setError] = useState("");
  const [state, setState] = React.useState({
    relation: historyInitialValues!== "" ? historyInitialValues.relation :"",
    historyType: historyInitialValues!== "" ? historyInitialValues.historyType :"",
    description: historyInitialValues!== "" ? historyInitialValues.description :"",
    onSet: historyInitialValues!== "" ? historyInitialValues.onset.split("T").shift() :"",
    age: historyInitialValues!== "" ? historyInitialValues.ageAtOnset :"",
    comments: historyInitialValues!== "" ? historyInitialValues.comments :"",
  })


  function clearData (){
    setState({
      relation:"",
      historyType:"",
      description:"",
      onSet:"",
      age:"",
      comments:"",
    })
    setSubmit(false);
  }
  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value
    });
  }

//   const [open, setOpen] = useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

  async function handleSubmit (){
    console.log(state);
    setSubmit(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const ContactID = userInfo.contactID;
    console.log("Patient ID "+ContactID);
    let historyRecord ='';
    if(state.relation !== '' && state.historyType !== '' && state.onSet !== '' && state.age !== ''){
      historyRecord = {
        patientID: ContactID,
        relation: state.relation,
        historyType: state.historyType,
        description: state.description,
        ageAtOnset: state.age,
        onset: state.onSet,
        comments: state.comments
      }
      console.log(historyRecord);
      const response = await PostHistoryRecord(historyRecord);
      console.log("response ",response);
      if(response.ok === true){
        setError("");
        clearData();
        handleEditContactInfoClick();
        onClose();
      }
      else{
        if(response.statusText === ""){
          setError("Your Request Was Not Fulfilled")
        }
        else{
          setError(response.statusText);
        }
        handleEditContactInfoClick();
      }
    }
  };

  const handleCancel = () => {
    //clearData();
    onClose();
  };
  function filterDataByValue(filterValue) {
    console.log("Filter " + filterValue);
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.resourceID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }
  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
      alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };
    return (
      <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type= {error ? "error" : "success"}
        content={error ? error :"Form Submitted."}
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
     <CustomPopUpForm
      open={open}
      onClose={onClose}
      title="Family History"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      showSubmit={showSubmit}
    >
        <div className="grid grid-cols-1 gap-4">
          <TextField required label="Relation" select size="small" variant="outlined" 
            name="relation"
            value={state.relation}
            onChange = {handleChange}
            error = {submit && !state.relation}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          >
            {filterDataByValue("Relation")}
          </TextField>
          <TextField required label="History Type" select size="small" variant="outlined"
            name="historyType"
            value={state.historyType}
            onChange = {handleChange}
            error = {submit && !state.historyType}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          >
            {filterDataByValue("HistoryType")}
          </TextField>
          <TextField label="Enter Description" size="small" variant="outlined"
            name="description"
            value={state.description}
            onChange = {handleChange}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          />
          <TextField required label="Onset" size="small" variant="outlined" type="Date" InputLabelProps={{ shrink: true }}
            name="onSet"
            value={state.onSet}
            onChange = {handleChange}
            error = {submit && !state.onSet}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          />
          <TextField required label="Age At Onset" size="small" variant="outlined" 
            name="age"
            value={state.age}
            onChange = {handleChange}
            error = {submit && !state.age}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          />
          <TextField label="Notes And Comments" size="small" variant="outlined" multiline
            name="comments"
            value={state.comments}
            onChange = {handleChange}
            disabled={!enableEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              }
            }}
          />
        </div>
      </CustomPopUpForm>
      </ThemeProvider>
    );
}

export default HistoryPopupForm;