import React, { useState } from "react";
import theme from "../../Theme";
import { TextField, ThemeProvider, Button, CardActions} from "@mui/material";
import Validator from "validator";
import {ValidateEmail} from "../../helper/APIHelper"



function SecondStep({handleNext , handleBack, record}) {

  const [submit, setSubmit] = React.useState(false);
  const [error, setError] = useState({
    mobileNumber: "",
    workPhone: "",
    validEmail: "",
    emailExists: "",

  });
  const [state, setState] = React.useState({
    firstName: record ? record.firstName : '',
    middleName: record ? record.middleName : '',
    lastName: record ? record.lastName : '',
    workNumber: record ? record.phone : '',
    mobileNumber: record ? record.mobilePhone : '',
    email: record ? record.eMail : '',
  });
  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value
    });
  }
  function validatePhoneNumber(phoneNumber) {
    const regex = /^\+?\d{1,3}[- ]?\d{3}[- ]?\d{4}$/;
    return regex.test(phoneNumber);
  }


  async function validations (){
    let error = {}
    if(state.email === undefined){
      setState({
        ...state,
        email:"",
      })
      error.validEmail = "Enter A Valid Email";
    }
    else if(state.email !== '' &&!Validator.isEmail(state.email)){
      error.validEmail = "Enter A Valid Email";
    }
    if(state.mobileNumber !== '' && state.mobileNumber !== undefined && !validatePhoneNumber(state.mobileNumber)){
      error.mobileNumber = "Enter A Valid Phone Number";
    }
    if(state.workNumber !== '' && state.workNumber !== undefined && !validatePhoneNumber(state.workNumber)){
      error.workPhone = "Enter A Valid Phone Number";
    }
    if(state.email !== undefined && state.email !== '') {
      const validateUser = await ValidateEmail(state.email);
      if(validateUser !== ""){
        error.emailExists = await validateUser;
      }
    }
    await setError(error);
    console.log("error ",error);
    console.log("ret ",Object.keys(error).length === 0);
    return await Object.keys(error).length === 0;
  }

  async function handleOnNextClick(){
    console.log("state ", state)
    setSubmit(true);
    console.log("validations ",validations());
    if(await validations())
    {
      setError("");
      let SecondRecord = '';
      if(state.firstName !== '' && state.firstName !== undefined
        && state.lastName!=='' && state.lastName!==undefined
        && state.email !== '' && state.email !== undefined
        && state.mobileNumber !=='' && state.mobileNumber !== undefined
        ){
        SecondRecord = {
          firstName: state.firstName,
          middleName: state.middleName,
          lastName: state.lastName,
          eMail: state.email,
          phone: state.workNumber,
          mobilePhone: state.mobileNumber,
        }
        handleNext(SecondRecord);
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <TextField required label="Enter First Name" size="small" variant="outlined"
        name="firstName"
        value={state.firstName}
        onChange={handleChange}
        error={submit && !state.firstName}
        style={{width: '85%', marginTop:"10px"}}
      />

      <TextField label="Enter Middle Name" size="small" variant="outlined"
        name="middleName"
        value={state.middleName}
        onChange={handleChange}
        style={{width: '85%' , marginTop:"0px"}}
      />

      <TextField required label="Enter Last Name" size="small" variant="outlined"
        name="lastName"
        value={state.lastName}
        onChange={handleChange}
        error={submit && !state.lastName}
        style={{width: '85%' , marginTop:"-10px"}}
      />
      <TextField required label="Enter Mobile Number" size="small" variant="outlined"
        name="mobileNumber"
        value={state.mobileNumber}
        onChange={handleChange}
        error={error.mobileNumber || (submit && !state.mobileNumber)}
        helperText={error.mobileNumber ? error.mobileNumber : ""}
        style={{width: '85%', marginTop:"-20px"}}
      />
      <TextField label="Enter Work Number" size="small" variant="outlined"
        name="workNumber"
        value={state.workNumber}
        onChange={handleChange}
        error={error.workPhone}
        helperText={error.workPhone ? error.workPhone : ""}
        style={{width: '85%', marginTop:"-30px"}}
      />
      <TextField required label="Enter Email" size="small" variant="outlined"
        name="email"
        type="email"
        value={state.email}
        onChange={handleChange}
        error={error.emailExists || error.validEmail || (submit && !state.email)}
        helperText={error.validEmail ? error.validEmail : error.emailExists}
        style={{width: '85%' , marginTop:"-40px"}}
      />
      <CardActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,marginTop:"-60px"}}>
        <Button sx={{ fontSize: '1rem', width: '300px' }} onClick={handleBack}>
          Back
        </Button>
        <Button sx={{ fontSize: '1rem', width: '300px' }} variant="contained" onClick={handleOnNextClick}>
          Next
        </Button>
      </CardActions>
    </ThemeProvider>
  );
}
export default SecondStep