import React, { useState } from "react";
import SingleDropDown from "./SingleDropDown";
import theme from "../Theme";
import { IconButton, ThemeProvider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

export default function GroupedPanelsComponent({ dropdownData }) {
  const [isViewOrEdit, setIsViewOrEdit] = useState(
    dropdownData[0].isViewOrEdit ? dropdownData[0].isViewOrEdit : 0
  );
  const [isExpanded, setIsExpanded] = useState(
    dropdownData[0].isExpanded !== undefined &&
      dropdownData[0].isExpanded !== null
      ? dropdownData[0].isExpanded
      : dropdownData[0].embeddedFunctions &&
        dropdownData[0].embeddedFunctions.some(
          (func) => func.toLowerCase() === "edit"
        ) &&
        isViewOrEdit === 1
      ? true
      : false
  );

  const [externalEdit, setExternalEdit] = useState(
    dropdownData[0].embeddedFunctions &&
      dropdownData[0].embeddedFunctions.some(
        (func) => func.toLowerCase() === "edit"
      ) &&
      isViewOrEdit === 1
      ? true
      : false
  );
  const [isCanceled, setIsCanceled] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleEmbeddedEditFunction = () => {
    setIsViewOrEdit(1);
    setExternalEdit(true);
    setIsExpanded(true);
  };
  const handleBackToEditFunction = () => {
    setIsViewOrEdit(0);
    setExternalEdit(false);
    setIsExpanded(true);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    setIsCanceled(true);
    handleBackToEditFunction();
  };
  const handleSaveClick = (event) => {
    event.stopPropagation();
    setIsSaved(true);
    handleBackToEditFunction();
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "1rem", width: "100%" }}>
        {dropdownData[0].embeddedFunctions &&
          dropdownData[0].embeddedFunctions.some(
            (func) => func.toLowerCase() === "edit"
          ) && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "1rem ",
              }}
            >
              {externalEdit ? (
                <div>
                  <IconButton onClick={handleSaveClick}>
                    <SaveIcon style={{ color: theme.palette.primary.main }} />
                  </IconButton>
                  <IconButton onClick={handleCancelClick}>
                    <CancelIcon style={{ color: theme.palette.primary.main }} />
                  </IconButton>
                </div>
              ) : (
                <IconButton onClick={handleEmbeddedEditFunction}>
                  <EditIcon style={{ color: theme.palette.primary.main }} />
                </IconButton>
              )}
            </div>
          )}
        {dropdownData.map((data, index) => (
          <React.Fragment key={index}>
            {data.contents.map((content, contentIndex) => (
              <SingleDropDown
                key={contentIndex}
                contents={content}
                viewType={data.viewType}
                bootstrapZones={data.bootstrapZones}
                captions={data.captions}
                headerName={data.headerName}
                disabled={data.disabled}
                isExpanded={isExpanded}
                showPagination={data.showPagination}
                itemsPerPage={data.itemsPerPage}
                paginationColor={data.paginationColor}
                functionNames={data.functionNames}
                functionIcons={data.functionIcons}
                functionsArray={data.functionsArray}
                width={data.width}
                headerColor={data.headerColor}
                hoverColor={data.hoverColor}
                IconColor={data.IconColor}
                mergeFields={data.mergeFields}
                mergeCounts={data.mergeCounts}
                hiddenFields={data.hiddenFields}
                cardViewType={data.cardViewType}
                autoDetectType={data.autoDetectType}
                isViewOrEdit={isViewOrEdit}
                editableFields={data.editableFields}
                types={data.types}
                showSearchBar={data.showSearchBar}
                isCanceled={isCanceled}
                setIsCanceled={setIsCanceled}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </ThemeProvider>
  );
}
