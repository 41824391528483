import React from "react";
import { useRef } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomAlert from "../CustomAlert";
import CustomToolbar from "./CustomToolBar";
import { GetEvents } from "../../helper/APIHelper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ViewEventForm from "./ViewEvents";
import CreateFeedbackButton from "../CreateFeedbackButton";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Theme.js";
import NewEvent from "./NewEvent";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const customEventColors = {
  Health: "#f38ba0",
  Communication: "#7dc5e6",
  Technology: "#b2d8d8",
  Education: "#f1a94e",
  Other: "#fcba03",
};

const defaultEventColor = "#0e6d73";
var grey = false;

const getEventColor = (event, view) => {
  if (view === "agenda" && !grey) {
    grey = true;
    return {
      style: {
        backgroundColor: "#ffffff",
      },
    };
  } else if (view === "agenda" && grey) {
    grey = false;
    return {
      style: {
        backgroundColor: "#f2f2f2",
      },
    };
  } else if (event.color) {
    return { style: { backgroundColor: event.color } };
  } else {
    return { style: { backgroundColor: defaultEventColor } };
  }
};
const Agenda = () => {
  const [error, setError] = React.useState("");
  const [view, setView] = React.useState("month");
  const [openPopup, setOpenPopup] = React.useState(false); // Flag for popup form
  const [openSelectEvent, setOpenSelectEvent] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [data, setData] = React.useState([]);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const isMobile = useMediaQuery("(max-width: 767px)");

  React.useEffect(() => {
    async function FillData() {
      let temp = await GetEvents();
      setData(temp);
    }
    FillData();
  }, []);

  function handleClosePopup() {
    setOpenPopup(false);
    setOpenSelectEvent(false);
  }

  function formatDate(start) {
    const dateObj = new Date(start);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // add 1 to month since it's zero-indexed, and pad with 0 if necessary
    const day = dateObj.getDate().toString().padStart(2, "0"); // pad with 0 if necessary

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function handlePopUp(slotInfo) {
    setStartDate(formatDate(slotInfo.start));
    setOpenPopup(true);
  }
  const handleViewChange = (view) => {
    setView(view);
  };
  function handleSelectEvent(event) {
    setSelectedEvent(event);
    setOpenSelectEvent(true);
  }

  const mappedEvents = data.map((event) => ({
    title: event.title,
    start: new Date(event.startDate),
    end: new Date(event.endDate),
    speaker: event.speaker,
    speakerBio: event.speakerBio,
    host: event.host,
    desc: event.description,
    type: event.category,
    color: event.eventColor,
  }));

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          fontFamily: "Arial, sans-serif",
          padding: "20px",
          height: "600px",
          backgroundColor: "#ffffff",
        }}
      >
        <style>
          {`
        .rbc-header {
          font-weight: normal;
          font-size: 16px;
        }
      `}
        </style>

        <Calendar
          localizer={localizer}
          events={mappedEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ fontFamily: "Arial, sans-serif" }}
          eventPropGetter={(event) => getEventColor(event, view)}
          onView={handleViewChange}
          onSelectSlot={(slotInfo) => {
            handlePopUp(slotInfo);
          }}
          selectable={true}
          popup={true}
          onSelectEvent={handleSelectEvent}
          components={{
            toolbar: CustomToolbar,
          }}
          // components={{
          //   toolbar: isMobile ? CustomToolbar : null,
          // }}
          // components={{
          //   toolbar: (props) => (
          //     <CustomToolbar
          //       {...props}
          //       handleViewChange={handleViewChange}
          //     />
          //   ),
          // }}
        />
        {openPopup && (
          <>
            <CustomAlert
              ref={alertRef} // This is how you pass the ref to the CustomAlert component
              title={error ? "Error" : "Success"}
              type={error ? "error" : "success"}
              content={error ? error : "Form Submitted."}
              duration={2000}
              ancorVertical="top"
              ancorHorizontal="center"
            />
            <NewEvent
              open={openPopup}
              onClose={handleClosePopup}
              start={startDate}
              handleEditContactInfoClick={handleEditContactInfoClick}
              error={error}
              setError={setError}
            />
          </>
        )}
        {selectedEvent && (
          <ViewEventForm
            open={openSelectEvent}
            onClose={handleClosePopup}
            selectedEvent={selectedEvent}
          />
        )}
        <CreateFeedbackButton />
      </div>
    </ThemeProvider>
  );
};
export default Agenda;
