import React from "react";

import { Box, Chip } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import ConnectionProfileInfo from "./ConnectionProfileInfo";
import { v4 as uuid } from "uuid";
import AddConnectionComponent from "./AddConnectionComponent";

const ListConnections = ({ connection, type, setEncID , setPatientID ,setPatientTab}) => {
  const badgeAlertsArray = connection.badgeAlert ? connection.badgeAlerts.split(",") : "";
  return (
    <div
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <AddConnectionComponent connection={connection} /> */}
      <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
        <ConnectionProfileInfo connection={connection} setEncID={setEncID} setPatientID={setPatientID} setPatientTab={setPatientTab}/>
        {type === "Connected" && (
          <Chip
            key={uuid}
            label={"Connected"}
            variant="outlined"
            icon={<PersonAdd color="#008c1d" />}
            color="secondary"
            sx={{
              height: "28px",
              backgroundColor: "white",
              color: "#008c1d",
              border: "1px color gray",
              marginBottom: "15px",
              textAlign: "left",
              width: "9rem",
            }}
          />
        )}
        {type === "Received" && (
          <Chip
            key={uuid}
            label={"Received"}
            variant="outlined"
            icon={<PersonAdd color="#FFC107" />}
            color="warning"
            sx={{
              height: "28px",
              backgroundColor: "white",
              color: "#FFC107",
              border: "1px color gray",
              marginBottom: "15px",
              textAlign: "left",
              width: "9rem",
            }}
          />
        )}
        {(type === "Pending" ||type ==="Sent" )&& (
          <Chip
            key={uuid}
            label={"Pending"}
            variant="outlined"
            color="info"
            icon={<PersonAdd color="#0288d1" />}
            sx={{
              height: "28px",
              backgroundColor: "white",
              color: "#0288d1",
              border: "1px color gray",
              marginBottom: "15px",
              textAlign: "left",
              width: "9rem",
            }}
          />
        )}
        {(type === "No Connection" || type ==="None") && (
          <Chip
            key={uuid}
            label={"No Connection"}
            variant="outlined"
            color="error"
            icon={<PersonAdd color="#d32f2f" />}
            sx={{
              height: "28px",
              backgroundColor: "white",
              color: "#d32f2f",
              border: "1px color gray",
              marginBottom: "15px",
              textAlign: "left",
              width: "9rem",
            }}
          />
        )}
      </Box>
      {connection.badgeAlerts  &&
        badgeAlertsArray.map((badgeAlert, index) => (
          <Chip
            key={uuid}
            label={badgeAlert}
            variant="outlined"
            // icon={<TagIcon color="black" fontSize="small" />}
            // onClick={() => handleTagClicked(tag.resourceName)}
            sx={{
              height: "28px",
              backgroundColor: "white",
              color: "#7a7a7a",
              border: "1px color gray",
              marginBottom: "15px",
              textAlign: "left",
              margin: "0.3rem",
            }}
          />
        ))}
        {
          connection.encStatus && (
            <Chip
            label={connection.encStatus}
            variant="outlined"
            // icon={<TagIcon color="black" fontSize="small" />}
            // onClick={() => handleTagClicked(tag.resourceName)}
            sx={{
              height: "28px",
              backgroundColor: "white",
              color: "#7a7a7a",
              border: "1px color gray",
              marginBottom: "15px",
              textAlign: "left",
              margin: "0.3rem",
            }}
          />
          )
        }
    </div>
  );
};

export default ListConnections;
