import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  GetRecordFolders,
  GetFeedDataFilter,
  GetSettingsByKey,
  ChangePassword,
  GetConnections,
  GetConnectionsByType,
  GetConnectionsByStatus,
  GetUserInfo,
  GetEvents,
  CreateConnectionRequest,
  UpdateConnection,
  RegisterUser,
  RegisterProvider,
  CreatePost,
  GetUsersByName,
  UpdateContactInfo,
} from "../helper/APIHelper";
import { useEffect, useState, useRef } from "react";
import { PostDocuments } from "../services/HealthServiceHelper";

function Events() {
  // Test for GetRecord Folders
  const memberID = "3-400";
  const requestorID = "3-400";
  const [records, setRecords] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const searchRef = useRef();

  function handleFileSelect(event) {
    // setSelectedFile(event.target.files[0]);
    setSelectedFile(event.target.files);
  }
  console.log("the file: ", selectedFile);

  // async function handleSubmit(event) {
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append("file", selectedFile);
  //   documentUpload.mediaFiles.push(formData);
  //   const response = UploadDocuments(documentUpload);
  // }

  let randomObj = { name: "Hello world", age: 19 };

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    for (var x = 0; x < selectedFile.length; x++) {
      // the name has to be 'files' so that .NET could properly bind it
      formData.append("files", selectedFile[x]);
    }
    //formData.append("patientDocument", baseRecord);
    //formData.append("registration", JSON.stringify(providerRegistration));
    formData.append("postData", JSON.stringify(newPost));
    console.log("form File: ", formData);
    //const response = UploadDocuments(formData);
    //const response = RegisterNewUser(formData);
    const response = await CreatePost(formData);
  }

  const UploadDocuments = async (formData) => {
    const response = await PostDocuments(formData);
  };

  const RegisterNewUser = async (formData) => {
    //const response = await RegisterUser(formData);
    const response = await RegisterProvider(formData);
  };

  const handleSearchUsers = async (editor) => {
    const response = await GetUsersByName(searchRef.current.value);
    console.log("Search by name: ", response);
  };

  const handleUpdateContactInfo = async (editor) => {
    const response = await UpdateContactInfo(contactInfo);
    console.log("Userinfo to update: ", contactInfo);
  };

  useEffect(() => {
    //const response = recordFolders();
    // setRecords(response);
    //PostMedicalRecord(newMedRecord);
    //console.log("Record Folders: ", response);
  }, []);

  const recordFolders = async () => {
    // let response = await GetRecordFolders(memberID, requestorID);
    // let response = await GetFeedDataFilter(feedFilter);
    //let response = await GetSettingsByKey(["TopicGroups", "Libraries"]);
    //let response = await ChangePassword("HelloWorld", "Test@123");
    //let response = await GetConnections();
    //let response = await GetConnectionsByStatus("Pending");
    //let response = await GetConnectionsByType("from", "Connected");
    //let response = await GetUserInfo("3-400");
    //let response = await GetEvents();
    console.log("Call test api");
    //let response = await CreateConnectionRequest("3-403");
    let response = await UpdateConnection("189", "Connected");
    return response;
    //setRecords(response);
  };

  const newPost = {
    userPkeyID: 275,
    QLibID: "11",
    Header: "Testing Creating New Post",
    SubHeader: "Covid-19",
    Description: "New Test 5/8/2023 ",
    CreateBy: "W.Wolf",
    CreateDate: "2023-03-22T19:27:23.693Z",
  };

  const patientRegistration = {
    Username: "John_Doe",
    FirstName: "John",
    LastName: "Doe",
    Password: "12345",
    EMail: "johndoe@gmail.com",
  };

  const providerRegistration = {
    Username: "Jane_Doe",
    FirstName: "Jane",
    LastName: "Doe",
    Password: "12345",
    IdentificationType: "MPN",
    IdentificationNumber: "98765",
    EMail: "janedoe@gmail.com",
  };

  const feedFilter = {
    index: 0,
    count: 5,
    topic: "",
    library: "",
    tag: "World Health Organization",
    search: "",
    userPkeyID: "0",
  };

  const newMedRecord = {
    appGUID: "Hello Frmo the Front End",
    patientID: 400,
    createdDate: "2023-03-22T19:27:23.693Z",
    medID: 0,
    medication: "Tylenol",
    dosage: "strawberry",
    units: "millilitre",
    fUnits: "dunno",
    frequency: "4234",
    route: "string",
    instructions: "string",
    beginDate: "2023-03-22T19:27:23.693Z",
    endDate: "2023-03-22T19:27:23.693Z",
    comments: "a random med records from front end",
    medStatus: "on going",
    provider: "Elsevier",
  };

  //const documentUpload = {
  const patientDocument = {
    PatientID: 403,
    CreatedDate: "2023-04-03T21:17:43.827Z",
    Title: "Testing Document Upload",
    Description: "TEST@0520",
    EncFolder: "Lab Results",
    EncFolderID: 10,
    QLibID: 0,
    SourceType: "Clinic",
    CreatedBy: "W.Wolf",
    SourceName: "The Lobotomy Specialists",
    EffectiveDate: "2023-04-03T21:17:43.827Z",
  };

  const baseRecord = {
    AppGUID: "Hello World",
    PatientID: 22,
    CreatedDate: "2023-04-03T21:17:43.827Z",
  };

  const contactInfo = {
    FirstName: "Stan",
    Middle: "W",
    LastName: "Lee",
    Address: "1 street",
    Address_Comp: null,
    City: "Glendora",
    State: "AK",
    Zip: "73664",
  };

  console.log(records);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
      <h1>Events Page</h1>
      <Typography paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus
        non enim praesent elementum facilisis leo vel. Risus at ultrices mi
        tempus imperdiet. Semper risus in hendrerit gravida rutrum quisque non
        tellus. Convallis convallis tellus id interdum velit laoreet id donec
        ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl
        suscipit adipiscing bibendum est ultricies integer quis. Cursus euismod
        quis viverra nibh cras. Metus vulputate eu scelerisque felis imperdiet
        proin fermentum leo. Mauris commodo quis imperdiet massa tincidunt. Cras
        tincidunt lobortis feugiat vivamus at augue. At augue eget arcu dictum
        varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt.
        Lorem donec massa sapien faucibus et molestie ac.
      </Typography>
      <Typography paragraph>
        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
        ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar elementum
        integer enim neque volutpat ac tincidunt. Ornare suspendisse sed nisi
        lacus sed viverra tellus. Purus sit amet volutpat consequat mauris.
        Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus sed
        vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra
        accumsan in. In hendrerit gravida rutrum quisque non tellus orci ac.
        Pellentesque nec nam aliquam sem et tortor. Habitant morbi tristique
        senectus et. Adipiscing elit duis tristique sollicitudin nibh sit.
        Ornare aenean euismod elementum nisi quis eleifend. Commodo viverra
        maecenas accumsan lacus vel facilisis. Nulla posuere sollicitudin
        aliquam ultrices sagittis orci a.
      </Typography>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          encType="multipart/form-data"
          onChange={handleFileSelect}
          multiple
        />
        <button type="submit">Upload</button>
      </form>
      <input ref={searchRef} placeholder="search by name"></input>
      <button onClick={handleUpdateContactInfo}>Change userInfo</button>
    </Box>
  );
}
export default Events;
