import React from "react";

function Terms() {
    return (
        <div style={{ margin: '20px' }}>
            <h1 class="header-label" style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>The HealthBloq Platform</h1>

            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>Customers</h2>
            <br></br>
            <p>The Service also permits end users to interact with developers that create means of displaying data which end
                users authorize to share via the Service ('Developers') and to organizational customers of HealthBloq
                ('Organizational Customers'). For purposes hereof, 'Customers' means Developers and Organizational
                Customers.
            </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>You Authorize Us To Use Your Data In Accordance With Our Privacy Policy</h2>
            <br></br>
            <p>You hereby authorize the collection, storage, integration, transmission, sharing and/or display by HealthBloq
                of personal data from health records, personal trackers or other personal data sources as further described
                herein (including in our Privacy Policy).</p>

            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>Notifications and Alerts</h2>
            <br></br>
            <p>We post electronic communications on the Site or within the Services to keep you informed. Notices and other
                information may be provided by text, on the Site or Services or through a link to the appropriate page,
                accessible through any standard, commercially available internet browser.</p>

            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>If You Consent To It, We May Send You Electronic Communications</h2>
            <br></br>
            <p>If you provide us with your e-mail address and your consent, you agree to receive all required notices and
                information delivered to your email address that we have on file for you. In such case, it is your
                responsibility to promptly update us with your complete, accurate contact information, or change your
                information, including email address, as appropriate. Your consent to receive communications electronically
                is valid until your transaction with a Customer is completed and you end your relationship with us. You may
                print a copy of any electronic communication and retain it for your records. We reserve the right to
                terminate or change how we provide electronic communications and will provide you with appropriate notice in
                accordance with applicable law.</p>

            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>We Are Not Responsible For Third Party Sources</h2>
            <br></br>
            <p>
                You may direct HealthBloq to retrieve your information maintained online by third parties with which you have
                customer or patient relationships, maintain accounts or engage in transactions ' e.g., through our Connect
                service. HealthBloq maintains online access to healthcare provider portals and other services and devices to
                access your information. HealthBloq does not review your information for accuracy, legality or
                non-infringement. HealthBloq is not responsible for the information or products and services offered by
                third parties. HealthBloq in no way controls, verifies or endorses any of the information uploaded by end
                users or Customers using the Service, including provider source data, health tracker data, messages, and
                genetics data. In addition, you acknowledge that HealthBloq does not control or maintain the various sources
                of data or other content that you may enable to be collected, stored, integrated, transmitted, shared and/or displayed
                in connection with the Service. If you believe that any such data or other content is inaccurate or otherwise should be
                corrected or changed, you must contact the third party source of such data or other content (whether it is a Customer or other third party).
                You agree that HealthBloq has no obligation to maintain, correct or amend such data or other content, except as required under applicable law.
                If You Elect To Enable The Sharing of Data From Our Customers, We Are Not Responsible For Such Data: You acknowledge that Customers,
                and not HealthBloq, are solely responsible for all information and/or other materials, in whatever form,
                made available by Customers to end users via the Service. We also provide certain services to Customers that collect
                one or more data streams on behalf of their employees, members or customers who are end users of the Service.
            </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>We Reserve the Right To Modify the Service</h2>
            <br></br>
                <p>
                    HealthBloq reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof)
                    with or without notice. You agree that HealthBloq will not be liable to you or to any third party for any modification,
                    suspension or discontinuance of the Service.
                </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>We May Update Our General Practices Regarding Use and Storage From Time to Time</h2>
            <br></br>
            <p>
                You acknowledge that HealthBloq may establish general practices and limits concerning use of the Service,
                including without limitation the maximum period of time that data or other content will be retained by the Service
                and the maximum storage space that will be allotted on HealthBloq's servers on your behalf. You agree that HealthBloq
                has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded
                by the Service. You acknowledge that HealthBloq reserves the right to terminate accounts that are inactive for an extended
                period of time. You further acknowledge that HealthBloq reserves the right to change these general practices and limits at
                any time, in its sole discretion, with or without notice.
            </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>If You Use Any Of Our Mobile Services, You Are Responsible For Connectivity</h2>
            <br></br>
            <p>
                The Service includes certain services that are available via a mobile device, including (i)
                the ability to upload data or other content to the Service via a mobile device, (ii)
                the ability to browse the Service and the Site from a mobile device and (iii)
                the ability to access certain features through an application downloaded and installed on a mobile device
                (collectively, the 'Mobile Services'). To the extent you access the Service through a mobile device,
                your wireless service carrier's standard charges, data rates and other fees may apply. In addition,
                downloading, installing, or using certain Mobile Services may be prohibited or restricted by your carrier,
                and not all Mobile Services may work with all carriers or devices.
            </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>We May Send You Text Messages</h2>
            <br></br>
                <p>
                    By using the Services, you consent to receive information from us via SMS and/or MMS messages
                    sent through an automated telephone dialing system ('Text Service'), even if you have opted
                    in to the National Do Not Call List, any state Do not Call List, or the internal Do Not Call
                    List of any company. You may be required to respond to an initial message as instructed to
                    complete your registration and confirm enrollment in the Text Service. The enrollment process
                    will disclose the program, frequency of messages, and options to cancel your enrollment.
                    You do not have to participate in the Text Service in order to use the Service. In the event
                    you no longer want to participate in the Text Service, you agree to notify us directly.
                    In the event you change or deactivate your mobile telephone number, you agree to promptly
                    update your account information to ensure that your messages are not sent to the person that
                    acquires your old number. There is no additional charge for the Text Service, but your mobile
                    carrier's standard message and data rates apply to any messages you send or receive through
                    the Text Service, including confirmations and subsequent texts. Your carrier may prohibit or
                    restrict certain mobile features and certain mobile features may be incompatible with your
                    carrier or mobile device. We are not liable for any delays in the receipt of, or any failures
                    to receive, any SMS or MMS messages, as delivery is subject to effective transmission by your
                    mobile carrier and compatibility of your mobile device. Please contact your mobile carrier if
                    you have any questions regarding these issues or your mobile data and messaging plan.
                    As described in the Text Service enrollment and welcome messages, including messages sent to a shortcode
                    associated with the Text Service or by reply to any message you receive from us, you may text 'STOP'
                    to cancel or 'HELP' for customer support information. If you choose to cancel your Text Service, you
                    agree to receive a final text message from the Text Service confirming your cancellation.
                </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>Age Requirements</h2>
            <br></br>
                <p>
                    If you are under 13 years of age, you are not authorized to use the Service, with or without registering.
                    In addition, if you are under 18 years of age, you may use the Service, with or without registering,
                    only with the approval of your parent or guardian.
                </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>HealthBloq</h2>
            <br></br>

                <p>
                    HealthBloq is an optional component of the Service that allows you to securely manage your connected
                    health and activity accounts and view what data is being shared with which customers. You can elect to
                    access and use HealthBloq, subject to the HealthBloq terms (set forth in section
                    'Your Registration Obligations For HealthBloq:' below).
                </p>
            <br></br>
            <h2 class="section-header" style={{ fontSize: '15px', fontWeight: 'bold' }}>Your Registration Obligations For HealthBloq</h2>
            <br></br>
                <p>
                    If you elect to use HealthBloq, you will be required to register and create an account with HealthBloq.
                    If you choose to create an account with us, you agree to provide and maintain true, accurate, current
                    and complete information about yourself as prompted by our registration form. Registration data and
                    certain other information about you are governed by our Privacy Policy. HealthBloq may verify your identity,
                    which may require asking you for information such as your full address, your date of birth, and/or requiring
                    you to take steps to confirm ownership of your email address or records ownership or verifying information you
                    provide. If you do not provide this information or HealthBloq cannot verify your identity, we can refuse to allow you to use HealthBloq.
                    If you provide information of any kind which is untrue, inaccurate, not current or incomplete, or we have reasonable grounds to suspect
                    that such information is untrue, inaccurate, not current or incomplete, we reserve the right to suspend or terminate your access to HealthBloq.
                    In addition, you are responsible for maintaining the confidentiality of your password and account, if any, and are fully responsible for any and
                    all activities that occur under your password or account. You agree to (a) immediately notify HealthBloq of any unauthorized use
                    of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each
                    session when accessing the Service. HealthBloq will not be liable for any loss or damage arising from your failure to comply with
                    this Section. If you become aware of any unauthorized use of your account or account information, you agree to notify HealthBloq
                    immediately at the email address security@HealthBloq.com."
                </p>

        </div>
    );
}
export default Terms;

