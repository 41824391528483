import { useRef } from "react";
import { Box, InputAdornment, IconButton, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

function SearchBar({ placeholder, searchFunction, size, setResult }) {
  const searchRef = useRef();
  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    const response = await searchFunction(searchRef.current.value);
    console.log("search function response ", response);
    setResult(response);
    console.log("Users from search: ", response);
  };
  return (
    <>
      {/* Search bar */}
      <Box sx={{ margin: "1rem" }}>
        <form onSubmit={handleSearchSubmit}>
          <TextField
            label={placeholder}
            fullWidth
            size={size}
            inputRef={searchRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Box>
    </>
  );
}
export default SearchBar;
