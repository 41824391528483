import React from "react";
import { Typography, Box, IconButton } from "@mui/material";

function EncounterRightMenu({
  encDate,
  provider,
  encDetType,
  description,
  fromDate,
  serviceProvider,
  toDate,
  value,
  description1,
  explanation,
  range,
  codeID
}) {
  return (
    <Box>
      <Typography variant="h5">{encDetType}</Typography>
      <Box>
        {encDate && (
          <Typography variant="body1">
            Encounter Date: {encDate.split("T").shift()}
          </Typography>
        )}
        {provider && (
          <Typography variant="body1">Provider: {provider}</Typography>
        )}
        {encDetType && (
          <Typography variant="body1">
            Encounter Detail Type: {encDetType}
          </Typography>
        )}
        {description && (
          <Typography variant="body1">
            Description: {description}
          </Typography>
        )}
        {fromDate && (
          <Typography variant="body1">
            From Date: {fromDate.split("T").shift()}
          </Typography>
        )}
        {serviceProvider && (
          <Typography variant="body1">
            Service Provider: {serviceProvider}
          </Typography>
        )}
        {toDate && (
          <Typography variant="body1">
            To Date: {toDate.split("T").shift()}
          </Typography>
        )}
        {value && (
          <Typography variant="body1">Value: {value}</Typography>
        )}
        {description1 && (
          <Typography variant="body1">
            Description1: {description1}
          </Typography>
        )}
        {explanation && (
          <Typography variant="body1">
            Explanation: {explanation}
          </Typography>
        )}
        {range && (
          <Typography variant="body1">Range: {range}</Typography>
        )}
        {codeID && (
          <Typography variant="body1">Code ID: {codeID}</Typography>
        )}
      </Box>
    </Box>
  );
}

export default EncounterRightMenu;
