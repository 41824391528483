import React, { useState } from "react";
import "./Dental.css";
import SingleTooth from "./SingleTooth";
function BottomArch() {
  return (
    <>
      <SingleTooth
        isPath={false}
        toothNumber={32}
        transform={"matrix(1 0 0 1 97.9767 402.1409)"}
        points="66.7,369.7 59,370.3 51,373.7 43.7,384.3 42.3,392 38.7,406 41,415.3 44.3,420.3 
            47.3,424 51.7,424.3 57.7,424 62.3,422.7 66.7,422.7 71,424.3 76.3,422.7 80.7,419.3 84.7,412.3 85.3,405 87.3,391.7 85,380 
            80.7,375 73.7,371.3 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={31}
        transform={"matrix(1 0 0 1 94.7426 449.1693)"}
        points="76,425.7 80.3,427.7 83.3,433 85.3,447.7 84.3,458.7 79.7,472.3 73,475 50.3,479.7 
            46.7,476.7 37.7,446.3 39.7,438.3 43.3,432 49,426.7 56,424.7 65,424.7 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={30}
        transform={"matrix(1 0 0 1 106.0002 495.5433)"}
        points="78.7,476 85,481 90.3,488.3 96.3,499.3 97.7,511.3 93,522 86,526.3 67,533 
            60.3,529.7 56.3,523.7 51.7,511 47.7,494.7 47.7,488.3 50.3,483.3 55,479.7 67,476.7 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={29}
        transform={"matrix(1 0 0 1 118.0002 538.667)"}
        points="93.3,525 99.3,527.3 108.3,536 114,546.7 115.7,559.3 114.3,567.3 106.3,573 
            98.3,578.3 88,579 82,575 75,565 69.3,552.3 67.3,542 69.7,536 74.3,531.7 84.3,528.3 	"
      />

      <SingleTooth
        isPath={true}
        toothNumber={28}
        transform={
          "matrix(0.9999 -1.456241e-02 1.456241e-02 0.9999 136.4086 573.5098)"
        }
        points="M117.3,569.7l7.7,1.3l6.3,3.7l6.3,7.7l4,8.3L144,602l-1.3,6.7l-6.7,6.7l-7.7,3.3l-7.3-1l-7-3
            l-7.3-7l-5-9l-2-10c0,0-0.7-7,0.3-7.3c1-0.3,5.3-6.7,5.3-6.7l9-5H117.3z"
      />

      <SingleTooth
        isPath={false}
        toothNumber={27}
        transform={"matrix(1 0 0 1 157.3335 603.8164)"}
        points="155.7,611 160.3,615.3 165,624.7 161.7,634.3 156,641.3 149,644 140.7,644.3 
            133.3,641.3 128.7,634.7 128.7,629 132.7,621.3 137.7,615 143.7,611 149.7,610 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={26}
        transform={"matrix(1 0 0 1 179.3335 623.8164)"}
        points="178.3,627 186,629 187.7,633.7 188.7,644 189,657 189.3,662.7 186.3,663.7 176.7,663 
            168,656.3 159.3,649.7 156.7,644 162,639.3 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={25}
        transform={"matrix(1 0 0 1 204.6669 628.483)"}
        points="214,637 218,642.7 223,654.3 225.7,664 225.3,666.3 219,668.3 206.7,668 196,665.7 
            190.3,662.7 193,657.3 199.7,647.3 207,638 210.7,635.5 	"
      />

      <SingleTooth
        isPath={true}
        toothNumber={24}
        transform={"matrix(1 0 0 1 231.3335 628.1497)"}
        points="M235.3,637c0,0,3-2,4-2.3c1-0.3,4.3,0,4.3,0l5,4.3l5.3,7.3l3.3,6.7l2,7.3l-2,3l-7.7,2.7
            l-10,0.3h-10l-2-6.7l2.7-7.3L235.3,637z"
      />

      <SingleTooth
        isPath={false}
        toothNumber={23}
        transform={"matrix(1 0 0 1 256.3335 619.1497)"}
        points="269.3,624 273.3,624.7 275.3,627.3 279,628.7 281.7,631.3 285.3,634.7 289.3,638.3 
            292,643.3 291.3,650 287,655 280.7,658.7 272,660 265,660.7 261.3,657.3 261.7,650 263.7,637 264.3,627 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={22}
        transform={"matrix(1 0 0 1 276.3335 602.483)"}
        points="286,629.3 286.7,633.3 291.3,638.7 295.3,642.3 302,644 311.7,643.3 318.3,637.7 
            321,630 321.3,620.3 317,614.3 308,608 298.3,607 291,609.3 287,612.3 286.7,617.7 287.3,624.7 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={21}
        transform={"matrix(1 0 0 1 286.6669 573.1497)"}
        points="331,565.7 335,565.7 341.3,568 349.3,574.3 352.3,578.3 352.7,583.7 350.7,593.7 
            342.7,604 337.7,609 328,612.7 320,613.3 315,611 308.3,604.7 306.7,598 307.3,591.3 309,584.7 312.7,578.3 318.3,571.7 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={20}
        transform={"matrix(1 0 0 1 303.6327 538.667)"}
        points="334,561 338.7,566 346,570 354.7,573 360.7,571.7 368,568.3 383,545 385.3,532.7 
            381.3,524.3 374,520.7 363.7,516.3 356.3,515.3 351.3,518.3 346.3,524 340.3,534.3 336,546.7 	"
      />

      <SingleTooth
        isPath={true}
        toothNumber={19}
        transform={"matrix(1 0 0 1 322.983 495.5432)"}
        points="M398,470l4.7,5.7l3,7.7l-0.3,11.7l-6,13.3l-6.3,10.3l-8.3,4.3l-7.3-1l-16.3-7c0,0-2.7-6-3-7.3
            c-0.3-1.3-0.3-11-0.3-11l3.7-14.3l3.7-7l5.3-6.7l8-2l9.7-0.7L398,470z"
      />

      <SingleTooth
        isPath={false}
        toothNumber={18}
        transform={"matrix(1 0 0 1 325.1251 449.1686)"}
        points="410,435 408.7,447.3 404.3,459 399.3,467.7 393.7,468 388,466 376.3,466.3 
            369.7,466.3 365.7,460 364.7,444.7 366.3,434.3 369,424 378.3,417.3 386.7,415.7 391.7,415.3 396,418 399.7,418 404,421.7 
            407.7,427.3 	"
      />

      <SingleTooth
        isPath={false}
        toothNumber={17}
        transform={"matrix(1 0 0 1 324.0004 402.1405)"}
        points="371.7,417 378.3,417.3 386.7,415.7 391.7,415.3 397.3,417.7 402.7,416.3 407.7,409.7 
            406.7,395 401,377.7 397.3,373 390.7,367.3 380,365 373,366.7 367.3,369 364,374.3 360,389 363.3,401.3 367.7,412.3 	"
      />
    </>
  );
}

export default BottomArch;
