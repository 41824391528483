import React from 'react';
import PropTypes from 'prop-types';

function AdBanner({ image, link, tooltip, imageWidth }) {
  return (
    <a href={link} title={tooltip} target="_blank" rel="noopener noreferrer">
      <img src={image} alt={tooltip} style={{ width: imageWidth }} />
    </a>
  );
}

AdBanner.propTypes = {
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default AdBanner;
