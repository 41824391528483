import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import CreateIcon from '@mui/icons-material/Create';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import theme from '../Theme.js';
import { ThemeProvider } from '@mui/material/styles';

import CreateNewPostPopupForm from './FormComponents/CreateNewPostPopupForm';

function CreateNewPostButton() {
  const [formOpen, setFormOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };

  const styles = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    padding: isMobile ? undefined : '0 16px',
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Fab
          color="primary"
          style={styles}
          onClick={handleOpenForm}
          variant={isMobile ? 'round' : 'extended'}
        >
          {!isMobile && <a style={{paddingRight:'5px'}}>Create Post</a>}
          <CreateIcon />
        </Fab>
        <CreateNewPostPopupForm
          formTitle="Create New Post"
          open={formOpen}
          onClose={handleCloseForm}
        />
      </ThemeProvider>
    </>
  );
}

export default CreateNewPostButton;
