import React, { useState, useRef } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  Button,
  Avatar,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { PersonAdd, MoreVert } from "@mui/icons-material";
import SettingsPage from "../../pages/SettingsPage.js";

import SettingsDropdown from "./SettingsDropdown.js";
import DisconectPopupForm from "../../components/FormComponents/DisconnectPopupForm.js";
import SettingsIcon from "@mui/icons-material/Settings";

import WebSiteTheme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import {
  GetAllMyConnections,
  CreateConnectionRequest,
  UpdateConnection,
} from "../../helper/APIHelper.js";
import ConnectionStatus from "../../data/ConnectionStatus.json";
import { RenderStatusMessage } from "../../helper/Utility.js";
import CreatePostForm from "../FormComponents/CreatePostForm.js";
import CustomAlert from "../CustomAlert.js";
import CreatePost from "../../assets/CreatePost.png";
function ProfileHeader({
  toggle,
  memberID,
  requestorID,
  name,
  address,
  email,
  coverPhotoURL,
  profilePictureURL,
  onTabChange,
  conDetStatus,
  conDetID,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeButton, setActiveButton] = useState("Timeline");
  //const [isConnected, setIsConnected] = useState(false);

  const [isConnected, setIsConnected] = useState(
    IsStatus("connected", conDetStatus)
  );
  const [isPending, setIsPending] = useState(IsStatus("pending", conDetStatus));
  const [isReceived, setIsReceived] = useState(IsStatus("sent", conDetStatus));

  const [openPopup, setOpenPopup] = React.useState(false); // Flag for popup form
  const [createPostsFlag, setCreatePostsFlag] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");

  console.log("request status: ", requestStatus);

  const [settings, setSettings] = useState(false);
  function handleSettingsClick() {
    if (!settings) {
      allButtonClickFunctions("Settings", 1);
    } else {
      allButtonClickFunctions("Timeline", 0);
    }
    setSettings(!settings);
  }

  const styles = {
    card: {
      maxWidth: "100%",
      margin: "0 auto",
    },
    coverPhoto: {
      height: isMobile ? 250 : 340,
    },
    profilePicture: {
      width: isMobile ? 100 : 150,
      height: isMobile ? 100 : 150,
      border: "5px solid white",
      position: "absolute",
      top: isMobile ? 281 : 343,
      left: isMobile ? "50%" : "",
      marginLeft: isMobile ? "-115px" : "5px",
      marginTop: "1rem",
    },
    profileInfoBox: {
      height: isMobile ? 100 : 150,
      position: "absolute",
      top: isMobile ? 334 : 425,
      left: isMobile ? "50%" : "",
      marginLeft: isMobile ? "-12px" : "169px",
    },
    buttonMenu: {
      justifyContent: "left",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    rightButton: {
      marginRight: "auto",
    },
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleFollowClick = () => {
    setIsConnected(!isConnected);
  };

  function handleClosePopup() {
    setOpenPopup(false);
    setCreatePostsFlag(false);
  }

  const handleConnectClick = async () => {
    setOpenPopup(!openPopup);

    if (!isConnected && !isPending) {
      //Call an API to create a friend request.
      setRequestStatus("sent");
      const response = await CreateConnectionRequest(memberID);
    } else if (isPending) {
      setRequestStatus("unsent");
      const response = await UpdateConnection(
        conDetID,
        ConnectionStatus.status.Canceled
      );
    }
  };

  const allButtonClickFunctions = (buttonName, buttonTab) => {
    setSettings(false);
    handleButtonClick(buttonName);
    onTabChange(buttonTab);
  };

  const IsOwnPage = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.memberID === memberID) {
      return true;
    }
    return false;
  };

  function IsStatus(connectionStatus, currentStatus) {
    if (currentStatus !== undefined && currentStatus !== null) {
      currentStatus = currentStatus.toLowerCase().trim();
      if (connectionStatus === currentStatus) {
        return true;
      }
    }
    return false;
  }

  const handleAcceptClick = () => {
    setRequestStatus("accepted");
    let response = AcceptFriendRequest();
  };

  const handleDenyClick = () => {
    setRequestStatus("denied");
    let response = DeclineFriendRequest();
  };

  const AcceptFriendRequest = async () => {
    let response = await UpdateConnection(
      conDetID,
      ConnectionStatus.status.Connected
    );
    return response;
  };

  const DeclineFriendRequest = async () => {
    let response = await UpdateConnection(
      conDetID,
      ConnectionStatus.status.Decline
    );
    return response;
  };

  function handleOpenCreatePostsPopUp() {
    setCreatePostsFlag(true);
  }

  const [error, setError] = useState("");
  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };
  const CreatePostButton = () => {
    return (
      <Box sx={{ display: "inline-flex", gap: "0.5rem", marginRight: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<img src={CreatePost} alt="" />}
          onClick={handleOpenCreatePostsPopUp}
        >
          Post To Feed
        </Button>
        {createPostsFlag && (
          <CreatePostForm
            open={createPostsFlag}
            onClose={handleClosePopup}
            handleEditContactInfoClick={handleEditContactInfoClick}
            setError={setError}
          />
        )}
      </Box>
    );
  };
  const friendRequestButton = () => {
    if (!requestStatus) {
      if (isReceived) {
        return (
          <Box
            sx={{ display: "inline-flex", gap: "0.5rem", marginRight: "1rem" }}
          >
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={handleAcceptClick}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleDenyClick}
            >
              Deny
            </Button>
          </Box>
        );
      } else {
        return (
          <Button
            variant={isConnected ? "outlined" : "contained"}
            color={isPending ? "info" : "secondary"}
            startIcon={<PersonAdd />}
            onClick={handleConnectClick}
          >
            {isConnected ? "Connected" : isPending ? "Pending" : "Connect"}
            {openPopup && isConnected && (
              <DisconectPopupForm
                open={openPopup}
                onClose={handleClosePopup}
                selectedContact={{ conDetID: conDetID }}
                setRequestStatus={setRequestStatus}
              />
            )}
          </Button>
        );
      }
    }
  };

  return (
    <ThemeProvider theme={WebSiteTheme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type={error ? "error" : "success"}
        content={error ? error : "Form Submitted."}
        // duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <Card sx={styles.card}>
        <Box
          style={{ backgroundColor: "lightGray" }}
          sx={{
            height: styles.coverPhoto.height,
            background: toggle
              ? `url('${coverPhotoURL}')`
              : `url('https://source.unsplash.com/random/1600x400')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            alt: "cover",
          }}
        />
        <Avatar
          src={
            toggle
              ? profilePictureURL
              : "https://source.unsplash.com/random/200x200"
          }
          alt="profile"
          sx={styles.profilePicture}
          style={toggle ? { backgroundColor: "lightGray" } : {}}
        />
        <Box
          sx={{
            textAlign: "right",
            paddingRight: 2,
            paddingTop: 2,
            minHeight: "8vh",
          }}
        >
          {RenderStatusMessage(requestStatus)}
          {IsOwnPage() ? CreatePostButton() : friendRequestButton()}
        </Box>
        <Box sx={styles.profileInfoBox}>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontWeight: "bold", paddingTop: "1rem" }}
          >
            {toggle ? name : "User Profile"}
          </Typography>
          {/* <Typography variant="body1" component="div">
            {toggle ? address : "La Verne, CA"}
          </Typography>
          <Typography variant="body2" component="div">
            {toggle ? email : "User@agiline.com"}
          </Typography> */}
        </Box>
        <CardHeader />
        <CardActions sx={styles.buttonMenu}>
          <Button
            variant={activeButton === "Timeline" ? "contained" : "text"}
            onClick={() => allButtonClickFunctions("Timeline", 0)}
          >
            Timeline
          </Button>
          {/* <Button
            variant={activeButton === "About" ? "contained" : "text"}
            onClick={() => allButtonClickFunctions("About", 1)}
          >
            Settings
          </Button> */}
          <Button
            variant={activeButton === "Friends" ? "contained" : "text"}
            onClick={() => allButtonClickFunctions("Friends", 2)}
          >
            Connections
          </Button>
          {memberID === requestorID && requestorID.startsWith("2") && (
            <Button
              variant={activeButton === "Encounters" ? "contained" : "text"}
              onClick={() => allButtonClickFunctions("Encounters", 3)}
            >
              Encounters
            </Button>
          )}
          <Button
            variant={activeButton === "Records" ? "contained" : "text"}
            onClick={() => allButtonClickFunctions("Records", 4)}
          >
            Records
          </Button>
          <Button
            variant={activeButton === "Events" ? "contained" : "text"}
            onClick={() => allButtonClickFunctions("Events", 5)}
          >
            Events
          </Button>
          <Box flexGrow={1}></Box>
          {/* <SettingsDropdown /> */}
          {requestorID === memberID && (
            <IconButton
              edge="end"
              onClick={handleSettingsClick}
              sx={{
                backgroundColor: settings
                  ? "primary.main"
                  : "rgba(0, 0, 0, 0.2)",
                mr: 1,
                "&:hover": {
                  backgroundColor: settings
                    ? "primary.dark"
                    : "rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <SettingsIcon sx={{ color: "common.white" }} />
            </IconButton>
          )}
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}

export default ProfileHeader;
