import { Button, Grid, ThemeProvider, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../Theme";
import AdBannerList from "../components/AdBannerList";
import Pharma from "../assets/Pharma.jpg";
import fitbitImage from "../assets/fitbitad.jpg";
import claritin from "../assets/claritin.png";
import LeftNavbar from "../components/LeftNavbar";
import { GetAllCommunities } from "../helper/APIHelper";

const adsList = [
  {
    image: Pharma,
    link: "https://www.example.com/ad1",
    tooltip: "Sample Ad 1",
  },
  {
    image: fitbitImage,
    link: "https://www.example.com/ad2",
    tooltip: "Sample Ad 2",
  },
  {
    image: claritin,
    link: "https://www.example.com/ad3",
    tooltip: "Sample Ad 3",
  },
];
const CommunityItem = ({
  title,
  members,
  imageLink,
  description,
  disclaimer,
}) => (
  <div
    style={{
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "10px",
      marginBottom: "10px",
      cursor: "pointer",
      backgroundColor: "white",
    }}
    onClick={() => console.log(`Clicked on ${title}`)}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={imageLink}
        alt="Community"
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#5FBDBD",
          marginRight: "10px",
        }}
      />
      <div>
        <h3 style={{ margin: "0" }}>{title}</h3>
        <p style={{ margin: "0", color: "#666" }}>{members} Members</p>
      </div>
    </div>
    <div dangerouslySetInnerHTML={{ __html: description }} />
    {disclaimer && (
      <p style={{ color: "red" }}>
        <strong>Disclaimer:</strong> {disclaimer}
      </p>
    )}
  </div>
);

const Community = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [communities, setCommunities] = useState("");
  React.useEffect(() => {
    const fetchData = async () => {
      let response = await GetAllCommunities();
      setCommunities(response);
    };
    fetchData();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item sm={0} md={4}>
          <LeftNavbar />
        </Grid>
        <Grid item sm={12} md={5}>
          <div
            style={{
              maxWidth: "40rem",
              margin: "0 auto",
              fontFamily: "Arial, sans-serif",
              height: "100vh",
            }}
          >
            {communities &&
              communities.map((community) => (
                <CommunityItem
                  key={community.comID}
                  title={community.communityName}
                  members={community.members}
                  imageLink={community.mediaURL}
                  description={community.description}
                  disclaimer={community.disclaimer}
                />
              ))}
            {/* <CommunityItem
              title="Physical Therapy Advice"
              members={8}
              description="For all of you who are current, past, or future PTs, PTAs, techs/aides or have some relation to Physical Therapy. This group is also open to anyone that has ever had PT. Please feel free to share your experience!"
              disclaimer="This group is NOT a substitute for medical"
              imageLink="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCj1uO57grh0JddNU0gc13RFPBqZiwmrRFnw&s"
            />
            <CommunityItem
              title="Health and Fitness"
              members={9}
              description="A fitness community to help you achieve the body and health of your dreams. Support each other, learn, and feel free to ask questions. There are hundreds of expert personal trainers and nutritionists who are members of this group. Don't post advertisements or promote."
              imageLink="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCj1uO57grh0JddNU0gc13RFPBqZiwmrRFnw&s"
            /> */}
            {/* <Button color="primary" variant="contained" fullWidth>
              FIND COMMUNITIES
            </Button> */}
          </div>
        </Grid>
        {!isSmallScreen && (
          <Grid item sm={0} md={3}>
            <AdBannerList sx={{ paddingLeft: "2rem" }} adsList={adsList} />
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default Community;
