import React, { useState } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import theme from "../../Theme";
import CustomPopUpForm from '../CustomPopUpForm';

function ChooseShape({ open, onClose ,selectedShapes , setSelectedShapes}) {

  const [tempSelectedShapes, setTempSelectedShapes] = useState(selectedShapes);

  const handleCancel = () => {
    onClose();
  };

  const handleSelectShape = (shape) => {
    if (tempSelectedShapes.includes(shape)) {
      setTempSelectedShapes(tempSelectedShapes.filter(tempSelectedShapes => tempSelectedShapes !== shape));
    } else {
      setTempSelectedShapes([...tempSelectedShapes, shape]);
    }
  };
  
  const handleSubmit = () => {
    setSelectedShapes(tempSelectedShapes);
    console.log("submit");
    onClose();
  };
  

  return (
    <ThemeProvider theme={theme}>
      <CustomPopUpForm
      open={open}
      onClose={onClose}
      title="Choose Shape"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      showSubmit={true}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
            <Button variant={tempSelectedShapes.includes('cross') ? 'contained' : 'outlined'} onClick={() => handleSelectShape('cross')}>
              Cross
            </Button>
            <Button variant={tempSelectedShapes.includes('circle') ? 'contained' : 'outlined'} onClick={() => handleSelectShape('circle')}>
              Circle
            </Button>
            <Button variant={tempSelectedShapes.includes('plus') ? 'contained' : 'outlined'} onClick={() => handleSelectShape('plus')}>
              Plus
            </Button>
            <Button variant={tempSelectedShapes.includes('square') ? 'contained' : 'outlined'} onClick={() => handleSelectShape('square')}>
              Square
            </Button>
          </div>
      </CustomPopUpForm>
    </ThemeProvider>
  );
}

export default ChooseShape;
