import React from 'react';
import { useState, useRef } from 'react';
import { TextField, Button, MenuItem, Input, InputLabel, FormControl } from '@mui/material'
import CustomPopUpForm from '../components/CustomPopUpForm';
import theme from '../Theme.js';
import { ThemeProvider } from "@mui/material/styles";
import { GetSettingsByKey } from "../helper/APIHelper"
import CustomAlert from '../components/CustomAlert';

const DocumentResourcesKey = ["Folders", "OrgClass"];
function UploadDocument() {
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        async function FillData() {
            let temp = await GetSettingsByKey(DocumentResourcesKey);
            setData(temp);
        }
        FillData();
    }, []);

    const [file, setFile] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [state, setState] = React.useState({
        date: "",
        title: "",
        folder: "",
        source: "",
        clinicName: "",
        description: "",
    })


    function clearData() {
        setState({
            date: "",
            title: "",
            folder: "",
            source: "",
            clinicName: "",
            description: "",
        })
        setSubmit(false);
    }
    function handleChange(event) {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        console.log(file);
        setSubmit(true);
        handleEditContactInfoClick();
        // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        // const ContactID = userInfo.contactID;
        // console.log("Patient ID "+ContactID);
        // let allergyRecord ='';
        // if(state.allergy !== '' && state.allergyType !== '' && state.onSet !== '' && state.reaction !== '' && state.severity !== '' && state.status !== ''){
        //   allergyRecord = {
        //     patientID: ContactID,
        //     allergen: state.allergy,
        //     allergyType: state.allergyType,
        //     reaction: state.reaction,
        //     severity: state.severity,
        //     status: state.status,
        //     onset: state.onSet,
        //     comments: state.status
        //   }
        //   PostAllergyRecord(allergyRecord);
        //   console.log("Calling Post Allergy API");
        //   clearData();
        //   handleClose();
        //}
    };

    const handleCancel = () => {
        //clearData();
        handleClose();
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    function filterDataByValue(filterValue) {
        console.log("Filter " + filterValue);
        return data
            .filter((option) => option.resourceKey === filterValue)
            .map((option) => (
                <MenuItem key={option.resourceID} value={option.resourceName}>
                    {option.resourceName}
                </MenuItem>
            ));
    }
    const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
    const handleEditContactInfoClick = () => {
        alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
        handleClose();
    };

    return (
        <ThemeProvider theme={theme}>
            <CustomAlert
                ref={alertRef} // This is how you pass the ref to the CustomAlert component
                title="Success"
                type="success"
                content="Form Submitted."
                duration={2000}
                ancorVertical="top"
                ancorHorizontal="center"
            />
            <Button variant="contained" color="primary"
                onClick={handleClickOpen}>
                Open Form
            </Button>
            <CustomPopUpForm
                open={open}
                onClose={handleClose}
                title="Upload New Document"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            >
                <div className="grid grid-cols-1 gap-4">
                    <TextField required label="Effective Date" size="small" variant="outlined" type="Date" InputLabelProps={{ shrink: true }}
                        name="date"
                        value={state.date}
                        onChange={handleChange}
                        error={submit && !state.date}
                    />
                    <TextField required label="Title" size="small" variant="outlined"
                        name="title"
                        value={state.title}
                        onChange={handleChange}
                        error={submit && !state.title}
                    />
                    <TextField required label="Folder" select size="small" variant="outlined"
                        name="folder"
                        value={state.folder}
                        onChange={handleChange}
                        error={submit && !state.folder}
                    >
                        {filterDataByValue("Folders")}
                    </TextField>
                    <TextField required label="Source" select size="small" variant="outlined"
                        name="source"
                        value={state.source}
                        onChange={handleChange}
                        error={submit && !state.source}
                    >
                        {filterDataByValue("OrgClass")}
                    </TextField>
                    <TextField required label="Clinic Name" size="small" variant="outlined"
                        name="clinicName"
                        value={state.clinicName}
                        onChange={handleChange}
                        error={submit && !state.clinicName}
                    >
                    </TextField>

                    <TextField size="small" variant="outlined"
                        type="file"
                        label="File"
                        onChange={handleFileChange}
                        InputLabelProps={{ shrink: true }}
                        error={submit && !file}
                    />

                    <TextField size="small" variant="outlined"
                        label="File Name"
                        value={file ? file.name : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField size="small" variant="outlined"
                        label="File Type"
                        value={file ? file.type : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField multiline label="Description" size="small" variant="outlined"
                        name="description"
                        value={state.description}
                        onChange={handleChange}
                    />
                </div>
            </CustomPopUpForm>
        </ThemeProvider>
    );
}

export default UploadDocument;