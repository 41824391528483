import { TextField, Button, MenuItem, Box } from "@mui/material";
import React from "react";
import { useState, useRef } from "react";
import { PostFeedback, GetSettingsByKey } from "../../helper/APIHelper";
import CustomAlert from "../CustomAlert";
import { Feed } from "@mui/icons-material";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import CustomPopUpForm from "../CustomPopUpForm";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { CloudUpload } from "@material-ui/icons";
import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { v4 as uuid } from "uuid";
//Resource keys from the backend
const feedbackResourcesKey = ["FeedbackType"];

function Feedback({ open, onClose , formTitle}) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function FillData() {
      //change accourding to resource key name
      let temp = await GetSettingsByKey(feedbackResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");

  const [state, setState] = React.useState({
    // Change according to textfields you added
    title: "",
    description: "",
    page: GetCurrentPageName(),
    feedbackType: "",
    status: "New",
    createBy: localStorage.getItem("userEmail")
      ? localStorage.getItem("userEmail")
      : "",
  });

  function clearData() {
    setState({
      ...state,
      //change according to state
      title: "",
      description: "",
      // page: GetCurrentPageName(),
      feedbackType: "",
      status: "New",
      // createBy: ""
    });
    setSubmit(false);
  }

  // max file size and number of files
  // uploading again doesn't reset upload files
  const excludedFileNames = ["mp4", "mp3", "lock", "CON", "PRN", "AUX", "NUL"];
  const excludedPrefixes = ["COM", "LPT", "_vti_", "~$"];
  const [uploadedFiles, setUploadedFiles] = useState([]);

  function handleFileUpload(files) {
    setError("");
    const existingFileNames = uploadedFiles.map((file) =>
      file.name.toLowerCase()
    );
    const validFiles = Array.from(files).filter((file) => {
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.split(".").pop();
      if (
        excludedFileNames.includes(fileExtension) ||
        excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
      ) {
        setError(`The File "${file.name}" Type Is Not Valid`);
      } else if (file.size > state.maxSize) {
        setError(`The File "${file.name}" Size Is Too Large`);
      } else if (file.size > state.maxSize) {
        setError(`The File "${file.name}" Size Is Too Large`);
      } else if (existingFileNames.includes(fileName)) {
        setError(`The File "${file.name}" Has Already Been Uploaded`);
      } else {
        return (
          !excludedFileNames.includes(fileExtension) &&
          !excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
        );
      }
    });
    if (validFiles.length + uploadedFiles.length > state.maxNumber) {
      setError(`You can only upload up to "${state.maxNumber}" files`);
    } else {
      setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  }

  function byteToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }

  const handleDeleteFile = (fileToDelete) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles.filter((file) => file !== fileToDelete);
    });
  };

  function handleDrop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileUpload(files);
  }

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
    if (event.target.name === "createBy") {
      localStorage.setItem("userEmail", JSON.stringify(value));
    }
  }

  function GetCurrentPageName() {
    const location = useLocation();
    let currentPageName;
    if (location.pathname.split("/").pop() === "") {
      currentPageName = "Feed";
    } else {
      currentPageName = location.pathname.split("/").at(1);
    }

    return currentPageName;
  }

  async function handleSubmit() {
    console.log(state);

    setSubmit(true);
    // change according to textfields
    let feedbackRecord = "";
    if (
      state.title !== "" &&
      state.description !== "" &&
      state.feedbackType !== "" &&
      state.page !== "" &&
      state.status !== "" &&
      state.createBy !== ""
    ) {
      feedbackRecord = {
        title: state.title,
        description: state.description,
        page: state.page,
        feedbackType: state.feedbackType,
        status: state.status,
        createBy: state.createBy,
      };
      console.log("documentRecord ", feedbackRecord);
      const formData = new FormData();
      for (var i = 0; i < uploadedFiles.length; i++) {
        formData.append("files", uploadedFiles[i]);
      }
      formData.append("feedbackRecord", JSON.stringify(feedbackRecord));
      console.log("form File: ", formData);
      const response = await PostFeedback(formData);
      console.log("response ", response);
      if (response.ok === true) {
        setError("");
        clearData();
        handleEditContactInfoClick();
        onClose();
      } else {
        if (response.statusText === "") {
          setError("Your Request Was Not Fulfilled");
        } else {
          setError(response.statusText);
        }
        handleEditContactInfoClick();
      }
    }
  }

  const handleCancel = () => {
    //clearData();
    // handleClose();
    onClose();
  };

  //remove IF you don't have any resources
  function filterDataByValue(filterValue) {
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={uuid()} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }

  function handleFileInputChange(e) {
    const files = e.target.files;
    handleFileUpload(files);
  }

  // setState("page", GetCurrentPageName());

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type={error ? "error" : "success"}
        content={error ? error : "Form Submitted."}
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <CustomPopUpForm
        open={open}
        onClose={onClose}
        title = {formTitle ? `${formTitle} `:"Feedback"}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        showSubmit={true}
      >
        <div className="grid grid-cols-1 gap-4">
          <TextField
            required
            label="Enter Title"
            size="small"
            variant="outlined"
            // name has to be the same name as the state
            name="title"
            value={state.title}
            onChange={handleChange}
            //error is for required values  (remove if field not required)
            error={submit && !state.title}
          />

          <TextField
            required
            label="Feedback Type"
            select
            size="small"
            variant="outlined"
            name="feedbackType"
            value={state.feedbackType}
            onChange={handleChange}
            error={submit && !state.feedbackType}
          >
            {filterDataByValue("FeedbackType")}
          </TextField>

          <TextField
            required
            label="Enter Description"
            size="small"
            variant="outlined"
            name="description"
            multiline
            value={state.description}
            onChange={handleChange}
            rows={5}
            error={submit && !state.description}
          />

          <TextField
            multiline
            label="Email"
            size="small"
            variant="outlined"
            name="createBy"
            required
            value={state.createBy}
            type="email"
            error={submit && !state.createBy}
            onChange={handleChange}
          />

          <TextField
            required
            label="Page"
            size="small"
            variant="outlined"
            name="page"
            value={state.page}
            onChange={handleChange}
            error={submit && !state.page}
          />

          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            multiple
          />
          <div
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={handleDrop}
            style={{
              border: `2px dashed ${error ? "red" : "gray"}`,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "auto",
              width: "100%",
              margin: "auto",
              marginBottom: "20px",
              backgroundColor: "#f1f1f1",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <p style={{ margin: "0", fontSize: "18px" }}>
              Drag and drop files here
            </p>
            <p style={{ margin: "0", fontSize: "14px" }}>OR</p>

            <Button
              variant="contained"
              color={
                uploadedFiles.length === state.maxNumber ? "error" : "primary"
              }
              component="label"
              htmlFor="fileInput"
              size="small"
              startIcon={<CloudUpload />}
              style={{ width: "200px", height: "40px", marginTop: "10px" }}
            >
              Browse
            </Button>
            <Box>
              {error && (
                <p
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {error}
                </p>
              )}
              {uploadedFiles.map((file) => (
                <Card
                  key={file.name}
                  style={{
                    height: "auto",
                    width: "auto",
                    margin: "5px",
                    display: "inline-block",
                  }}
                >
                  {file.type.startsWith("image/") ? (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <button
                        onClick={() => handleDeleteFile(file)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <ClearIcon />
                      </button>
                      <CardMedia
                        component="img"
                        image={URL.createObjectURL(file)}
                        alt={file.name}
                        style={{ padding: 0, margin: 0 }}
                      />
                    </div>
                  ) : (
                    <CardContent>
                      <p>
                        <InsertDriveFileIcon />
                        {file.name}
                        <button
                          onClick={() => handleDeleteFile(file)}
                          style={{
                            marginLeft: "auto",
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <ClearIcon />
                        </button>
                      </p>
                      <Typography
                        color="textSecondary"
                        style={{ marginLeft: "25px" }}
                      >
                        {byteToSize(file.size)}
                      </Typography>
                    </CardContent>
                  )}
                </Card>
              ))}
            </Box>
          </div>
        </div>
      </CustomPopUpForm>
    </ThemeProvider>
  );
}
export default Feedback;
