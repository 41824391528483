import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CreateIcon from "@mui/icons-material/Create";
import theme from "../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import FeedbackForm from "./FormComponents/FeedbackForm.js";

function CreateFeedbackButton() {
  const [formOpen, setFormOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };

  const styles = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    padding: isMobile ? undefined : "0 16px",
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Fab
          color="primary"
          style={styles}
          onClick={handleOpenForm}
          variant={isMobile ? "round" : "extended"}
        >
          {!isMobile && <a style={{ paddingRight: "5px" }}>Feedback</a>}
          <CreateIcon />
        </Fab>
        <FeedbackForm open={formOpen} onClose={handleCloseForm} />
      </ThemeProvider>
    </>
  );
}

export default CreateFeedbackButton;
