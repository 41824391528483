import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {
  Box,
  Chip,
  Grid,
  Container,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import theme from "../Theme.js";
import { ThemeProvider } from "@mui/material/styles";

import TextPost from "../components/feedComponents/TextPost";
import AdBanner from "../components/AdBanner";
import { GetFeedPostDetails } from "../helper/APIHelper";
import Comment from "../components/feedComponents/Comment";
import useScrollToTop from "../components/hookComponents/useScrollToTop";
import CreateNewPostButton from "../components/CreateNewPostButton";

import generateFakeComments from "../data/generateFakeComments";
import Pharma from "../assets/Pharma.jpg";
import fitbitImage from "../assets/fitbitad.jpg";
import claritin from "../assets/claritin.png";

const adsList = [
  {
    image: Pharma,
    link: "https://www.example.com/ad1",
    tooltip: "Sample Ad 1",
  },
  {
    image: fitbitImage,
    link: "https://www.example.com/ad2",
    tooltip: "Sample Ad 2",
  },
  {
    image: claritin,
    link: "https://www.example.com/ad3",
    tooltip: "Sample Ad 3",
  },
];

function useCardStyle() {
  const [cardStyle, setCardStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth <= 1280) {
        setCardStyle({
          display: "none",
        });
      } else {
        setCardStyle({
          marginRight: "auto",
          position: "sticky",
          top: "86px",
          height: "600px",
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return cardStyle;
}

function PostDetails() {
  const [post, setPost] = useState({});
  // const [comments, setComments] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const mainComments = generateFakeComments();

  useScrollToTop();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { FeedID } = useParams();

  const cardStyle = useCardStyle();

  useEffect(() => {
    const fetchData = async (FeedID) => {
      try {
        const result = await GetFeedPostDetails(FeedID);
        console.log(result);
        if (result) {
          setPost(result);
          // setComments([]); // Use an empty array for comments as we don't have comments data from API
        } else {
          console.error("Invalid data received from GetFeedPostDetails");
        }
      } catch (error) {
        console.error("Error fetching data from GetFeedPostDetails:", error);
      }
    };

    fetchData(FeedID);
  }, [FeedID]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % adsList.length);
    }, 20000000); // 20 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentAd = adsList[currentAdIndex];

  const handleBackToFeed = (event) => {
    event.preventDefault();
    if (location.state && location.state.from) {
      navigate(location.state.from.pathname, { replace: true });
    } else {
      navigate("/");
    }

    // Restore the scroll position
    setTimeout(() => {
      const savedScrollPosition = sessionStorage.getItem("scrollPosition");
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition));
        sessionStorage.removeItem("scrollPosition");
      }
    }, 100);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box position="relative">
          <IconButton
            onClick={handleBackToFeed}
            sx={{
              position: "absolute",
              left: windowWidth <= 1280 ? "0" : "-50px",
              top: "0",
              zIndex: "1000",
            }}
          >
            <ArrowBack />
          </IconButton>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ marginLeft: windowWidth <= 1280 ? "" : "80px" }}
          >
            <Grid item xs={12} md={8}>
              <TextPost
                onProfile={false}
                onFeed={false}
                tags={post.tags}
                onTagClick={console.log("Tag clicked")}
                FeedID={post.feedID}
                UserPKeyID={post.userPKeyID}
                ContactID={post.contactID}
                ProjectID={post.projectID}
                RoleID={post.roleID}
                SourceName={post.sourceName}
                Owner={post.owner}
                DefView={post.defViewID}
                Title={post.title}
                Description={post.description}
                PubDate={post.pubdate}
                TimeElapsed={post.timeElapsed}
                MediaURL={post.media}
                Link={post.link}
                Image={post.image}
                Category={post.category}
                Tags={post.tags}
                CreateDate={post.createDate}
              />
              {mainComments.map((comment) => (
                <Comment
                  key={comment.id}
                  content={comment.content}
                  author={comment.author}
                  level={comment.level}
                  comments={comment.children}
                />
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ position: "sticky", top: "86px", height: "600px" }}>
                <AdBanner
                  image={currentAd.image}
                  link={currentAd.link}
                  tooltip={currentAd.tooltip}
                  imageWidth="150px"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <CreateNewPostButton />
    </ThemeProvider>
  );
}

export default PostDetails;
