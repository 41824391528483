import React, { useState, useEffect } from "react";
import theme from "../../Theme";
import { ThemeProvider } from "@emotion/react";
import { GetLandingPageSections, GetFilters } from "../../helper/APIHelper";
import MobileLandingPage from "./MobileLandingPage";
import DesktopLandingPage from "./DesktopLandingPage";

function Landing() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [landingSectionsData, setLandingSectionsData] = useState([]);

  const TAGS_COUNT = 8;
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    async function FillData() {
      let temp = await GetLandingPageSections();
      setLandingSectionsData(temp);
      console.log("temp ", temp);
    }
    FillData();
  }, []);
  const isMobile = screenWidth <= 750;

  const [topicGroupsData, setTopicGroupsData] = useState([]);
  const [tags, setTags] = useState([]);
  const [FeedFilterChildState, setFeedFilterChildState] = useState(null); //to turn off filter style when tag is clicked

  useEffect(() => {
    //const response = GetTopicGroups();
    const response = GetFeedFilters();
  }, []);

  async function GetFeedFilters() {
    let response = await GetFilters(true, TAGS_COUNT);
    if (response !== null || response !== undefined) {
      setTags(response.Tags);
      setTopicGroupsData(response.TopicGroups);
    }
  }

  //set local storage first visit to false
  useEffect(() => {
    localStorage.setItem("firstVisit", "false");
  }, []);

  // Method to past to Image Carousel to allow for state change from children class
  const handleFilterStateChange = (state) => {
    setFeedFilterChildState(state);
  };

  const margin = `${(screenWidth - 750) / 3}px`;
  const lightBgColor = "#D7E2E3";
  const darkBgColor = "#83B1B3";
  return (
    <ThemeProvider theme={theme}>
      <div style={{ overflowY: "auto" }}>
        {isMobile ? (
          <MobileLandingPage
            landingSectionsData={landingSectionsData}
            topicGroupsData={topicGroupsData}
            tags={tags}
            FeedFilterChildState={FeedFilterChildState}
            handleFilterStateChange={handleFilterStateChange}
            lightBgColor={lightBgColor}
            darkBgColor={darkBgColor}
          />
        ) : (
          <div style={{marginLeft:margin , marginRight:margin , border: "1px solid #D4D4D4",}}>
            <DesktopLandingPage
              landingSectionsData={landingSectionsData}
              topicGroupsData={topicGroupsData}
              tags={tags}
              FeedFilterChildState={FeedFilterChildState}
              handleFilterStateChange={handleFilterStateChange}
              lightBgColor={lightBgColor}
              darkBgColor={darkBgColor}
            />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default Landing;
