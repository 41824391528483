import React from "react";
import { AppBar, Tabs, Tab } from "@mui/material";

function ConnectionTabs({
  isOwnConnectionPage,
  tabIndex,
  handleTabChange,
  connectionsCount,
  receivedRequestsCount,
  sentRequestsCount,
}) {
  return (
    <AppBar position="static" color="transparent">
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={`Connections (${connectionsCount})`} />
        {isOwnConnectionPage && (
          <Tab label={`Received Requests (${receivedRequestsCount})`} />
        )}
        {isOwnConnectionPage && (
          <Tab label={`Sent Requests (${sentRequestsCount})`} />
        )}
      </Tabs>
    </AppBar>
  );
}

export default ConnectionTabs;
