import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MenuFolderSelector from "./MenuFolderSelector";
import RecordViewer from "./RecordViewer";
import MedicationPopupForm from "../../FormComponents/MedicationPopupForm";
import HistoryPopupForm from "../../FormComponents/HistoryPopupForm";
import AllergyPopupForm from "../../FormComponents/AllergyPopupForm";
import DocumentPopupForm from "../../FormComponents/DocumentPopupForm";
import ShareRecordsPopupForm from "../../FormComponents/ShareRecordsPopupForm";
import ShareIndividualRecordsPopupForm from "../../FormComponents/ShareIndividualRecordsPopupForm";

const Records = ({
  username,
  memberID,
  requestorID,
  openPopup,
  handleClosePopup,
  handleOnAddRecord,
  handleOnSelectSharedFolders,
  handleShareSelectedRecords,
  selectedForm,
  documentForm,
  userFolders,
  selectedTable,
  setSelectedTable,
  selectedFolders,
  setSelectedFolders,
  selectedFoldersID,
  selectedRecords,
  setSelectedRecords,
}) => {
  const [selectedRecordsCount, setSelectedRecordsCount] = useState(0);

  const handleRecordSelection = (index, tableName) => {
    setSelectedRecordsCount(index);
    setSelectedTable(tableName);
  };

  const handleOnSelectFolder = (folderName) => {
    console.log(" folderName ",folderName);
  };

  return (
    <Grid container spacing={3} style={{ marginTop: "7px" }}>
      <Grid item xs={12} md={5}>
        <MenuFolderSelector
          folderData={userFolders}
          onSelect={handleRecordSelection}
          onSelectFolder={handleOnSelectFolder}
          onAddRecord={handleOnAddRecord}
          onShareRecords={handleOnSelectSharedFolders}
          requestorID={requestorID}
          memberID={memberID}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <RecordViewer
          documentTitle={documentForm}
          recordCaption={selectedTable}
          folderName={selectedTable}
          setRecordsToSend={handleShareSelectedRecords}
          onSendButtonClick={() => handleOnAddRecord("shareIndividualRecords")}
          memberID={memberID}
          username={username}
        />
      </Grid>
      <>
        {selectedForm === "medication" && (
          <MedicationPopupForm
            open={openPopup}
            onClose={handleClosePopup}
            enableEditing={true}
            medicationInitialValues=""
            showSubmit={true}
          />
        )}
        {selectedForm === "allergy" && (
          <AllergyPopupForm
            open={openPopup}
            onClose={handleClosePopup}
            enableEditing={true}
            allergyInitialValues=""
            showSubmit={true}
          />
        )}
        {selectedForm === "history" && (
          <HistoryPopupForm
            open={openPopup}
            onClose={handleClosePopup}
            enableEditing={true}
            historyInitialValues=""
            showSubmit={true}
          />
        )}
        {selectedForm === "document" && (
          <DocumentPopupForm
            formTitle={documentForm}
            open={openPopup}
            onClose={handleClosePopup}
            enableEditing={true}
            documentInitialValues=""
            showSubmit={true}
          />
        )}
        {selectedForm === "shareRecords" && (
          <ShareRecordsPopupForm
            open={openPopup}
            onClose={handleClosePopup}
            records={selectedFolders}
            recordIDs = {selectedFoldersID}
          />
        )}
        {selectedForm === "shareIndividualRecords" && (
          <ShareIndividualRecordsPopupForm
            open={openPopup}
            onClose={handleClosePopup}
            records={selectedRecords}
            folderName={selectedTable}
          />
        )}
      </>
    </Grid>
  );
};

export default Records;
