import React from 'react';
import { useState , useRef} from 'react';
import {TextField, Button,MenuItem} from '@mui/material'
import CustomPopUpForm from '../components/CustomPopUpForm';
import theme from '../Theme.js';
import { ThemeProvider } from "@mui/material/styles";
import { GetSettingsByKey } from "../helper/APIHelper"
import { PostHistoryRecord } from "../services/HealthServiceHelper";
import CustomAlert from '../components/CustomAlert';

const historyResourcesKey = ["HistoryType", "Relation"];
function History() {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(historyResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [submit, setSubmit] = useState (false);
  const [state, setState] = React.useState({
    relation:"",
    historyType:"",
    description:"",
    onSet:"",
    age:"",
    comments:"",
  })


  function clearData (){
    setState({
      relation:"",
      historyType:"",
      description:"",
      onSet:"",
      age:"",
      comments:"",
    })
    setSubmit(false);
  }
  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value
    });
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    console.log(state);
    setSubmit(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const ContactID = userInfo.contactID;
    console.log("Patient ID "+ContactID);
    let historyRecord ='';
    if(state.relation !== '' && state.historyType !== '' && state.onSet !== '' && state.age !== ''){
      historyRecord = {
        patientID: ContactID,
        relation: state.relation,
        historyType: state.historyType,
        description: state.description,
        ageAtOnset: state.age,
        onset: state.onSet,
        comments: state.comments
      }
      console.log(historyRecord);
      PostHistoryRecord(historyRecord);
      console.log("Calling Post History API");
      clearData();
      handleEditContactInfoClick();
    }
  };

  const handleCancel = () => {
    //clearData();
    handleClose();
  };
  function filterDataByValue(filterValue) {
    console.log("Filter " + filterValue);
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.resourceID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }
  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
      alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
      handleClose();
    };

    return (
      <ThemeProvider theme={theme}>
        <CustomAlert
                ref={alertRef} // This is how you pass the ref to the CustomAlert component
                title="Success"
                type="success"
                content="Form Submitted."
                duration={2000}
                ancorVertical="top"
                ancorHorizontal="center"
            />
      <Button variant="contained" color="primary"
      onClick={handleClickOpen}>
       Open Form
     </Button>
      <CustomPopUpForm
        open={open}
        onClose={handleClose}
        title="History"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      >
        <div className="grid grid-cols-1 gap-4">
          <TextField required label="Relation" select size="small" variant="outlined" 
            name="relation"
            value={state.relation}
            onChange = {handleChange}
            error = {submit && !state.relation}
          >
            {filterDataByValue("Relation")}
          </TextField>
          <TextField required label="History Type" select size="small" variant="outlined"
            name="historyType"
            value={state.historyType}
            onChange = {handleChange}
            error = {submit && !state.historyType}
          >
            {filterDataByValue("HistoryType")}
          </TextField>
          <TextField label="Enter Description" size="small" variant="outlined"
            name="description"
            value={state.description}
            onChange = {handleChange}
          />
          <TextField required label="Onset" size="small" variant="outlined" type="Date" InputLabelProps={{ shrink: true }}
            name="onSet"
            value={state.onSet}
            onChange = {handleChange}
            error = {submit && !state.onSet}
          />
          <TextField required label="Age At Onset" size="small" variant="outlined" 
            name="age"
            value={state.age}
            onChange = {handleChange}
            error = {submit && !state.age}
          />
          <TextField label="Notes And Comments" size="small" variant="outlined" multiline
            name="comments"
            value={state.comments}
            onChange = {handleChange}
            error = {submit && !state.comments}
          />
        </div>
      </CustomPopUpForm>
      </ThemeProvider>
    );
}

export default History;