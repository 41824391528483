import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CategoryIcon from "@mui/icons-material/Category";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function ViewEventForm({ open, onClose, selectedEvent }) {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        width="70%"
        open={open}
        onClose={onClose}
        className=""
      >
        <DialogTitle>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              padding: "10px",
              fontSize: "25px",
            }}
            className="text-center"
          >
            {selectedEvent.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: "10px" }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    {selectedEvent.speaker.charAt(0).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Speaker"
                  secondary={selectedEvent.speaker}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AccessTimeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Start Date"
                  secondary={selectedEvent.start.toLocaleString()}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AccessTimeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="End Date"
                  secondary={selectedEvent.end.toLocaleString()}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <CategoryIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Type" secondary={selectedEvent.type} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemText
                  primary="Description"
                  secondary={
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedEvent.desc }}
                    />
                  }
                  style={{ marginLeft: "55px" }}
                />
              </ListItem>
            </List>
          </div>
          <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default ViewEventForm;
