import React from "react";
import theme from "../../Theme";
import { ThemeProvider } from "@emotion/react";
import { useAuth } from "../../components/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";
import { Navigate, Link } from "react-router-dom";

const MobileCircleComponent = ({
  src,
  alt,
  size,
  position,
  title,
  text,
  textSize,
  itemID,
}) => {
  const EDIT_URL =
    "https://aiworksqa.agiline.com//global/index?globalurlid=787048E1-D78C-4E6F-BEB2-D9ED05BD6266&param1=";
  const screenWidth = window.innerWidth;
  const imageSize = position === "right" ? `${size / 4}px` : `${size / 2}px`;
  const circleHeight =
    position === "right" ? `${size / 2.7}px` : `${size / 1.4}px`;
  const circleWidth =
    position === "right" ? `${size / 2.7}px` : `${size / 1.4}px`;

  let auth = useAuth();

  const isAdmin = () => {
    let result =
      auth.user !== null &&
      auth.user !== undefined &&
      auth.user.isAdmin === true;
    return result;
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: position === "right" ? "row" : "column",
          alignItems: "center",
          padding: position === "right" ? "2rem" : "60px",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: circleWidth,
            height: circleHeight,
            borderRadius: "50%",
            backgroundColor: "#f2f2f2",
            overflow: "hidden",
            marginRight: position === "right" ? "16px" : "0",
            marginBottom: position === "bottom" ? "16px" : "0",
          }}
        >
          <img
            src={src}
            alt={alt}
            style={{
              width: imageSize,
              height: imageSize,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            width: position === "right" ? "50%" : "500px",
          }}
        >
          {title && (
            <div
              style={{
                marginTop: "15px",
                fontSize: `${textSize}px`,
                fontWeight: "bold",
                color: theme.palette.primary.main,
                textAlign: position === "right" ? "left" : "center",
                maxWidth: "300px", // set the maximum width of the container
                wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
              }}
            >
              {title}
            </div>
          )}
          {text && (
            <div
              style={{
                fontSize: `${textSize * 0.75}px`,
                color: "#333",
                justifyContent: "center",
                textAlign: position === "right" ? "left" : "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxHeight: "250px",
                maxWidth: "300px", // set the maximum width of the container
                wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
              }}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
            //{/* {text}
            //</div> */}
          )}
        </div>

        {isAdmin() && (
          <Link
            to={EDIT_URL + itemID}
            target="_blank"
            style={{
              position: position !== "right" ?"absolute" : "sticky",
              // bottom: position === "right" ? 270: "",
              // left: position === "right" ? 40 : "",
              top: position !== "right" ? 65 : "",
              right: position !== "right" ? 225:"",
            }}
          >
            <EditIcon
              style={{
                backgroundColor: "#f2f2f2",
                borderColor: "#e8e8e8",
                borderWidth: "2px",
                borderRadius: "25px",
                height: "40px",
                width: "40px",
                padding: "5px",
                marginLeft: '5%',
              }}
            />
          </Link>
        ) }
      </div>
    </ThemeProvider>
  );
};

export default MobileCircleComponent;
