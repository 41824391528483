import React from "react";
import { useState } from 'react';
import { TextField, Button , Typography } from '@mui/material';
import theme from '../Theme.js';
import { ThemeProvider } from "@mui/material/styles";
import CustomPopUpForm from "../components/CustomPopUpForm"



function PopupForm() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Handle form submission
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" color="primary"
      onClick={handleClickOpen}>
       Open Form
     </Button>
      <CustomPopUpForm
        open={open}
        onClose={handleClose}
        title="Dialog Title"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      >
        <TextField label="Field 1" size="small" variant="outlined" />
        <TextField label="Field 2" size="small" variant="outlined" />
        <TextField label="Field 3" size="small" variant="outlined" />
        <TextField label="Field 4" size="small" variant="outlined" />
      </CustomPopUpForm>
    </ThemeProvider>
  );
}

export default PopupForm;