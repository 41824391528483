import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import {
  useNavigate,
  useLocation,
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useHistory,
} from "react-router-dom";
import {} from "react-router-dom";
import Home from "./Home.js";
import API_data from "../data/data.json";
import SignUp from "./SignUp/SignUp.js";
// import { Alert } from "react-bootstrap";
import CustomAlert from "../components/CustomAlert.js";

import bgImage from "../assets/globeStethoscope.png";

import theme from "../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { LoginHelper } from "../helper/APIHelper";
import { useAuth } from "../components/AuthProvider";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Avatar,
  TextField,
  Button,
  Card,
  Box,
  Link,
  Typography,
  Divider,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    margin: "auto",
    display: "grid",
    alignItems: "center",
    width: "22rem",
    // height: "", // set the height of the container to the height of the viewport

    [theme.breakpoints.up("md")]: {
      width: "25rem",
      // height: "100%",
      marginLeft: "15vw",
      // marginTop: "5vw",
    },
  },
}));

const myStyle = {
  backgroundImage: `url(${bgImage})`,
  height: "100vh",
  fontSize: "50px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "grid",
  alignItems: "center",
};

function isLogged() {
  return localStorage.getItem("userInfo") ? true : false;
}

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [userInfo, setUserInfo] = useState();
  const usernameInput = useRef();
  const passwordInput = useRef();
  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = React.useState("");
  let auth = useAuth();

  let state = location.state;
  let from = state ? state.from.pathname : "/";

  // const handleSubmit = async (e) => {
  //   const response = await LoginHelper(
  //     usernameInput.current.value,
  //     passwordInput.current.value
  //   );
  //   setIsLogin(response);
  // };

  async function handleSubmit(event) {
    event.preventDefault();
    let username = usernameInput.current.value;
    let password = passwordInput.current.value;
    let response = auth.signin(username, password, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.

      //Uncomment to route to the previous page that was private
      //navigate(from, { replace: true });
      navigate("/", { replace: true });
      window.location.reload();
    });
    let message = await response;
    console.log("message ", message);
    if (message === true) {
      setError("");
    } else {
      setError(message);
    }
    handleEditContactInfoClick();
  }

  const handleSubmitDummy = async (e) => {
    let userInfo = { username: "w.wolf", personID: 2, token: "strawberry" };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    console.log("username", usernameInput.current.value);
    console.log("password", passwordInput.current.value);
    setToken(userInfo.personID);
  };

  const handleRegisterClick = (e) => {
    e.preventDefault();
    navigate("/SignUp");
  };
  const handleBrowseAsGuestClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const classes = useStyles();

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type={error ? "error" : "success"}
        content={error ? error : "Form Submitted."}
        // duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <form onSubmit={handleSubmit}>
        <div style={myStyle}>
          {/* <div className="bg-white bg-opacity-40 pl-60">
              <a className="opacity-100 text-2xl font-bold"> Agiline Software </a>
              <a className="opacity-100 text-2xl "> HealthbloQ </a>
          </div> */}
          <Grid style={{ alignItems: "center" }}>
            <Card className={classes.card}>
              <a
                className="text-4xl pb-10 font-semibold text-center"
                style={{ color: theme.palette.primary.main }}
              >
                Log In
              </a>
              <TextField
                style={{ paddingBottom: "10px" }}
                size="small"
                label="Username"
                variant="outlined"
                inputRef={usernameInput}
              />
              <TextField
                style={{ paddingBottom: "10px" }}
                size="small"
                label="Password"
                variant="outlined"
                type="password"
                inputRef={passwordInput}
              />
              {error !== "" && (
                <p
                  style={{
                    fontSize: "14px",
                    color: "red",
                    marginBottom: "10px",
                  }}
                >
                  {error}
                </p>
              )}
              <Link
                className="text-base"
                href="#"
                underline="hover"
                sx={{ fontSize: "1rem", lineHeight: "1.5rem" }} // adjust this value as needed
              >
                Forgot your Password?
              </Link>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "7px",
                  marginBottom: "3px",
                }}
              >
                <Button
                  className="h-10 w-3/5"
                  variant="contained"
                  // onClick={handleSubmit}
                  fullWidth
                  type="submit"
                >
                  Log In
                </Button>
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Divider sx={{ width: "42%", mr: "1rem" }} />
                <Typography variant="body2" color="text.secondary">
                  Or
                </Typography>
                <Divider sx={{ width: "42%", ml: "1rem" }} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  onClick={handleBrowseAsGuestClick}
                  className="text-base"
                  href=""
                  underline="hover"
                  sx={{ fontSize: "1rem", lineHeight: "1.5rem" }} // adjust this value as needed
                >
                  Browse As Guest
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  onClick={handleRegisterClick}
                  className="text-base"
                  href=""
                  underline="hover"
                  sx={{ fontSize: "1rem", lineHeight: "1.5rem" }} // adjust this value as needed
                >
                  Sign Up
                </Link>
              </Box>
              {/* <Link
              onClick={handleRegisterPatientClick}
              className="text-base"
              href=""
              underline="hover"
              sx={{ fontSize: "1rem", lineHeight: "1.5rem" }} // adjust this value as needed
            >
              Register As a Patient
            </Link> */}
            </Card>
          </Grid>
          {/* <div className="bg-white bg-opacity-40 h-14 pt-2 pl-60 absolute w-full">
              <a className="opacity-100 text-2xl "> HealthbloQ </a>
          </div> */}
        </div>
      </form>
    </ThemeProvider>
  );
}

export default Login;
