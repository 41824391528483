import React, { useState } from "react";
import CardComponent from "./CardComponent";
import CircleComponent from "./CircleComponent";
import image from "../../assets/LaptopAndStethoscope.jpg";
import heart from "../../assets/heart.png";
import doctor from "../../assets/doctor.png";
import hand from "../../assets/hand.png";
import theme from "../../Theme";
import { ThemeProvider } from "@emotion/react";
import ImageCarousel from "./ImageCarousel";
import { GetSettingsByKey } from "../../helper/APIHelper";
import { Chip } from "@mui/material";
const topicGroupsResourcesKey = ["TopicGroups"];
function MyPage() {
  const [topicGroupsData, setTopicGroupsData] = useState([]);

  React.useEffect(() => {
    const response = GetTopicGroups();
    console.log("Categories for feed: ", response);
  }, []);

  async function GetTopicGroups() {
    let temp = await GetSettingsByKey(topicGroupsResourcesKey);
    setTopicGroupsData(temp);
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingLeft: "10px" }}>
        <CardComponent
          image={image}
          title="Title"
          text="Lorem ipsum dolor sit amet"
          textSize={16}
          position="top"
          size={400}
        />
      </div>
      <div>
        <CircleComponent
          src={heart}
          alt="Example"
          size={100}
          position="bottom"
          title="Title"
          text="Lorem ipsum dolor sit amet"
          textSize={16}
        />
        <CircleComponent
          src={doctor}
          alt="Example"
          size={100}
          position="right"
          title="Title"
          text="Lorem ipsum dolor sit amet"
          textSize={16}
        />
        <CircleComponent
          src={hand}
          alt="Example"
          size={100}
          position="right"
          title="Title"
          text="Lorem ipsum dolor sit amet"
          textSize={16}
        />
      </div>
      {/* <div>
        <Chip
          label= "Topic 1"
          className="mx-1 my-1"
          color="primary"
          sx={{ marginRight: '4px' }}
        />
      </div>
      <ImageCarousel topicGroupsData={topicGroupsData} /> */}
    </ThemeProvider>
  );
}

export default MyPage;
