import React, { useState } from 'react';
import { Card, CardHeader, CardMedia, CardActions, Button, Avatar, useTheme, useMediaQuery, Box, Typography, IconButton } from '@mui/material';
import { PersonAdd, MoreVert } from '@mui/icons-material';

import WebSiteTheme from '../Theme.js';
import { ThemeProvider } from "@mui/material/styles";

function EventHeader({ toggle, name, address, email, coverPhotoURL, profilePictureURL }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isConnected, setIsConnected] = useState(false);

  const styles = {
    card: {
      maxWidth: '100%',
      margin: '0 auto',
    },
    coverPhoto: {
      height: isMobile ? 250 : 340,
    },
    profilePicture: {
      width: isMobile ? 100 : 150,
      height: isMobile ? 100 : 150,
      border: '5px solid white',
      position: 'absolute',
      top: isMobile ? 281 : 343,
      left: isMobile ? '50%' : '',
      marginLeft: isMobile ? '-115px' : '5px',
    },
    profileInfoBox: {
      height: isMobile ? 100 : 150,
      position: 'absolute',
      top: isMobile ? 327 : 422,
      left: isMobile ? '50%' : '',
      marginLeft: isMobile ? '-12px' : '169px',
    },
    buttonMenu: {
      justifyContent: 'left',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    rightButton: {
      marginRight: 'auto',
    },
  };

  const handleFollowClick = () => {
    setIsConnected(!isConnected);
  };

  if (!toggle) {
    return (
      <ThemeProvider theme={WebSiteTheme}>
        <Card sx={styles.card}>
          <Box sx={{
            height: styles.coverPhoto.height,
            background: `url('https://source.unsplash.com/random/1600x400') no-repeat center center`,
            backgroundSize: 'cover',
            alt: 'cover',
          }}
          />
          <Avatar
            src="https://source.unsplash.com/random/200x200"
            alt="profile"
            sx={styles.profilePicture}
          />
          <Box sx={{ textAlign: 'right', paddingRight: 2, paddingTop: 2 }}>
            <Button
              variant={isConnected ? 'outlined' : 'contained'}
              color={'secondary'}
              startIcon={<PersonAdd />}
              onClick={handleFollowClick}
            >
              {isConnected ? 'Subscribed' : 'Subscribe'}
            </Button>
          </Box>
          <Box sx={styles.profileInfoBox}>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
              User Profile
            </Typography>
            <Typography variant="body1" component="div">
              La Verne, CA
            </Typography>
            <Typography variant="body2" component="div">
              User@agiline.com
            </Typography>
          </Box>
          <CardHeader />
        </Card>
      </ThemeProvider>
    );
  }

  return (
      <ThemeProvider theme={WebSiteTheme}>
        <Card sx={styles.card}>
          <Box sx={{
            height: styles.coverPhoto.height,
            background: `url('${coverPhotoURL}') no-repeat center center`,
            backgroundSize: 'cover',
            alt: 'cover',
          }}
          />
          <Avatar
            src={profilePictureURL}
            alt="profile"
            sx={styles.profilePicture}
          />
          <Box sx={{ textAlign: 'right', paddingRight: 2, paddingTop: 2 }}>
            <Button
              variant={isConnected ? 'outlined' : 'contained'}
              color={'secondary'}
              startIcon={<PersonAdd />}
              onClick={handleFollowClick}
            >
              {isConnected ? 'Subscribed' : 'Subscribe'}
            </Button>
          </Box>
          <Box sx={styles.profileInfoBox}>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
              {name}
            </Typography>
            <Typography variant="body1" component="div">
              {address}
            </Typography>
            <Typography variant="body2" component="div">
              {email}
            </Typography>
          </Box>
          <CardHeader />
        </Card>
      </ThemeProvider>
    );
  };

export default EventHeader;
