import React from "react";
import { Outlet } from "react-router-dom";
import LeftNavbar from "../LeftNavbar";
import Box from "@mui/material/Box";

const Layout = () => {
  return (
    <>
      <Box sx={{ display: "flex", marginTop: "85px" }}>
        <LeftNavbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Layout;
