import React from 'react';

function ContactPage() {
  const userEmail = 'user@example.com';

  return (
    <div>
      <p>
        You can contact us at{' '}
        <a href={`mailto:${userEmail}`}>{userEmail}</a>.
      </p>
    </div>
  );
}

export default ContactPage;
