/**
 * This represents some generic auth provider API, like Firebase.
 */
import { LoginHelper, LogoutHelper } from "../helper/APIHelper";
// const fakeAuthProvider = {
//   isAuthenticated: false,
//   signin(callback) {
//     fakeAuthProvider.isAuthenticated = true;
//     setTimeout(callback, 100); // fake async
//   },
//   signout(callback) {
//     fakeAuthProvider.isAuthenticated = false;
//     setTimeout(callback, 100);
//   },
// };

const healthAuthProvider = {
  isAuthenticated: false,
  async signin(username, password, callback) {
    // const response = await LoginHelper(username, password);
    let response = await LoginHelper(username, password);
    healthAuthProvider.isAuthenticated = response;
    if (response) {
      setTimeout(callback, 1); // fake async
    } else {
      response = "Your Information Is Incorrect";
    }
    return response;
  },
  async signout(callback) {
    healthAuthProvider.isAuthenticated = false;
    localStorage.removeItem("userInfo");
    const response = await LogoutHelper();
    setTimeout(callback, 1);
    return response;
  },
};
export { healthAuthProvider };
// export { fakeAuthProvider, healthAuthProvider };
