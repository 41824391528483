import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Carousel from 'react-material-ui-carousel';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { ThemeProvider } from "@mui/material";
import theme from "../../Theme";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Navigate, Link } from "react-router-dom";
import { useAuth } from "../../components/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    maxHeight: "480px",
    minWidth: "100%",
    width: "100%",
  },
  media: {
    height: "250px",
    width: "180px",
  },
  description: {
    fontSize: "15px",
    display: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    maxHeight: "100px",
    maxWidth: "270px", // set the maximum width of the container
    wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
    overflow: "hidden",
  },
  arrowButton: {
    position: "relative",
    display: "flex-end",
    left: "90%",
    bottom: "-25px", // move the button down slightly
  },
}));

const MobileProviderComponent = ({ items }) => {
  const classes = useStyles();

  const EDIT_URL =
    "https://aiworksqa.agiline.com//global/index?globalurlid=787048E1-D78C-4E6F-BEB2-D9ED05BD6266&param1=";
  let auth = useAuth();
  const isAdmin = () => {
    let result =
      auth.user !== null &&
      auth.user !== undefined &&
      auth.user.isAdmin === true;
    return result;
  };
  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: "1rem" }}>
        <Carousel
          // customRightArrow={<CustomRightArrow />}
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          deviceType={"mobile"}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {items.map((item, i) => (
            <Card key={i} className={classes.card}>
              <CardMedia className={classes.media} image={item.mediaURL} />
              <CardContent>
                <Typography
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.palette.primary.main,
                    maxWidth: "270px",
                    wordWrap: "break-word",
                    fontSize: "20px",
                  }}
                >
                  {item.header}
                </Typography>
                <Typography
                  className={classes.description}
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></Typography>
                <Box>
                  <IconButton
                    className={classes.arrowButton}
                    style={{ color: theme.palette.primary.main }}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
                {isAdmin() ? (
                  <Link
                    to={EDIT_URL + item.feedID}
                    target="_blank"
                    style={{ position: "absolute", top: 10, right: 10 }}
                  >
                    <EditIcon />
                  </Link>
                ) : (
                  <></>
                )}
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </div>
    </ThemeProvider>
  );
};

export default MobileProviderComponent;
