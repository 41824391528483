import { useEffect, useState } from "react";
import React from "react";
import {
  GetEncounterHeader,
  GetEncounterDetails,
  GetEncounterUI,
} from "../../../services/HealthServiceHelper";
import { CardContent, CardHeader, Typography, Grid, Box } from "@mui/material";
import EncounterLeftMenu from "./EncounterLeftMenu";
import EncounterRightMenu from "./EncounterRightMenu";
import EncounterEdit from "./EncounterEdit";
import SingleDropDown from "../../../reusableComponents/SingleDropDown";

function ViewTransmission({ encID, data }) {
  console.log("data Encounters.js", data);
  console.log("encID Encounters.js", encID);
  const [loading, setLoading] = useState(false);
  const [encounterHeader, setEncounterHeader] = useState(null);
  const [encounterDetails, setEncounterDetails] = useState(null);
  const [encounterUI, setEncounterUI] = useState(null);
  const [encounterRightMenu, setEncounterRightMenu] = useState(-1);
  const [editButton, setEditButton] = useState(-1);
  //0 view 1 edit
  const [editOrView, setEditOrView] = useState(-1);

  useEffect(() => {
    async function FillData() {
      setLoading(true);
      let encHeader = await GetEncounterHeader(encID);
      let encDetails = await GetEncounterDetails(encID);
      let encUI = await GetEncounterUI(encID);
      setEncounterHeader(encHeader);
      setEncounterDetails(encDetails);
      setEncounterUI(encUI);
      setEditOrView(-1);
      setLoading(false);
    }
    FillData();
  }, [encID, data]);

  // Function to render different components based on encDetType
  const renderEncounterDetails = () => {
    if (encounterUI) {
      const diagnosisSections = [];
      const serviceSections = [];
      const documentSections = [];

      encounterUI.forEach((section, index) => {
        switch (section.encDetType) {
          case "Diagnosis":
            diagnosisSections.push(section);
            break;
          case "Service":
            serviceSections.push(section);
            break;
          case "Document":
            documentSections.push(section);
            break;
          default:
            break;
        }
      });

      console.log("diagnosisSection ", diagnosisSections);
      return (
        <>
          <SingleDropDown
          contents={diagnosisSections}
          viewType={0}
          headerName={"Diagnosis"}
          disabled={false}
          showPagination={false}
          itemsPerPage={2}
          paginationColor="secondary"
          functionNames={""}
          functionIcons={""}
          functionsArray={""}
          width="100%"
          headerColor="primary.main"
          hoverColor="primary.light"
          IconColor="common.white"
          hiddenFields={""}
        />

          {/* Render other sections */}
          {/* Add similar SingleDropDown components for serviceSections and documentSections */}
        </>
      );
    }
    return null;
  };

  // const renderEncounterDetails = () => {
  //   if (encounterUI) {
  //     return encounterUI.map((section, index) => {
  //       switch (section.encDetType) {
  //         case "Diagnosis":
  //           return (
  //             <EncounterLeftMenu
  //               index={index}
  //               setEncounterRightMenu={setEncounterRightMenu}
  //               title={section.encDetType}
  //               fromDate={encounterUI[index].fromDate.split("T").shift()}
  //               toDate={encounterUI[index].toDate.split("T").shift()}
  //               description1={encounterUI[index].description1}
  //               explanation={encounterUI[index].explanation}
  //               value={encounterUI[index].value}
  //               setEditButton={setEditButton}
  //               setEditOrView={setEditOrView}
  //             />
  //           );
  //         case "Service":
  //           return (
  //             <EncounterLeftMenu
  //               index={index}
  //               setEncounterRightMenu={setEncounterRightMenu}
  //               title={section.encDetType}
  //               fromDate={encounterUI[index].fromDate.split("T").shift()}
  //               toDate={encounterUI[index].toDate.split("T").shift()}
  //               description1={encounterUI[index].description1}
  //               explanation={encounterUI[index].explanation}
  //               value={encounterUI[index].value}
  //               range={encounterUI[index].range}
  //               setEditButton={setEditButton}
  //               setEditOrView={setEditOrView}
  //             />
  //           );
  //         case "Document":
  //           return <p>help</p>;
  //         default:
  //           return null;
  //       }
  //     });
  //   }
  //   return null;
  // };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="1rem"
          marginBottom="1rem"
        >
          <div className="spinner"></div>
        </Box>
      ) : (
        <CardContent>
          <CardHeader
            title={
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div>
                  <img
                    src={data.profilePicture}
                    alt="Profile"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                  <Typography variant="h6">{`${data.firstName} ${data.lastName}`}</Typography>
                  <Typography variant="subtitle1">{`Created Date: ${data.createdDate
                    .split("T")
                    .shift()}`}</Typography>
                  <Typography variant="body1">
                    Encounter Type: {encounterHeader?.encType}
                  </Typography>
                </div>
              </div>
            }
          />
          <hr
            style={{
              borderColor: "#B6B8BB",
              borderWidth: "1px",
              marginBottom: "20px",
            }}
          />
          <Grid container spacing={2} sx={{ margin: "1rem" }}>
            <Grid item xs={4}>
              {renderEncounterDetails()}
            </Grid>
            {editOrView === 1 && editButton !== -1 ? (
              <Grid item xs={8}>
                <EncounterEdit
                  encDate={encounterUI[editButton].encDate}
                  provider={encounterUI[editButton].provider}
                  encDetType={encounterUI[editButton].encDetType}
                  description={encounterUI[editButton].description}
                  fromDate={encounterUI[editButton].fromDate}
                  serviceProvider={encounterUI[editButton].serviceProvider}
                  toDate={encounterUI[editButton].toDate}
                  value={encounterUI[editButton].value}
                  description1={encounterUI[editButton].description1}
                  explanation={encounterUI[editButton].explanation}
                  range={encounterUI[editButton].range}
                  codeID={encounterUI[editButton].codeID}
                />
              </Grid>
            ) : editOrView === 0 && encounterRightMenu !== -1 ? (
              <Grid item xs={8}>
                <EncounterRightMenu
                  encDate={encounterUI[encounterRightMenu].encDate}
                  provider={encounterUI[encounterRightMenu].provider}
                  encDetType={encounterUI[encounterRightMenu].encDetType}
                  description={encounterUI[encounterRightMenu].description}
                  fromDate={encounterUI[encounterRightMenu].fromDate}
                  serviceProvider={
                    encounterUI[encounterRightMenu].serviceProvider
                  }
                  toDate={encounterUI[encounterRightMenu].toDate}
                  value={encounterUI[encounterRightMenu].value}
                  description1={encounterUI[encounterRightMenu].description1}
                  explanation={encounterUI[encounterRightMenu].explanation}
                  range={encounterUI[encounterRightMenu].range}
                  codeID={encounterUI[encounterRightMenu].codeID}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </CardContent>
      )}
    </>
  );
}
export default ViewTransmission;
