import { TextField, MenuItem } from '@mui/material'
import React from 'react';
import CustomPopUpForm from '../CustomPopUpForm';
import { useState, useRef } from 'react';
import { GetSettingsByKey, GetMedicationName } from '../../helper/APIHelper'
import { PostMedicalRecord } from '../../services/HealthServiceHelper';
import { ThemeProvider } from '@mui/material';
import theme from '../../Theme';
import CustomAlert from '../CustomAlert';


const medicationResourcesKey = ["MedUnits", "Frequency", "MedRoute"];

function MedicationPopupForm({ open, onClose, enableEditing, medicationInitialValues, showSubmit }) {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(medicationResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [unit, setUnit] = useState(medicationInitialValues !== "" ? medicationInitialValues.units : "");
  const [dosage, setDosage] = useState(medicationInitialValues !== "" ? medicationInitialValues.dosage : "");
  const [times, setTimes] = useState(medicationInitialValues !== "" ? medicationInitialValues.fUnits : "");
  const [frequency, setFrequency] = useState(medicationInitialValues !== "" ? medicationInitialValues.frequency : "");
  const [route, setRoute] = useState(medicationInitialValues !== "" ? medicationInitialValues.route : "");
  const [instructions, setInstructions] = useState(medicationInitialValues !== "" ? medicationInitialValues.instructions : "");
  const [medicationStatus, setMedicationStatus] = useState(medicationInitialValues !== "" ? medicationInitialValues.medStatus : "");
  const [medicationName, setMedicationName] = useState(medicationInitialValues !== "" ? medicationInitialValues.medication : "");
  const [searchResults, setSearchResults] = useState([]);
  const [startDate, setStartDate] = useState(medicationInitialValues !== "" ? medicationInitialValues.beginDate.split("T").shift() : "");
  const [endDate, setEndDate] = useState(medicationInitialValues !== "" && medicationInitialValues.endDate.split("T").shift() !== '0001-01-01' ? medicationInitialValues.endDate.split("T").shift() : "");
  const [comments, setComments] = useState(medicationInitialValues !== "" ? medicationInitialValues.comments : "");
  const [startBiggerThanEnd, setStartBiggerThanEnd] = useState(false);
  const [valueSelected, setValueSelected] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    dosageError: "",
    timesError: "",
  })

  function clearData() {
    console.log("Clear Data");
    setMedicationName('');
    setDosage('');
    setUnit('');
    setTimes('');
    setFrequency('');
    setRoute('')
    setStartDate('');
    setEndDate('')
    setMedicationStatus('');
    setInstructions('');
    setComments('');
    setStartBiggerThanEnd(false);
    setValueSelected(true);
    setSubmit(false);
  }

  const handleTimesChange = (event) => {
    console.log("Times");
    setTimes(event.target.value);
  }
  const handleDosageChange = (event) => {
    console.log("Dosage");
    setDosage(event.target.value);
  }
  const handleUnitChange = (event) => {
    console.log("Unit Change " + event.target.value);
    setUnit(event.target.value);
  };
  const handleFrequencyChange = (event) => {
    console.log("Frequency Change " + event.target.value);
    setFrequency(event.target.value);
  };

  const handleRouteChange = (event) => {
    console.log("Route Change " + event.target.value);
    setRoute(event.target.value);
  };
  const handleMedicationStatusChange = (event) => {
    console.log("Medicaton Status Change " + event.target.value);
    setMedicationStatus(event.target.value);
  };
  const handleInstructionsChange = (event) => {
    console.log("Instructions");
    setInstructions(event.target.value);
  }
  const handleCommentsChange = (event) => {
    console.log("Comments");
    setComments(event.target.value);
  }
  React.useEffect(() => {
    const fetchData = async () => {
      let temp = await GetMedicationName(medicationName);
      setSearchResults(temp);
      console.log(temp);
    };
    fetchData();
  }, [medicationName]);
  const handleMedicationNameChange = (event) => {
    console.log("Medication Name Change " + event.target.value);
    setMedicationName(event.target.value);
    setValueSelected(false);
  };
  const handleStartDateChange = (event) => {
    if (endDate != "" && event.target.value > endDate) {
      console.log("Start date " + event.target.value);
      console.log("End date " + endDate);
      setStartBiggerThanEnd(true);
      setStartDate("");
    } else {
      setStartBiggerThanEnd(false);
      console.log("StartDate Change " + event.target.value);
      setStartDate(event.target.value);
    }
  };
  const handleEndDateChange = (event) => {
    if (startDate > event.target.value) {
      console.log("Start date " + startDate);
      console.log("End date " + event.target.value);
      setStartBiggerThanEnd(true);
      setEndDate("");
    } else {
      setStartBiggerThanEnd(false);
      console.log("EndDate Change " + event.target.value);
      setEndDate(event.target.value);
    }
  };
  const handleSelectName = (param) => {
    console.log("ONCLICK " + param);
    setMedicationName(param);
    setValueSelected(true);
  }
  function validation() {
    let validationErrors = {}
    if (isNaN(dosage)) {
      validationErrors.dosageError = "Please enter a number";
    }
    if (isNaN(times)) {
      validationErrors.timesError = "Please enter a number";
    }
    setValidationErrors(validationErrors)
    console.log("validationErrors ", validationErrors);
    console.log("ret ", Object.keys(validationErrors).length === 0);
    return Object.keys(validationErrors).length === 0;
  }

  async function handleSubmit() {
    setSubmit(true);
    if (validation()) {
      if (medicationName !== '' && dosage !== '' && unit !== '' && times !== '' && frequency !== '' && route !== '' && startDate !== '' && medicationStatus !== '') {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const ContactID = userInfo.contactID;
        const newMedRecord = {
          patientID: ContactID,
          medication: medicationName,
          dosage: dosage,
          units: unit,
          fUnits: times,
          frequency: frequency,
          route: route,
          instructions: instructions,
          beginDate: startDate,
          comments: comments,
          medStatus: medicationStatus,
          provider: 'Provider Name',
        };
        if (endDate !== "") {
          newMedRecord.endDate = endDate;
        }
        const response = await PostMedicalRecord(newMedRecord);
        if (response.ok === true) {
          setError("");
          clearData();
          handleEditContactInfoClick();
          onClose();
        }
        else {
          if (response.statusText === "") {
            setError("Your Request Was Not Fulfilled")
          }
          else {
            setError(response.statusText);
          }
          handleEditContactInfoClick();
        }
      }
    }
  };

  const handleCancel = () => {
    console.log("Cancel");
    //clearData();
    onClose();
  };

  function filterDataByValue(filterValue) {
    console.log("Filter " + filterValue);
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.resourceID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Only allow numeric characters
    if (!/^[0-9]*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAlert
        ref={alertRef} // This is how you pass the ref to the CustomAlert component
        title={error ? "Error" : "Success"}
        type={error ? "error" : "success"}
        content={error ? error : "Form Submitted."}
        duration={2000}
        ancorVertical="top"
        ancorHorizontal="center"
      />
      <CustomPopUpForm
        open={open}
        onClose={onClose}
        title="Medications"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        showSubmit={showSubmit}
      >
        <TextField
          required
          label="Medication Name"
          variant="outlined"
          size="small"
          value={medicationName}
          onChange={handleMedicationNameChange}
          error={submit && !medicationName}
          disabled={!enableEditing}
          sx={{
            width: "100%",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
        {!valueSelected && (
          <div>
            <ul>
              {searchResults.map((option) => (
                <MenuItem
                  key={option.resourceID}
                  onClick={() => handleSelectName(option.resourceName)}
                >
                  {option.resourceName}
                </MenuItem>
              ))}
            </ul>
          </div>
        )}
        <TextField required label="Dosage" size="small" variant="outlined" value={dosage} onChange={handleDosageChange}
          placeholder="Please Enter Number"
          InputProps={{ inputProps: { onKeyPress: handleKeyPress } }}
          error={(submit && !dosage) || validationErrors.dosageError}
          helperText={validationErrors.dosageError}
          disabled={!enableEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
        {/* ResourceKey =  MedUnits*/}
        <TextField required label="Unit"
          select size="small" variant="outlined"
          value={unit} onChange={handleUnitChange}
          sx={{
            textAlign: 'start',
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
          error={submit && !dosage}
          disabled={!enableEditing}
        >
          {filterDataByValue("MedUnits")}
        </TextField>
        <TextField required label="How Many Times" size="small" variant="outlined" value={times} onChange={handleTimesChange}
          placeholder="Please Enter Number"
          InputProps={{ inputProps: { onKeyPress: handleKeyPress } }}
          error={(submit && !times) || validationErrors.timesError}
          helperText={validationErrors.timesError}
          disabled={!enableEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
        {/* ResourceKey = Frequency */}
        <TextField required label="How Often"
          select size="small" variant="outlined"
          value={frequency} onChange={handleFrequencyChange}
          sx={{
            textAlign: 'start',
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
          error={submit && !frequency}
          disabled={!enableEditing}
        >
          {filterDataByValue("Frequency")}
        </TextField>
        {/* ResourceKey =  MedRoute*/}
        <TextField required label="Route"
          select size="small" variant="outlined"
          value={route} onChange={handleRouteChange}
          sx={{
            textAlign: 'start',
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
          error={submit && !route}
          disabled={!enableEditing}
        >
          {filterDataByValue("MedRoute")}
        </TextField>
        <TextField label="Instructions" size="small"
          variant="outlined"
          value={instructions} onChange={handleInstructionsChange}
          disabled={!enableEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
        <TextField required label="Treatment Dates From" size="small" variant="outlined" type="Date"
          error={startBiggerThanEnd || (submit && !startDate)}
          helperText={startBiggerThanEnd ? "Treatment Ends Before It Starts" : ""}
          InputLabelProps={{ shrink: true, placeholder: '', }} value={startDate} onChange={handleStartDateChange}
          disabled={!enableEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
        <TextField label="Treatment Dates To" size="small" variant="outlined" type="Date"
          error={startBiggerThanEnd}
          helperText={startBiggerThanEnd ? "Treatment Ends Before It Starts" : ""}
          InputLabelProps={{ shrink: true, placeholder: '', }} value={endDate} onChange={handleEndDateChange}
          disabled={!enableEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
        <TextField label="Comments" size="small"
          variant="outlined" multiline value={comments}
          onChange={handleCommentsChange} disabled={!enableEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
        {/* ResourceKey =  Active || Not Active*/}
        <TextField required label="Medication Status"
          select size="small" variant="outlined"
          value={medicationStatus} onChange={handleMedicationStatusChange}
          sx={{
            textAlign: 'start',
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
          error={submit && !medicationStatus}
          disabled={!enableEditing}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Not Active">Not Active</MenuItem>
        </TextField>
        {/* ResourceKey = "" */}
        <TextField label="Select Provider"
          // select 
          size="small" variant="outlined"
          disabled={!enableEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#7A7A7A",
            }
          }}
        />
      </CustomPopUpForm>
    </ThemeProvider>
  );
}

export default MedicationPopupForm;

