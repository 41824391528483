import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RenderCard from "./CardComponent";
import EditGrid, { renderEditGrid } from "./EditGridComponent";
import RenderGrid from "./GridComponent";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import GradeIcon from "@mui/icons-material/Grade";

function SingleDropDown({
  contents,
  setContents,
  captions,
  viewType,
  bootstrapZones,
  headerName,
  disabled,
  isExpanded,
  showPagination,
  itemsPerPage,
  paginationColor,
  functionNames,
  functionIcons,
  functionsArray,
  width,
  headerColor,
  hoverColor,
  IconColor,
  mergeFields,
  mergeCounts,
  hiddenFields,
  cardViewType,
  autoDetectType,
  isViewOrEdit,
  editableFields,
  requiredFields,
  types,
  showSearchBar,
  embeddedFunctions,
  isCanceled,
  setIsCanceled,
  isSaved,
  setIsSaved,
  isSavedAsArray,
}) {
  //header color default
  if (headerColor === null || headerColor === undefined) {
    headerColor = "#BBBBBB";
    hoverColor = "#D0CECE";
  }
  if (paginationColor === null || paginationColor === undefined) {
    paginationColor = headerColor;
  }

  //view or edit
  const [viewOrEdit, setViewOrEdit] = useState(isViewOrEdit ? isViewOrEdit : 0);
  const [saveAsArray, setSaveAsArray] = useState(
    isSavedAsArray ? isSavedAsArray : true
  );
  //to control opening and closing the accordion
  const expandedInit =
    embeddedFunctions &&
    embeddedFunctions.some((func) => func.toLowerCase() === "edit") &&
    isViewOrEdit === 1
      ? true
      : isExpanded !== undefined && isExpanded !== null
      ? isExpanded
      : false;
  const [expanded, setExpanded] = useState(isExpanded ? isExpanded : false);
  //To update when i call from the parent component
  useEffect(() => {
    setViewOrEdit(isViewOrEdit);
    setExpanded(expandedInit);
    if (isCanceled) {
      handleParentCancelClick();
    }
    if (isSaved) {
      handleParentSaveClick();
    }
  }, [isViewOrEdit, isExpanded, isCanceled, isSaved]);
  //dirtyFlag for separate accordions
  const [separateDirtyFlag, setSeparateDirtyFlag] = useState(false);

  // State to hold field errors
  const [fieldErrors, setFieldErrors] = useState([]);

  //open and close the accordion
  function handleAccordionClick() {
    setExpanded(!expanded);
  }

  //for sorted contents
  const [sortedContents, setSortedContents] = React.useState(() => {
    // Add id field to each object and auto-generate ids
    return contents.map((content, index) => ({
      ...content,
      externalIdForSorting: `${index}`,
    }));
  });

  const [externalEdit, setExternalEdit] = useState(
    embeddedFunctions && embeddedFunctions.includes("Edit") && viewOrEdit === 1
      ? true
      : false
  );
  //EmbeddedFunctions
  const handleEmbeddedEditFunction = () => {
    setViewOrEdit(1);
    setExternalEdit(true);
    setExpanded(true);
  };
  const handleBackToEditFunction = () => {
    setViewOrEdit(0);
    setExternalEdit(false);
    setExpanded(true);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    handleParentCancelClick();
  };
  const handleParentCancelClick = () => {
    setSortedContents(
      contents.map((content, index) => ({
        ...content,
        externalIdForSorting: `${index}`,
      }))
    );
    console.log("contents ", contents);
    if (saveAsArray) {
      setContents?.(contents);
    } else {
      setContents?.(contents[0]);
    }
    setFieldErrors([]);
    setSeparateDirtyFlag(false);
    if (isCanceled) {
      setIsCanceled(false);
    }
    handleBackToEditFunction();
  };
  const handleSaveClick = (event) => {
    event.stopPropagation();
    handleParentSaveClick();
  };

  // const handleParentSaveClick = () => {
  //   if (fieldErrors.length === 0) {
  //     const newContents = sortedContents.map((item) => {
  //       // Use object destructuring to remove externalIdForSorting
  //       const { externalIdForSorting, ...contentWithoutExternalId } = item;
  //       return contentWithoutExternalId;
  //     });
  //     contents = newContents;
  //     setSeparateDirtyFlag(false);
  //     if (isSaved) {
  //       setIsSaved(false);
  //     }
  //     handleBackToEditFunction();
  //   }
  // };
  const handleParentSaveClick = () => {
    console.log("save");
    if (fieldErrors.length === 0) {
      console.log("no errors");
      let isValid = true;

      // Check if all required fields have values
      requiredFields?.forEach((fieldName) => {
        sortedContents.forEach((item) => {
          const externalIdForSorting = item.externalIdForSorting;

          const fieldValue = item[fieldName];

          // Check if the field is required and has no value
          if (
            requiredFields.includes(fieldName) &&
            (fieldValue === undefined ||
              fieldValue === null ||
              fieldValue === "")
          ) {
            isValid = false;

            // Check if an error already exists for this item and field
            const errorExists = fieldErrors.some(
              (error) =>
                error.externalIdForSorting === externalIdForSorting &&
                error.key === fieldName
            );

            // If the error doesn't exist, add it to the fieldErrors array
            if (!errorExists) {
              setFieldErrors((prevErrors) => [
                ...prevErrors,
                {
                  externalIdForSorting,
                  key: fieldName,
                  message: `The field ${fieldName} is required`,
                },
              ]);
            }
          }
        });
      });

      if (isValid) {
        console.log("all required fields are filled");
        const newContents = sortedContents.map((item) => {
          // Use object destructuring to remove externalIdForSorting
          const { externalIdForSorting, ...contentWithoutExternalId } = item;
          return contentWithoutExternalId;
        });
        contents = newContents;
        if (saveAsArray) {
          setContents?.(contents);
        } else {
          setContents?.(contents[0]);
        }
        setSeparateDirtyFlag(false);
        if (isSaved) {
          setIsSaved(false);
        }
        handleBackToEditFunction();
      }
    }
  };

  //for merging external and external functions
  // Create new instances of functionNames, functionIcons, and functionsArray
  const updatedFunctionNames = functionNames ? [...functionNames] : [];
  const updatedFunctionIcons = functionIcons ? [...functionIcons] : [];
  const updatedFunctionsArray = functionsArray ? [...functionsArray] : [];

  // Iterate through embeddedFunctions
  if (embeddedFunctions !== null && embeddedFunctions !== undefined) {
    embeddedFunctions.forEach((functionName) => {
      // Check if the function name already exists in updatedFunctionNames
      if (
        updatedFunctionNames &&
        !updatedFunctionNames.includes(functionName)
      ) {
        // If not, add the function name to updatedFunctionNames
        updatedFunctionNames.push(functionName);

        // Determine the corresponding icon based on the function name
        let functionIcon;
        if (functionName.toLowerCase() === "edit") {
          functionIcon = EditIcon;
        } else if (functionName.toLowerCase() === "add") {
          functionIcon = AddIcon;
        } else if (functionName.toLowerCase() === "delete") {
          functionIcon = DeleteOutlineIcon;
        }

        // Add the function icon to updatedFunctionIcons
        updatedFunctionIcons.push(functionIcon);

        // Determine the corresponding function for updatedFunctionsArray
        let functionForArray;
        if (functionName.toLowerCase() === "edit") {
          functionForArray = handleEmbeddedEditFunction;
        } else if (functionName.toLowerCase() === "add") {
          functionForArray = "handleBackToEditFunction";
        } else if (functionName.toLowerCase() === "delete") {
          functionForArray = "internalDeleteFunctionForEmbeddedFunctions";
        }

        // Add the function to updatedFunctionsArray
        updatedFunctionsArray.push(functionForArray);
      }
    });
  }

  //for pop up menu
  const [popupMenu, setPopupMenu] = useState(null);
  const handleOpenPopupMenu = (event) => {
    event.stopPropagation();
    setPopupMenu(event.currentTarget);
  };
  const handleClosePopupMenu = (event) => {
    event.stopPropagation();
    setPopupMenu(null);
  };
  const handleAction = (event, index) => {
    event.stopPropagation();
    updatedFunctionsArray[index]();
    handleClosePopupMenu(event);
  };
  //for IconButtons
  function handleIconClick(event) {
    event.stopPropagation();
    for (var i = 0; i < updatedFunctionsArray.length; i++) {
      updatedFunctionsArray[i]();
    }
  }
  //for pagination
  if (itemsPerPage === null || itemsPerPage === undefined) {
    itemsPerPage = 1;
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(
    contents !== undefined && contents !== null
      ? Math.ceil(contents.length / itemsPerPage)
      : 1
  );
  const handlePageChange = (event, value) => {
    console.log("value ", value);
    setCurrentPage(value);
  };
  //sorting the grid
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  //If IconColor Not sent
  // Calculate brightness of the background color
  const backgroundBrightness = getBrightness(headerColor);

  // Function to get the brightness of a color
  function getBrightness(color) {
    const hex = color.replace("#", "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  }

  // Determine the text color based on background brightness
  const textColor = backgroundBrightness > 128 ? "#000000" : "#FFFFFF";

  // Use IconColor if provided, otherwise use the calculated textColor
  const iconColorToUse = IconColor || textColor;

  // first render function to render the panel/accordion header
  const renderAccordionHeader = () => {
    return [
      <AccordionSummary
        key="summary"
        expandIcon={<ExpandMoreIcon style={{ color: iconColorToUse }} />}
        sx={{
          minHeight: 45,
          maxHeight: 45,
          backgroundColor: headerColor,
          "&:hover": {
            backgroundColor: hoverColor,
          },
          "&.Mui-expanded": {
            minHeight: 55,
            maxHeight: 55,
          },
          justifyContent: "space-between",
          paddingLeft: 0,
          width: "100%",
        }}
        onClick={handleAccordionClick}
      >
        <Grid container alignItems="center">
          <Grid item>
            {updatedFunctionsArray &&
            updatedFunctionsArray.length === 1 &&
            !externalEdit ? (
              <IconButton
                onClick={handleIconClick}
                sx={{
                  backgroundColor: headerColor,
                  mr: 1,
                  ml: 1,
                }}
              >
                {updatedFunctionIcons.map((Icon, index) => (
                  <Icon style={{ color: iconColorToUse }} key={index} />
                ))}
              </IconButton>
            ) : updatedFunctionsArray &&
              updatedFunctionsArray.length !== 0 &&
              !externalEdit ? (
              <>
                <IconButton
                  onClick={handleOpenPopupMenu}
                  sx={{
                    backgroundColor: headerColor,
                    mr: 1,
                    ml: 1,
                  }}
                >
                  <MoreVertIcon style={{ color: iconColorToUse }} />
                </IconButton>
                <Menu
                  anchorEl={popupMenu}
                  open={Boolean(popupMenu)}
                  onClose={handleClosePopupMenu}
                >
                  {updatedFunctionIcons[0] !== "None"
                    ? updatedFunctionIcons.map((Icon, index) => (
                        <MenuItem
                          onClick={(event) => handleAction(event, index)}
                          key={index}
                        >
                          {Icon === "NoIcon" ? (
                            <MoreVertIcon
                              sx={{
                                color: "transparent",
                                marginRight: "0.3rem",
                              }}
                            />
                          ) : (
                            <Icon sx={{ marginRight: "0.3rem" }} />
                          )}
                          {updatedFunctionNames[index]}
                        </MenuItem>
                      ))
                    : updatedFunctionNames.map((Name, index) => (
                        <MenuItem
                          onClick={(event) => handleAction(event, index)}
                          key={index}
                        >
                          {Name}
                        </MenuItem>
                      ))}
                </Menu>
              </>
            ) : externalEdit ? (
              <div>
                <IconButton
                  onClick={handleSaveClick}
                  sx={{
                    backgroundColor: headerColor,
                    ml: 1,
                  }}
                >
                  <SaveIcon style={{ color: iconColorToUse }} />
                </IconButton>
                <IconButton
                  onClick={handleCancelClick}
                  sx={{
                    backgroundColor: headerColor,
                    mr: 1,
                  }}
                >
                  <CancelIcon style={{ color: iconColorToUse }} />
                </IconButton>
              </div>
            ) : (
              <div style={{ margin: "1rem" }}></div>
            )}
          </Grid>
          <Grid item>
            <Typography style={{ color: iconColorToUse }}>
              {headerName}
              {separateDirtyFlag && (
                <GradeIcon sx={{ color: iconColorToUse, marginLeft: 1 }} />
                // " *"
              )}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>,
      <AccordionDetails key="details">
        {viewType === 0 ? (
          <RenderGrid
            contents={contents}
            captions={captions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            handleSort={handleSort}
            showPagination={showPagination}
            headerColor={headerColor}
            hoverColor={hoverColor}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            paginationColor={paginationColor}
            hiddenFields={hiddenFields}
            autoDetectType={autoDetectType}
            types={types}
            showSearchBar={viewOrEdit === 0 ? showSearchBar : false}
            IconColor={iconColorToUse}
            viewOrEdit={viewOrEdit}
            editableFields={editableFields}
            requiredFields={requiredFields}
            sortedContents={sortedContents}
            setSortedContents={setSortedContents}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            separateDirtyFlag={separateDirtyFlag}
            setSeparateDirtyFlag={setSeparateDirtyFlag}
          />
        ) : (
          <RenderCard
            contents={contents}
            captions={captions}
            setSortBy={setSortBy}
            showPagination={showPagination}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            paginationColor={paginationColor}
            hoverColor={hoverColor}
            bootstrapZones={bootstrapZones}
            mergeFields={mergeFields}
            mergeCounts={mergeCounts}
            hiddenFields={hiddenFields}
            cardViewType={cardViewType}
            autoDetectType={autoDetectType}
            types={types}
            showSearchBar={viewOrEdit === 0 ? showSearchBar : false}
            IconColor={iconColorToUse}
            viewOrEdit={viewOrEdit}
            editableFields={editableFields}
            requiredFields={requiredFields}
            sortedContents={sortedContents}
            setSortedContents={setSortedContents}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            separateDirtyFlag={separateDirtyFlag}
            setSeparateDirtyFlag={setSeparateDirtyFlag}
          />
        )}
      </AccordionDetails>,
    ];
  };

  //main return function to disable a whole panel/accordion
  return (
    <div style={{ marginBottom: "1rem", width: width ? width : "100%" }}>
      {disabled ? (
        <Accordion disabled TransitionProps={{ unmountOnExit: true }}>
          {renderAccordionHeader()}
        </Accordion>
      ) : (
        <Accordion
          expanded={expanded}
          TransitionProps={{ unmountOnExit: true }}
        >
          {renderAccordionHeader()}
        </Accordion>
      )}
    </div>
  );
}
export default SingleDropDown;
