import React from "react";
import { useState, useRef } from "react";
import { TextField, MenuItem, Box } from "@mui/material";
import { Chip, Autocomplete } from "@mui/material";
import CustomPopUpForm from "../CustomPopUpForm";
import theme from "../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { GetCreatePostResources, CreatePost } from "../../helper/APIHelper";
import CustomAlert from "../CustomAlert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";
import defview from "../../data/DefView.json";
import FileUploadComponent from "../FileUploadComponent";
import { v4 as uuid } from "uuid";

// for rich textbox
const useStyles = makeStyles((theme) => ({
  quillContainer: {
    "& .ql-container.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      padding: "8px",
    },
    "& .ql-container.ql-snow:focus-within": {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.primary.main, 0.1)}`,
    },
    "& .ql-toolbar.ql-snow": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      padding: "4px",
    },
    "& .ql-container.ql-snow .ql-editor": {
      padding: "0",
    },
    "& .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar button:focus":
      {
        color: theme.palette.primary.main,
      },
    "& .ql-snow .ql-toolbar button svg": {
      fill: "currentColor",
    },
  },
}));

function CreatePostForm({ open, onClose , handleEditContactInfoClick ,setError}) {
  // Rich Text Box ----
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];
  // ------------------

  const classes = useStyles(theme);

  const [state, setState] = React.useState({
    category: "",
    library: "",
    qLibID:"",
    title: "",
    link: "",
    mediaURL: "",
  });
  
  function clearData() {
    setState({
      category: "",
      library: "",
      qLibID:"",
      title: "",
      link: "",
      mediaURL: "",
    });

    setSubmit(false);
  }

  const [uploadedFiles, setUploadedFiles] = useState([]);
  //data to fill form
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetCreatePostResources(false);
      setData(temp);
      console.log("data ", temp);
    }
    FillData();
  }, []);

  //only show the library values in the library field
  function filterDataForLibraries() {
    if (data.Libraries !== undefined) {
      return data.Libraries.map((option) => (
        <MenuItem key={option.settingID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
    } else {
      return <MenuItem value=""></MenuItem>;
    }
  }

  //only show the category values in the category field
  function filterDataForTopicGroups() {
    if (data.TopicGroups !== undefined) {
      return data.TopicGroups.map((option) => (
        <MenuItem key={option.settingID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
    } else {
      return <MenuItem value=""></MenuItem>;
    }
  }

  //Tag autocomplete to categorize by letter
  let options = [];
  if (data.Tags !== undefined) {
    options = data.Tags.map((option) => {
      const firstLetter = option.resourceName[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
  }

  const [submit, setSubmit] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    requiredRichTextBox: "",
    requiredFile: "",
  });

  const [defView, setDefView] = React.useState("0");

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }
  const handleTagInput = (event, option) => {
    if (
      option !== null &&
      option.resourceName &&
      !tags.includes(option.resourceName)
    ) {
      setTags([...tags, option.resourceName]);
    }
  };

  function handleLibraryChange(event) {
    const value = event.target.value;
    const selectedData = data.Libraries.find(
      (item) => item.resourceName === value
    );

    const selectedDefView = Object.keys(defview.defView).find(
      (key) => defview.defView[key] === selectedData.resourceValue
    );
    setState({
      ...state,
      qLibID: selectedData.qLibID,
      [event.target.name]: selectedData.resourceName,
    });
    console.log("selectedDefView ", selectedDefView);
    setDefView(selectedDefView);
    // console.log("Event Value: ", value);
    // console.log("Selected Data: ", selectedData);
    // console.log("Selected DefView: ", selectedDefView);
  }

  function validations() {
    let validationErrors = {};

    if (defView === "Post" && richTextBox === "") {
      validationErrors.requiredRichTextBox = "This filed is required";
    }
    if (
      (defView === "PDF" || defView === "Video" || defView === "Image") &&
      uploadedFiles.length === 0
    ) {
      validationErrors.requiredFile = "File field is required";
    }
    setValidationErrors(validationErrors);
    console.log("validationErrors ", validationErrors);
    console.log("ret ", Object.keys(validationErrors).length === 0);
    return Object.keys(validationErrors).length === 0;
  }
  async function handleSubmit() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userPkeyID = userInfo.userPkeyID;
    const userName = userInfo.username;
    setSubmit(true);
    let feedPost = "";
    if (validations()) {
      if (state.category !== "" && state.qLibID !== "" && state.title !== "") {
        feedPost = {
          SubHeader: state.category,
          QLibID: state.qLibID,
          Header: state.title,
          CreateBy: userName,
          Description: richTextBox,
          userPkeyID: parseInt(userPkeyID),
        };
        if (defView === "Link") {
          feedPost.URL = state.link;
          feedPost.MediaURL = state.mediaURL;
        }
        if (tags.length !== 0) {
          feedPost.Tags= tags;
          // for (let i = 0; i < tags.length; i++) {
          //   if (i !== tags.length - 1) {
          //     if (tags[i] !== undefined) {
          //       feedPost.LibTags += tags[i] + ",";
          //     }
          //   } else {
          //     if (tags[i] !== undefined) {
          //       feedPost.LibTags += tags[i];
          //     }
          //   }
          // }
        }
        console.log("feedPost ", feedPost);

        const formData = new FormData();
        for (var x = 0; x < uploadedFiles.length; x++) {
          formData.append("files", uploadedFiles[x]);
        }
        formData.append("postData", JSON.stringify(feedPost));
        console.log("form File: ", formData);
        const response = await CreatePost(formData);

        console.log("response ", response);
        if (response.ok === true) {
          setError("");
          clearData();
          handleEditContactInfoClick();
          onClose();
        } else {
          if (response.statusText === "") {
            setError("Your Request Was Not Fulfilled");
          } else {
            setError(response.statusText);
          }
          handleEditContactInfoClick();
        }
      }
    }
  }

  const handleCancel = () => {
    //clearData();
    // handleClose();
    onClose();
  };

  const [tags, setTags] = useState([]);
  const [richTextBox, setRichTextBox] = useState("");

  const handleDeleteTag = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };



  return (
    <ThemeProvider theme={theme}>

      <CustomPopUpForm
        open={open}
        onClose={onClose}
        title="Post to Feed"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        showSubmit={true}
      >
        <div className="grid grid-cols-1 gap-4">
          {/* Form content goes here (use the UploadDocument form as a reference) */}

          <TextField
            required
            label="Enter Title"
            size="small"
            variant="outlined"
            name="title"
            value={state.title}
            onChange={handleChange}
            error={submit && !state.title}
          />
          <TextField
            required
            label="Select Library"
            select
            size="small"
            variant="outlined"
            name="library"
            value={state.library}
            onChange={handleLibraryChange}
            error={submit && !state.library}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          >
            {filterDataForLibraries()}
          </TextField>

          <TextField
            required
            label="Select Category"
            select
            size="small"
            variant="outlined"
            name="category"
            value={state.category}
            onChange={handleChange}
            error={submit && !state.category}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#7A7A7A",
              },
            }}
          >
            {filterDataForTopicGroups()}
          </TextField>

          <Box display="flex" flexDirection="row" alignItems="center">
            <Autocomplete
              size="small"
              options={options.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.resourceName}
              renderInput={(params) => <TextField {...params} label="Tags" />}
              onChange={handleTagInput}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
          >
            {tags.map((tag) => (
              <Chip
                key={uuid()}
                label={tag}
                onDelete={handleDeleteTag(tag)}
                style={{ margin: "4px" }}
              />
            ))}
          </Box>

          <ReactQuill
            className={classes.quillContainer}
            theme="snow"
            value={richTextBox}
            onChange={setRichTextBox}
            modules={modules}
            formats={formats}
            style={{ height: "150px", marginBottom: "32px" }}
          />
          {validationErrors.requiredRichTextBox !== "" && (
            <p style={{ fontSize: "14px", color: "red", marginBottom: "10px" }}>
              {validationErrors.requiredRichTextBox}
            </p>
          )}

          {defView === "Link" && (
            <>
              <TextField
                required
                label="Enter Link"
                size="small"
                variant="outlined"
                name="link"
                value={state.link}
                onChange={handleChange}
                error={submit && !state.link}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#7A7A7A",
                  },
                }}
              />

              <TextField
                label="Enter Thumbnail URL"
                size="small"
                variant="outlined"
                name="mediaURL"
                value={state.mediaURL}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#7A7A7A",
                  },
                }}
              />
            </>
          )}

          {(defView === "PDF" ||
            defView === "Video" ||
            defView === "Image") && (
            <>
              <FileUploadComponent
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
              {validationErrors.requiredFile && (
                <p
                  style={{
                    marginLeft: "10px",
                    marginTop: "-20px",
                    color: "red",
                  }}
                >
                  {validationErrors.requiredFile}
                </p>
              )}
            </>
          )}
        </div>
      </CustomPopUpForm>
    </ThemeProvider>
  );
}

export default CreatePostForm;
