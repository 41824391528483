import React from "react";
import { MenuItem, TextField, Select } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CustomCard from "../components/CustomCard";
import { useState } from "react";
import { GetSettingsByKey, GetMedicationName } from "../helper/APIHelper";

function Medications() {
  const [data, setData] = React.useState([]);
  const medicationResourcesKey = ["MedUnits", "Frequency", "MedRoute"];

  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(medicationResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  const [unit, setUnit] = useState("");
  const [frequency, setFrequency] = useState("");
  const [route, setRoute] = useState("");
  const [medicationStatus, setMedicationStatus] = useState("");
  const [medicationName, setMedicationName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startBiggerThanEnd, setStartBiggerThanEnd] = useState(false);
  const [valueSelected, setValueSelected] = useState(false);

  const handleUnitChange = (event) => {
    console.log("Unit Change " + event.target.value);
    setUnit(event.target.value);
  };
  const handleFrequencyChange = (event) => {
    console.log("Frequency Change " + event.target.value);
    setFrequency(event.target.value);
  };

  const handleRouteChange = (event) => {
    console.log("Route Change " + event.target.value);
    setRoute(event.target.value);
  };
  const handleMedicationStatusChange = (event) => {
    console.log("Medicaton Status Change " + event.target.value);
    setMedicationStatus(event.target.value);
  };
  // const handleMedicationNameChange = (event,option) => {
  //   console.log("Medication Name Change "+ option.resourceName);
  //   setMedicationName(option.ResourceName);
  // };
  React.useEffect(() => {
    const fetchData = async () => {
      let temp = await GetMedicationName(medicationName);
      setSearchResults(temp);
      console.log(temp);
    };
    fetchData();
  }, [medicationName]);
  const handleMedicationNameChange = (event) => {
    console.log("Medication Name Change " + event.target.value);
    setMedicationName(event.target.value);
    setValueSelected(false);
  };
  const handleStartDateChange = (event) => {
    console.log("StartDate Change " + event.target.value);
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    if (startDate > event.target.value) {
      console.log("Start date " + startDate);
      console.log("End date " + event.target.value);
      setStartBiggerThanEnd(true);
    } else {
      setStartBiggerThanEnd(false);
      console.log("EndDate Change " + event.target.value);
      setEndDate(event.target.value);
    }
  };
  const handleSelectName = (param) => {
    console.log("ONCLICK " + param);
    setMedicationName(param);
    setValueSelected(true);
  };

  const handleCancelClick = (event) => {
    event.preventDefault();
    console.log("Cancel");
  };
  const handleSubmitClick = () => {
    console.log("Submit");
    console.log(data[0]);
  };

  function filterDataByValue(filterValue) {
    console.log("Filter " + filterValue);
    return data
      .filter((option) => option.resourceKey === filterValue)
      .map((option) => (
        <MenuItem key={option.resourceID} value={option.resourceName}>
          {option.resourceName}
        </MenuItem>
      ));
  }

  // const options = data
  //   .filter((option) => option.resourceKey === "Drugs")
  //   .map((option) => {
  //     const firstLetter = option.resourceName[0].toUpperCase();
  //     return {
  //       firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
  //       ...option,
  //     };
  //   });

  return (
    <CustomCard
      title="Medications"
      cancelHandler={handleCancelClick}
      submitHandler={handleSubmitClick}
    >
      <div className="grid grid-cols-1 gap-4">
        {/* ResourceKey = Drugs */}
        {/* <Autocomplete
          size="small"
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.resourceName}
          renderInput={(params) => (
            <TextField {...params} label="Medication Name" />
          )}
          onChange={handleMedicationNameChange}
        /> */}
        <div>
          <TextField
            required
            label="Medication Name"
            variant="outlined"
            size="small"
            sx={{ width: "100%" }}
            value={medicationName}
            onChange={handleMedicationNameChange}
          />
          {!valueSelected && (
            <ul>
              {searchResults.map((option) => (
                <MenuItem
                  key={option.resourceID}
                  onClick={() => handleSelectName(option.resourceName)}
                >
                  {option.resourceName}
                </MenuItem>
              ))}
            </ul>
          )}
        </div>
        <TextField required label="Dosage" size="small" variant="outlined" />
        {/* ResourceKey =  MedUnits*/}
        <TextField
          required
          label="Unit"
          select
          size="small"
          variant="outlined"
          value={unit}
          onChange={handleUnitChange}
          sx={{ textAlign: "start" }}
        >
          {filterDataByValue("MedUnits")}
        </TextField>
        <TextField
          required
          label="How Many Times"
          size="small"
          variant="outlined"
        />
        {/* ResourceKey = Frequency */}
        <TextField
          required
          label="How Often"
          select
          size="small"
          variant="outlined"
          value={frequency}
          onChange={handleFrequencyChange}
          sx={{ textAlign: "start" }}
        >
          {filterDataByValue("Frequency")}
        </TextField>
        {/* ResourceKey =  MedRoute*/}
        <TextField
          required
          label="Route"
          select
          size="small"
          variant="outlined"
          value={route}
          onChange={handleRouteChange}
          sx={{ textAlign: "start" }}
        >
          {filterDataByValue("MedRoute")}
        </TextField>
        <TextField label="Instructions" size="small" variant="outlined" />
        <TextField
          required
          label="Treatment Dates From"
          size="small"
          variant="outlined"
          type="Date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={handleStartDateChange}
        />
        <TextField
          required
          label="Treatment Dates To"
          size="small"
          variant="outlined"
          type="Date"
          error={startBiggerThanEnd}
          helperText={
            startBiggerThanEnd ? "Treatment Ends Before It Starts" : ""
          }
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={handleEndDateChange}
        />
        <TextField label="Comments" size="small" variant="outlined" multiline />
        {/* ResourceKey =  Active || Not Active*/}
        <TextField
          required
          label="Medication Status"
          select
          size="small"
          variant="outlined"
          value={medicationStatus}
          onChange={handleMedicationStatusChange}
          sx={{ textAlign: "start" }}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Not Active">Not Active</MenuItem>
        </TextField>
        {/* ResourceKey = "" */}
        <TextField
          label="Select Provider"
          select
          size="small"
          variant="outlined"
        />
      </div>
    </CustomCard>
  );
}

export default Medications;
