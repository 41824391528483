import { React, useState, createContext, useContext } from "react";
const FilterContext = createContext();

function FilterProvider({ children }) {
  const filterValue = {
    index: 0,
    count: 30,
    topic: "",
    library: "",
    libraryCaption: "",
    tag: "",
    search: "",
    memberID: "",
    byMemberID: "",
  };
  let [filter, setFilter] = useState(filterValue);

  const clearFilterValue = () => {
    filter.topic = "";
    filter.library = "";
    filter.libraryCaption = "";
    filter.tag = "";
    filter.search = "";
  };

  const setTag = (tag) => {
    let filterValue = {
      index: 0,
      count: 30,
      topic: "",
      library: "",
      libraryCaption: "",
      tag: tag,
      search: "",
      memberID: "",
      byMemberID: "",
    };
    setFilter(filterValue);
  };

  let value = { filter, setFilter, setTag, clearFilterValue };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}
function GetFilterContext() {
  return useContext(FilterContext);
}

export { FilterProvider, GetFilterContext };
