import React, { useState, useEffect } from "react";
import AdBanner from "./AdBanner";

function AdBannerList({ adsList }) {
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const currentAd = adsList[currentAdIndex];

  console.log("adsList: ", adsList);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % adsList.length);
    }, 20000); // 20 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AdBanner
      image={currentAd.image}
      link={currentAd.link}
      tooltip={currentAd.tooltip}
      imageWidth="150px"
    />
  );
}
export default AdBannerList;
