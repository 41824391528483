import {
  GetRequestHeader,
  PostRequestHeader,
  GetAPI,
  GetSessionUser,
  IsLogin,
  GetDefaultUserID,
} from "../helper/APIHelper";

async function GetRecordFolders(memberID, requestorID) {
  //console.log("url: ", url);
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.getMedicalFolders +
      "?memberID=" +
      memberID +
      "&requestorID=" +
      requestorID,
    GetRequestHeader()
  );
  const recordFolders = await response.json();
  return recordFolders;
}

async function GetMedicalRecords(patientID, isInactive) {
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.getMedical +
      "?memberID=" +
      patientID +
      "&isInactive=" +
      isInactive,
    GetRequestHeader()
  );
  const records = await response.json();
  return records;
}

async function GetAllergyRecords(patientID, isInactive) {
  console.log("patientID ", patientID);
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.getAllergyRecords +
      "?memberID=" +
      patientID +
      "&isInactive=" +
      isInactive,
    GetRequestHeader()
  );
  const records = await response.json();
  console.log("records ", records);
  return records;
}

async function GetFamilyHistory(patientID, isInactive) {
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.getFamilyHistory +
      "?memberID=" +
      patientID +
      "&isInactive=" +
      isInactive,
    GetRequestHeader()
  );
  const records = await response.json();
  return records;
}

async function GetDocuments(username, folderName, isInactive) {
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.getDocuments +
      "?username=" +
      username +
      "&folderName=" +
      folderName +
      "&isInactive=" +
      isInactive,
    GetRequestHeader()
  );
  const documents = await response.json();
  return documents;
}

async function PostMedicalRecord(medicalRecord) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(medicalRecord),
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.postMedicationRecord,
    request
  );
  console.log("Post medical Response: ", response);
  return response;
}

async function PostAllergyRecord(allergyRecord) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(allergyRecord),
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.postAllergyRecord,
    request
  );
  console.log("Post Allergy Response: ", response);
  return response;
}

async function PostHistoryRecord(historyRecord) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(historyRecord),
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.postHistoryRecord,
    request
  );
  console.log("Post History Response: ", response);
  return response;
}

async function PostDocuments(importPatientDocument) {
  const requestOptions = {
    method: "POST",
    headers: {
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: importPatientDocument,
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.postDocuments,
    requestOptions
  );
  console.log("Post History Response: ", response);
  return response;
}

async function ArchiveRecord(recordIDs) {
  const request = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(recordIDs),
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.ArchiveRecords,
    request
  );
  console.log("Archive Record Response: ", response);
  return response;
}
async function UnarchiveRecord(recordIDs) {
  const request = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(recordIDs),
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.UnarchiveRecords,
    request
  );
  console.log("Unarchive Record Response: ", response);
  return response;
}

async function GetPatient(patientLookupRecord) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(patientLookupRecord),
  };
  let response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.GetPatients,
    request
  );
  let temp = await response.json();
  return temp;
}
async function GrantFolderAccess(shareRecord) {
  console.log("GetSessionUser().memberID ", GetSessionUser().memberID);
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(shareRecord),
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.grantFolderAccess,
    request
  );
  console.log("Grant folder access: ", response);
  return response;
}

async function RevokeFolderAccess(shareRecord) {
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(shareRecord),
  };
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.revokeFolderAccess,
    request
  );
  console.log("Grant folder access: ", response);
  return response;
}

async function GetRecentEncoutersbyProvider(providerID, patientID) {
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.GetRecentEncoutersbyProvider +
      "?providerID=" +
      providerID +
      "&patientID=" +
      patientID,
    GetRequestHeader()
  );
  const encounters = await response.json();
  return encounters;
}

async function GetEncounterHeader(encID) {
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.GetEncounterHeader +
      "?encID=" +
      encID,
    GetRequestHeader()
  );
  const encounterHeader = await response.json();
  return encounterHeader;
}
async function GetEncounterDetails(encID) {
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.GetEncounterDetails +
      "?encID=" +
      encID,
    GetRequestHeader()
  );
  const encounterDetails = await response.json();
  return encounterDetails;
}
async function GetEncounterUI(encID) {
  const response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.GetEncounterUI + "?encID=" + encID,
    GetRequestHeader()
  );
  const encounterUI = await response.json();
  return encounterUI;
}

async function SearchForPatients(patientLookupRecord) {
  console.log("patientLookupRecord ", patientLookupRecord)
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
      MemberID: IsLogin() ? GetSessionUser().memberID : GetDefaultUserID(),
    },
    mode: "cors",
    body: JSON.stringify(patientLookupRecord),
  };
  let response = await fetch(
    GetAPI().baseURL + GetAPI().recordPath.SearchForPatients,
    request
  );
  let temp = await response.json();
  console.log("temp ", temp)
  return temp;
}

async function GetEncountersForAPatient(patientID , providerID) {
  const response = await fetch(
    GetAPI().baseURL +
      GetAPI().recordPath.GetEncountersForAPatient +
      "?patientID=" +
      patientID+
      "&providerID=" +
      providerID,
    GetRequestHeader()
  );
  const patientsEncounters = await response.json();
  return patientsEncounters;
}

export {
  GetRecordFolders,
  GetAllergyRecords,
  GetMedicalRecords,
  GetFamilyHistory,
  GetDocuments,
  PostMedicalRecord,
  PostAllergyRecord,
  PostHistoryRecord,
  PostDocuments,
  GrantFolderAccess,
  RevokeFolderAccess,
  ArchiveRecord,
  UnarchiveRecord,
  GetPatient,
  GetRecentEncoutersbyProvider,
  GetEncounterHeader,
  GetEncounterDetails,
  GetEncounterUI,
  SearchForPatients,
  GetEncountersForAPatient
};
