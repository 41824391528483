import React, { useState, useEffect } from "react";
import theme from "../../Theme";
import ImageCarousel from "../../components/LandingPage/ImageCarousel";
import MultipleChipComponent from "../../components/LandingPage/MultipleChipComponent";
import CircleComponent from "../../components/LandingPage/CircleComponent";
import CardComponent from "../../components/LandingPage/CardComponent";
import MiniCardComponent from "../../components/LandingPage/MiniCardComponent";
import { v4 as uuid } from "uuid";
import ProviderComponent from "../../components/LandingPage/ProviderComponent";
import LandingFooter from "../../components/LandingPage/LandingFooter";

import ArticleCard from "../../components/LandingPage/ArticleCard";

function DesktopLandingPage({
  landingSectionsData,
  topicGroupsData,
  tags,
  FeedFilterChildState,
  handleFilterStateChange,
  lightBgColor,
  darkBgColor
}) {
  return (
    <div style={{backgroundColor: lightBgColor}}>
      <div style={{ backgroundColor: lightBgColor, paddingBottom: "50px" }}>
        <div
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingBottom: "50px",
          }}
        >
          <h1
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: theme.palette.primary.main,
              paddingBottom: "40px",
            }}
          >
            Living Healthy
          </h1>
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          >
            <ImageCarousel
              activeState={FeedFilterChildState}
              onFilterStateChange={handleFilterStateChange}
              topicGroupsData={topicGroupsData}
            />
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <MultipleChipComponent filters={tags} />
          </div>
        </div>
      </div>
      {landingSectionsData
        .sort((a, b) => a.sortId - b.sortId)
        .map((section, index) => (
          <div key={uuid()}>
            <div
              key={uuid()}
              style={{
                backgroundColor: darkBgColor,
                // marginTop: "30px",
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {section.defView === 8 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <CircleComponent
                      key={uuid()}
                      src={item.mediaURL}
                      alt={item.header}
                      size={250}
                      position={"left"}
                      title={item.header}
                      text={item.description}
                      textSize={20}
                      itemID={item.feedID}
                    />
                  ))}
            </div>

            {section.defView === 9 && (
              <div key={uuid()}>
                <h2
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "28px",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: "20px",
                    marginRight: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  {section.title}
                </h2>
                <hr
                  style={{
                    borderColor: "#B6B8BB",
                    borderWidth: "1px",
                    marginBottom: "20px",
                  }}
                />
              </div>
            )}
            <div
              key={uuid()}
              style={{
                backgroundColor: lightBgColor,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {section.defView === 9 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <CardComponent
                      key={uuid()}
                      image={item.mediaURL}
                      size={700}
                      position={"left"}
                      title={item.header}
                      text={item.description}
                      textSize={20}
                      itemID={item.feedID}
                      article={item.link}
                    />
                  ))}
            </div>

            {section.defView === 10 && (
              <div key={uuid()}>
                <h2
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "28px",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: "20px",
                    marginRight: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  {section.title}
                </h2>
                <hr
                  style={{
                    borderColor: "#B6B8BB",
                    borderWidth: "1px",
                    marginBottom: "20px",
                  }}
                />
              </div>
            )}

            <div
              key={uuid()}
              style={{
                backgroundColor: lightBgColor,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {section.defView === 10 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <>
                      <MiniCardComponent
                        key={uuid()}
                        image={item.mediaURL}
                        size={350}
                        position={"left"}
                        title={item.header}
                        textSize={17}
                        isMobile={false}
                        itemID={item.feedID}
                      />
                    </>
                  ))}
            </div>

            <div
              key={uuid()}
              style={{
                backgroundColor: lightBgColor,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {section.defView === 10 &&
                section.items
                  .sort((a, b) => a.dSortID - b.dSortID)
                  .map((item, itemIndex) => (
                    <ArticleCard
                      feedID={item.feedID}
                      image={item.mediaURL}
                      title={item.header}
                      description={item.description}
                      size={300}
                    />
                  ))}
            </div>

            {section.defView === 11 && (
              <div key={uuid()}>
                <h2
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "28px",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: "20px",
                    marginRight: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  {section.title}
                </h2>
                <hr
                  style={{
                    borderColor: "#B6B8BB",
                    borderWidth: "1px",
                    marginBottom: "20px",
                  }}
                />
              </div>
            )}

            {section.defView === 11 && (
              <ProviderComponent items={section.items} />
            )}
          </div>
        ))}
      <div style={{ backgroundColor: darkBgColor, paddingTop: "20px" }}>
        <LandingFooter />
      </div>
    </div>
  );
}
export default DesktopLandingPage;
