import React, { Component } from "react";
import { Typography } from "@mui/material";
import '../App.css'

class FooterComponent extends Component {

    render() {
        return (
            <div>
                <footer className="footer">
                    <span className="text-muted"> Copyright © 2023 |  HealthBloQ™ | <br/> Powered by Agiline Software, LLC</span>
                </footer>
            </div>
        )
    }
}
export default FooterComponent