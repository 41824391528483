import { faker } from '@faker-js/faker';

const generateFakeComments = (count = 10, level = 0, maxLevel = 3) => {
  let comments = [];

  for (let i = 0; i < count; i++) {
    const comment = {
      id: faker.datatype.uuid(),
      author: faker.name.fullName(),
      username: faker.internet.userName(),
      content: faker.lorem.sentences(),
      level: level,
      date: `2023-03-${(i % 28) + 1}`,
      profileLink: `https://example.com/profiles/friend${i + 1}`,
      profileImage: faker.image.nature(300, 300, true),
    };

    if (level < maxLevel) {
      comment.children = generateFakeComments(
        Math.floor(Math.random() * 4),
        level + 1,
        maxLevel
      );
    } else {
      comment.children = [];
    }

    comments.push(comment);
  }

  return comments;
};

export default generateFakeComments;
