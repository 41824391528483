import { React, useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import TextPost from "./TextPost";
import FeedFilter from "./FeedFilter";
import { GetFeedData, GetFeedDataFilter } from "../../helper/APIHelper";
import { FilterProvider, GetFilterContext } from "./FilterProvider";
import Spinner from "../../assets/Spinner.css";
import { filter, get } from "lodash";

function Feed(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMoreData, setLoadingMoreData] = useState(true);

  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [noDataToShow, setNoDataToShow] = useState(false);

  const [FeedFilterChildState, setFeedFilterChildState] = useState(null); //to turn off filter style when tag is clicked
  const [FeedFilterTagName, setFeedFilterTagName] = useState(null); //to pass the tag name to the feed filter for the chip component

  const ITEMS_PER_PAGE = 30;
  const userInfo = localStorage.getItem("userInfo");
  const DEFAULT_USERID = "-1";
  const defaultFilter = {
    index: 0,
    count: ITEMS_PER_PAGE,
    topic: "",
    library: "",
    tag: "",
    search: "",
    memberID: "",
    byMemberID: "",
  };
  let filterContext = GetFilterContext();
  const [filterObj, setFilterObj] = useState(
    filterContext.filter !== undefined && filterContext.filter !== null
      ? filterContext.filter
      : ""
  ); // Filter for the Feed base on categories, libraries

  useEffect(() => {
    // Get the current user's posts and reset the filters
    if (props.onProfile === true) {
      filterContext.clearFilterValue();
      filterObj.byMemberID = props.memberID;
      if (filterObj !== undefined && filterObj !== null) {
        setPage(0);
        filterObj.index = 0;
        getAPIData(filterObj, "USE EFFECT IF");
      }
    }
    // if not signed in
    else {
      //getAPIData(defaultFilter);
      filterContext.filter.byMemberID = "";
      setFilterObj(filterContext.filter);
      if (filterContext.filter !== undefined && filterContext.filter !== null) {
        getAPIData(filterContext.filter, "USE EFFECT ELSE");
      }
    }
    //getAPIData(filterObj);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleGlobalTagClicked();
  }, [filterContext.filter]);

  const loadMoreData = () => {
    if (page > 0) {
      setLoadingMoreData(true);
      //getAPIData();
      //getAPIData(filterObj);
      if (props.onProfile === true) {
        filterObj.byMemberID = props.memberID;
      }
      if (filterObj !== undefined && filterObj !== null) {
        getAPIData(filterObj, "LOAD MORE DATA");
      }
    }
  };

  // Used for both getting unfiltered and filter post:
  // Unfiltered data uses default filter object with no filters
  // Filtered data uses filter object with filter pass into it.
  // Use to keep requesting for data as the user scrolls (infinite scrolling);
  const getAPIData = async (filterObj, caller) => {
    const feedData = await GetFeedDataFilter(filterObj);
    console.log("feedData ", feedData);
    if (feedData.length > 0 && feedData[0] !== null) {
      setPosts([...posts, ...feedData]);
      if (feedData.length === ITEMS_PER_PAGE) {
        setPage(page + 1);
        setHasNextPage(true);
        filterObj.index = page;
      }
      setNoDataToShow(false);
    } else {
      setHasNextPage(false);
      setNoDataToShow(true);
    }
    setLoadingMoreData(false);
  };

  //Initialized the feed with data that is filtered
  const getAPIDataWithFilter = async (filters) => {
    filters.index = 0;
    const feedData = await GetFeedDataFilter(filters);
    console.log("feed data ", feedData);
    if (feedData.length > 0 && feedData[0] !== null) {
      setPosts(feedData);
      if (feedData.length === ITEMS_PER_PAGE) {
        setPage(page + 1);
        setHasNextPage(true);
        filters.index = page;
      }
      setNoDataToShow(false);
    } else {
      setHasNextPage(false);
      setNoDataToShow(true);
    }
    setLoadingMoreData(false);
  };

  function handleFilterSelects(filters) {
    setLoadingMoreData(true);
    setPosts([]); // Clear the feed
    getAPIDataWithFilter(filters);
    setFilterObj(filters);
  }

  function handleTagClicked(tag) {
    filterContext.clearFilterValue();
    filterContext.filter.tag = tag;
    if (props.onProfile !== true) {
      getAPIDataWithFilter(filterContext.filter);
    } else {
      navigate("/");
    }

    setFeedFilterChildState(null);
    setFeedFilterTagName(tag);
  }

  function handleGlobalTagClicked() {
    getAPIDataWithFilter(filterContext.filter);
    setFeedFilterChildState(null);
    setFeedFilterTagName(filterContext.filter.tag);
  }

  const handleFilterStateChange = (state) => {
    setFeedFilterChildState(state);
  };

  function emptyFeedMessage() {
    //Return message based on internet access
    if (navigator.onLine) {
      return "No posts match your search criteria.";
    } else {
      return "No internet connection.";
    }
  }

  console.log("posts.length ", posts.length);

  return (
    <div style={{ margin: props.margin, marginTop: props.marginTop }}>
      {!props.onProfile && !isLoading ? (
        <FeedFilter
          activeState={FeedFilterChildState}
          onFilterStateChange={handleFilterStateChange}
          filterTagName={FeedFilterTagName}
          filters={handleFilterSelects}
        />
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className="spinner m-auto"></div>
      ) : (
        <>
          {posts.length > 0 ? (
            Object.keys(posts).map((key, index) => (
              <TextPost
                key={index}
                onProfile={props.onProfile}
                onFeed={true}
                // tags={["tag1", "tag2", "tag3"]}
                memberID={posts[key].memberID}
                tags={posts[key].tags}
                onTagClick={handleTagClicked}
                FeedID={posts[key].feedID}
                UserPKeyID={posts[key].userPKeyID}
                ContactID={posts[key].contactID}
                ProjectID={posts[key].projectID}
                RoleID={posts[key].roleID}
                SourceName={posts[key].sourceName}
                Owner={posts[key].owner}
                DefView={posts[key].defViewID}
                Title={posts[key].title}
                Description={posts[key].description}
                PubDate={posts[key].pubdate}
                TimeElapsed={posts[key].timeElapsed}
                MediaURL={posts[key].media}
                Link={posts[key].link}
                Image={posts[key].image}
                Category={posts[key].category}
                Tags={posts[key].tags}
                CreateDate={posts[key].createDate}
                profilePicture={posts[key].profilePicture}
              />
            ))
          ) : noDataToShow && !loadingMoreData ? (
            <p className="m-auto text-center mt-5">
              {`${emptyFeedMessage()}`}
            </p>
          ) : (
            <></>
          )}
        </>
      )}
      {isLoading ? (
        <p></p>
      ) : (
        <>
          {hasNextPage && (
            <Waypoint onEnter={loadMoreData}>
              {loadingMoreData ? (
                <div className="pb-8">
                  <div className="spinner m-auto mt-5"></div>
                </div>
              ) : (
                <div className="pb-16"></div>
                // posts.length == 0 ? <p className="m-auto text-center">No data available.</p> : <></>
              )}
            </Waypoint>
          )}
        </>
      )}
    </div>
  );
}

export default Feed;
