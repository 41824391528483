import React, { useState } from "react";

import theme from "../../Theme";
import { ThemeProvider } from "@mui/material/styles";
import {
  Stepper,
  StepLabel,
  Step,
  makeStyles,
} from "@material-ui/core";
import FirstStep from "./first-step";
import SecondStep from "./second-step";
import ThirdStep from "./third-step";
import { GetSettingsByKey , PostEvent} from "../../helper/APIHelper";
import CustomStepperPopUp from "../CustomStepperPopUp";

const useStyles = makeStyles({
  stepper: {
    "& .MuiStepIcon-root": {
      color: "#f2f2f2", // or any color you want for inactive steps
    },
    // "& .MuiStepLabel-label": {
    //   color: theme.palette.primary.main, // or any color you want for inactive number
    // },
    "& .MuiStepIcon-completed": {
      color: theme.palette.primary.main, // or any color you want
    },
    "& .MuiStepIcon-active": {
      color: theme.palette.primary.main, // or any color you want
    },
    // backgroundColor: "red"
  },
  completed: {},
  active: {},
  inactive: {
    "& .MuiStepIcon-text": {
      fill: theme.palette.primary.main, // or any color you want for inactive number
    },
  },
});

const eventResourcesKey = ["Visibility", "Pricing"];
function NewEvent({open, onClose , start , handleEditContactInfoClick ,error , setError}) {
  const classes = useStyles();

  const steps = ["Credentials", "Contact Info", "Address"];
  const [activeStep, setActiveStep] = useState(0);
  const [firstRecord, setFirstRecord] = useState("");
  const [secondRecord, setSecondRecord] = useState({StartDate: start});
  const [thirdRecord, setThirdRecord] = useState("");

  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    async function FillData() {
      let temp = await GetSettingsByKey(eventResourcesKey);
      setData(temp);
    }
    FillData();
  }, []);

  function handleNext(record) {
    console.log("handle next");
    if (activeStep === 0) {
      setFirstRecord(record);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1) {
      setSecondRecord(record);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    console.log("record ", record);
  }

  function handleBack() {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    if(activeStep === 0){
      onClose();
    }
  }

  async function handleSubmit(record) {
    setThirdRecord(record);
    const { PricingName, VisibilityName, ...rest } = record;
    const eventRecord = { ...firstRecord, ...secondRecord, ...rest };
    console.log("Event Record ", eventRecord);
    let response = "";
    let message = "";
    response = await PostEvent(eventRecord);
    message = await response.text();
    console.log("Event response: ", message);
    console.log("response ", response);
    if (response.ok === true) {
      setError("");
      handleEditContactInfoClick();
      onClose();
      window.location.reload();
    } else {
      setError(message);
      handleEditContactInfoClick();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomStepperPopUp title="Event" open={open} onClose={onClose}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          classes={{ root: classes.stepper }}
          style={{marginTop:'-10px' ,marginBottom:'-11px'}}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                sx={{ display: "flex", flexDirection: "column" }}
                classes={{
                  root:
                    activeStep === index ? classes.active : classes.inactive,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <FirstStep
            handleNext={handleNext}
            handleBack={handleBack}
            record={firstRecord}
          />
        )}
        {activeStep === 1 && (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            record={secondRecord}
          />
        )}
        {activeStep === 2 && (
          <ThirdStep
            handleSubmit={handleSubmit}
            handleBack={handleBack}
            record={thirdRecord}
            data={data}
          />
        )}
      </CustomStepperPopUp>
    </ThemeProvider>
  );
}

export default NewEvent;
