import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  IconButton,
} from "@mui/material";
import { PersonAdd, Share } from "@mui/icons-material";
import {
  UpdateConnection,
  CreateConnectionRequest,
} from "../../../helper/APIHelper";
import ConnectionStatus from "../../../data/ConnectionStatus.json";
import DisconectPopupForm from "../../FormComponents/DisconnectPopupForm";
import ConnectionCardProfileInfo from "./ConnectionCardProfileInfo";

const ConnectionCard = ({ connection, type }) => {
  // const [isConnected, setIsConnected] = useState(
  //   connection.conDetStatus === ConnectionStatus.status.Connected ? true : false
  // );
  // const [isConnected, setIsConnected] = useState(
  //   IsPending(connection.conDetStatus)
  // );
  const [isConnected, setIsConnected] = useState(
    IsStatus(ConnectionStatus.status.Connected, connection.conDetStatus)
  );

  const [isPending, setIsPending] = useState(
    IsStatus(ConnectionStatus.status.Pending, connection.conDetStatus)
  );
  const [isReceived, setIsReceived] = useState(
    IsStatus(ConnectionStatus.status.Sent, connection.conDetStatus)
  );

  const [requestStatus, setRequestStatus] = useState("");

  useEffect(() => {
    setIsConnected(
      IsStatus(ConnectionStatus.status.Connected, connection.conDetStatus)
    );
    setIsPending(
      IsStatus(ConnectionStatus.status.Pending, connection.conDetStatus)
    );
    setIsReceived(
      IsStatus(ConnectionStatus.status.Sent, connection.conDetStatus)
    );
  }, [connection]);

  function IsConnected(connectionStatus) {
    if (connectionStatus !== null) {
      connectionStatus = connectionStatus.toLowerCase().trim();
      if (connectionStatus === ConnectionStatus.status.Connected) {
        return true;
      }
    }
    return false;
  }

  function IsPending(connectionStatus) {
    if (connectionStatus !== null) {
      connectionStatus = connectionStatus.toLowerCase().trim();
      if (
        connectionStatus === ConnectionStatus.status.Pending ||
        connectionStatus === ConnectionStatus.status.Sent
      ) {
        return true;
      }
    }
    return false;
  }

  // Parameters: connectionStatus: the enum string that you are comparing to.
  // currentStatus: the status that you want to compare to the enum
  function IsStatus(connectionStatus, currentStatus) {
    if (currentStatus !== undefined && currentStatus !== null) {
      currentStatus = currentStatus.toLowerCase().trim();
      if (connectionStatus === currentStatus) {
        return true;
      }
    }
    return false;
  }

  const [openPopup, setOpenPopup] = React.useState(false); // Flag for popup form

  function handleClosePopup() {
    setOpenPopup(false);
    console.log("openPopup ", openPopup);
  }

  const handleConnectClick = async () => {
    setOpenPopup(!openPopup);
    console.log("isPending: ", isPending);
    if (!isConnected && !isPending) {
      setRequestStatus("sent");
      //Call an API to create a friend request.
      const response = await CreateConnectionRequest(connection.memberID);
    } else if (isPending) {
      const response = await UpdateConnection(
        connection.conDetID,
        ConnectionStatus.status.Canceled
      );
    }
  };

  const handleAcceptClick = () => {
    setRequestStatus("accepted");
    let response = AcceptFriendRequest();
    console.log("response for accepcting request: ", response);
  };

  const handleDenyClick = () => {
    setRequestStatus("denied");
    let response = DeclineFriendRequest();
    console.log("response for declining: " + response);
  };

  const handleUnsendClick = () => {
    setRequestStatus("unsent");
    let response = CancelFriendRequest();
    console.log("response for cancelling: ", response);
  };

  const AcceptFriendRequest = async () => {
    console.log("conDetID: ", connection.conDetID);
    console.log("con Status: ", connection.conDetStatus);
    let response = await UpdateConnection(
      connection.conDetID,
      ConnectionStatus.status.Connected
    );
    return response;
  };

  const DeclineFriendRequest = async () => {
    console.log("conDetID: ", connection.conDetID);
    console.log("con Status: ", connection.conDetStatus);
    let response = await UpdateConnection(
      connection.conDetID,
      ConnectionStatus.status.Decline
    );
    return response;
  };

  const CancelFriendRequest = async () => {
    console.log("conDetID: ", connection.conDetID);
    console.log("con Status: ", connection.conDetStatus);
    let response = await UpdateConnection(
      connection.conDetID,
      ConnectionStatus.status.Canceled
    );
    return response;
  };

  const renderStatusMessage = () => {
    if (requestStatus === "accepted") {
      return (
        <Typography sx={{ color: "success.main", paddingLeft: "10px" }}>
          Request has been accepted
        </Typography>
      );
    }
    if (requestStatus === "denied") {
      return (
        <Typography sx={{ color: "error.main", paddingLeft: "10px" }}>
          Request has been denied
        </Typography>
      );
    }
    if (requestStatus === "unsent") {
      return (
        <Typography sx={{ color: "error.main", paddingLeft: "10px" }}>
          Request has been unsent
        </Typography>
      );
    }
    if (requestStatus === "sent") {
      return (
        <Typography sx={{ color: "success.main", paddingLeft: "10px" }}>
          Request has been sent
        </Typography>
      );
    }
    if (requestStatus === "disconnect") {
      return (
        <Typography sx={{ color: "error.main", paddingLeft: "10px" }}>
          Disconnected
        </Typography>
      );
    }
    return null;
  };

  const friendRequestButton = () => {
    if (!requestStatus) {
      if (isReceived) {
        return (
          <Box
            sx={{
              display: "inline-flex",
              gap: "0.5rem",
              marginRight: "0.1rem",
            }}
          >
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={handleAcceptClick}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleDenyClick}
            >
              Deny
            </Button>
          </Box>
        );
      } else {
        return (
          <Button
            variant={isConnected ? "outlined" : "contained"}
            color={isPending ? "info" : "secondary"}
            startIcon={<PersonAdd />}
            onClick={handleConnectClick}
          >
            {isConnected ? "Connected" : isPending ? "Pending" : "Connect"}
            {openPopup && isConnected && (
              <DisconectPopupForm
                open={openPopup}
                onClose={handleClosePopup}
                selectedContact={{ conDetID: connection.conDetID }}
                setRequestStatus={setRequestStatus}
              />
            )}
          </Button>
        );
      }
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        height: "100px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
          <ConnectionCardProfileInfo connection={connection} />
          {renderStatusMessage()}
          {type === "connections" && friendRequestButton()}
          {type === "receivedRequests" && !requestStatus && (
            <Box
              sx={{
                display: "inline-flex",
                gap: "0.5rem",
                marginRight: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={handleAcceptClick}
              >
                Accept
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDenyClick}
              >
                Deny
              </Button>
            </Box>
          )}
          {type === "sentRequests" && !requestStatus && (
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ marginRight: "16px" }}
              onClick={handleUnsendClick}
            >
              Cancel
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ConnectionCard;
