import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { Alert } from "react-bootstrap";
import bgImage from "../../assets/globeStethoscope.png";

import theme from "../../Theme";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth } from "../../components/AuthProvider";
import { Stepper, StepLabel, Step, makeStyles } from "@material-ui/core";
import FirstStep from "./first-step";
import SecondStep from "./second-step";
import ThirdStep from "./third-step";
import CustomCard from "../../components/CustomCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { RegisterPatient, RegisterProvider } from "../../helper/APIHelper";
import CustomAlert from "../../components/CustomAlert";
import ZeroStep from "./zero-step";
import { stateToAbbreviation } from "../../helper/StateUtils";

const myStyle = {
  backgroundImage: `url(${bgImage})`,
  height: "100vh",
  paddingTop: "50px",
  fontSize: "50px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};
const useStyles = makeStyles({
  stepper: {
    "& .MuiStepIcon-root": {
      color: "#f2f2f2", // or any color you want for inactive steps
    },
    // "& .MuiStepLabel-label": {
    //   color: theme.palette.primary.main, // or any color you want for inactive number
    // },
    "& .MuiStepIcon-completed": {
      color: theme.palette.primary.main, // or any color you want
    },
    "& .MuiStepIcon-active": {
      color: theme.palette.primary.main, // or any color you want
    },
    // backgroundColor: "red"
  },
  completed: {},
  active: {},
  inactive: {
    "& .MuiStepIcon-text": {
      fill: theme.palette.primary.main, // or any color you want for inactive number
    },
  },
});

function SignUp() {
  const [isProvider, setIsProvider] = useState(false);
  // const loc = useLocation();

  // React.useEffect(() => {
  //   if (loc.state && loc.state.isProvider) {
  //     setIsProvider(loc.state.isProvider);
  //   }
  // }, [loc.state]);

  // console.log(isProvider);

  const icons = {
    1: <AccountCircleIcon />,
    2: <SentimentSatisfiedAltIcon />,
    3: <LocationOnIcon />,
  };

  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const usernameInput = useRef();
  const passwordInput = useRef();
  let auth = useAuth();

  const steps = ["Register As", "Credentials", "Contact Info", "Address"];
  const [activeStep, setActiveStep] = useState(0);
  const [firstRecord, setFirstRecord] = useState("");
  const [secondRecord, setSecondRecord] = useState("");
  const [thirdRecord, setThirdRecord] = useState("");
  const [error, setError] = useState("");

  function handleNext(record) {
    if (activeStep === 0) {
      setIsProvider(record);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1) {
      setFirstRecord(record);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 2) {
      setSecondRecord(record);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    console.log("record ", record);
  }

  function handleBack() {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }

  async function handleSubmit(record) {
    setThirdRecord(record);
    record.state = stateToAbbreviation(record.state);
    console.log("record ", record);
    const { visibility, ...restFirstRecord } = firstRecord;
    const signUpRecord = { ...restFirstRecord, ...secondRecord, ...record };
    console.log("sign up record ", signUpRecord);
    let response = "";
    let message = "";
    if (isProvider) {
      console.log("Provider");
      response = await RegisterProvider(signUpRecord);
      message = await response.text();
      console.log("Provider response: ", message);
    } else {
      console.log("Patient");
      response = await RegisterPatient(signUpRecord);
      message = await response.text();
      console.log("Patient response: ", message);
    }
    console.log("response ", response);
    if (response.ok === true) {
      setError("");
      handleEditContactInfoClick();
      navigate("/Login");
    } else {
      setError(message);
      handleEditContactInfoClick();
    }
  }
  const alertRef = useRef(); // This is the ref that will be passed to the CustomAlert component
  const handleEditContactInfoClick = () => {
    alertRef.current.showAlert(); // This is how you call the showAlert function from the CustomAlert component
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={myStyle}>
        <CustomAlert
          ref={alertRef} // This is how you pass the ref to the CustomAlert component
          title={error ? "Error" : "Success"}
          type={error ? "error" : "success"}
          content={error ? error : "Form Submitted."}
          duration={2000}
          ancorVertical="top"
          ancorHorizontal="center"
        />
        <CustomCard title="Sign Up" maxWidth="550px">
          {/* <IconButton  sx={{position: 'sticky' , right:'20%', top:'10%'}}>
            <CloseIcon />
          </IconButton> */}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            classes={{ root: classes.stepper }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  sx={{ display: "flex", flexDirection: "column" }}
                  classes={{
                    root:
                      activeStep === index ? classes.active : classes.inactive,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && <ZeroStep handleNext={handleNext} />}
          {activeStep === 1 && (
            <FirstStep
              handleNext={handleNext}
              handleBack={handleBack}
              isProvider={isProvider}
              record={firstRecord}
            />
          )}
          {activeStep === 2 && (
            <SecondStep
              handleNext={handleNext}
              handleBack={handleBack}
              record={secondRecord}
            />
          )}
          {activeStep === 3 && (
            <ThirdStep
              handleSubmit={handleSubmit}
              handleBack={handleBack}
              record={thirdRecord}
            />
          )}
        </CustomCard>
      </div>
    </ThemeProvider>
  );
}

export default SignUp;
