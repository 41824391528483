import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

function PhotosGridCard({ imageURLs }) {
    const urls = imageURLs.slice(0, 12);

    return (
        <Card className="mt-8">
            <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Photos
                </Typography>
                <Grid container spacing={2}>
                    {urls.map((url, index) => (
                        <Grid item xs={4} sm={3} key={index}>
                            <img 
                                src={url} 
                                alt={`Image ${index + 1}`} 
                                style=
                                {{ 
                                    width: "120px", 
                                    height: "120px", 
                                    borderRadius: "6px"
                                }} 
                            />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
}

export default PhotosGridCard;