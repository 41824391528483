import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Pagination,
} from "@mui/material";
import RenderContentComponent from "./RenderContentComponent";
import TextField from "@mui/material/TextField";
import PaginationComponent from "./PaginationComponent";
import { StyledTableCellHeader, StyledTableCellBody } from "./StyleComponent";
const RenderGrid = ({
  contents,
  captions,
  sortBy,
  setSortBy,
  sortDirection,
  handleSort,
  showPagination,
  currentPage,
  itemsPerPage,
  totalPages,
  handlePageChange,
  paginationColor,
  hoverColor,
  hiddenFields,
  autoDetectType,
  types,
  showSearchBar,
  IconColor,
  viewOrEdit,
  editableFields,
  requiredFields,
  sortedContents,
  setSortedContents,
  fieldErrors,
  setFieldErrors,
  separateDirtyFlag,
  setSeparateDirtyFlag,
}) => {
  //search vars
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const term = event.target.value.trim();
    setSearchTerm(term);
  };

  // // Sorting the grid
  // let sortedContents = [...contents];
  if (sortBy) {
    sortedContents.sort((curr, next) => {
      const currValue = curr[sortBy];
      const nextValue = next[sortBy];
      if (currValue < nextValue) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (currValue > nextValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  // Function to check if a key should be hidden
  // const isHiddenField = (key) => hiddenFields.includes(key);
  const isHiddenField = (key) => {
    if (hiddenFields && hiddenFields.includes) {
      return hiddenFields.includes(key);
    }
    return false;
  };

  const shouldDisplayAsterisk = (key) =>
    requiredFields?.includes(key) && !isHiddenField(key);

  //function to show the contents of the grid
  const renderGridContent = (row, index) => {
    return [
      <TableRow key={index}>
        {Object.entries(row).map(([key, value]) =>
          !isHiddenField(key) && key !== "externalIdForSorting" ? (
            <StyledTableCellBody key={key}>
              {viewOrEdit === 1 &&
              (editableFields ? editableFields.includes(key) : 1) ? (
                <RenderContentComponent
                  key={key}
                  keyy={key}
                  value={value}
                  types={types !== undefined ? types : []}
                  autoDetectType={autoDetectType}
                  searchTerm={searchTerm}
                  viewOrEdit={viewOrEdit}
                  row={row}
                  setSortBy={setSortBy}
                  sortedContents={sortedContents}
                  setSortedContents={setSortedContents}
                  isCard={false}
                  fieldErrors={fieldErrors}
                  setFieldErrors={setFieldErrors}
                  separateDirtyFlag={separateDirtyFlag}
                  setSeparateDirtyFlag={setSeparateDirtyFlag}
                  requiredFields={requiredFields}
                />
              ) : viewOrEdit === 0 ? (
                <RenderContentComponent
                  key={key}
                  keyy={key}
                  value={value}
                  types={types !== undefined ? types : []}
                  autoDetectType={autoDetectType}
                  searchTerm={searchTerm}
                  viewOrEdit={viewOrEdit}
                  row={row}
                  setSortBy={setSortBy}
                  sortedContents={sortedContents}
                  setSortedContents={setSortedContents}
                  isCard={false}
                  fieldErrors={fieldErrors}
                  setFieldErrors={setFieldErrors}
                  separateDirtyFlag={separateDirtyFlag}
                  setSeparateDirtyFlag={setSeparateDirtyFlag}
                />
              ) : (
                value
              )}
            </StyledTableCellBody>
          ) : null
        )}
      </TableRow>,
    ];
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {showSearchBar && (
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
            sx={{ marginBottom: "0.5rem" }}
          />
        )}
        <TableContainer component={Paper}>
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                {Object.keys(contents[0]).map((key) =>
                  !isHiddenField(key) ? (
                    <StyledTableCellHeader
                      key={key}
                      onClick={() => handleSort(key)}
                    >
                      {captions && captions[key] ? captions[key] : key}
                      {shouldDisplayAsterisk(key) && (
                        <span>*</span>
                      )}
                    </StyledTableCellHeader>
                  ) : null
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {showPagination
                ? sortedContents
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((row, index) => renderGridContent(row, index))
                : sortedContents.map((row, index) =>
                    renderGridContent(row, index)
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination && (
          <Box mt={2} display="flex" justifyContent="center">
            <PaginationComponent
              onPageChange={handlePageChange}
              totalCount={contents.length}
              currentPage={currentPage}
              pageSize={itemsPerPage}
              selectedColor={paginationColor}
              hoverColor={hoverColor}
              IconColor={IconColor}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default RenderGrid;
