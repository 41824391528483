import React, { useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import { Favorite, ChatBubble, Share } from '@mui/icons-material';

import generateFakeComments from '../../data/generateFakeComments';

function Comment({ content, author, level = 0, comments }) {
  const [liked, setLiked] = useState(false);
  const [hiddenComments, setHiddenComments] = useState([]);

  const handleLike = () => {
    setLiked(!liked);
  };

  const handleHide = (id) => {
    setHiddenComments((prevState) =>
      prevState.includes(id)
        ? prevState.filter((hiddenId) => hiddenId !== id)
        : [...prevState, id]
    );
  };

  const renderCommentTree = (comments) => {
    if (comments.length === 0) {
      return null;
    }

    return comments.map((comment) => (
      <div key={comment.id}>
        <div
          style={{
            marginLeft: `${(comment.level-1) * 20}px`,
            borderLeft: comment.level > 1 ? '1px solid #ccc' : 'none',
            paddingLeft: '10px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <CardHeader
            avatar={
              <Avatar src={comment.profileImage}>
                {comment.author[0]}
              </Avatar>
            }
            title={comment.author}
            subheader="5 minutes ago"
            onClick={() => handleHide(comment.id)}
            sx={{
              cursor: 'pointer',
              paddingTop: hiddenComments.includes(comment.id) ? '0px' : '',
              paddingBottom: hiddenComments.includes(comment.id) ? '0px' : '',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          />
          {!hiddenComments.includes(comment.id) && (
            <>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {comment.content}
                </Typography>
                <div>
                  <IconButton size="small" onClick={handleLike}>
                    <Favorite color="disabled" />
                  </IconButton>
                  {(comment.children && comment.children.length > 0) && (
                    <IconButton
                      size="small"
                      onClick={() => handleHide(comment.id)}
                    >
                      <ChatBubble color="disabled" />
                    </IconButton>
                  )}
                  <IconButton size="small">
                    <Share color="disabled" />
                  </IconButton>
                </div>
              </CardContent>
              {renderCommentTree(comment.children)}
            </>
          )}
        </div>
      </div>
    ));
  };

  return (
    <Card sx={{ marginTop: '1rem' }}>
      <CardContent>{renderCommentTree(comments)}</CardContent>
    </Card>
  );
}

export default Comment;
