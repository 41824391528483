import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Bio({bio}) {
  return (
    <div>
      {
        bio? (
          <Card>
          <CardContent>
            <Box>
              <Typography variant="h6" component="h2" gutterBottom>
                Bio
              </Typography>
              <Typography variant="body1" component="p">
              {bio}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        ) : (
          <div>
          </div>
        )
      }
    </div>

  );
}

export default Bio;
