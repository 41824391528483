import React from "react";
import { Link } from "react-router-dom";

import { Typography, Box } from "@mui/material";
import { Share } from "@mui/icons-material";
import ProviderIcon from "../../../assets/providerIcon.png";

const ConnectionCardProfileInfo = ({ connection, disableLinks = false }) => {
  const renderLink = (content, to) => {
    //return disableLinks ? content : <Link to={to}>{content}</Link>;
    return disableLinks ? (
      content
    ) : (
      <Link to={to} state={{ userID: connection.memberID }}>
        {content}
      </Link>
    );
  };

  return (
    <>
      {renderLink(
        <img
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            objectFit: "cover",
            marginLeft: "16px",
            maxWidth: "300px",
          }}
          src={connection.profilePicture}
          alt={connection.username}
        />,
        `/Profile/`
      )}
      <Box sx={{ marginLeft: "24px", flexGrow: 1 }}>
        {renderLink(
          <Typography
            component="a"
            href={connection.profileLink}
            sx={{ textDecoration: "none", color: "inherit" }}
          >
            {connection.firstName + " " + connection.lastName}
          </Typography>,
          `/Profile/`
        )}

        {renderLink(
          <Typography sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
            {connection.username}
          </Typography>,
          `/Profile/`
        )}
        {renderLink(
          <Typography sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
            {connection.startDate !== null && connection.startDate !== undefined
              ? connection.startDate.substring(0, 10)
              : ""}
          </Typography>,
          `/Profile/`
        )}
        {connection.sharedRecords &&
          renderLink(
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: "text.secondary",
                fontSize: "0.875rem",
              }}
            >
              Sharing Records with User
              <Share sx={{ marginRight: "4px", fontSize: "1rem" }} />
            </Typography>,
            `/Profile/`
            // `/profile/${connection.memberID}`
          )}
      </Box>
      {connection.memberID.at(0) === "2" && (
        <img
          src={ProviderIcon}
          alt="Provider Icon"
          style={{ width: "1.5rem", height: "1.5rem" }}
        />
      )}
    </>
  );
};

export default ConnectionCardProfileInfo;
