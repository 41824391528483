import React from 'react';
import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import theme from '../Theme';
import { ThemeProvider } from "@mui/material/styles";

function CustomMessageBox({ open, onClose, icon, question, onConfirm, title }) {

    const handleSubmit = () => {
        onConfirm();
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };


    return (
        <ThemeProvider theme={theme}>
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                width="70%"
                open={open}
                onClose={onClose}
                className=""
            >
                <DialogTitle style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}>
                    <div style={{textAlign: 'center'}}>
                        <Typography
                            variant="h5"
                            style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
                        >
                            {title}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {icon}
                        <p>{question}</p>
                    </div>
                </DialogTitle>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button onClick={handleCancel} sx={{ fontSize: '1rem', width: '500px' }}>
                        No
                    </Button>
                    <Button onClick={handleSubmit} sx={{
                        fontSize: '1rem',
                        width: '500px',
                        '&:hover': {
                            backgroundColor: '#ff0000', // set the background color to red
                        },
                    }} variant="contained">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default CustomMessageBox;