import React, { useState } from "react";
import { TextField, Button, MenuItem, Box } from "@mui/material";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { CloudUpload } from "@material-ui/icons";


//Things you need to use this component

//Make sure the user uploaded a file

// function validations() {
//     let error = {};
    
//     if (uploadedFiles.length === 0) {
//       error.requiredFile = "File is required";
//     }

//     setError(error);
//     console.log("error ", error);
//     console.log("ret ", Object.keys(error).length === 0);
//     return Object.keys(error).length === 0;
//   }

// Submit the File

// const formData = new FormData();
// for (var i = 0; i < uploadedFiles.length; i++) {
//   formData.append("files", uploadedFiles[i]);
// }
// formData.append("patientDocument", JSON.stringify(DocumentRecord));
// console.log("form File: ", formData);
// const response = await PostDocuments(formData);



// uploading again doesn't reset upload files
const excludedFileNames = [ "mp3", "lock", "CON", "PRN", "AUX", "NUL"];
const excludedPrefixes = ["COM", "LPT", "_vti_", "~$"];

function FileUploadComponent({
  uploadedFiles,
  setUploadedFiles,
}) {

  // changeable constraints
  const [state, setState] = React.useState({
    maxSize: 50000000, //50 MB
    maxNumber: 1,
  });

  const [error, setError] = useState("");

  function handleFileUpload(files) {
    setError("");
    const existingFileNames = uploadedFiles.map((file) =>
      file.name.toLowerCase()
    );
    const validFiles = Array.from(files).filter((file) => {
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.split(".").pop();
      if (
        excludedFileNames.includes(fileExtension) ||
        excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
      ) {
        setError(`The File "${file.name}" Type Is Not Valid`);
      } else if (file.size > state.maxSize) {
        setError(`The File "${file.name}" Size Is Too Large`);
      } else if (existingFileNames.includes(fileName)) {
        setError(`The File "${file.name}" Has Already Been Uploaded`);
      } else {
        return (
          !excludedFileNames.includes(fileExtension) &&
          !excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
        );
      }
    });
    if (validFiles.length + uploadedFiles.length > state.maxNumber) {
      setError(`You can only upload up to "${state.maxNumber}" files`);
    } else {
      setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  }
  const handleDeleteFile = (fileToDelete) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles.filter((file) => file !== fileToDelete);
    });
  };

  function handleDrop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileUpload(files);
  }

  function handleFileInputChange(e) {
    const files = e.target.files;
    handleFileUpload(files);
  }
  function byteToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }
  function validations() {
    let error = {};

    if (uploadedFiles.length === 0) {
      error.requiredFile = "File is required";
    }

    setError(error);
    console.log("error ", error);
    console.log("ret ", Object.keys(error).length === 0);
    return Object.keys(error).length === 0;
  }


  return (
    <>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        multiple
      />
      <div
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={handleDrop}
        style={{
          border: `2px dashed ${error ? "red" : "gray"}`,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "auto",
          width: "100%",
          margin: "auto",
          marginBottom: "20px",
          backgroundColor: "#f1f1f1",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <p style={{ margin: "0", fontSize: "18px" }}>
          Drag and drop files here
        </p>
        <p style={{ margin: "0", fontSize: "14px" }}>OR</p>

        <Button
          variant="contained"
          color={uploadedFiles.length === state.maxNumber ? "error" : "primary"}
          component="label"
          htmlFor="fileInput"
          size="small"
          startIcon={<CloudUpload />}
          style={{ width: "200px", height: "40px", marginTop: "10px" }}
        >
          Browse
        </Button>
        <Box>
          {error && (
            <p
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                color: "red",
              }}
            >
              {error}
            </p>
          )}
          {uploadedFiles.map((file) => (
            <Card
              key={file.name}
              style={{
                height: "auto",
                width: "auto",
                margin: "5px",
                display: "inline-block",
              }}
            >
              {file.type.startsWith("image/") ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <button
                    onClick={() => handleDeleteFile(file)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <ClearIcon />
                  </button>
                  <CardMedia
                    component="img"
                    image={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{ padding: 0, margin: 0 }}
                  />
                </div>
              ) : (
                <CardContent>
                  <p>
                    <InsertDriveFileIcon />
                    {file.name}
                    <button
                      onClick={() => handleDeleteFile(file)}
                      style={{
                        marginLeft: "auto",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </p>
                  <Typography
                    color="textSecondary"
                    style={{ marginLeft: "25px" }}
                  >
                    {byteToSize(file.size)}
                  </Typography>
                </CardContent>
              )}
            </Card>
          ))}
        </Box>
      </div>
    </>
  );
}
export default FileUploadComponent;
