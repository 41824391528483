import { Box, Typography, ThemeProvider, Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import theme from "../Theme";
import SearchBar from "../components/SearchBar";
import { GetUsersByName, GetAllMyConnections } from "../helper/APIHelper";
import ConnectionCard from "../components/profileComponents/connectionComponents/ConnectionCard";
import ConnectionPagination from "../components/profileComponents/connectionComponents/ConnectionPagination";
import { useAuth } from "../components/AuthProvider";
import AdBannerList from "../components/AdBannerList";
import Pharma from "../assets/Pharma.jpg";
import fitbitImage from "../assets/fitbitad.jpg";
import claritin from "../assets/claritin.png";

const adsList = [
  {
    image: Pharma,
    link: "https://www.example.com/ad1",
    tooltip: "Sample Ad 1",
  },
  {
    image: fitbitImage,
    link: "https://www.example.com/ad2",
    tooltip: "Sample Ad 2",
  },
  {
    image: claritin,
    link: "https://www.example.com/ad3",
    tooltip: "Sample Ad 3",
  },
];

function SearchUsers() {
  const [users, setUsers] = useState([]);
  // Loading state - used for loading wheel
  const [loading, setLoading] = useState(true);
  // Pagination Vars
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 12;
  let auth = useAuth();
  //prefix for local storage items for this component
  const localStoragePrefix = "Connections_";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //Initialized connection data
  useEffect(() => {
    GetConnectionData(false);
    // Clear local storage when the component is unmounted
    return () => {
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key.startsWith(localStoragePrefix)) {
          localStorage.removeItem(key);
        }
      }
    };
  }, []);

  console.log("Users ", users);

  // const renderSearchConnections = (connections, type) =>
  //   connections
  //     .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
  //     .map((connection, index) => (
  //       <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
  //         <ConnectionCard connection={connection} type={type} />
  //       </Grid>
  //     ));
  const renderSearchConnections = (connections, type) => {
    if (connections && connections.length > 0) {
      return connections
        .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
        .map(
          (connection, index) =>
            connection.memberID && (
              <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
                <ConnectionCard connection={connection} type={type} />
              </Grid>
            )
        );
    }

    return null; // or render a fallback UI, e.g., a message indicating no connections found
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function IsConnectionDataInStorage() {
    let result = localStorage.getItem(
      `${localStoragePrefix}connectedConections`
    );
    return result;
  }

  const GetConnectionData = async (refreshFlag = false) => {
    setLoading(true);
    let connectedConections;
    if (refreshFlag === false && IsConnectionDataInStorage()) {
      // If data is in local storage, parse and assign it to connectedConections
      connectedConections = JSON.parse(
        localStorage.getItem(`${localStoragePrefix}connectedConections`)
      );
    } else {
      // If data is not in local storage, fetch connected connections data using the API
      const response = await GetAllMyConnections();
      connectedConections = response.connected;
      // Store the fetched connected connections data in local storage
      localStorage.setItem(
        `${localStoragePrefix}connectedConections`,
        JSON.stringify(connectedConections)
      );

      setUsers(connectedConections);

      // Turn off the loading wheel
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="md"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          sx={{
            flexGrow: 1,
            padding: "1rem",
            paddingTop: 0,
            marginRight: "2rem",
            bgcolor: theme.palette.white.main,
          }}
        >
          <SearchBar
            placeholder="Find Users"
            searchFunction={GetUsersByName}
            size="small"
            setResult={setUsers}
          ></SearchBar>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <div className="spinner"></div>
            </Box>
          ) : (
            users !== null && renderSearchConnections(users, "connections")
          )}
          {/* {renderUsers()} */}
          {users !== null && (
            <Box mt={2} display="flex" justifyContent="center">
              <ConnectionPagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                filteredDataLength={users.length}
                itemsPerPage={ITEMS_PER_PAGE}
              />
            </Box>
          )}
        </Box>
        <AdBannerList sx={{ paddingLeft: "2rem" }} adsList={adsList} />
      </Container>
    </ThemeProvider>
  );
}
export default SearchUsers;
