import React, { useEffect, useState } from "react";
import "./Dental.css";
import ChooseShape from "./ChooseShape";
function SingleTooth({ toothNumber, transform, points, isPath }) {
  const [open, setOpen] = React.useState(false);

  function onClose() {
    setOpen(false);
  }

  const [center, setCenter] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (isPath) {
      getPathCenter();
    } else {
      calculateCenter();
    }
  }, []);

  function getPathCenter() {
    const path = document.getElementById(`Tooth${Number(toothNumber)}`);
    const bbox = path.getBBox();
    const centerX = bbox.x + bbox.width / 2;
    const centerY = bbox.y + bbox.height / 2;
    setCenter({ x: centerX, y: centerY });
  }
  function calculateCenter() {
    const pointsArray = points.split(" ");

    const xValues = pointsArray.map((point) => parseFloat(point.split(",")[0]));
    const yValues = pointsArray.map((point) => parseFloat(point.split(",")[1]));
    let sumX = 0;
    let countX = 0;
    for (let i = 0; i < xValues.length; i++) {
      if (!isNaN(xValues[i])) {
        sumX += parseFloat(xValues[i]);
        countX++;
      }
    }
    const avgX = sumX / countX;

    let sumY = 0;
    let countY = 0;
    for (let i = 0; i < yValues.length; i++) {
      if (!isNaN(yValues[i])) {
        sumY += parseFloat(yValues[i]);
        countY++;
      }
    }
    const avgY = sumY / countY;

    setCenter({ x: avgX, y: avgY });
  }

  const [selectedShapes, setSelectedShapes] = useState(["circle"]);

  function drawShapes() {
    return selectedShapes.map((shape) => {
      switch (shape) {
        case "circle":
          return (
            <circle
              key="circle"
              cx={center.x}
              cy={center.y}
              r="10"
              stroke="black"
              strokeWidth="2"
              fill="none"
            />
          );
        case "cross":
          return (
            <g key="cross" transform="translate(50,50)">
              <line
                x1="-20"
                y1="-20"
                x2="20"
                y2="20"
                strokeWidth="4"
                stroke="red"
              />
              <line
                x1="-20"
                y1="20"
                x2="20"
                y2="-20"
                strokeWidth="4"
                stroke="red"
              />
            </g>
          );
        case "plus":
          return (
            <g key="plus" transform="translate(50,50)">
              <rect x="-20" y="-2" width="40" height="4" fill="green" />
              <rect x="-2" y="-20" width="4" height="40" fill="green" />
            </g>
          );
        case "square":
          return (
            <rect
              key="square"
              x="30"
              y="30"
              width="40"
              height="40"
              fill="purple"
            />
          );
        default:
          return null;
      }
    });
  }

  function handleClick(event) {
    setOpen(true);
  }

  return (
    <>
      <g id="toothLabels">
        <text
          id={`lbl${Number(toothNumber)}`}
          transform={transform}
          fontFamily="'Avenir-Heavy'"
          fontSize="21px"
        >
          {toothNumber}
        </text>
      </g>
      <g id="Spots">
        {isPath ? (
            <>
          <path
            id={`Tooth${Number(toothNumber)}`}
            onClick={handleClick}
            fill="#FFFFFF"
            data-key={toothNumber}
            d={points}
          />
          {drawShapes()}
            </>
        ) : (
          <g>
            <polygon
              id={`Tooth${Number(toothNumber)}`}
              onClick={handleClick}
              fill="#FFFFFF"
              data-key={toothNumber}
              points={points}
            />
            {drawShapes()}
          </g>
        )}
      </g>
      {open && (
        <ChooseShape
          open={open}
          onClose={onClose}
          selectedShapes={selectedShapes}
          setSelectedShapes={setSelectedShapes}
        />
      )}
    </>
  );
}

export default SingleTooth;
