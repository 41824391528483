import { ThemeProvider, createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0e6d73",
      // light: "#42adb3",
      light: "#83B1B3",
    },
    secondary: {
      main: "#008c1d",
      light:"#53AF65"
    },
    background: {
      default: "#f1f1f1",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
      light: "#212325",
    },
    gray: {
      main: "#BBBBBB",
      light: "#D0CECE"
    },
    darkGray: {
      main: "#4d4d4d",
    },
    lightGray: {
      main: "#f1f1f1",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#ffffff",
          opacity: '0.8',
        },
      },
    },
  },
});

export default theme;