import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import MedicationIcon from "@mui/icons-material/Medication";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import MasksIcon from "@mui/icons-material/Masks";

import {
  ArchiveRecord,
  UnarchiveRecord,
} from "../../../services/HealthServiceHelper";

import React, { useState, useEffect } from "react";
import {
  Grid,
  Checkbox,
  Typography,
  Card,
  Button,
  CardActions,
  IconButton,
} from "@mui/material";

import MedicationPopupForm from "../../FormComponents/MedicationPopupForm";
import AllergyPopupForm from "../../FormComponents/AllergyPopupForm";
import HistoryPopupForm from "../../FormComponents/HistoryPopupForm";
import DocumentPopupForm from "../../FormComponents/DocumentPopupForm";

import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ReplayIcon from '@mui/icons-material/Replay';

import FileImage from "../../../assets/file.jpg";
import PdfImage from "../../../assets/pdf_icon_2.png";
import Tooltip from "@mui/material/Tooltip";

import theme from "../../../Theme.js";
import { ThemeProvider } from "@mui/material/styles";

const localStoragePrefix = "RecordViewer_";
const archiveLocalStoragePrefix = "ArchivedRecordViewer_";

function RecordViewerCard({
  documentTitle,
  row,
  recordType,
  onRecordSelection,
  isSelected,
  shareMode,
  tabIndex,
  setData,
  setArchivedData,
}) {
  const link = row.link;
  // un select record if share mode is turned off
  useEffect(() => {
    if (!shareMode && isSelected) {
      onRecordSelection(false);
    }
  }, [shareMode, isSelected, onRecordSelection]);

  const handleClick = () => {
    if (link.endsWith(".pdf")) {
      window.open(link, "_blank");
    } else if (/\.(jpe?g|png|gif)$/i.test(link)) {
      window.open(link, "_blank");
    } else {
      handleCardClick();
    }
  };

  const imageSrc = link
    ? /\.(jpe?g|png|gif)$/i.test(link)
      ? link
      : link.endsWith(".pdf")
      ? PdfImage
      : null
    : null;

  const getIcon = () => {
    const iconStyle = {
      color: isSelected ? "white" : theme.palette.primary.main,
      fontSize: shareMode ? "inherit" : "large",
      width: "100%",
      height: "100%",
    };

    switch (recordType) {
      case "Allergies":
        return <MasksIcon style={iconStyle} />;
      case "Family History":
        return <FamilyRestroomIcon style={iconStyle} />;
      case "Medications":
        return <MedicationIcon style={iconStyle} />;
      default:
        return <DescriptionIcon style={iconStyle} />;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return "Invalid date";
    }

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit',
    }).format(date);

    return formattedDate;
  };

  const DivField = (props) => {
    const { title, value, isStatus } = props;

    const statusStyle =
      isStatus && value
        ? {
            fontWeight: "bold",
            color:
              value.toLowerCase() === "active"
                ? theme.palette.success.main
                : value.toLowerCase() === "resolved"
                ? theme.palette.error.main
                : value.toLowerCase() === "not active" ||
                  value.toLowerCase() === "inactive"
                ? theme.palette.warning.main
                : "",
          }
        : {};

    const textColor = isSelected ? "white" : theme.palette.primary.main;

    return (
      <div>
        <ThemeProvider theme={theme}>
          <Typography variant="body1" component="span">
            <span style={{ fontWeight: "bold", color: textColor }}>
              {title}:
            </span>
            <span
              style={{
                marginLeft: "4px",
                ...statusStyle,
                color: isSelected ? "white" : statusStyle.color,
              }}
            >
              {value}
            </span>
          </Typography>
        </ThemeProvider>
      </div>
    );
  };

  const displayFields = (row) => {
    const getField = (title, value, isStatus) => {
      return <DivField title={title} value={value} isStatus={isStatus} />;
    };

    const getFirstField = (fields) => {
      return fields.map((field, index) => {
        if (index === 0)
          return getField(field.title, field.value, field.isStatus);
        return null;
      });
    };

    switch (recordType) {
      case "Allergies":
        const allergyFields = [
          { title: "Allergen", value: row.allergen },
          { title: "Allergy Type", value: row.allergyType },
          { title: "Reaction", value: row.reaction },
          { title: "Onset", value: formatDate(row.onset) },
          { title: "Status", value: row.status, isStatus: true },
        ];
        return shareMode
          ? getFirstField(allergyFields)
          : allergyFields.map((field) =>
              getField(field.title, field.value, field.isStatus)
            );

      case "Family History":
        const familyHistoryFields = [
          { title: "Relation", value: row.relation },
          { title: "History Type", value: row.historyType },
          { title: "Description", value: row.description },
          { title: "Onset", value: formatDate(row.onset) },
          { title: "Age at Onset", value: row.ageAtOnset },
        ];
        return shareMode
          ? getFirstField(familyHistoryFields)
          : familyHistoryFields.map((field) =>
              getField(field.title, field.value, field.isStatus)
            );

      case "Medications":
        const method = `${row.dosage} ${row.units}/${row.frequency} ${row.route}`;
        const medicationFields = [
          { title: "Medication", value: row.medication },
          { title: "Method", value: method },
          // { title: "Begin Date", value: formatDate(row.beginDate) },
          // { title: "End Date", value: formatDate(row.endDate) },
          {
            title: "On Set",
            value: `${formatDate(row.beginDate)} - ${
              formatDate(row.endDate) <= "January 1, 1900"
                ? "Current"
                : formatDate(row.endDate)
            }`,
          },
          { title: "Status", value: row.medStatus, isStatus: true },
        ];
        return shareMode
          ? getFirstField(medicationFields)
          : medicationFields.map((field) =>
              getField(field.title, field.value, field.isStatus)
            );

      default:
        const defaultFields = [
          { title: "Title", value: row.description },
          { title: "Created Date", value: formatDate(row.fromDate) },
          { title: "Description", value: row.explanation },
          { title: "Source", value: row.sourceName },
          { title: "Source Type", value: row.sourceType },
        ];
        return shareMode
          ? getFirstField(defaultFields)
          : defaultFields.map((field) =>
              getField(field.title, field.value, field.isStatus)
            );
    }
  };

  const [showMed, setShowMed] = React.useState(false);
  const [showAllergy, setShowAllergy] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(false);
  const [showDocument, setShowDocument] = React.useState(false);
  function handleCardClick() {
    if (recordType === "Medications") {
      setShowMed(true);
    } else if (recordType === "Allergies") {
      setShowAllergy(true);
    } else if (recordType === "Family History") {
      setShowHistory(true);
    } else {
      setShowDocument(true);
    }
  }

  function handleClosePopup() {
    setShowMed(false);
    setShowAllergy(false);
    setShowHistory(false);
    setShowDocument(false);
  }

  function addRecordToCache(prefix) {
    const localStorageKey = `${prefix}${recordType}`;
    const existingItems =
      JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const newItem = row;
    const updatedItems = [...existingItems, newItem];
    localStorage.setItem(localStorageKey, JSON.stringify(updatedItems));
  }
  function removeRecordFromCache(prefix) {
    const localStorageKey = `${prefix}${recordType}`;
    const existingItems =
      JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const recordIndex = existingItems.findIndex(
      (item) => item.encDetID === row.encDetID
    );
    if (recordIndex !== -1) {
      existingItems.splice(recordIndex, 1);
      localStorage.setItem(localStorageKey, JSON.stringify(existingItems));
    } else {
      console.log("not found");
    }
  }
  function reSetRecordData() {
    const localStorageKey = `${localStoragePrefix}${recordType}`;
    setData(JSON.parse(localStorage.getItem(localStorageKey)));
    const archivedLocalStorageKey = `${archiveLocalStoragePrefix}${recordType}`;
    setArchivedData(JSON.parse(localStorage.getItem(archivedLocalStorageKey)));
  }
  async function handleArchiveClick(row) {
    const requestBody = {
      recordIDs: [String(row.encDetID)],
    };
    const response = await ArchiveRecord(requestBody);
    if (response.ok === true) {
      addRecordToCache(archiveLocalStoragePrefix);
      removeRecordFromCache(localStoragePrefix);
      reSetRecordData();
    }
  }
  async function handleUnarchiveClick(row) {
    const requestBody = {
      recordIDs: [String(row.encDetID)],
    };
    const response = await UnarchiveRecord(requestBody);
    if (response.ok === true) {
      addRecordToCache(localStoragePrefix);
      removeRecordFromCache(archiveLocalStoragePrefix);
      reSetRecordData();
    }
  }
  return (
    <Card
      style={{
        marginBottom: "16px",
        backgroundColor: isSelected ? theme.palette.primary.main : "",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={shareMode ? 1 : 2}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={(e) => {
              onRecordSelection(e.target.checked);
            }}
            style={{ color: isSelected ? "white" : "" }}
            sx={{ display: shareMode ? "" : "none" }}
          />
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Preview"
              onClick={handleClick}
              style={{
                width: "80%",
                height: "80%",
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          ) : (
            <div
              style={{
                width: "80%",
                height: "80%",
              }}
              onClick={handleCardClick}
            >
              {getIcon()}
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={8}
          style={{ padding: "16px", maxWidth: "50%" }}
          onClick={handleCardClick}
        >
          {displayFields(row)}
        </Grid>
        <Grid
          xs={4}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {tabIndex === 0 ? (
            // <Button
            //   onClick={() => handleArchiveClick(row)}
            //   sx={{
            //     fontSize: "1rem",
            //     width: "7rem",
            //     height: "100%",
            //     backgroundColor: theme.palette.gray.main,
            //     "&:hover": {
            //       backgroundColor: theme.palette.gray.light,
            //     },
            //   }}
            //   variant="contained"
            // >
            //   {/* <ArchiveIcon/> */}
            //   Archive
            // </Button>
            <Tooltip title="Archive this record">
              <IconButton
                size="large"
                sx={{ marginRight: "1rem" }}
                onClick={() => handleArchiveClick(row)}
              >
                <ArchiveIcon sx={{width:"2.5rem", height:"2.5rem"}}/>
              </IconButton>
            </Tooltip>
          ) : tabIndex === 1 ? (
            // <Button
            //   onClick={() => handleUnarchiveClick(row)}
            //   sx={{
            //     fontSize: "1rem",
            //     width: "7rem",
            //     height: "100%",
            //     backgroundColor: theme.palette.secondary.main,
            //     "&:hover": {
            //       backgroundColor: theme.palette.secondary.light,
            //     },
            //   }}
            //   variant="contained"
            // >
            //   Restore
            // </Button>
            <Tooltip title="Restore this record">
            <IconButton
              size="large"
              sx={{ marginRight: "1rem" }}
              onClick={() => handleUnarchiveClick(row)}
            >
              {/* <ReplayIcon sx={{width:"2.5rem", height:"2.5rem"}}color="secondary" /> */}
              <UnarchiveIcon sx={{width:"2.5rem", height:"2.5rem"}} color="secondary"/>
            </IconButton>
          </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
        <>
          {showMed && (
            <MedicationPopupForm
              open={showMed}
              onClose={handleClosePopup}
              enableEditing={false}
              medicationInitialValues={row}
              showSubmit={false}
            />
          )}
          {showAllergy && (
            <AllergyPopupForm
              open={showAllergy}
              onClose={handleClosePopup}
              enableEditing={false}
              allergyInitialValues={row}
              showSubmit={false}
            />
          )}
          {showHistory && (
            <HistoryPopupForm
              open={showHistory}
              onClose={handleClosePopup}
              enableEditing={false}
              historyInitialValues={row}
              showSubmit={false}
            />
          )}
          {showDocument && (
            <DocumentPopupForm
              formTitle={documentTitle}
              open={showDocument}
              onClose={handleClosePopup}
              enableEditing={false}
              documentInitialValues={row}
              showSubmit={false}
            />
          )}
        </>
      </Grid>
    </Card>
  );
}

export default RecordViewerCard;
