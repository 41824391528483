import React, { useState, useRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Card,
  Badge,
  Box,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../Theme";

function ClickableList({ title, records, onSelect, Icon }) {
  //For selecting a list item
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onSelect(index);
  };
  if (records === undefined || records === null) {
    return null;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  sx={{
                    mr: 1,
                  }}
                />
                {title}
              </Box>
            </Typography>
            <List component="nav">
              {records.map((record, index) => (
                <div key={index}>
                  <ListItem
                    button
                    onClick={(event) => handleListItemClick(event, index)}
                    sx={{
                      backgroundColor:
                        selectedIndex === index ? "gray.main" : "inherit",
                      color:
                        selectedIndex === index ? "common.white" : "inherit",
                      "&:hover": {
                        backgroundColor:
                          selectedIndex === index
                            ? "gray.main"
                            : "lightGray.main",
                        color:
                          selectedIndex === index ? "common.white" : "inherit",
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center">
                          {record}
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < records.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
}
export default ClickableList;
