import React from "react";
import TopArch from "./TopArch";
import BottomArch from "./BottomArch";

function FullArch() {
  return (
    <div className="tooth-chart">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 450 700"
        enableBackground="new 0 0 450 700"
        xmlSpace="preserve"
      >
        <TopArch />
        <BottomArch />
      </svg>
    </div>
  );
}
export default FullArch;
