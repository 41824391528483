import React, { useState } from "react";
import theme from "../../Theme";
import { ThemeProvider, Button, Link } from "@mui/material";
import { CloudUpload } from "@material-ui/icons";
import ClearIcon from "@mui/icons-material/Clear";
import patient from "../../assets/patient.png";
import provider from "../../assets/provider.png";
import { CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ZeroStep({ handleNext }) {
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    isProvider: false,
  });

  function handleChange(event) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  }

  function handlePatientClick() {
    handleNext(false);
  }
  function handleProviderClick() {
    handleNext(true);
  }
  function handleLogin(event) {
    event.preventDefault();
    navigate("/Login");
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginRight: "30px",
          marginLeft: "30px",
        }}
      >
        <Button
          sx={{ fontSize: "1rem", width: "200px", marginRight: "10px" }}
          onClick={handlePatientClick}
          variant="outlined"
        >
          <img
            src={patient}
            alt="Patient Icon"
            style={{ width: "24px", marginRight: "8px" }}
          />
          Patient
        </Button>
        <Button
          sx={{ fontSize: "1rem", width: "200px" }}
          variant="contained"
          onClick={handleProviderClick}
        >
          <img
            src={provider}
            alt="Provider Icon"
            style={{ width: "24px", marginRight: "8px" }}
          />
          Provider
        </Button>
      </div>
      <Link
        onClick={handleLogin}
        className="text-base"
        href=""
        underline="hover"
        sx={{ fontSize: "1rem", lineHeight: "1.5rem" }} // adjust this value as needed
      >
        Already have an account with us? Log In
      </Link>
    </ThemeProvider>
  );
}
export default ZeroStep;
