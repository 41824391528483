import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Typography, Box } from "@mui/material";

function PatientInfoBanner({ PatientInfo }) {
  const renderLink = (content, to) => {
    return <Link to={to}>{content}</Link>;
  };

  const getCreationDuration = (createdDate) => {
    const duration = moment(createdDate).fromNow(); // Calculate duration using moment.js
    return duration;
  };

  return (
    <div>
      {renderLink(
        <img
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            objectFit: "cover",
            marginLeft: "16px",
            maxWidth: "300px",
          }}
          src={PatientInfo.profilePicture}
          alt={PatientInfo.username}
        />,
        `/profile/${PatientInfo.memberID}`
      )}
      <Box sx={{ justifyContent: "center" }}>
        <Typography
          component="div"
          sx={{ textDecoration: "none", color: "inherit" , fontSize:"1.2rem" }}
        >
          {PatientInfo.firstName + " " + PatientInfo.lastName}
        </Typography>
        <Typography sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
          Username: {PatientInfo.username}
        </Typography>
        <Typography sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
          Created: {PatientInfo.createdDate.split("T").shift()} ({getCreationDuration(PatientInfo.createdDate)})
        </Typography>
      </Box>
    </div>
  );
}

export default PatientInfoBanner;
