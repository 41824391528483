import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Switch,
} from "@mui/material";
import CustomPopUpForm from "../../CustomPopUpForm";

const FilterDialog = ({
  open,
  onClose,
  onSubmit,
  onCancel,
  statusFilter,
  setStatusFilter,
  roleFilter,
  setRoleFilter,
  sortBy,
  setSortBy,
  sortAscending,
  setSortAscending,
  encounterFilter,
}) => {
  const [tempFilters, setTempFilters] = useState({
    status: statusFilter ? statusFilter : "",
    role: roleFilter ? roleFilter : "",
    sortBy: sortBy,
    sortAscending: sortAscending,
  });

  const handleCancel = () => {
    setTempFilters({
      status: "",
      role: "",
      sortBy: "startDate",
      sortAscending: true,
    });
    onCancel();
  };

  const handleFilterChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setTempFilters({
      ...tempFilters,
      [name]: value,
    });
    console.log("name ", name);
    console.log("value ", value);
  };

  const handleSwitchChange = (event) => {
    setTempFilters({ ...tempFilters, sortAscending: event.target.checked });
  };

  const handleApplyFilters = () => {
    setStatusFilter(tempFilters.status);
    setRoleFilter(tempFilters.role);
    setSortBy(tempFilters.sortBy);
    setSortAscending(tempFilters.sortAscending);
    if(encounterFilter){
      onSubmit(
        tempFilters.status,
        // tempFilters.role,
        tempFilters.sortBy,
        tempFilters.sortAscending
      );
    }
    else{
      onSubmit(
        // tempFilters.status,
        tempFilters.role,
        tempFilters.sortBy,
        tempFilters.sortAscending
      );
    }
  };

  return (
    <CustomPopUpForm
      open={open}
      onClose={onClose}
      title="Filter Connections"
      onSubmit={handleApplyFilters}
      onCancel={handleCancel}
      showSubmit={true}
    >
      {encounterFilter ? (
        <FormControl fullWidth size="small">
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            name="status"
            value={tempFilters.status}
            onChange={handleFilterChange}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Connected">Connected</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Received">Received</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth size="small">
          <InputLabel>Role</InputLabel>
          <Select
            label="Role"
            name="role"
            value={tempFilters.role}
            onChange={handleFilterChange}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="provider">Provider</MenuItem>
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth size="small">
        <InputLabel>Sort By</InputLabel>
        <Select
          label="Sort By"
          name="sortBy"
          value={tempFilters.sortBy}
          onChange={handleFilterChange}
        >
          <MenuItem value="startDate">Date</MenuItem>
          <MenuItem value="firstName">First Name</MenuItem>
          <MenuItem value="lastName">Last Name</MenuItem>
          <MenuItem value="username">Username</MenuItem>
        </Select>
      </FormControl>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>Sort Ascending</Typography>
        <Switch
          checked={tempFilters.sortAscending}
          onChange={handleSwitchChange}
        />
      </Box>
    </CustomPopUpForm>
  );
};

export default FilterDialog;
